import type { ReactElement } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'
import type { RouteProps } from 'react-router-dom'
import { Redirect, Route } from 'react-router-dom'
import { LocalStorage } from '../../services/LocalStorage'
import { WebRoutes } from './WebRoutes'

interface DefaultProps extends RouteProps {
  externalOnly: boolean
}

interface Props extends Partial<DefaultProps> {
  pageComponent: ReactElement
  pageTitle: string
}

const defaultProps: DefaultProps = {
  externalOnly: false,
}

const UnprotectedRoute = ({
  pageComponent,
  pageTitle,
  externalOnly,
  ...rest
}: Props) => {
  const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })
  const token = sessionLocalStorage.get()
  if (externalOnly && token) {
    return <Redirect to={WebRoutes.root} />
  }
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <React.Fragment>
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            {React.cloneElement(pageComponent, { ...matchProps })}
          </React.Fragment>
        )
      }}
    />
  )
}

UnprotectedRoute.defaultProps = defaultProps

export default UnprotectedRoute
