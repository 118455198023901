import type { ReactElement } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'
import type { RouteProps } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { LocalStorage } from '../../services/LocalStorage'

interface DefaultProps extends RouteProps {
  internalTitle: string
  externalTitle: string
}

interface Props extends Partial<DefaultProps> {
  internalComponent: ReactElement
  externalComponent: ReactElement
}

const DualRoute = ({
  internalComponent,
  externalComponent,
  internalTitle,
  externalTitle,
  ...rest
}: Props) => {
  const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })
  const token = sessionLocalStorage.get()

  let title = externalTitle
  let component = externalComponent
  if (token) {
    title = internalTitle
    component = internalComponent
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <React.Fragment>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {React.cloneElement(component, { ...matchProps })}
          </React.Fragment>
        )
      }}
    />
  )
}

export default DualRoute
