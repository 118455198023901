import type { HTMLChakraProps, ThemingProps } from '@chakra-ui/react'
import { chakra, forwardRef, useStyleConfig } from '@chakra-ui/react'
import React from 'react'

export interface DisplayTextProps
  extends HTMLChakraProps<'p'>,
    Omit<ThemingProps<'DisplayText'>, 'variant'> {}

export const DisplayText = forwardRef<DisplayTextProps, 'p'>((props, ref) => (
  // @ts-expect-error
  <chakra.p __css={useStyleConfig('DisplayText', props)} ref={ref} {...props} />
))
