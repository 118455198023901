import React, { useRef, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Hero,
  HeroCtaButton,
  HeroCtaContainer,
  HeroLimitedSpacesAvailable,
  HeroSubTitle,
  HeroTitle,
} from 'components/landing/hero'
import {
  ProductImage,
  ProductInfo,
  ProductInfoDescription,
  ProductInfoTags,
  ProductInfoText,
} from 'components/landing/product-info'
import { ExternalLayout } from 'components/layout'
import hero from '../../images/landing/hero.png'
import { Testimonials } from 'components/landing/testimonials'
import { Testimonial } from 'components/landing/testimonial'
import TheRealTyroneAvatar from '../../images/landing/jordans/The_RealTyrone-avatar.png'
import intellektualpropertyAvatar from '../../images/landing/jordans/intellektualproperty-avatar.png'
import jAvatar from '../../images/landing/jordans/j-avatar.png'
import NiyAvatar from '../../images/landing/jordans/Niy-avatar.png'
import AboutDropImage from '../../images/landing/jordans/drop-img.png'
import CommunitySupportImage from '../../images/landing/jordans/community-support-img.png'
import { CtaBlock, CtaBlockText } from 'components/landing/cta-block'
import { Feature, FeatureText } from 'components/landing/feature'
import { LandingCtaButton } from 'components/landing/landing-cta-button'
import { SupportSection } from 'components/landing/support-section'
import { Block } from 'components/landing/block'
import { Pricing } from '../../components/landing/pricing'
import { BlogBlock } from 'components/landing/blog-block'
import Img02 from '../../images/landing/Img02.png'
import Img04 from '../../images/landing/Img04.png'
import { useToasts } from 'react-toast-notifications'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Locale } from 'gql/local'
import { UserSubscriptionProductType } from 'packlets/generated'
import { Analytics } from 'services/Analytics'
import { GetPricingDataDocument } from 'gql/checkout.generated'
import { useFeatureFlags } from 'providers/remote-config'
import { SubscriptionCheckout } from 'features/subscription-checkout'
import { StripeClient } from 'services/StripeClient/StripeClient'
import { Box } from '@chakra-ui/layout'
import { BsDiscord } from 'react-icons/bs'
import { Chat } from 'phosphor-react'

const stripePromise = StripeClient.loadStripe()

interface Props {
  coupon?: string
  errorDescription?: string
}

const LandingJordans: React.FC<Props> = ({ coupon, errorDescription }) => {
  const toast = useToasts()
  const locale = useReactiveVar(Locale)
  const subscriptionSectionRef = useRef<HTMLDivElement>(null)

  const onSignupClick = () => {
    Analytics.openSignup({
      coupon,
      userSubscriptionProductType: UserSubscriptionProductType.Sneakers,
    })

    scrollToSubscriptionSection()
  }

  const scrollToSubscriptionSection = (behavior: ScrollBehavior = 'smooth') => {
    if (subscriptionSectionRef) {
      subscriptionSectionRef.current?.scrollIntoView({ behavior })
    }
  }

  const { isAbTestingPriceActive: isAbTestingPriceActiveValue } =
    useFeatureFlags()

  const isAbTestingPriceActive =
    isAbTestingPriceActiveValue !== undefined
      ? isAbTestingPriceActiveValue?.asBoolean() === true
      : null
  const pricingCode = 'ENY'

  const { data: pricingData, loading: loadingPricingData } = useQuery(
    GetPricingDataDocument,
    {
      variables: {
        input: {
          currency: locale.currency,
          promoCode: coupon,
          pricingCode,
        },
      },
    }
  )

  useEffect(() => {
    if (errorDescription) {
      toast.removeAllToasts()
      toast.addToast(errorDescription, {
        appearance: 'error',
        autoDismiss: false,
      })
    }
    // eslint-disable-next-line
  }, [errorDescription])

  return (
    <ExternalLayout onSignup={onSignupClick}>
      <Hero>
        <HeroTitle>Never miss a pair of Jordans again</HeroTitle>
        <HeroSubTitle>
          We cover all of the latest Jordan drops and we provide information on
          how to get shoes for retail prices. So you never have to pay resale
          prices again for your sneakers.
        </HeroSubTitle>
        <HeroCtaContainer>
          <HeroCtaButton onClick={onSignupClick}>Sign Up</HeroCtaButton>

          <HeroLimitedSpacesAvailable>
            Limited spaces available
          </HeroLimitedSpacesAvailable>

          {coupon &&
            pricingData?.getPricingData.monthly.discountMetadata?.amount && (
              <Box
                fontFamily="JetBrains Mono"
                fontWeight="bold"
                fontSize="18px"
                textAlign="center"
                color="#636363"
                lineHeight="150%"
                mt="1"
              >
                Use Code: {coupon} for{' '}
                {pricingData?.getPricingData.monthly.discountMetadata?.amount}%
                off
              </Box>
            )}
        </HeroCtaContainer>
      </Hero>

      <ProductInfo>
        <ProductImage src={hero} alt="Secured hero" />
        <ProductInfoDescription>
          <ProductInfoText>
            <p>
              Secured is a members-only premium service that was created to help
              sneakerheads purchase the latest sneakers and streetwear directly
              from brands before they sell out.
            </p>
            <p>
              The Secured Hub gives you access to the latest release
              information, leaked images, early links to secure raffles, sneaker
              bots and other tools for your favourite sneakers. Plus you will
              have access to the Secured Discord community where you can talk
              sneaker with other members. This is your key to stop paying resale
              prices for Jordans.
            </p>
          </ProductInfoText>
          <ProductInfoTags />
        </ProductInfoDescription>
      </ProductInfo>

      <Testimonials>
        <Testimonial
          description="I just scored 2 pairs of the Jordan 1 a ma maniere for me and my son thanks to @cking he was awesome even went on a zoom call the morning of to help me out. He was awesome and glad to be part of the secured crew."
          imageUrl={TheRealTyroneAvatar}
          username="The_RealTyrone"
        />
        <Testimonial
          description="I just finished my first flip. I joined to cop for my personal collection, was tired of missing out on Jordans."
          imageUrl={intellektualpropertyAvatar}
          username="intellektualproperty"
        />
        <Testimonial
          description="I just won a raffle with nohble for the lightning 4’ssss"
          imageUrl={NiyAvatar}
          username="Niy"
        />
        <Testimonial
          description="Its been a bit over a month since I've joined and started my reselling journey, and let me tell you, no regrets. I went from buying brick pairs and not knowing what to do at all, to copping Union 4s."
          imageUrl={jAvatar}
          username=".j"
        />
      </Testimonials>

      <CtaBlock>
        <CtaBlockText>
          Get access to all the tools you need to successfully cop the latest
          Jordans at retail price with a Secured membership.
        </CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>

      <Feature
        title="Everything you need to know about a drop"
        imageUrl={AboutDropImage}
        imageLocation="right"
      >
        <FeatureText>
          Secured groups together every online store releasing the next pair of
          Jordans you're looking for, so come drop day you're prepared and know
          exactly where to go. You can filter by location and use our direct
          links once a drop goes live to go straight to the product, saving you
          valuable seconds.
        </FeatureText>
        <FeatureText>
          Being part of the Secured community also means you'll be alerted for
          any restock happening over past releases.
        </FeatureText>
      </Feature>

      <Feature
        title="Enter all raffles from one place"
        imageUrl={Img02}
        imageLocation="left"
      >
        <FeatureText>
          On top of gathering all the links and information you need, Secured
          finds all the raffles that you can enter for the next Jordans release,
          and lets you enter them all from one place. You also have the option
          to filter by location so you're not hit with any crazy shipping costs
          when you win your pair.
        </FeatureText>
      </Feature>

      <Feature title="Free Bots" imageUrl={Img04} imageLocation="right">
        <FeatureText>
          With your Secured membership you get unlimited access to our checkout
          bot which will help you checkout faster on more than 240 sites.
        </FeatureText>
        <FeatureText>
          Our experienced team also owns and operates some of the best bots
          available on the market. If you're interested in getting the next
          Jordans without the stress of purchasing, renting or setting up a bot,
          we're here to help. Just let us know which release you'd like us to
          bot and we'll take it from there.{' '}
        </FeatureText>
      </Feature>

      <Feature
        title="Community & Support"
        imageUrl={CommunitySupportImage}
        imageLocation="left"
      >
        <FeatureText>
          We provide 1-on-1 support through instant chat or zoom calls to make
          sure you understand all that Secured has to offer and make the best
          out of your experience with us.
        </FeatureText>
        <FeatureText>
          You're in the best place to learn with our written guides, support
          staff, as well as the community of sneakerheads you will be joining by
          signing up to Secured. Share and learn together to quickly become a
          Jordans expert!
        </FeatureText>
      </Feature>

      <CtaBlock>
        <CtaBlockText>
          Join Secured and learn how to use sneaker bots and purchase your
          sneaker of choice directly from brands
        </CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>

      <SupportSection>
        <Block
          title="Community Access"
          subTitle="Connect with fellow sneakerheads"
          description="Secured is a diverse community of enthusiasts which means regardless of your experience level, beginner or expert, there's always people that you can chat with and learn from."
          icon={BsDiscord}
        />
        <Block
          title="1 on 1 support"
          subTitle="You’re in the best place to learn"
          description="with our easy to understand written guides and around the clock support staff, you can quickly become an expert in getting sneakers for retail prices."
          icon={Chat}
        />
      </SupportSection>
      <Pricing
        ref={subscriptionSectionRef}
        coupon={coupon}
        amount={pricingData?.getPricingData.monthly.discountMetadata?.amount}
      >
        <Elements stripe={stripePromise}>
          <SubscriptionCheckout
            mt={12}
            coupon={coupon}
            userSubscriptionProductType={UserSubscriptionProductType.Sneakers}
            monthly={pricingData?.getPricingData.monthly}
            annual={pricingData?.getPricingData.annual}
            currency={locale.currency}
            pricingCode={pricingCode}
            isLoading={isAbTestingPriceActive === null || loadingPricingData}
          />
        </Elements>
      </Pricing>
      <BlogBlock>
        Visit the Secured Blog to learn more about sneakers and how Secured can
        help you secure more pairs.
      </BlogBlock>
    </ExternalLayout>
  )
}

export default LandingJordans
