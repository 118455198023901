import { ApolloError } from '@apollo/client'

// TODO: Get enum values from the backend
export enum ErrorCode {
  // Generic Errors
  generic = 'generic',
  notFound = 'notFound',
  unauthorized = 'unauthorized',

  // User
  userNotFound = 'userNotFound',
  userAlreadyExists = 'userAlreadyExists',
  userEmailAlreadyTaken = 'userEmailAlreadyTaken',
  userIsMemberAlready = 'userIsMemberAlready',
  userNotMember = 'userNotMember',
  userMissingSubscription = 'userMissingSubscription',
  userCancellationNotFound = 'userCancellationNotFound',
  userCannotBeUpdatedToAnnual = 'userCannotBeUpdatedToAnnual',
  userDiscordIdMissing = 'userDiscordIdMissing',

  // Pending User
  pendingUserNotFound = 'pendingUserNotFound',
  pendingUserAlreadyExists = 'pendingUserAlreadyExists',
  pendingUserToUserMismatch = 'pendingUserToUserMismatch',
  pendingUserHasNoActiveUser = 'pendingUserHasNoActiveUser',

  // Authorization
  clientIdMissing = 'clientIdMissing',
  clientVersionMissing = 'clientVersionMissing',
  discordAccessTokenMissing = 'discordAccessTokenMissing',

  // Drops
  dropNotFound = 'dropNotFound',
  dropAlreadyExists = 'dropAlreadyExists',

  // Raffles
  raffleNotFound = 'raffleNotFound',
  raffleAlreadyExists = 'raffleAlreadyExists',

  // Stores
  storeNotFound = 'storeNotFound',

  // Store groups
  storeGroupNotFound = 'storeGroupNotFound',

  // Products
  productNotFound = 'productNotFound',

  // Product Collections
  productCollectionNotFound = 'productCollectionNotFound',

  // Discord
  discordIdMismatch = 'discordIdMismatch',
  discordDetailsMissing = 'discordDetailsMissing',
  discordServerMissing = 'discordServerMissing',
  discordChannelIdMissing = 'discordChannelIdMissing',

  // Stripe
  stripeCustomerNotFound = 'stripeCustomerNotFound',
  stripeSubscriptionTypeOrCurrencyCodeInvalid = 'stripeSubscriptionTypeOrCurrencyCodeInvalid',
  stripeCustomerIdMissing = 'stripeCustomerIdMissing',
  stripeCustomerIsDeleted = 'stripeCustomerIsDeleted',

  // Events
  eventCannotBeCreated = 'eventCannotBeCreated',
}

export const errorMessages: { [key in ErrorCode]: string } = {
  // Generic
  generic: 'Something went wrong, please try again.',
  notFound: 'Requested resource could not be found on the server.',
  unauthorized: 'You are not authorized to access requested resource.',

  // User
  userNotFound: 'User not found',
  userAlreadyExists: 'User already exists.',
  userEmailAlreadyTaken: 'This email is already taken.',
  userIsMemberAlready:
    'Please connect a unique discord account that is not connected to another Secured account.',
  userNotMember: 'You are not a member of any Secured Discord servers.',
  userMissingSubscription: 'You do not have an active subscription.',
  userCancellationNotFound: 'Could not find specified user cancellation.',
  userCannotBeUpdatedToAnnual:
    'Could not upgrade user to annual subscription type.',
  userDiscordIdMissing: "User's Discord ID is missing.",

  // Pending User
  pendingUserNotFound: 'Pending user not found.',
  pendingUserAlreadyExists: 'Pending user already exists',
  pendingUserToUserMismatch: "Pending user's ID does not match user's ID.",
  pendingUserHasNoActiveUser: 'Pending user has no active user assigned.',

  // Authorization
  clientIdMissing: 'Please provide a valid Client ID.',
  clientVersionMissing: 'Please provide a valid Client Version.',
  discordAccessTokenMissing: 'Discord access token is missing.',

  // Drops
  dropNotFound: 'Drop not found.',
  dropAlreadyExists: 'Drop entry already exists.',

  // Raffles
  raffleNotFound: 'Raffle not found.',
  raffleAlreadyExists: 'Raffle entry already exists.',

  // Stores
  storeNotFound: 'Store not found.',

  // Store groups
  storeGroupNotFound: 'Store group not found.',

  // Products
  productNotFound: 'Product not found.',

  // Product Collections
  productCollectionNotFound: 'Product collection not found.',

  // Discord
  discordIdMismatch: 'Discord IDs do not match',
  discordDetailsMissing: 'Discord details are not set for the user.',
  discordServerMissing: 'Please provide a valid Secured Discord server.',
  discordChannelIdMissing: 'Discord DM channel ID is missing.',

  // Stripe
  stripeCustomerNotFound: 'Could not find matching Stripe customer.',
  stripeSubscriptionTypeOrCurrencyCodeInvalid:
    'Subscription type or currency code is invalid.',
  stripeCustomerIdMissing: "Could not find user's Stripe Customer ID.",
  stripeCustomerIsDeleted: 'Stripe Customer is deleted.',

  // Events
  eventCannotBeCreated: 'Could not create an event.',
}

export const GenericErrorMessage = 'Something went wrong, please try again.'

export const parseError = (error: ApolloError | Error): string => {
  if (error instanceof ApolloError) {
    const [gqlError] = error.graphQLErrors
    // @ts-expect-error
    const errorCode = gqlError.extensions?.code ?? gqlError?.code

    // Custom sent message is more important that message parsed from code
    if (gqlError.message.length) {
      return gqlError.message
    }

    if (errorCode && Object.values(ErrorCode).includes(errorCode)) {
      return errorMessages[errorCode as ErrorCode]
    }

    return GenericErrorMessage
  }

  if (error.message.length || error instanceof Error) {
    return error.message
  }

  return GenericErrorMessage
}
