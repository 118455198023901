import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import type { LayoutProps, SpaceProps } from '@chakra-ui/react'
import { Box, Flex, Heading } from '@chakra-ui/react'

interface Props extends SpaceProps, LayoutProps {
  title?: string
}

export const Testimonials: React.FC<Props> = ({ title, children, ...rest }) => {
  const [width, setWidth] = useState<number>(0)
  const [contentWidth, setContentWidth] = useState<number>(0)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [contentOffset, setContentOffset] = useState<number>(0)
  const [recalculate, setRecalculate] = useState<boolean>(false)

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const listContainerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const marginBetweenElements = 16

  const numberOfBackups = 3
  const backupWidth = width * (numberOfBackups - 1)

  const handleScroll = () => {
    if (listContainerRef.current && contentRef.current) {
      const scrollValue = listContainerRef.current.scrollLeft

      // If User scrolled too much to the left or right
      if (scrollValue >= backupWidth + width || scrollValue < backupWidth) {
        // Reset scroll to be in the middle again
        listContainerRef.current.scrollLeft =
          backupWidth + (scrollValue % width)
      }
    }
  }

  useLayoutEffect(() => {
    if (contentRef.current && listContainerRef.current && wrapperRef.current) {
      // Set content block width
      const numOfChildren = contentRef.current.children.length
      const childrenWidth =
        contentRef.current.children[0].clientWidth * numOfChildren
      const totalMarginBetweenChildren =
        (numOfChildren - 1) * marginBetweenElements
      const leftRightMargin = 2 * (marginBetweenElements / 2)

      setContentWidth(
        childrenWidth + totalMarginBetweenChildren + leftRightMargin
      )

      // Set content block height
      setContentHeight(contentRef.current.children[0].clientHeight)

      // Set content offset
      setContentOffset(
        (document.body.clientWidth - wrapperRef.current.clientWidth) / 2
      )
    }

    setRecalculate(false)
  }, [recalculate])

  useLayoutEffect(() => {
    if (listContainerRef.current && contentRef.current && contentWidth !== 0) {
      // Measure width of content
      setWidth(contentRef.current.offsetWidth)

      // Set container's scroll to be in the middle
      listContainerRef.current.scrollLeft =
        (listContainerRef.current.scrollWidth -
          listContainerRef.current.offsetWidth) /
        2
    }
  }, [contentWidth])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setRecalculate(true)
    })
  }, [])

  return (
    <Box as="section" mt="184px" {...rest}>
      <Heading fontSize={['3xl', '3xl', '4xl']} textAlign="center">
        {title || 'Testimonials'}
      </Heading>
      <Box
        mt={6}
        pos="relative"
        style={{
          height: `${contentHeight}px`,
          left: `-${contentOffset}px`,
        }}
        ref={wrapperRef}
      >
        <Flex
          sx={{
            flex: '0 0 100%',
            width: '100vw',
            overflowX: 'scroll',
            position: 'absolute',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
          ref={listContainerRef}
          onScroll={handleScroll}
        >
          {Array(numberOfBackups)
            .fill(0)
            .map((_v, i) => (
              <React.Fragment key={i}>{children}</React.Fragment>
            ))}

          <Box ref={contentRef} d="flex" flex={`0 0 ${contentWidth}px`}>
            {children}
          </Box>

          {Array(numberOfBackups)
            .fill(0)
            .map((_v, i) => (
              <React.Fragment key={i}>{children}</React.Fragment>
            ))}
        </Flex>
      </Box>
    </Box>
  )
}
