import type {
  ComponentWithAs,
  HTMLChakraProps,
  InputProps,
  ThemingProps,
  FormLabelProps,
  InputElementProps,
} from '@chakra-ui/react'
import {
  FormLabel,
  forwardRef,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { baseTypography } from '../theme/typography'
import { mode } from '@chakra-ui/theme-tools'

export interface FloatingLabelInputGroupProps
  extends HTMLChakraProps<'div'>,
    ThemingProps<'Input'> {
  isInvalid?: boolean
  isDisabled?: boolean
}

const floatingInputSizes = {
  lg: {
    label: {
      ...baseTypography.body,
      fontSize: 'lg',
      h: '1.125rem',
    },
    input: {
      pt: 7,
      pb: 3,
    },
    transformPlaceholderShown: 'translate(1rem, 1.25rem) scale(1)',
    transformPlaceholderNotShown: 'translate(1rem, 0.5rem) scale(0.75)',
  },

  md: {
    label: {
      ...baseTypography.body,
    },
    input: {
      pt: 5,
      pb: 2,
    },
    transformPlaceholderShown: 'translate(0.75rem, 1.0rem) scale(1)',
    transformPlaceholderNotShown: 'translate(0.75rem, 0.5rem) scale(0.75)',
  },

  sm: {
    label: {
      ...baseTypography.body,
      fontSize: 'sm',
    },
    input: {
      pt: 3,
      pb: 1,
    },
    transformPlaceholderShown: 'translate(0.5rem, 0.75rem) scale(1)',
    transformPlaceholderNotShown: 'translate(0.5rem, 0.25rem) scale(0.75)',
  },
}

function getErrorColor(props: Record<string, any>) {
  const { errorBorderColor: ec } = props
  return ec || mode('red.500', 'red.300')(props)
}

export const FloatingLabelInputGroup = forwardRef<
  FloatingLabelInputGroupProps,
  'div'
>(({ isInvalid, isDisabled, ...props }, ref) => {
  if (React.Children.count(props.children) < 2) {
    throw Error(
      'You should pass at least two children inside FloatingLabelInputGroup it being FormLabel and Input, plus optionally InputRightElement'
    )
  }

  let label: React.ReactNode
  let input: React.ReactNode
  let inputRightElement: React.ReactNode

  React.Children.forEach(props.children, (child: any) => {
    const childType = child.type?.id // These we hardcoded below. It will be preserved in production too.

    if (childType === 'FloatingLabelInput') {
      input = React.cloneElement(
        child,
        Object.assign({}, child.props, { isInvalid })
      )
    } else if (childType === 'FloatingLabel') {
      label = child
    } else if (childType === 'FloatingLabelInputRightElement') {
      inputRightElement = child
    } else if (process.env.NODE_ENV !== 'production') {
      throw Error(
        `You shall pass only FloatingLabelInput and FloatingLabel and FloatingLabelInputRightElement! Found: ${child.type?.displayName}`
      )
    }
  })

  if (props.size === 'xs') {
    throw Error('There is no xs sized floating input')
  }

  const size: typeof floatingInputSizes.md = useMemo(() => {
    // @ts-expect-error Annoying index type issue
    return floatingInputSizes[props.size ?? 'md'] ?? floatingInputSizes.md
  }, [props.size])

  // @ts-expect-error We've set these props already for sure
  const errorColor = getErrorColor(input.props)

  const sx = useMemo(() => {
    return {
      w: 'full',
      position: 'relative',
      '& label': {
        m: 0,
        position: 'absolute',
        transformOrigin: 'top left',
        ...size.label,
      },
      '& label, & input': {
        transition: 'all 0.2s',
        touchAction: 'manipulation',
        cursor: isDisabled ? 'not-allowed' : 'text',
        userSelect: isDisabled ? 'none' : undefined,
      },
      '& input': {
        pt: size.input.pt,
        pb: size.input.pb,
        appearance: 'none',
        cursor: isDisabled ? 'not-allowed' : 'text',
        userSelect: isDisabled ? 'none' : undefined,
      },
      '& input[aria-invalid=true]': {
        color: 'currentColor',
      },
      '& input:placeholder-shown + label': {
        cursor: 'text',
        maxWidth: '66.66%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transform: size.transformPlaceholderShown,
      },
      '& ::placeholder': { opacity: 0, transition: 'inherit' },
      '& input:focus::placeholder': { opacity: 1 },
      '& input:not(:placeholder-shown) + label, & input:focus + label': {
        transform: size.transformPlaceholderNotShown,
      },
      '& input[aria-invalid=true] + label': {
        color: errorColor,
      },
      ...props.sx,
    }
  }, [
    isDisabled,
    errorColor,
    props.sx,
    size.input.pb,
    size.input.pt,
    size.label,
    size.transformPlaceholderNotShown,
    size.transformPlaceholderShown,
  ])

  return (
    <InputGroup {...props} sx={sx} ref={ref}>
      {input}
      {label}
      {inputRightElement}
    </InputGroup>
  )
})

FloatingLabelInputGroup.displayName = 'FloatingLabelInputGroup'
FloatingLabelInputGroup.id = 'FloatingLabelInputGroup'

export const FloatingLabelInput = {
  ...Input,
  id: 'FloatingLabelInput',
} as ComponentWithAs<'input', InputProps>

export const FloatingLabel = {
  ...FormLabel,
  id: 'FloatingLabel',
} as ComponentWithAs<'label', FormLabelProps>

export const FloatingLabelInputRightElement = {
  ...InputRightElement,
  id: 'FloatingLabelInputRightElement',
} as ComponentWithAs<'div', InputElementProps>

if (process.env.NODE_ENV !== 'production') {
  FloatingLabelInput.displayName = 'FloatingLabelInput'
  FloatingLabel.displayName = 'FloatingLabel'
  FloatingLabelInputRightElement.displayName = 'FloatingLabelInputRightElement'
}
