import type { MouseEvent, FunctionComponent } from 'react'
import React from 'react'
import classNames from 'classnames'

import styles from './icon.module.scss'

export enum Icons {
  'activity' = 'activity',
  'alert-octagon' = 'alert-octagon',
  'bell' = 'bell',
  'book' = 'book',
  'calendar' = 'calendar',
  'check-square' = 'check-square',
  'check' = 'check',
  'chevron-down' = 'chevron-down',
  'chevron-left' = 'chevron-left',
  'chevron-right' = 'chevron-right',
  'chevron-up' = 'chevron-up',
  'chevrons-right' = 'chevrons-right',
  'cross' = 'cross',
  'discord' = 'discord',
  'edit' = 'edit',
  'external-link' = 'external-link',
  'eye-off' = 'eye-off',
  'eye' = 'eye',
  'home' = 'home',
  'info' = 'info',
  'loader' = 'loader',
  'log-out' = 'log-out',
  'mail' = 'mail',
  'map' = 'map',
  'menu' = 'menu',
  'newfile' = 'newfile',
  'package' = 'package',
  'phone' = 'phone',
  'play-circle' = 'play-circle',
  'plus-circle' = 'plus-circle',
  'plus' = 'plus',
  'refresh' = 'refresh',
  'search' = 'search',
  'settings' = 'settings',
  'shopping-bag' = 'shopping-bag',
  'star' = 'star',
  'trending-up' = 'trending-up',
  'upload' = 'upload',
  'user' = 'user',
  'chat' = 'chat',
  'check-circle' = 'check-circle',
  'instagram' = 'instagram',
  'phone1' = 'phone1',
  'twitter' = 'twitter',
  'group' = 'group',
}

interface DefaultProps {
  styleType: 'primary' | 'success'
  className: string
  fontSize: string
  onClick: (event: MouseEvent) => void
}

interface Props extends Partial<DefaultProps> {
  type: Icons
}

const defaultProps: DefaultProps = {
  styleType: 'primary',
  fontSize: '16px',
  onClick: () => {},
  className: '',
}

const Icon: FunctionComponent<Props> = ({
  onClick,
  type,
  styleType,
  className,
  fontSize,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      style={{ fontSize }}
      onClick={onClick}
      className={classNames(styles.icon, {
        [styles[`icon__${type}`]]: type,
        // @ts-expect-error
        [styles[`icon__${styleType}`]]: styleType,
        [className as string]: className,
      })}
    />
  )
}

Icon.defaultProps = defaultProps

export default Icon
