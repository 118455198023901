/**
 * Various CSS tokens that will be nice to reuse across the app
 */

document.documentElement.style.setProperty(
  '--internal-header-height',
  `calc(4rem - env(safe-area-inset-top))`
)
export const internalHeaderHeight = 'var(--internal-header-height)'
export const internalNavigationHeight = '3.5rem'

export const fullScreenHeight =
  'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))'
export const fullScreenWidth =
  'calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))'
export const internalAppContentHeight = `calc(${fullScreenHeight} + ${internalNavigationHeight} + ${internalHeaderHeight})`

export const sideNaviPadWidth = '93px'
export const sideNavDesktopWidth = '210px'
