import React, { useCallback, useEffect, useState } from 'react'
import type { UseDisclosureReturn } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react'
import { Center } from '@chakra-ui/react'
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ModalOrMobileMenu } from 'components/modal-or-mobile-menu'
import type { PurchasesProduct } from 'cordova-plugin-purchases/www/plugin'
import Purchases from 'cordova-plugin-purchases/www/plugin'
import { useHistory } from 'react-router'
import { WebRoutes } from 'containers/routes/WebRoutes'
import {
  Card,
  FloatingLabel,
  FloatingLabelInput,
  FloatingLabelInputGroup,
} from 'ui-kit'
import { AppConfig } from '../../services/AppConfig'
import { z } from 'zod'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { openInInternalBrowserOrRedirect } from '../../utils/app'

interface InAppPurchaseProps extends UseDisclosureReturn {}

const Email = z.object({
  email: z.string().email('Shall be valid e-mail.'),
})

type EmailSchema = z.infer<typeof Email>

export const InAppPurchase = ({ ...modalProps }: InAppPurchaseProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmailSchema>({
    resolver: zodResolver(Email),
    defaultValues: {
      email: '',
    },
  })
  const email = useWatch<EmailSchema>({
    control,
    name: 'email',
    defaultValue: '',
  })
  const emailDisclosure = useDisclosure()
  const onCloseEmailModal = emailDisclosure.onClose
  const [disableButtons, setDisableButtons] = useState(false)
  const [error, setError] = useState<string>()
  const history = useHistory()
  const [monthlySneakersProduct, setMonthlySneakersProduct] = useState<
    PurchasesProduct | undefined
  >()

  const [annualSneakersProduct, setAnnualSneakersProduct] = useState<
    PurchasesProduct | undefined
  >()

  const [allPurchasedProductIdentifiers, setAllPurchasedProductIdentifiers] =
    useState<string[] | null>(null)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    AppConfig.isNativePlatform &&
      Purchases.getOfferings((offerings) => {
        if (
          offerings.current !== null &&
          offerings.current.availablePackages.length !== 0
        ) {
          setMonthlySneakersProduct(offerings.current.monthly?.product)
          setAnnualSneakersProduct(offerings.current.annual?.product)
        }
      }, console.error)

    AppConfig.isNativePlatform &&
      Purchases.getPurchaserInfo((purchaserInfo) => {
        setAllPurchasedProductIdentifiers(purchaserInfo.activeSubscriptions)
      }, console.error)
  }, [])

  useEffect(() => {
    if (
      monthlySneakersProduct &&
      annualSneakersProduct &&
      allPurchasedProductIdentifiers?.length !== undefined
    ) {
      setLoading(false)
    }
  }, [
    monthlySneakersProduct,
    annualSneakersProduct,
    allPurchasedProductIdentifiers,
  ])

  const isSubscribedToMonthly = allPurchasedProductIdentifiers?.includes(
    monthlySneakersProduct?.identifier ?? ''
  )
  const isSubscribedToAnnual = allPurchasedProductIdentifiers?.includes(
    annualSneakersProduct?.identifier ?? ''
  )

  const handlePurchase = useCallback(
    (identifier: string) => {
      setDisableButtons(true)
      Purchases.purchaseProduct(
        identifier,
        ({ purchaserInfo }) => {
          const urlParams = new URLSearchParams({
            revenueCatId: purchaserInfo.originalAppUserId,
            email,
          })
          onCloseEmailModal()
          setLoading(false)
          history.push(
            `${WebRoutes.mobileCheckoutValidation}?${urlParams.toString()}`
          )
        },
        ({ error: anError, userCancelled }) => {
          if (userCancelled) {
            setDisableButtons(false)
            setLoading(false)
            return
          }
          setLoading(false)
          setDisableButtons(false)
          setError(anError.message)
          console.error(anError)
        },
        null,
        Purchases.PURCHASE_TYPE.SUBS
      )
    },
    [email, history, onCloseEmailModal]
  )
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>()

  return (
    <>
      <ModalOrMobileMenu
        title="Secured Membership"
        {...modalProps}
        size="2xl"
        primaryButtonProps={{ children: 'Cancel', onClick: modalProps.onClose }}
      >
        <Flex direction="column" gridGap={4}>
          {monthlySneakersProduct && !loading ? (
            <Card
              _disabled={{
                opacity: 0.3,
                cursor: 'none',
              }}
              minW="300px"
              w="full"
              p={4}
              as="button"
              disabled={disableButtons || isSubscribedToMonthly}
              onClick={() => {
                setSelectedProduct(monthlySneakersProduct.identifier)
                setDisableButtons(true)
                emailDisclosure.onOpen()
              }}
            >
              <Text variant="subheading" color="gray.600" w="full">
                {monthlySneakersProduct.title}
              </Text>
              <Heading size="xl" mt={2}>
                {monthlySneakersProduct.price.toFixed(2)}{' '}
                {monthlySneakersProduct.currency_code}
              </Heading>
              <Stack spacing={2} mt={4}>
                <Text>Sneaker & Streetwear bot</Text>
                <Text>Secured Discord Community Access</Text>
                <Text>Cancel anytime</Text>
              </Stack>
            </Card>
          ) : (
            <Skeleton minH="90px" w="full" />
          )}
          {annualSneakersProduct && !loading ? (
            <Card
              _disabled={{
                opacity: 0.3,
                cursor: 'none',
              }}
              as="button"
              minW="300px"
              w="full"
              p={4}
              disabled={disableButtons || isSubscribedToAnnual}
              onClick={() => {
                setSelectedProduct(annualSneakersProduct.identifier)
                setDisableButtons(true)
                emailDisclosure.onOpen()
              }}
            >
              <Text variant="subheading" color="gray.600" w="full">
                {annualSneakersProduct.title}
              </Text>
              <Heading size="xl" mt={2}>
                {annualSneakersProduct.price.toFixed(2)}{' '}
                {annualSneakersProduct.currency_code}
              </Heading>
              <Stack spacing={2} mt={4}>
                <Text>Sneaker & Streetwear bot</Text>
                <Text>Secured Discord Community Access</Text>
                <Text>Exclusive annual member perks</Text>
              </Stack>
            </Card>
          ) : (
            <Skeleton minH="90px" w="full" />
          )}
        </Flex>
        <Center mt={2}>
          <Link
            href="#"
            onClick={() =>
              openInInternalBrowserOrRedirect(
                'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
              )
            }
          >
            Terms of Use (EULA)
          </Link>
        </Center>
        <Text>{error}</Text>
      </ModalOrMobileMenu>
      <Modal
        closeOnOverlayClick={false}
        {...emailDisclosure}
        onClose={() => {
          emailDisclosure.onClose()
          setDisableButtons(false)
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          mx={2}
          as="form"
          onSubmit={handleSubmit(() => {
            setLoading(true)
            selectedProduct && handlePurchase(selectedProduct)
            Purchases.setEmail(email)
          })}
        >
          {loading ? (
            <Center h="200px">
              <Spinner size="lg" />
            </Center>
          ) : (
            <>
              <ModalHeader>Please enter your email</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FloatingLabelInputGroup
                  isInvalid={Boolean(errors.email?.message)}
                >
                  <FloatingLabel htmlFor="ipp-email">
                    {errors.email?.message ?? 'E-mail'}
                  </FloatingLabel>
                  <FloatingLabelInput
                    {...register('email')}
                    placeholder="Enter your e-mail"
                    id="ipp-email"
                  />
                </FloatingLabelInputGroup>
              </ModalBody>

              <ModalFooter>
                <Button type="submit" w="full">
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
