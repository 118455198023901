import { Button } from '../../components/button'
import { ExternalLayout } from '../../components/layout'
import React from 'react'
import styles from './sports-cards-login-success.module.scss'
import { Icon } from '../../components/icon'
import { Icons } from '../../components/icon/icon'
import { DISCORD_SERVER_URL_US } from '../../constants'

export const SportsCardsLoginSuccess = () => {
  return (
    <ExternalLayout headerActionsType="none" isFooterVisible={false}>
      <div className={styles['sports-cards-login-success']}>
        <div className={styles['sports-cards-login-success__header']}>
          <h1 className={styles['sports-cards-login-success__header__h1']}>
            Welcome to Secured
          </h1>
        </div>
        <div className={styles['sports-cards-login-success__discord-section']}>
          <p
            className={
              styles['sports-cards-login-success__discord-section__heading']
            }
          >
            Start investing with our members
          </p>
          <Button
            className={
              styles['sports-cards-login-success__discord-section__button']
            }
            styleType="fill"
            colour="primary"
            href={DISCORD_SERVER_URL_US}
            iconPosition="left"
            icon={
              <Icon
                type={Icons.discord}
                className={
                  styles[
                    'sports-cards-login-success__discord-section__button__icon'
                  ]
                }
              />
            }
          >
            Launch Secured
          </Button>
        </div>
      </div>
    </ExternalLayout>
  )
}
