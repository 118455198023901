import { useMutation } from '@apollo/client'
import { Box, Skeleton, Button, Text } from '@chakra-ui/react'
import { CreditCard } from 'phosphor-react'
import React from 'react'
import { STRIPE_BILLING_PORTAL_REDIRECT_URL } from '../../constants'
import { Analytics } from '../../services/Analytics'
import { openInInternalBrowserOrRedirect } from '../../utils/app'
import { CreateStripeBillingPortalDocument } from '../../gql/checkout.generated'

interface Props {
  loading: boolean
  userId?: string
}

const AccountMembership = ({ loading, userId }: Props) => {
  const [
    createStripeBillingPortal,
    { loading: loadingCreateStripeBillingPortal },
  ] = useMutation(CreateStripeBillingPortalDocument, {
    onCompleted: (data) => {
      openInInternalBrowserOrRedirect(data.createStripeBillingPortal.url)
    },
    onError: (e) => {
      console.error(e)
    },
  })

  return (
    <Box
      border={1}
      borderStyle="solid"
      borderColor="gray.300"
      borderRadius={12}
      p={6}
      my={6}
    >
      <CreditCard size="21.5px" />
      <Box mt={3}>
        <Text variant="title" pb={2}>
          Membership Details
        </Text>
        <Text variant="paragraph">
          Edit membership payment details or cancel auto renewal.
        </Text>
        <Box mt={4}>
          {loading && <Skeleton height={12} />}
          {!loading && (
            <Button
              colorScheme="gray"
              disabled={loadingCreateStripeBillingPortal}
              width="full"
              onClick={() => {
                Analytics.openEditMembershipRobin()

                createStripeBillingPortal({
                  variables: {
                    userId: userId ?? '',
                    input: { redirectUrl: STRIPE_BILLING_PORTAL_REDIRECT_URL },
                  },
                })
              }}
            >
              Edit Details
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default AccountMembership
