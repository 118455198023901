import type React from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { LocalStorage } from '../services/LocalStorage'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { AppConfig } from '../services/AppConfig'

export const useHorizontalDragScroll = (walk: number = 1) => {
  const ref = useRef<HTMLDivElement>(null)
  const isDownRef = useRef(false)
  const startX = useRef<number>(0)
  const scrollLeft = useRef<number>(0)

  const onMouseDown = useCallback<React.MouseEventHandler>((e) => {
    isDownRef.current = true
    if (ref.current) {
      startX.current = e.pageX - ref.current.offsetLeft
      scrollLeft.current = ref.current?.scrollLeft
    }
  }, [])

  const onMouseLeave = useCallback<React.MouseEventHandler>((e) => {
    isDownRef.current = false
  }, [])

  const onMouseUp = useCallback<React.MouseEventHandler>((e) => {
    isDownRef.current = false
  }, [])

  const onMouseMove = useCallback<React.MouseEventHandler>(
    (e) => {
      if (!isDownRef.current) return
      e.preventDefault()
      if (ref.current) {
        const x = e.pageX - ref.current.offsetLeft
        const aWalk = (x - startX.current) * walk // scroll-fast
        ref.current.scrollLeft = scrollLeft.current - aWalk
      }
    },
    [walk]
  )

  return useMemo(
    () => ({
      ref,
      onMouseDown,
      onMouseLeave,
      onMouseUp,
      onMouseMove,
    }),
    [onMouseDown, onMouseLeave, onMouseMove, onMouseUp]
  )
}

export const useToken = () => {
  const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })
  const token = sessionLocalStorage.get()
  return token
}

export const useRegisterPushNotifications = () => {
  useEffect(() => {
    if (
      Capacitor.isPluginAvailable('PushNotifications') &&
      AppConfig.isNativePlatform
    ) {
      PushNotifications.requestPermissions()
        .then((result) => {
          if (result.receive === 'granted') {
            void PushNotifications.register()
          } else {
            console.error(
              'No registration happened, reason is:',
              result.receive
            )
          }
          return result
        })
        .catch((it) => {
          console.error(it)
        })
    }
  }, [])
}
