import React from 'react'
import { LandingCtaButton } from '../landing-cta-button'
import type { BoxProps, ButtonProps } from '@chakra-ui/react'
import { Box, Center, Heading, useColorModeValue } from '@chakra-ui/react'
import { DisplayText } from '../../../ui-kit'

export const Hero: React.FC = ({ children }) => (
  <Center
    sx={{
      textAlign: 'center',
      marginTop: ['60px', '192px'],
      flexDirection: 'column',
      mb: [8, undefined],
    }}
  >
    {children}
  </Center>
)

export const HeroTitle: React.FC = ({ children }) => (
  <Heading
    as="h1"
    sx={{
      letterSpacing: '-0.03em',
      fontSize: ['32px', '32px', '67px'],
      marginBottom: 6,
    }}
  >
    {children}
  </Heading>
)

export const HeroSubTitle: React.FC = ({ children }) => (
  <DisplayText
    size="xl"
    sx={{
      fontWeight: 'medium',
      lineHeight: '140%',
      maxWidth: '632px',
      margin: '0',
      fontSize: ['lg', 'lg', 'xl'],
    }}
  >
    {children}
  </DisplayText>
)

export const HeroCtaContainer: React.FC = ({ children }) => (
  <Box
    sx={{
      marginTop: [8, 8, 10],
      marginBottom: 4,
      padding: 4,
      background: useColorModeValue('blackAlpha.200', 'whiteAlpha.300'),
      backdropFilter: 'blur(15px)',
      transform: 'translate3d(0, 0, 0)',
      borderRadius: 'base',
    }}
  >
    {children}
  </Box>
)

export const HeroLimitedSpacesAvailable: React.FC<BoxProps> = (props) => {
  return (
    <Box
      {...props}
      sx={{
        textAlign: 'center',
        color: useColorModeValue('gray.600', 'gray.400'),
        lineHeight: '150%',
        fontFamily: "'JetBrains Mono', monospace",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        ...props.sx,
      }}
    />
  )
}

export const HeroCoupon: React.FC = ({ children }) => {
  return (
    <Box
      fontFamily="JetBrains Mono"
      fontWeight="bold"
      fontSize="18px"
      textAlign="center"
      color="green.500"
      lineHeight="150%"
      mt="1"
    >
      {children}
    </Box>
  )
}

export const HeroCtaButton = (props: ButtonProps) => (
  <LandingCtaButton w={['100%', '100%', '360px']} mb={4} {...props} />
)
