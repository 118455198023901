import React from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Button, useColorModeValue, forwardRef } from '@chakra-ui/react'

export interface ChipProps
  extends Omit<ButtonProps, 'variant' | 'colorScheme'> {
  variant?: 'multi' | 'default'
  active: boolean
}

export const Chip = forwardRef<ChipProps, 'button'>(
  ({ active, children, variant = 'default', ...buttonProps }, ref) => {
    const activeBg = useColorModeValue('black', 'gray.200')

    const activeBorderColor = activeBg
    const inactiveBorderColor = useColorModeValue('gray.200', 'gray.800')

    const activeTextColor = useColorModeValue('white', 'gray.900')

    const variantProps =
      variant === 'default'
        ? ({
            variant: 'solid',
            colorScheme: 'gray',
          } as ButtonProps)
        : ({
            variant: 'outline',
          } as ButtonProps)

    return (
      <Button
        {...variantProps}
        ref={ref}
        size="sm"
        borderWidth={1}
        borderColor={inactiveBorderColor}
        aria-selected={active ? 'true' : 'false'}
        flex="0 0 auto"
        _selected={{
          bg: activeBg,
          borderColor: activeBorderColor,
          color: activeTextColor,
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    )
  }
)
