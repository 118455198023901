import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useCookies } from 'react-cookie'
import {
  PriceDescription,
  PricingCard,
  PricingCardFeatures,
  PricingCardHeader,
  PricingCardHeading,
  PricingCardMo,
  PricingCardPreDiscountPrice,
  PricingCardPrice,
  PricingCardSave,
} from '../../components/landing/pricing-card'
import { formatPriceCents } from '../../utils/utils'
import { Analytics } from '../../services/Analytics'
import { useMutation } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'
import type { CurrencyCode, PricingData } from 'packlets/generated'
import {
  UserSubscriptionProductType,
  UserSubscriptionType,
} from 'packlets/generated'
import { useToasts } from 'react-toast-notifications'
import {
  CURRENCY_SYMBOLS,
  STRIPE_CHECKOUT_CANCEL_URL,
  STRIPE_CHECKOUT_SUCCESS_URL,
} from '../../constants'
import { FacebookPixel } from '../../services/FacebookPixel'
import { LocalStorage } from '../../services/LocalStorage'
import SnapchatPixel from 'react-snapchat-pixel'
import { TikTokEventType, TikTokPixel } from '../../services/TikTokPixel'
import { RedditEventType, RedditPixel } from '../../services/RedditPixel'
import {
  GoogleTagManager,
  GoogleTagManagerEventType,
} from '../../services/GoogleTagManager'
import { CreateStripeCheckoutDocument } from '../../gql/checkout.generated'
import type { LayoutProps, SpaceProps } from '@chakra-ui/react'
import { Button, Center, Skeleton, useColorModeValue } from '@chakra-ui/react'

interface Props extends SpaceProps, LayoutProps {
  coupon?: string
  monthly?: PricingData
  annual?: PricingData
  currency: CurrencyCode
  userSubscriptionProductType: UserSubscriptionProductType
  pricingCode: string
  isLoading: boolean
}

const FEATURES_LIST = {
  [UserSubscriptionProductType.Sneakers]: {
    monthly: [
      'Sneaker & Streetwear bot',
      'Secured Discord Community Access',
      'Cancel anytime',
    ],
    annual: [
      'Sneaker & Streetwear bot',
      'Secured Discord Community Access',
      'Exclusive annual member perks',
    ],
  },
  [UserSubscriptionProductType.SportsCards]: {
    monthly: ['Sports cards bot', 'Secured Community Access', 'Cancel anytime'],
    annual: [
      'Sports cards bot',
      'Secured Community Access',
      'Exclusive annual member perks',
    ],
  },
  [UserSubscriptionProductType.Nft]: {
    monthly: [
      '7 Day Free Trial',
      'NFT Tools',
      'Secured Community Access',
      'Cancel anytime',
    ],
    annual: [
      '7 Day Free Trial',
      'NFT Tools',
      'Secured Community Access',
      'Exclusive annual member perks',
    ],
  },
  [UserSubscriptionProductType.Pokemon]: {
    monthly: ['Secured Community Access', 'Cancel anytime'],
    annual: ['Secured Community Access', 'Exclusive annual member perks'],
  },
  [UserSubscriptionProductType.Crypto]: {
    monthly: ['Crypto Alerts', 'Secured Community Access', 'Cancel anytime'],
    annual: [
      'Crypto Alerts',
      'Secured Community Access',
      'Exclusive annual member perks',
    ],
  },
}

export const SubscriptionCheckout: React.FC<Props> = ({
  coupon,
  userSubscriptionProductType,
  monthly,
  annual,
  currency,
  pricingCode,
  isLoading,
  ...rest
}) => {
  const stripe = useStripe()
  const toast = useToasts()
  const [cookies] = useCookies()

  const onError = (e: Error) => {
    console.error(e)

    toast.addToast('Something went wrong, please try again.', {
      appearance: 'error',
      autoDismiss: false,
    })
  }

  const [createStripeCheckout, { loading: isLoadingCheckout }] = useMutation(
    CreateStripeCheckoutDocument,
    {
      onCompleted: (data) => {
        const { sessionId } = data.createStripeCheckout

        stripe?.redirectToCheckout({ sessionId })
      },
      onError: (e) => onError(e),
    }
  )

  const onSignup = (subscriptionType: UserSubscriptionType) => {
    toast.removeAllToasts()
    if (!monthly || !annual) {
      return
    }

    const promotionCode =
      subscriptionType === UserSubscriptionType.Monthly ? coupon : undefined

    const successUrl = STRIPE_CHECKOUT_SUCCESS_URL
    let productType = ''
    switch (userSubscriptionProductType) {
      case UserSubscriptionProductType.Sneakers: {
        productType = 'sneakers'
        break
      }
      case UserSubscriptionProductType.SportsCards: {
        productType = 'sports-cards'
        break
      }
      case UserSubscriptionProductType.Nft: {
        productType = 'nft'
        break
      }
      case UserSubscriptionProductType.Pokemon: {
        productType = 'pokemon'
        break
      }
      case UserSubscriptionProductType.Crypto: {
        productType = 'crypto'
        break
      }
    }

    const cancelUrl = new URL(
      STRIPE_CHECKOUT_CANCEL_URL.replace('{product-type}', productType)
    )
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.forEach((value, key) => {
        if (key === 'error_description') {
          return
        }

        cancelUrl.searchParams.set(key, value)
      })
    }

    const fbEventId = uuidv4()

    const productPrice =
      subscriptionType === UserSubscriptionType.Monthly
        ? monthly.priceCents
        : annual.priceCents

    const productId =
      subscriptionType === UserSubscriptionType.Monthly
        ? monthly.productId
        : annual.productId

    const subscriptionLocalStorage = LocalStorage({ key: 'SUBSCRIPTION' })
    const subscriptionProductTypeLocalStorage = LocalStorage({
      key: 'SUBSCRIPTION_PRODUCT_TYPE',
    })

    const subscription = {
      subscriptionType,
      productPrice,
      productId,
      pricingCode,
      promoCode: coupon,
    }

    subscriptionLocalStorage.save(JSON.stringify(subscription))
    subscriptionProductTypeLocalStorage.save(userSubscriptionProductType)

    Analytics.initiateCheckout({
      userSubscriptionProductType,
      planType: subscriptionType,
      price: productPrice,
      currency,
      pricingCode,
      promoCode: coupon,
    })

    SnapchatPixel.track('ADD_CART', {
      currency,
      value: productPrice / 100,
      contentType: 'product',
      numItems: 1,
      contents: [
        {
          id: productId,
          quantity: 1,
        },
      ],
    })

    FacebookPixel.track({
      name: 'InitiateCheckout',
      eventId: fbEventId,
      customData: {
        currency,
        value: productPrice / 100,
        contentType: 'product',
        numItems: 1,
        contents: [
          {
            id: productId,
            quantity: 1,
          },
        ],
      },
    })

    TikTokPixel.track(TikTokEventType.StartCheckout, {
      content_id: productId,
      content_type: 'product',
      quantity: 1,
      currency,
      value: productPrice / 100,
    })

    RedditPixel.track(RedditEventType.AddToCart)

    GoogleTagManager.track(GoogleTagManagerEventType.InitiateCheckout, {
      currency,
      value: productPrice / 100,
    })

    createStripeCheckout({
      variables: {
        input: {
          pricingCode,
          userSubscriptionProductType,
          analyticsEvent: {
            eventId: fbEventId,
            eventSourceUrl: window.location.href,
            fbp: cookies._fbp,
            fbc: cookies._fbc,
          },
          subscriptionType,
          currencyCode: currency,
          promotionCode,
          cancelUrl: cancelUrl.toString(),
          successUrl,
        },
      },
    })
  }
  let monthlyPrice: string | undefined
  let monthlyPreDiscountPrice: string | null | undefined
  if (monthly) {
    monthlyPrice = monthly?.discountedPriceCents
      ? formatPriceCents(monthly.discountedPriceCents, monthly.currency)
      : formatPriceCents(monthly.priceCents, monthly.currency)
    monthlyPreDiscountPrice =
      coupon && monthly.discountedPriceCents
        ? formatPriceCents(monthly.priceCents, monthly.currency)
        : null
  }

  let annualPrice: string | undefined
  let annualDiscountAmount: number | null | undefined
  let annualPriceDescription: string | undefined
  let save: string | undefined
  if (annual) {
    annualDiscountAmount = annual?.discountedPriceCents
      ? (annual.priceCents - annual.discountedPriceCents) / 100
      : null
    annualPrice = annual.discountedPriceCents
      ? formatPriceCents(annual.discountedPriceCents / 12, annual.currency)
      : formatPriceCents(annual.priceCents / 12, annual.currency)
    annualPriceDescription = annual.discountedPriceCents
      ? `Billed annually at ${CURRENCY_SYMBOLS[annual.currency]}${
          annual.discountedPriceCents / 100
        } - Save ${
          CURRENCY_SYMBOLS[annual.currency]
        }${annualDiscountAmount} over 12 months.`
      : `Billed annually at ${CURRENCY_SYMBOLS[annual.currency]}${
          annual.priceCents / 100
        } - Save ${
          CURRENCY_SYMBOLS[annual.currency]
        }${annualDiscountAmount} over 12 months.`
    save = `Save ${CURRENCY_SYMBOLS[annual.currency]}${annualDiscountAmount}`
  }

  return (
    <Center flexDirection={['column', 'column', 'row']} {...rest}>
      <PricingCard
        sx={{
          maxWidth: '366px',
          width: '100%',
          borderRight: ['2px solid', '2px solid', 0],
          borderRightColor: useColorModeValue('gray.300', 'gray.700'),
          borderTopRightRadius: ['lg', 'lg', 0],
          borderBottomRightRadius: ['lg', 'lg', 0],
        }}
      >
        <PricingCardHeader>
          <PricingCardHeading>Monthly</PricingCardHeading>
        </PricingCardHeader>
        {!isLoading && monthlyPrice ? (
          <PricingCardPrice mt={2}>
            {monthlyPreDiscountPrice && (
              <PricingCardPreDiscountPrice>
                {monthlyPreDiscountPrice}
              </PricingCardPreDiscountPrice>
            )}
            {monthlyPrice}
            <PricingCardMo>/ mo</PricingCardMo>
          </PricingCardPrice>
        ) : (
          isLoading && (
            <PricingCardPrice mt={2}>
              <Skeleton display="inline-block" h="73px" w="100px" />
              <PricingCardMo>/ mo</PricingCardMo>
            </PricingCardPrice>
          )
        )}
        {!isLoading ? (
          <PriceDescription mt={2}>
            {!coupon
              ? 'Billed Monthly'
              : 'Billed monthly - Offer price for first month only. '}
          </PriceDescription>
        ) : (
          isLoading && <Skeleton h="12px" mt={2} w="full" />
        )}
        {!isLoading ? (
          <Button
            w="full"
            mt={7}
            size="lg"
            isDisabled={isLoadingCheckout}
            onClick={() => {
              Analytics.openBuyNow({
                coupon,
                plantype: 'Monthly',
                userSubscriptionProductType,
              })

              onSignup(UserSubscriptionType.Monthly)
            }}
          >
            {userSubscriptionProductType === UserSubscriptionProductType.Nft
              ? 'Start Free Trial'
              : 'Buy Now'}
          </Button>
        ) : (
          <Skeleton w="full" h="56px" mt={7} borderRadius="lg" />
        )}
        <PricingCardFeatures
          features={FEATURES_LIST[userSubscriptionProductType].monthly}
          mt={8}
        />
      </PricingCard>
      <PricingCard
        mt={[4, 4, 0]}
        withSavings={!!save}
        sx={{
          maxWidth: '366px',
          width: '100%',
        }}
      >
        <PricingCardHeader>
          <PricingCardHeading>Annual</PricingCardHeading>
          {save && <PricingCardSave>{save}</PricingCardSave>}
        </PricingCardHeader>
        {!isLoading && annualPrice ? (
          <PricingCardPrice mt={2}>
            {annualPrice}
            <PricingCardMo>/ mo</PricingCardMo>
          </PricingCardPrice>
        ) : (
          isLoading && (
            <PricingCardPrice mt={2}>
              <Skeleton display="inline-block" h="73px" w="100px" />
              <PricingCardMo>/ mo</PricingCardMo>
            </PricingCardPrice>
          )
        )}
        {!isLoading && annualPriceDescription ? (
          <PriceDescription mt={2}>{annualPriceDescription}</PriceDescription>
        ) : (
          isLoading && <Skeleton h="12px" mt={2} w="full" />
        )}
        {!isLoading ? (
          <Button
            w="full"
            mt={7}
            size="lg"
            isDisabled={isLoadingCheckout}
            onClick={() => {
              Analytics.openBuyNow({
                coupon,
                plantype: 'Anual',
                userSubscriptionProductType,
              })

              onSignup(UserSubscriptionType.Annual)
            }}
          >
            {userSubscriptionProductType === UserSubscriptionProductType.Nft
              ? 'Start Free Trial'
              : 'Buy Now'}
          </Button>
        ) : (
          <Skeleton w="full" h="56px" mt={7} borderRadius="lg" />
        )}
        <PricingCardFeatures
          features={FEATURES_LIST[userSubscriptionProductType].annual}
          mt={8}
        />
      </PricingCard>
    </Center>
  )
}
