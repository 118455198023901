import React from 'react'
import type { BoxProps, FlexProps, TextProps } from '@chakra-ui/react'
import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  Icon,
  Flex,
} from '@chakra-ui/react'
import { CheckCircle } from 'phosphor-react'

export const PricingCardHeading: React.FC = (props) => (
  <Heading as="h3" flex={1} textTransform="uppercase">
    {props.children}
  </Heading>
)

export const PricingCardSave: React.FC = (props) => (
  <Text
    variant="paragraph"
    sx={{
      fontSize: '16px',
      background: 'linear-gradient(90deg, #007adf 0%, #00ecbc 100%)',
      px: 3,
      py: 2,
      borderRadius: 'full',
      color: useColorModeValue('gray.100', 'gray.900'),
    }}
  >
    {props.children}
  </Text>
)

export const PricingCardHeader: React.FC = (props) => (
  <Box
    as="header"
    sx={{
      height: '28px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    {props.children}
  </Box>
)

export const PricingCardMo: React.FC = (props) => (
  <Text
    as="span"
    sx={{
      fontSize: '18px',
      color: useColorModeValue('gray.600', 'gray.300'),
    }}
  >
    {props.children}
  </Text>
)
export const PricingCardPreDiscountPrice: React.FC = (props) => (
  <Text
    as="span"
    sx={{
      fontWeight: 'bold',
      fontSize: '28px',
      textDecorationLine: 'line-through',
      mr: 2,
      color: 'gray.600',
    }}
  >
    {props.children}
  </Text>
)

export const PricingCardPrice: React.FC<FlexProps> = (props) => (
  <Flex fontSize="50px" fontWeight="bold" alignItems="baseline" {...props}>
    {props.children}
  </Flex>
)

export const PriceDescription: React.FC<TextProps> = (props) => {
  return (
    <Text
      variant="caption"
      fontWeight="normal"
      color={useColorModeValue('gray.600', 'gray.300')}
      {...props}
    />
  )
}

export const PricingCard: React.FC<BoxProps & { withSavings?: boolean }> = ({
  withSavings = false,
  sx,
  ...props
}) => {
  const pricingCardStyles = {
    padding: 6,
    backdropFilter: 'blur(15px)',
    transform: 'translate3d(0, 0, 0)',
    borderRadius: 'lg',
    border: '2px solid',
    borderColor: useColorModeValue('gray.300', 'gray.700'),
    ...sx,
  }
  const pricingCardSaved = {
    ...pricingCardStyles,
    py: 12,
    px: 6,
    borderColor: useColorModeValue('gray.900', 'gray.200'),
  }
  return (
    <Box
      as="section"
      sx={withSavings ? pricingCardSaved : pricingCardStyles}
      {...props}
    />
  )
}

export const PricingCardFeatures: React.FC<BoxProps & { features: string[] }> =
  ({ features, ...props }) => {
    const featureFontColor = useColorModeValue('gray.800', 'gray.200')
    return (
      <Box as="ul" {...props}>
        {features.map((feature, index) => (
          <Flex as="li" key={`feature-${index}`} mt={3} alignItems="baseline">
            <Box mr={2}>
              <Icon fontSize={16} mt="-4px" as={CheckCircle} />
            </Box>
            <Text fontSize="md" color={featureFontColor}>
              {feature}
            </Text>
          </Flex>
        ))}
      </Box>
    )
  }
