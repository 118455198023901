import type { FunctionComponent, ReactElement } from 'react'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import styles from './modal.module.scss'
import { Icon } from '../icon'
import { Icons } from '../icon/icon'
import { Button } from '../button'

export enum Position {
  'center' = 'center',
  'right' = 'right',
  'left' = 'left',
}

interface DefaultProps {
  className?: string
  position: Position
  title: string
  subTitle: string
  onBackButtonClick?: () => void
  alert?: ReactElement
  handleClose?: () => void
  sectionRef?: React.RefObject<HTMLDivElement>
  steps?: {
    currentStep: number
    total: number
  }
}

interface Props extends Partial<DefaultProps> {
  children: ReactElement | ReactElement[] | null
}

const defaultProps: DefaultProps = {
  position: Position.center,
  className: '',
  title: '',
  subTitle: '',
}

const Modal: FunctionComponent<Props> = ({
  position,
  handleClose,
  children,
  className,
  title,
  subTitle,
  onBackButtonClick,
  alert,
  sectionRef,
  steps,
}) => {
  const innerClasses = [
    styles.modal__inner,
    // @ts-expect-error
    styles[`modal__inner__${position}`],
    className,
  ]

  // Stop body scroll
  document.body.style.overflow = 'hidden'

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const showHeader = title || subTitle || onBackButtonClick

  return (
    <div
      className={classNames(
        styles.modal,
        // @ts-expect-error
        styles[`modal--${position}`]
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles['modal-close-area']} onClick={handleClose} />
      <section ref={sectionRef} className={innerClasses.join(' ')}>
        {alert && <div className={styles.modal__alert}>{alert}</div>}
        {steps && (
          <div className={styles.modal__steps}>
            {[...Array(steps.total)].map((x, i) => {
              return (
                <div
                  className={classNames(styles.modal__steps__step, {
                    [styles['modal__steps__step--active']]:
                      i + 1 <= steps.currentStep,
                  })}
                />
              )
            })}
          </div>
        )}
        {showHeader && (
          <div className={styles.modal__header}>
            <div>
              {onBackButtonClick ? (
                <Button
                  onClick={onBackButtonClick}
                  className={styles.modal__header__left__button}
                  styleType="ghost"
                  icon={
                    <Icon
                      className={styles.modal__header__left__button__icon}
                      type={Icons['chevron-left']}
                    />
                  }
                />
              ) : (
                <></>
              )}
            </div>
            <div className={styles.modal__header__center}>
              <div className={styles.modal__header__center__title}>{title}</div>
              <p className={styles.modal__header__center__p}>{subTitle}</p>
            </div>
            <div>
              {handleClose && (
                <Button
                  className={styles.modal__header__right__button}
                  styleType="ghost"
                  icon={
                    <Icon
                      className={styles.modal__header__left__button__icon}
                      type={Icons.cross}
                    />
                  }
                  onClick={handleClose}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles['modal__inner__content-wrapper']}>
          {children}
        </div>
      </section>
    </div>
  )
}

Modal.defaultProps = defaultProps

export default Modal
