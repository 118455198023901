import axios from 'axios'

export const isAdblockEnabled = async () => {
  try {
    // Some generic script that gets blocked by every AdBlocker software
    await axios.get('https://sc-static.net/scevent.min.js')

    return false
  } catch {
    return true
  }
}
