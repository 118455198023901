import React from 'react'
import { useColorModeValue } from '@chakra-ui/system'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { AppConfig } from 'services/AppConfig'
import type { ButtonProps, ModalProps } from '@chakra-ui/react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  Spacer,
  Button,
} from '@chakra-ui/react'
import {
  MobileMenu,
  MobileMenuOverlay,
  MobileMenuHeader,
  MobileMenuContent,
  MobileMenuBody,
  MobileMenuFooter,
} from 'ui-kit'

export interface SmartModalProps {
  title: string
  onClose(): void
  primaryButtonProps: ButtonProps
  secondaryButtonProps?: ButtonProps
  isOpen: boolean
  size?: ModalProps['size']
  closeOnOverlayClick?: boolean
}

/**
 * Use this one for typical and very common patterns. Such as filters and whatnot. If you need something more custom
 * just use Modal and MobileMenu yourself can move back between each other by using `useBreakpointValue`
 * How to do that properly you can search the codebase for plenty of examples
 */
export const ModalOrMobileMenu: React.FC<SmartModalProps> = ({
  title,
  isOpen,
  children,
  primaryButtonProps,
  onClose,
  secondaryButtonProps,
  size,
  closeOnOverlayClick = true,
}) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700')

  const isMobileScreenSize = useBreakpointValue(
    [true, true, false],
    AppConfig.isNativePlatform ? 'base' : 'xl'
  )

  return isMobileScreenSize ? (
    <MobileMenu
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <MobileMenuOverlay />
      <MobileMenuContent>
        <MobileMenuHeader>
          <Flex
            mt="4"
            position="relative"
            justifyContent="space-between"
            alignItems="center"
            w="full"
            px={2}
          >
            <Spacer flex="1 1 15%" />
            <Text
              variant="title"
              textAlign="center"
              fontWeight="bold"
              fontSize="23px"
            >
              {title}
            </Text>
            {secondaryButtonProps ? (
              <Flex justifyContent="flex-end" flex="1 1 15%">
                <Button
                  p="0"
                  variant="ghost"
                  colorScheme="gray"
                  fontWeight="bold"
                  height="auto"
                  // This is includes children ;)
                  {...secondaryButtonProps}
                />
              </Flex>
            ) : (
              <Spacer flex="1 1 15%" />
            )}
          </Flex>
        </MobileMenuHeader>
        <MobileMenuBody px={6}>{children}</MobileMenuBody>
        <MobileMenuFooter>
          <Button
            w="full"
            onClick={primaryButtonProps?.onClick}
            // This is includes children ;)
            {...primaryButtonProps}
          />
        </MobileMenuFooter>
      </MobileMenuContent>
    </MobileMenu>
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px" borderColor={borderColor}>
          <Text fontWeight="bold" textAlign="center" fontSize="20px">
            {title}
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={6} pt={4}>
          {children}
        </ModalBody>
        <ModalFooter borderTopWidth="1px" borderColor={borderColor}>
          <Flex w="full">
            {secondaryButtonProps ? (
              <Button
                w="full"
                colorScheme="gray"
                // This is includes children ;)
                {...secondaryButtonProps}
              />
            ) : null}
            <Button
              w="full"
              ml={secondaryButtonProps ? '3' : '0'}
              // This is includes children ;)
              {...primaryButtonProps}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
