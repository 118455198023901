import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { UserSubscriptionProductType } from 'packlets/generated'
import { Analytics } from '../../services/Analytics'
import type { ILocalStorage } from '../../services/LocalStorage'
import { LocalStorage } from '../../services/LocalStorage'
import { WebRoutes } from '../routes/WebRoutes'
import type { ExchangeDiscordCodeResponseFragmentFragment } from '../../gql/auth.generated'

export const useLoginToSecured = (
  loginRedirectLocalStorage?: ILocalStorage
) => {
  const history = useHistory()

  const terminateDiscordSession = useCallback(() => {
    const discordSessionLocalStorage = LocalStorage({
      key: 'AUTH_DISCORD_SESSION_STORAGE',
    })
    discordSessionLocalStorage.clear()
  }, [])

  const logInToSecured = useCallback(
    (loginResponse: ExchangeDiscordCodeResponseFragmentFragment) => {
      terminateDiscordSession()
      // TODO: Properly handle MultiLogin
      if (loginResponse.__typename === 'LoginResponse') {
        switch (loginResponse.session.subscriptionProductType) {
          case UserSubscriptionProductType.Sneakers: {
            const token = loginResponse.session.token
            const sessionLocalStorage = LocalStorage({
              key: 'AUTH_SESSION_STORAGE',
            })
            sessionLocalStorage.save(token)

            Analytics.setUser(loginResponse.user)

            if (loginRedirectLocalStorage) {
              const redirectUrlLocal = loginRedirectLocalStorage.get()
              loginRedirectLocalStorage.clear()
              window.location.href =
                redirectUrlLocal !== 'null' && redirectUrlLocal !== null
                  ? redirectUrlLocal
                  : '/'
              return
            }

            history.push(WebRoutes.root)
            return
          }
          case UserSubscriptionProductType.SportsCards: {
            history.push(WebRoutes.sportsCardsLoginSuccess)
            return
          }
          case UserSubscriptionProductType.Nft: {
            history.push(WebRoutes.nftLoginSuccess)
            return
          }
          case UserSubscriptionProductType.Pokemon:
          case UserSubscriptionProductType.Crypto: {
            history.push(WebRoutes.root)
          }
        }
      }
    },
    [history, loginRedirectLocalStorage, terminateDiscordSession]
  )

  return { logInToSecured, terminateDiscordSession }
}
