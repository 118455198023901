import { Box, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { ExternalFooter } from './external-footer'
import { ExternalHeader } from './external-header'
import bgImage from '../../images/background.png'

interface Props {
  headerActionsType?: 'none' | 'loginSignup' | 'loginOnly' | 'signupOnly'
  isFooterVisible?: boolean
  isLoggedIn?: boolean
  onSignup?: () => void
}

export const ExternalLayout: React.FC<Props> = ({
  children,
  headerActionsType = 'loginSignup',
  isFooterVisible = true,
  onSignup,
  isLoggedIn = false,
}) => {
  const footerHeightWithMargin = useBreakpointValue(
    ['521px', '403px', '332px'],
    'xl'
  )
  return (
    <Box>
      <Box
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: 'base',
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'no-repeat',
          width:
            'calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))',
          height:
            'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
          backgroundSize: 'cover',
        }}
      />

      <Box
        sx={{
          position: 'relative',
          zIndex: 'docked',
          maxHeight: '100vh',
          minHeight: '100vh',
          overflowX: 'hidden',
        }}
      >
        <ExternalHeader
          isLoggedIn={isLoggedIn}
          actionsType={headerActionsType}
          onSignup={onSignup}
        />
        <Box
          m="0 auto"
          maxW="1280px"
          px={6}
          minHeight={`calc(100vh - 72px - ${
            // 72px is header height
            isFooterVisible ? footerHeightWithMargin : '0px'
          })`}
        >
          {children}
        </Box>
        {isFooterVisible && <ExternalFooter />}
      </Box>
    </Box>
  )
}
