import React from 'react'
import { ExternalLayout } from '../../components/layout'
import styles from './resubscribe.module.scss'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Alert } from '../../components/alert'
import { ResubscribeMain } from './resubscribe-main'
import { ResubscribeSuccess } from './resubscribe-success'
import { useState } from 'react'
import { parseError } from '../../utils/errorParser'
import { ResubscribeMemberDocument } from '../../gql/checkout.generated'

export interface Props {
  userCancellationId?: string
}

export const Resubscribe = ({ userCancellationId }: Props) => {
  const [errorMessage, setErrorMessage] = useState('')

  const [resubscribeMember, { data, loading }] = useMutation(
    ResubscribeMemberDocument,
    {
      onError: (error) => {
        setErrorMessage(parseError(error))
      },
    }
  )

  if (!userCancellationId) {
    return <Redirect to="/" />
  }

  const onResubscribeMember = () => {
    setErrorMessage('')

    resubscribeMember({
      variables: {
        input: {
          userCancellationId,
        },
      },
    })
  }

  const isSuccess = data?.resubscribeMember === true

  return (
    <ExternalLayout headerActionsType="none" isFooterVisible={false}>
      <div className={styles.resubscribe}>
        {!isSuccess && (
          <ResubscribeMain
            isButtonDisabled={loading}
            onButtonClick={onResubscribeMember}
          />
        )}

        {isSuccess && <ResubscribeSuccess />}

        {errorMessage && (
          <Alert
            type="error"
            title={errorMessage}
            className={styles.resubscribe__alert}
            onClose={() => setErrorMessage('')}
          />
        )}
      </div>
    </ExternalLayout>
  )
}
