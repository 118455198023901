import { Redirect, useLocation } from 'react-router-dom'
import { WebRoutes } from '../routes/WebRoutes'
import { Text, Flex, Spinner } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router'
import { ValidateRevenueCatPurchaseDocument } from './index.generated'
import { fullScreenHeight } from '../../ui-kit'

export const MobileCheckoutValidation = () => {
  const params = new URLSearchParams(useLocation().search)
  const revenueCatId = params.get('revenueCatId')
  const email = params.get('email')
  const history = useHistory()
  const [, setCookie] = useCookies()

  const [validateRevenueCatCheckout] = useMutation(
    ValidateRevenueCatPurchaseDocument
  )

  useEffect(() => {
    if (!revenueCatId || !email) {
      return
    }
    validateRevenueCatCheckout({
      variables: {
        input: {
          revenueCatId,
          email,
        },
      },
    })
      .then((result) => {
        setCookie(
          'pending_user_id',
          result.data?.validateRevenueCatCheckout.id,
          {
            expires: moment().add(7, 'days').toDate(),
          }
        )
        const path = `${WebRoutes.checkoutConfirmation}?pending_user_id=${result.data?.validateRevenueCatCheckout.id}`
        history.replace(path)
      })
      .catch((error) => {
        console.error(error)
        console.error('AN ERROR')
      })
  }, [setCookie, revenueCatId, email, validateRevenueCatCheckout, history])

  if (!revenueCatId) {
    return <Redirect to={WebRoutes.login} />
  }
  return (
    <Flex
      minH={fullScreenHeight}
      width="100vw"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gridGap={4}
    >
      <Text>Processing purchase...</Text>
      <Spinner size="lg" />
    </Flex>
  )
}
