import { useMutation } from '@apollo/client'
import {
  Box,
  Stack,
  Button,
  HStack,
  Circle,
  Alert,
  AlertDescription,
  CloseButton,
  useColorModeValue,
  useDisclosure,
  Center,
  Link as ChakraLink,
  Heading,
  Flex,
} from '@chakra-ui/react'
import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { ExternalLayout } from '../../components/layout'
import { DISCORD_LOGIN_REDIRECT_URL } from '../../constants'
import { LocalStorage } from '../../services/LocalStorage'
import { ReactComponent as Logo } from './logo.svg'
import background from './background.svg'
import {
  ChatsCircle,
  GlobeHemisphereWest,
  ShoppingCartSimple,
  Sticker,
} from 'phosphor-react'
import { AppConfig } from '../../services/AppConfig'
import { Container } from '../../components/layout/container'
import { openInInternalBrowserOrRedirect } from '../../utils/app'
import { useLoginToSecured } from './useLoginToSecured'
import { WebRoutes } from '../routes/WebRoutes'
import { parseError } from '../../utils/errorParser'
import { fullScreenHeight } from 'ui-kit'
import {
  CreateDiscordAuthorizationLinkDocument,
  ExchangeDiscordCodeDocument,
} from '../../gql/auth.generated'
import { Capacitor } from '@capacitor/core'
import { InAppPurchase } from './inapp-purchase'
import { Link } from 'react-router-dom'

interface DefaultProps {
  code?: string
  state?: string
  errorDescription?: string
}

interface Props extends Partial<DefaultProps> {}

const defaultProps: DefaultProps = {
  code: '',
  state: '',
  errorDescription: '',
}

const discordOAuthStateStorage = LocalStorage({ key: 'DISCORD_OAUTH_STATE' })

const Login: FunctionComponent<Props> = ({ code, state, errorDescription }) => {
  const [hasError, setHasError] = useState(!!errorDescription)
  const [error, setError] = useState(errorDescription ?? '')
  const loginRedirectLocalStorage = LocalStorage({ key: 'LOGIN_REDIRECT' })
  const history = useHistory()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const redirectUrl = params.get('redirect_url')

  const [loginWithDiscord, { loading: loginWithDiscordLoading }] = useMutation(
    CreateDiscordAuthorizationLinkDocument,
    {
      onCompleted(data) {
        const authorizationLink = data.createDiscordAuthorizationLink
        const url = new URL(authorizationLink)

        const token = uuidv4()
        const theState = JSON.stringify({
          token,
        })

        url.searchParams.set('state', btoa(theState))
        discordOAuthStateStorage.save(token)
        loginRedirectLocalStorage.save(redirectUrl)

        openInInternalBrowserOrRedirect(url.toString())
      },
      onError() {
        setHasError(true)
        setError('Something went wrong, please try again.')
      },
    }
  )

  const { logInToSecured } = useLoginToSecured(loginRedirectLocalStorage)

  const [exchangeDiscordCode, { loading: exchangeDiscordCodeLoading }] =
    useMutation(ExchangeDiscordCodeDocument, {
      onCompleted(aData) {
        switch (aData.exchangeDiscordCode.__typename) {
          case 'LoginResponse': {
            logInToSecured(aData.exchangeDiscordCode)
            break
          }
          case 'MultipleAccountsResponse': {
            const token = aData.exchangeDiscordCode.session.token
            const discordSessionLocalStorage = LocalStorage({
              key: 'AUTH_DISCORD_SESSION_STORAGE',
            })
            discordSessionLocalStorage.save(token)

            history.push(WebRoutes.loginAccountSelection)
            break
          }
          default:
            return undefined
        }
        return undefined
      },
      onError(anError) {
        setHasError(true)
        setError(parseError(anError))
      },
    })

  useEffect(() => {
    if (!code || !state) {
      return
    }

    const parsedState = JSON.parse(atob(state)) as any

    const currentToken = discordOAuthStateStorage.get()
    discordOAuthStateStorage.clear()

    if (currentToken !== parsedState.token) {
      // setHasError(true)
      // setError('Something went wrong, please try again.')

      return
    }

    exchangeDiscordCode({
      variables: {
        input: {
          code,
          redirectUrl: DISCORD_LOGIN_REDIRECT_URL,
        },
      },
    })
  }, [code, exchangeDiscordCode, state])

  const logoFill = useColorModeValue('black', 'white')

  const inAppPurchaseDislosure = useDisclosure()
  const legacyLoginBg = useColorModeValue('blackAlpha.100', 'whiteAlpha.300')
  if (AppConfig.isNativePlatform) {
    return (
      <>
        <Container>
          <Box
            sx={{
              h: fullScreenHeight,
              w: 'full',
              backgroundPositionY: '105%',
              backgroundSize: 'contain',
              backgroundPositionX: 'center',
              backgroundImage: background,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Stack h="full" pb={14} pt={10} px={10}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                h="full"
              >
                {hasError && error ? (
                  <Alert status="error">
                    <AlertDescription textAlign="center">
                      {error}
                    </AlertDescription>
                    <CloseButton
                      size="sm"
                      position="absolute"
                      right="8px"
                      top="8px"
                      onClick={() => {
                        setHasError(false)
                        setError('')
                      }}
                    />
                  </Alert>
                ) : (
                  <Box />
                )}
                <Stack spacing={8}>
                  <HStack spacing={4}>
                    <Circle size="40px" bg="gray.200" color="black">
                      <Sticker />
                    </Circle>
                    <Circle size="40px" bg="gray.200" color="black">
                      <GlobeHemisphereWest />
                    </Circle>
                    <Circle size="40px" bg="gray.200" color="black">
                      <ShoppingCartSimple />
                    </Circle>
                    <Circle size="40px" bg="gray.200" color="black">
                      <ChatsCircle />
                    </Circle>
                  </HStack>
                  <Box fill={logoFill}>
                    <Logo />
                  </Box>
                </Stack>
              </Stack>
              <Stack alignItems="center" justifyContent="flex-end" h="full">
                <Button
                  size="lg"
                  w="full"
                  disabled={
                    loginWithDiscordLoading || exchangeDiscordCodeLoading
                  }
                  onClick={() =>
                    loginWithDiscord({
                      variables: {
                        redirectUrl: DISCORD_LOGIN_REDIRECT_URL,
                      },
                    })
                  }
                >
                  Log in with Discord
                </Button>
                {Capacitor.getPlatform() === 'ios' && (
                  <Button
                    variant="link"
                    onClick={() => {
                      inAppPurchaseDislosure.onOpen()
                    }}
                  >
                    Or select your subscription here
                  </Button>
                )}
              </Stack>
              <Center gridGap={2}>
                <Link to={WebRoutes.terms}>
                  <ChakraLink>Terms of use</ChakraLink>
                </Link>
                <Link to={WebRoutes.privacyPolicy}>
                  <ChakraLink>Privacy policy</ChakraLink>
                </Link>
              </Center>
            </Stack>
          </Box>
        </Container>
        <InAppPurchase {...inAppPurchaseDislosure} />
      </>
    )
  }
  return (
    <ExternalLayout headerActionsType="signupOnly">
      <Flex direciton="column" justifyContent="center" w="full" mt="88px">
        <Box maxW="438px">
          <Heading as="h1" size="xl">
            Discord Log In
          </Heading>
          <Box p={6} mt={4} bg={legacyLoginBg}>
            <Box as="p" fontWeight="medium">
              Log in with your Discord account to access Secured.
            </Box>
            <Button
              mt={4}
              width="full"
              isDisabled={loginWithDiscordLoading || exchangeDiscordCodeLoading}
              onClick={() =>
                loginWithDiscord({
                  variables: {
                    redirectUrl: DISCORD_LOGIN_REDIRECT_URL,
                  },
                })
              }
            >
              Log In with Discord
            </Button>
            {hasError ? (
              <Alert status="error" mt={4}>
                <AlertDescription textAlign="center">{error}</AlertDescription>
                <CloseButton
                  size="sm"
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={() => {
                    setHasError(false)
                    setError('')
                  }}
                />
              </Alert>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Flex>
    </ExternalLayout>
  )
}

Login.defaultProps = defaultProps

export default Login
