import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { interactive } from '../typography'

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props

  if (c === 'primary') {
    const bg = mode(`gray.900`, `gray.50`)(props)

    return {
      color: mode('white', 'gray.900')(props),
      bg,
      _hover: {
        bg: mode(`gray.800`, `gray.100`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.700`, `whiteAlpha.200`)(props) },
    }
  }

  return {}
}

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  if (c === 'primary') {
    const bg = mode(`white`, `gray.900`)(props)
    return {
      border: '1px solid',
      borderColor: mode('gray.900', 'white')(props),
      color: mode('gray.900', 'white')(props),
      bg,
      _hover: {
        bg: mode(`gray.100`, `gray.800`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.200`, `gray.700`)(props) },
    }
  }
  return {}
}

const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  // Note that it's exactly the same as gray :)
  if (c === 'primary') {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    }
  }
  return {}
}

const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const base = {
    p: 0,
    px: 0,
    py: 0,
  }
  if (c === 'primary') {
    return {
      color: mode('black', 'white')(props),
      ...base,
    }
  }
  return base
}

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'lg',
  },
  variants: {
    solid: variantSolid,
    outline: variantOutline,
    ghost: variantGhost,
    link: variantLink,
  },
  sizes: {
    lg: {
      py: 4,
      px: 6,
      h: 14,
      ...interactive.lg,
      minW: 14,
    },
    md: {
      p: 4,
      ...interactive.md,
      h: 12,
      minW: 12,
    },
    sm: {
      py: 2,
      px: 3,
      h: 9,
      ...interactive.sm,
      minW: 9,
    },
    xs: {
      px: 1,
      py: 2,
      ...interactive.sm,
      h: 6,
      minW: 6,
    },
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}
