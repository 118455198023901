import React, { createRef } from 'react'
import classNames from 'classnames'
import styles from './checkbox.module.scss'
import { mergeRefs } from '../../../utils/utils'

interface DefaultProps {
  className?: string
  type: 'radio' | 'checkbox'
  selected?: string | string[] | null
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

interface Props extends Partial<DefaultProps> {
  value: string
  name: string
  title: string
  subTitle?: string
}

const defaultProps: DefaultProps = {
  type: 'checkbox',
  selected: null,
}

const Checkbox = React.forwardRef(
  (
    {
      className,
      type,
      selected,
      value,
      name,
      onChange,
      disabled,
      title,
      subTitle,
    }: Props,
    ref
  ) => {
    const localInputRef = createRef<HTMLInputElement>()

    let isSelected = false
    if (Array.isArray(selected) && type === 'checkbox' && value) {
      isSelected = selected.includes(value)
    }
    if (type === 'radio') {
      isSelected = selected === value
    }

    const handleOnClick = () => {
      localInputRef?.current?.click()
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        onClick={handleOnClick}
        className={classNames(className, styles.checkbox, {
          [styles['checkbox--disabled']]: disabled,
        })}
      >
        <input
          className={styles.checkbox__input}
          onChange={onChange}
          ref={mergeRefs(ref, localInputRef)}
          value={value}
          type={type}
          name={name}
          disabled={disabled}
        />
        <div
          className={classNames(styles.checkbox__inner, {
            [styles['checkbox__inner--is-selected']]: isSelected,
          })}
        >
          {/* <div
            className={classNames(styles['checkbox__inner__check'], {
              [styles['checkbox__inner__check--is-selected']]: isSelected,
            })}
          /> */}
          <div
            className={classNames(styles.checkbox__inner__title, {
              [styles['checkbox__inner__title--is-selected']]: isSelected,
            })}
          >
            {title}
          </div>
          {subTitle && (
            <div
              className={classNames(styles['checkbox__inner__sub-title'], {
                [styles['checkbox__inner__sub-title--is-selected']]: isSelected,
              })}
            >
              {subTitle}
            </div>
          )}
        </div>
      </div>
    )
  }
)

Checkbox.defaultProps = defaultProps

export default Checkbox
