import type { FunctionComponent } from 'react'
import React from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

export const LandingCtaButton: FunctionComponent<ButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <Button
      size="lg"
      minW={['inherit', 'inherit', '288px']}
      w={['100%', '100%', 'inherit']}
      {...rest}
    >
      {children}
    </Button>
  )
}
