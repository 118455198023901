import type { MouseEvent } from 'react'
import React, { createRef, useState } from 'react'
import classNames from 'classnames'
import type { FieldError } from 'react-hook-form'
import styles from './input.module.scss'
import { mergeRefs } from '../../../utils/utils'

interface DefaultProps {
  className: string
  disabled?: boolean
  value?: string
  name: string
  placeholder: string
  size: 'normal' | 'small'
  onClick?: (event: MouseEvent) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClear?: (event: MouseEvent) => void
  error?: FieldError | boolean
  type?: 'text' | 'date'
  max?: string
  min?: string
}

interface Props extends Partial<DefaultProps> {}

const defaultProps: DefaultProps = {
  className: '',
  name: '',
  placeholder: '',
  size: 'small',
  type: 'text',
}

const Input = React.forwardRef(
  (
    {
      onClick,
      className,
      onChange,
      disabled,
      value,
      name,
      placeholder,
      size,
      error,
      type,
      max,
      min,
    }: Props,
    ref
  ) => {
    const localInputRef = createRef<HTMLInputElement>()
    const [isFocused, setIsFocused] = useState(false)

    const handleOnClick = () => {
      localInputRef?.current?.click()
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
    }

    const onFocus = () => {
      setIsFocused(true)
    }

    const onBlur = () => {
      setIsFocused(false)
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <div
        onClick={onClick}
        className={classNames(styles.input, {
          [styles[`input--disabled`]]: disabled,
          [className as string]: className,
        })}
      >
        <input
          className={classNames(styles['input__input-field'], {
            // @ts-expect-error
            [styles[`input__input-field--${size}`]]: true,
            [styles[`input__input-field--focus`]]: isFocused,
            [styles[`input__input-field--value`]]: value,
            [styles[`input__input-field--disabled`]]: disabled,
            [styles[`input__input-field--error`]]: error,
          })}
          name={name}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          onClick={handleOnClick}
          onChange={handleOnChange}
          ref={mergeRefs(ref, localInputRef)}
          onFocus={onFocus}
          onBlur={onBlur}
          max={max}
          min={min}
        />
      </div>
    )
  }
)

Input.defaultProps = defaultProps

export default Input
