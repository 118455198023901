import React from 'react'
import { Box, Heading } from '@chakra-ui/react'

export const SupportSection: React.FC = ({ children }) => {
  return (
    <Box as="section" mt={['120px', '120px', '184px']} px={[0, 0, '68px']}>
      <Heading fontSize={['3xl', '3xl', '4xl']} textAlign="center">
        You’re always supported
      </Heading>
      <Box
        mt={8}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['100%', '100%', '50%'],
          gridColumnGap: '16px',
          gridAutoFlow: ['row', 'row', 'column'],
          gridRowGap: '16px',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
