import { Box, Text } from '@chakra-ui/react'
import * as React from 'react'

export const CtaBlock: React.FC = ({ children }) => {
  return (
    <Box as="section" mt="184px" textAlign="center">
      {children}
    </Box>
  )
}
export const CtaBlockText: React.FC = ({ children }) => {
  return (
    <Text
      as="div"
      lineHeight="shorter"
      m="0 auto"
      mb="8"
      maxW="650px"
      fontSize={['2xl', '2xl', '28px']}
      fontWeight="bold"
    >
      {children}
    </Text>
  )
}
