import type { UseRadioProps } from '@chakra-ui/react'
import {
  chakra,
  Text,
  useRadio,
  VStack,
  useColorModeValue as mode,
  useId,
} from '@chakra-ui/react'
import * as React from 'react'

const RadioBox = chakra('div', {
  baseStyle: {
    height: 'full',
    borderWidth: '2px',
    p: 3,
    borderRadius: 'md',
    cursor: 'pointer',
    transition: 'all 0.2s',
    _focusVisible: { shadow: 'outline' },
    color: 'gray.500',
  },
})

interface ButtonRadioProps extends UseRadioProps {
  label: string
  description?: string
}

export const ButtonRadio = (props: ButtonRadioProps) => {
  const { label, description } = props
  const { getCheckboxProps, getInputProps, getLabelProps } = useRadio(props)
  const id = useId()

  const checkedStyles = {
    bg: mode('gray.50', 'gray.300'),
    borderColor: 'gray.300',
    color: 'gray.700',
  }

  return (
    <label style={{ width: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <VStack spacing="4">
          <VStack textAlign="center">
            <Text variant="subheading">{label}</Text>
            {description && (
              <Text variant="body" fontSize="11px">
                {description}
              </Text>
            )}
          </VStack>
        </VStack>
      </RadioBox>
    </label>
  )
}
