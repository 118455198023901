declare const gtag: GoogleTagManager.Gtag

declare module GoogleTagManager {
  interface Gtag {
    (track: string, event: string, options?: any): void
  }
}

/**
 * twitter pixel documentation
 * https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
 */
export enum GoogleTagManagerEventType {
  ViewContent = 'ViewContent',
  InitiateCheckout = 'InitiateCheckout',
  Purchase = 'Purchase',
}

export class GoogleTagManager {
  public static track(event: GoogleTagManagerEventType, options?: any) {
    if (options?.currency) {
      options.currency = options?.currency.toUpperCase() as any
    }

    gtag(
      'event',
      GoogleTagManager.mapEventTypeToGoogleEventName(event),
      options
    )
  }

  private static mapEventTypeToGoogleEventName(
    event: GoogleTagManagerEventType
  ): string {
    switch (event) {
      case GoogleTagManagerEventType.ViewContent:
        return 'view_item'
      case GoogleTagManagerEventType.InitiateCheckout:
        return 'begin_checkout'
      case GoogleTagManagerEventType.Purchase:
        return 'purchase'
      default:
        throw Error('mapEventTypeToGoogleEventName no mapping found')
    }
  }
}
