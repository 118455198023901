import type { ReactElement } from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './status-tag.module.scss'

interface DefaultProps {
  className?: string
}

interface Props extends Partial<DefaultProps> {
  size: 'small' | 'medium'
  color: 'success' | 'error' | 'default'
  title: string
  icon?: ReactElement
}

const defaultProps: DefaultProps = {}

const StatusTag: React.FC<Props> = ({
  className,
  color,
  title,
  icon,
  size,
}) => {
  return (
    <div
      className={classNames(className, [
        styles['status-tag'],
        styles[`status-tag--${color}`],
        styles[`status-tag--${size}`],
      ])}
    >
      <div>{title}</div>
      {icon && <div className={styles['status-tag__icon']}>{icon}</div>}
    </div>
  )
}

StatusTag.defaultProps = defaultProps

export default StatusTag
