import React, { useEffect, useMemo, useState } from 'react'
import type { UseToastOptions } from '@chakra-ui/react'
import {
  Alert,
  AlertDescription,
  chakra,
  CircularProgress,
  CloseButton,
  forwardRef,
  useToast as useToastChakra,
} from '@chakra-ui/react'
import { baseTypography } from '../theme/typography'

export interface CustomUseToastOptions
  extends Omit<UseToastOptions, 'title' | 'render' | 'variant'> {}

interface ToastProps extends CustomUseToastOptions {
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const statusToColorMap = {
  info: {
    bg: 'gray.200',
    color: 'black',
    trackColor: 'white',
  },
  warning: {
    bg: 'yellow.500',
    color: 'white',
    trackColor: 'yellow.600',
  },
  success: {
    bg: 'green.500',
    color: 'white',
    trackColor: 'green.600',
  },
  error: {
    bg: 'red.500',
    color: 'white',
    trackColor: 'red.600',
  },
}
const Toast = forwardRef<ToastProps, 'div'>((props, ref) => {
  const {
    status,
    id,
    isClosable,
    onClose,
    description,
    duration = 5000,
  } = props
  const [progress, setProgress] = useState(0)
  const { bg, color, trackColor } = statusToColorMap[status ?? 'info']

  useEffect(() => {
    if (duration && progress <= duration) {
      const STEP = 300
      setTimeout(() => {
        // So we have circle closed before unmount animation started.
        // Otherwise it closes circle instantly halfway through as unmount animation starts ~200ms before end of duration
        const A_BIT_BIGGER_STEP_FOR_CIRCLE_BE_CLOSED_BEFORE_UNMOUNT_ANIMATION =
          STEP + 30 // ms
        setProgress(
          (aProgress) =>
            aProgress +
            A_BIT_BIGGER_STEP_FOR_CIRCLE_BE_CLOSED_BEFORE_UNMOUNT_ANIMATION
        )
      }, STEP)
    }
  }, [duration, progress])

  const closeButtonProps = useMemo(() => {
    return {
      color,
      size: 'sm',
      onClick: onClose,
      position: 'absolute' as any,
      transform: 'translateY(-50%)',
      top: '50%',
      right: 2,
      p: 0,
    }
  }, [color, onClose])

  return (
    <Alert
      ref={ref}
      status={status}
      // @ts-expect-error
      id={id}
      sx={{
        boxShadow: 'lg',
        minWidth: '20rem',
        width: 'auto',
        py: 3,
        px: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bg,
      }}
    >
      <chakra.div flex="1" maxWidth="100%">
        {description && (
          <AlertDescription
            display="block"
            textAlign="center"
            color={color}
            {...baseTypography.body}
            fontWeight="bold"
          >
            {description}
          </AlertDescription>
        )}
      </chakra.div>
      {isClosable ? (
        <CloseButton {...closeButtonProps} />
      ) : (duration ?? 0) > 0 ? (
        <CircularProgress
          color={color}
          max={duration ?? 100}
          value={progress}
          position="absolute"
          transform="translateY(-50%)"
          top="50%"
          right={3}
          size="1rem"
          trackColor={trackColor}
        />
      ) : (
        <CloseButton {...closeButtonProps} />
      )}
    </Alert>
  )
})

/**
 * This is an override of original chakra useToast in order to override default chakra looks and fine tune API to how our Toast should look
 */
export const useToast = (options?: CustomUseToastOptions) => {
  const initialOptions = useMemo<Omit<UseToastOptions, 'title'>>(() => {
    return {
      ...options,
      render(props) {
        return <Toast {...options} {...props} />
      },
    }
  }, [options])
  const toast = useToastChakra(initialOptions)

  /**
   * This is hack to fix certain limitations of `render` callback API.
   *
   * We passing down original `initialOptions` and new `optionsInternal` that we may pass down later as per original Chakra useToast API
   * As `render` callback doesn't get actual `options` as `props` in its callback we have to closure options at the very beginning e.g.
   * when we create `optionsInternal` config  like `useToast(initialOptions)` and then if we pass new options later on like `toast(newOptions)`
   * we then using closure to merge `initialOptions` with the new options `optionsInternal` and pass them down to new `render` meaning our custom
   * Toast component gets different look based on new set of `options`.
   */
  return useMemo(() => {
    const toastInstance: typeof toast = (
      optionsInternal?: CustomUseToastOptions
    ) => {
      return optionsInternal
        ? toast({
            ...optionsInternal,
            render(props) {
              return (
                <Toast
                  {...{ ...initialOptions, ...optionsInternal }}
                  {...props}
                />
              )
            },
          })
        : toast()
    }
    // Here we remap all of the helper methods for original toast to our toastInstance function to keep API 1:1 to original
    toastInstance.close = toast.close
    toastInstance.closeAll = toast.closeAll
    toastInstance.update = toast.update
    toastInstance.isActive = toast.isActive

    return toastInstance
  }, [initialOptions, toast])
}
