import type { BoxProps } from '@chakra-ui/react'
import { Box, forwardRef, useStyleConfig } from '@chakra-ui/react'
import classNames from 'classnames'

export const Card = forwardRef<BoxProps, 'div'>(
  ({ className, sx, ...props }, ref) => {
    const styles = useStyleConfig('Card', props)
    return (
      <Box
        className={classNames('secured-card', className)}
        __css={styles}
        {...props}
        ref={ref}
      />
    )
  }
)
