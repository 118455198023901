import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { ExternalLayout } from '../../components/layout'
import { Analytics } from '../../services/Analytics'
import { Link } from 'react-router-dom'
import { WebRoutes } from '../routes/WebRoutes'
import { Box } from '@chakra-ui/react'

interface Props {
  isLoggedIn: boolean
}

const PrivacyPolicy: FunctionComponent<Props> = ({ isLoggedIn }) => {
  useEffect(() => {
    Analytics.viewTerms()
  }, [])

  const content = (
    <div>
      <Box
        sx={{
          paddingTop: '40px',
          maxWidth: '632px',
          h1: { fontWeight: 'bold', fontSize: '32px', marginBottom: '40px' },
          section: {
            marginBottom: '40px',
            h2: { fontWeight: 'bold', fontSize: '16px', marginBottom: '8px' },
            p: {
              marginTop: '16px',
              marginBottom: '8px',
              '&:last-of-type': { marginBottom: '0' },
            },
            ul: {
              marginTop: '8px',
              li: {
                marginTop: '8px',
                listStyle: 'disc',
                marginLeft: '20px',
              },
            },
            ol: {
              marginTop: '8px',
              li: { marginTop: '8px', marginLeft: '20px', listStyle: 'none' },
            },
          },
        }}
      >
        <h1>Privacy Policy</h1>
        <section>
          <h2>Last Updated: 30th June 2021</h2>
          <p>
            S94 Inc. and its affiliates (<strong>“S94,”</strong>{' '}
            <strong>“we,”</strong> <strong>“our,”</strong> and/or{' '}
            <strong>“us”</strong>) values the privacy of individuals who use our
            websites (including secured.community) or services that link to this
            Privacy Policy (collectively, the <strong>“Services”</strong>). This
            privacy policy (the <strong>“Privacy Policy”</strong>) explains how
            we collect, use, and disclose information from users of the
            Services. If you are located in the European Economic Area (
            <strong>“EEA”</strong>) or the United Kingdom, “information” means
            any information relating to an identified or identifiable
            individual. By using the Services, you agree to the collection, use,
            and disclosure of your information as described in this Privacy
            Policy. Beyond this Privacy Policy, your use of the Services is also
            subject to our <Link to={WebRoutes.terms}>Terms of Service</Link>.
          </p>
        </section>
        <section>
          <h2>
            <u>INFORMATION WE COLLECT</u>{' '}
          </h2>
          <p>
            We may collect a variety of information from or about you or your
            devices from various sources, as described below. If you do not
            provide your information when requested, you may not be able to use
            the Services if that information is necessary to provide you with
            the Services or if we are legally required to collect it.
          </p>
        </section>
        <section>
          <h2>A. Information You Provide to Us </h2>
          <p>
            <strong>Registration. </strong>
            When you register for an account to use the Services, we ask you for
            your personal information, including your name, email address,
            mailing address, and phone number.
          </p>
          <p>
            <strong>Communications. </strong>
            If you contact us directly, we may receive additional personal
            information about you. For example, when you email us, we will
            receive your name, email address, and any other information you
            choose to provide to us. If you subscribe to our newsletter, we will
            receive your email address.
          </p>
          <p>
            <strong>Payment Information. </strong>
            When you make a payment to S94 your credit card information, debit
            card information, billing information, and any other financial
            information necessary to complete your payment (
            <strong>“Payment Information”</strong>) is processed. When you use
            these methods to make a payment, our third-party payment processor
            (Stripe) processes the Payment Information, and we do not collect,
            store, or process your Payment Information. For more information on
            payment processing, please see the Payment Processing section below.
          </p>
          <p>
            <strong>Careers. </strong>
            If you decide to apply for a job with us, you may submit your
            contact information and your resume via email. We will collect the
            information you choose to provide us as part of your job
            application, such as your contact information, current employment
            information, and other information you choose to submit with your
            application and on your resume. If you apply for a job with us
            through a third-party platform (such as Glassdoor or LinkedIn), we
            will collect the information you make available to us through such
            third-party platform.
          </p>
        </section>
        <section>
          <h2>B. Information We Collect When You Use the Services</h2>
          <p>
            <strong>
              Location Information. <strong />
            </strong>
            When you use the Services, we may infer your general location
            information. For example, your IP address may indicate your general
            geographic region.
          </p>
          <p>
            <strong>Device Information. </strong>
            We receive information about the device and software you use to
            access the Services, including internet protocol (IP) address, web
            browser type, operating system version, and device identifiers.
          </p>
          <p>
            <strong>Usage Information. </strong>
            To help us understand how you use the Services and to help us
            improve them, we automatically receive information about your
            interactions with the Services, such as date and time of visits, the
            pages viewed, links to/from any page, and time spent at a site.
          </p>
          <p>
            <strong>Analytics Partners. </strong>
            We use analytics services such as Google Analytics, Facebook,
            Hotjar, and Mixpanel to collect and process certain analytics data.
            These services may also collect information about your use of other
            websites, apps, and online resources. You can learn about Google’s
            practices by going to{' '}
            <a href="https://www.google.com/policies/privacy/partners/">
              https://www.google.com/policies/privacy/partners/
            </a>{' '}
            and opt out of them by downloading the Google Analytics opt-out
            browser add-on, available at{' '}
            <a href="https://tools.google.com/dlpage/gaoptout.">
              https://tools.google.com/dlpage/gaoptout.
            </a>
          </p>
        </section>
        <section>
          <h2>C. Information We Receive from Third Parties</h2>
          <p>
            <strong>Partners. </strong>
            We may receive additional information about you from third parties
            such as data or marketing partners and combine it with other
            information we have about you.
          </p>
        </section>
        <section>
          <h2> D. Payment Processing</h2>
          <p>
            When you make purchases through our Services, your payment is
            processed by a third-party payment processor (
            <strong>“Payment Processor”</strong>). This Payment Processor may
            collect Payment Information and other information from you,
            including your name, email address, and billing address. The use of
            your information by the Payment Processor is governed by its terms
            and conditions and privacy policy, and information we receive about
            you from our Payment Processor is governed by this Privacy Policy.
          </p>
        </section>
        <section>
          <h2>
            <u>HOW WE USE THE INFORMATION WE COLLECT</u>
          </h2>
          <p>We use the information we collect:</p>
          <ul>
            <li>
              To provide, maintain, debug, improve, and enhance the Services;
            </li>
            <li>
              To understand and analyze how you use the Services, and develop
              new products, services, features, and functionalities;
            </li>
            <li>To understand and analyze usage patterns;</li>
            <li>To process your job application;</li>
            <li>To send you SMS messages and push notifications;</li>
            <li>
              To contact prospective customers through social medial and email;
            </li>
            <li>
              To communicate with you, provide you with updates and other
              information relating to the Services, provide information that you
              request, respond to comments and questions, and otherwise provide
              customer support;
            </li>
            <li>
              To find and prevent fraud, and respond to trust and safety issues
              that may arise;
            </li>
            <li>
              For compliance purposes, including enforcing our Terms of Service
              or other legal rights, or as may be required by applicable laws
              and regulations or requested by any judicial process or
              governmental agency; and
            </li>
            <li>
              For other purposes for which we provide specific notice at the
              time the information is collected.
            </li>
          </ul>
        </section>
        <section>
          <h2>
            <u>WHAT ARE COOKIES AND HOW DO WE USE THEM</u>
          </h2>
          <p>
            Cookies are small files of letters and numbers that we store on your
            browser or the hard drive of your computer. They contain information
            that is transferred to your computer’s hard drive. We use cookies,
            beacons, invisible tags, and similar technologies (collectively
            <strong>“Cookies”</strong>) to collect information about your
            browsing activities and to distinguish you from other users of the
            Services. This aids your experience when you use the Services and
            also allows us to improve the functionality of the Services. Cookies
            can be used for performance management, collecting information on
            how our Services are being used for analytics purposes. They can
            also be used for functionality management, enabling us to make the
            User’s visit more efficient by, for example, remembering language
            preferences, passwords and log-in details. We may also use pixel
            tags and web beacons on our Services. These are tiny graphic images
            placed on web pages or in our emails that allow us to determine
            whether you have performed a specific action. Below is an overview
            of the types of Cookies we and third parties may use to collect
            information.
          </p>
          <ul>
            <li>
              <strong>Strictly necessary cookies.</strong> Some Cookies are
              strictly necessary to make the Services available to you. We
              cannot provide you with the Services without this type of cookies.
            </li>
            <li>
              <strong>Functional cookies.</strong> These are used to recognize
              you when you return to the Services. This enables us to adapt our
              content for you, and remember your preferences (for example, your
              choice of language or region).
            </li>
            <li>
              <strong>Analytical or Performance cookies.</strong> We also use
              Cookies for website and app analytics purposes in order to
              operate, maintain and improve our Services. We may use our own
              analytics cookies or use third party analytics providers such as
              Google Analytics, Facebook, Hotjar, TikTok, Snapchat and Mixpanel
              to collect and process certain analytics data on our behalf. These
              providers may also collect information about your use of other
              websites, apps, and online resources. You can opt out of these
              analytical and/or performance cookies without affecting how you
              visit our Services by emailing{' '}
              <a href="mailto:hello@secured.community.">
                hello@secured.community.
              </a>
            </li>
          </ul>
          <p>
            Where required by applicable law, we obtain your consent to use
            Cookies. You can find more information about your rights and
            choices, and how to block the use of certain Cookies in the section
            Your Rights and Choices below.
          </p>
        </section>
        <section>
          <h2>
            <u>LEGAL BASES FOR PROCESSING EUROPEAN INFORMATION</u>
          </h2>
          <p>
            If you are located in the European Economic Area or the United
            Kingdom, we only process your information when we have a valid
            “legal basis”, including when:
          </p>
          <ul>
            <li>
              <strong>Consent. </strong> You have consented to the use of your
              information, for example to send you marketing communications or
              to use Cookies.
            </li>
            <li>
              <strong>Contractual necessity. </strong> We need your information
              to provide you with the Services, for example to respond to your
              inquiries.
            </li>
            <li>
              <strong>Compliance with a legal obligation. </strong> We have a
              legal obligation to use your information, for example to comply
              with tax and accounting obligations.
            </li>
            <li>
              <strong>Legitimate interests. </strong> We or a third party have a
              legitimate interest in using your information. In particular, we
              have a legitimate interest in using your information for product
              development and internal analytics purposes, and otherwise to
              improve the safety, security, and performance of our Services. We
              only rely on our or a third party’s legitimate interests to
              process your information when these interests are not overridden
              by your rights and interests.
            </li>
          </ul>
        </section>
        <section>
          <h2>
            <u>HOW WE SHARE THE INFORMATION WE COLLECT</u>
          </h2>

          <p>
            We do not share or otherwise disclose information we collect from
            you except as described below or otherwise disclosed to you at the
            time of the collection.
          </p>

          <p>
            <strong>Affiliates</strong>. We may share any information we receive
            with our affiliates for any of the purposes described in this
            Privacy Policy.
          </p>

          <p>
            <strong>Vendors and Service Providers.</strong> We may share any
            information we receive with vendors and service providers retained
            in connection with the provision of the Services.
          </p>

          <p>
            <strong>Marketing.</strong> We do not rent, sell, or share
            information about you with nonaffiliated companies for their direct
            marketing purposes unless we have your permission.
          </p>

          <p>
            <strong>Analytics Partners.</strong> We use analytics services such
            as Google Analytics, Facebook, TikTok, Snapchat, Mixpanel and Hotjar
            to collect and process certain analytics data. These services may
            also collect information about your use of other websites, apps, and
            online resources. You can learn about Google’s practices by going to
            <a href="https://www.google.com/policies/privacy/partners/">
              https://www.google.com/policies/privacy/partners/
            </a>{' '}
            and opt out of them by downloading the Google Analytics opt-out
            browser add-on, available at{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </p>

          <p>
            <strong>Advertising Partners.</strong> We work with third-party
            advertising partners to show you ads that we think may interest you.
            Some of our advertising partners are members of the Network
            Advertising Initiative (
            <a href="http://optout.networkadvertising.org/?c=1#!/">
              http://optout.networkadvertising.org/?c=1#!/
            </a>
            ) or the Digital Advertising Alliance (
            <a href="http://optout.aboutads.info/?c=2&lang=EN">
              http://optout.aboutads.info/?c=2&lang=EN
            </a>
            ). If you do not wish to receive personalized ads, please visit
            their opt-out pages to learn about how you may opt out of receiving
            web-based personalized ads from member companies. You can access any
            settings offered by your mobile operating system to limit ad
            tracking, or you can install the AppChoices mobile app to learn more
            about how you may opt out of personalized ads in mobile apps.
          </p>

          <p>
            <strong>As Required by Law and Similar Disclosures.</strong> We may
            access, preserve, and disclose your personal information if we
            believe doing so is required or appropriate to: (i) comply with law
            enforcement requests and legal process, such as a court order or
            subpoena; (ii) respond to your requests; or (iii) protect your, our,
            or others’ rights, property, or safety.
          </p>

          <p>
            <strong>Merger, Sale, or Other Asset Transfers.</strong> We may
            disclose and transfer your personal information to service
            providers, advisors, potential transactional partners, or other
            third parties in connection with the consideration, negotiation, or
            completion of a corporate transaction in which we are acquired by or
            merged with another company or we sell, liquidate, or transfer all
            or a portion of our business or assets.
          </p>

          <p>
            <strong>Consent</strong>. We may also disclose personal information
            with your permission.
          </p>
        </section>
        <section>
          <h2>
            <u>YOUR RIGHTS AND CHOICES</u>
          </h2>
          <p>
            <strong>Marketing Communications.</strong> You can unsubscribe from
            our promotional emails via the link provided in the emails. Even if
            you opt out of receiving promotional messages from us, you will
            continue to receive administrative messages from us.
          </p>
          <p>
            <strong>Do Not Track.</strong> There is no accepted standard on how
            to respond to Do Not Track signals, and we do not respond to such
            signals.
          </p>
          <p>
            If you choose not to provide us with the information we collect,
            some features of the Services may not work as intended.
          </p>
          <p>
            <strong>How to Block Cookies.</strong> You can block Cookies by
            setting your internet browser to block some or all the Cookies.
            However, if you use your browser settings to block all Cookies
            (including essential Cookies) you may not be able to access all or
            parts of our Services. By using our Services you consent to our use
            of Cookies and our processing of information collected through such
            cookies, in accordance with this Privacy Policy. You can withdraw
            your consent at any time by deleting placed cookies and disabling
            Cookies in your browser, or as explained below. You can change your
            browser settings to block or notify you when you receive a Cookie,
            delete Cookies or browse our Services using your browser’s anonymous
            usage setting. Please refer to your browser instructions or help
            screen to learn more about how to adjust or modify your browser
            settings. If you do not agree to our use of Cookies or similar
            technologies which store information on your device, you should
            change your browser settings accordingly. You should understand that
            some features of our Services may not function properly if you do
            not accept Cookies or these technologies. Where required by
            applicable law, you will be asked to consent to certain Cookies and
            similar technologies before we use or install them on your computer
            or other device.
          </p>
          <p>
            <strong>Your European Privacy Rights.</strong> If you are located in
            the European Economic Area or the United Kingdom, you have the
            additional rights described below:
          </p>
          <ul>
            <li>
              You may request access to the information we maintain about you,
              update and correct inaccuracies in your information, restrict or
              object to the processing of your information, have the information
              anonymized or deleted, as appropriate, or exercise your right to
              data portability to easily transfer your information to another
              company. In addition, you also have the right to lodge a complaint
              with a supervisory authority, including in your country of
              residence, place of work or where an incident took place.
            </li>
            <li>
              You may withdraw any consent you previously provided to us
              regarding the processing of your information, at any time and free
              of charge. We will apply your preferences going forward and this
              will not affect the lawfulness of the processing before you
              withdrew your consent.
            </li>
            <li>
              You may exercise these rights by contacting us using the contact
              details at the end of this Privacy Policy. Before meeting your
              request, we may ask you to provide reasonable information to
              verify your identity. Please note that there are exceptions and
              limitations to each of these rights, and that while any changes
              you make will be reflected in active user databases instantly or
              within a reasonable period of time, we may retain information for
              backups, archiving, prevention of fraud and abuse, analytics,
              satisfaction of legal obligations, or where we otherwise
              reasonably believe that we have a legitimate reason to do so.
            </li>
          </ul>
        </section>
        <section>
          <h2>
            <u>THIRD PARTIES</u>
          </h2>
          <p>
            The Services may contain links to other websites, products, or
            services that we do not own or operate (
            <strong>“Third-Party Services”</strong>). We are not responsible for
            the privacy practices of these Third-Party Services. Please be aware
            that this Privacy Policy does not apply to your activities on these
            Third-Party Services or any information you disclose to these
            Third-Party Services. We encourage you to read the privacy policies
            of these Third-Party Services.
          </p>
        </section>
        <section>
          <h2>
            <u>RETENTION</u>
          </h2>
          <p>
            We take measures to delete your information or keep it in a form
            that does not permit identifying you when this information is no
            longer necessary for the purposes for which we process it, unless we
            are required by law to keep this information for a longer period.
            When determining the specific retention period, we take into account
            various criteria, such as the type of service provided to you, the
            nature and length of our relationship with you, and mandatory
            retention periods provided by law and the statute of limitations.
          </p>
        </section>
        <section>
          <h2>
            <u>SECURITY</u>
          </h2>
          <p>
            We make reasonable efforts to protect your personal information by
            using physical and electronic safeguards designed to improve the
            security of the personal information we maintain. However, as no
            electronic transmission or storage of information can be entirely
            secure, we can make no guarantees as to the security or privacy of
            your personal information.
          </p>
        </section>
        <section>
          <h2>
            <u>INTERNATIONAL VISITORS</u>
          </h2>
          <p>
            The Services are hosted in the United States and intended for
            visitors located within the United States. If you choose to use the
            Services from the European Union or other regions of the world with
            laws governing data collection and use that may differ from U.S.
            law, then please note that you are transferring your personal
            information outside of those regions to the United States for
            storage and processing. We may transfer information from the
            European Economic Area or the United Kingdom to the United States as
            needed to perform the Services that you have requested from us, or
            with your consent. Also, we may transfer your data from the U.S. to
            other countries or regions in connection with storage and processing
            of data, fulfilling your requests, and operating the Services. By
            providing any information, including personal information, on or to
            the Services, you consent to such transfer, storage, and processing.
          </p>
        </section>
        <section>
          <h2>
            <u>CHILDREN’S PRIVACY</u>
          </h2>
          <p>
            We do not knowingly collect, maintain, or use personal information
            from children under 16 years of age, and no part of the Services are
            directed to children. If you learn that a child has provided us with
            personal information in violation of this Privacy Policy, please
            alert us at{' '}
            <a href="mailto:hello@secured.community.">
              hello@secured.community.
            </a>
          </p>
        </section>
        <section>
          <h2>
            <u>CHANGES TO THIS PRIVACY POLICY</u>
          </h2>
          <p>
            We will post any adjustments to the Privacy Policy on this page, and
            the revised version will be effective when it is posted. If we
            materially change the ways in which we use or share personal
            information previously collected from you through the Services, we
            will attempt to notify you through the Services, by email, or by
            other means.
          </p>
        </section>
        <section>
          <h2>
            <u>CONTACT INFORMATION</u>
          </h2>
          <p>
            If you have any questions, comments, or concerns about our
            processing activities, please email us at{' '}
            <a href="mailto:hello@secured.community.">
              hello@secured.community
            </a>{' '}
            or write to us at:
          </p>
          <p>
            S94 Ltd.
            <br />
            9th Floor 107 Cheapside,
            <br />
            London, <br />
            EC2V 6DN, <br />
            United Kingdom <br />
          </p>
        </section>
      </Box>
    </div>
  )

  return <ExternalLayout isLoggedIn={isLoggedIn}>{content}</ExternalLayout>
}

export default PrivacyPolicy
