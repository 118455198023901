import type { ReactElement } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'
import type { RouteProps } from 'react-router-dom'
import { Redirect, Route } from 'react-router-dom'
import { LocalStorage } from '../../services/LocalStorage'
import { WebRoutes } from './WebRoutes'

interface DefaultProps extends RouteProps {
  sessionToken: string
  pageTitle: string
}

interface Props extends Partial<DefaultProps> {
  pageComponent: ReactElement
}

const ProtectedRoute = ({ pageComponent, pageTitle, ...rest }: Props) => {
  const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })
  const token = sessionLocalStorage.get()
  return token ? (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <React.Fragment>
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            {React.cloneElement(pageComponent, { ...matchProps })}
          </React.Fragment>
        )
      }}
    />
  ) : (
    <Redirect
      to={`${WebRoutes.login}?redirect_url=${encodeURIComponent(
        window.location.href
      )}`}
    />
  )
}

export default ProtectedRoute
