import type { SystemStyleInterpolation } from '@chakra-ui/theme-tools'

/**
 * Here are a reusable typography tokens. Misses bold variants as you can achieve it with fontWeight prop easily.
 */

export const interactive = {
  sm: {
    fontSize: 'xs',
    lineHeight: 3,
    letterSpacing: '0.005em',
    fontWeight: 'bold',
  },
  md: {
    fontSize: 'sm',
    lineHeight: 4,
    letterSpacing: '0.005em',
    fontWeight: 'bold',
  },
  lg: {
    fontSize: 'md',
    lineHeight: 6,
    letterSpacing: '0.01em',
    fontWeight: 'bold',
  },
}

export const baseTypography = {
  caption: {
    fontSize: 'xs',
    lineHeight: 3,
    letterSpacing: '-0.005em',
    fontWeight: 'bold',
  },
  link: {
    fontSize: 'xs',
    lineHeight: 4,
    letterSpacing: '-0.005em',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  paragraph: {
    fontSize: 'sm',
    lineHeight: 5,
    letterSpacing: '-0.005em',
    fontWeight: 'medium',
  },
  body: {
    fontSize: 'sm',
    lineHeight: 4,
    letterSpacing: '-0.005em',
    fontWeight: 'medium',
  },
  subheading: {
    fontSize: 'sm',
    lineHeight: 5,
    letterSpacing: '-0.01em',
    fontWeight: 'bold',
  },
  title: {
    fontSize: 'lg',
    lineHeight: 5,
    letterSpacing: '-0.01em',
    fontWeight: 'bold',
  },
}

const displayBaseStyles: SystemStyleInterpolation = {
  fontFamily: 'body',
  letterSpacing: '-0.03em',
  fontWeight: 'extrabold',
}

export const baseDisplay = {
  baseStyle: displayBaseStyles,
  sizes: {
    xl: {
      fontSize: 'xl',
      letterSpacing: '-0.02em',
      fontWeight: 'bold',
      lineHeight: 6,
    },
    '2xl': {
      fontSize: '2xl',
      lineHeight: 7,
    },
    '3xl': {
      fontSize: '3xl',
      lineHeight: 8,
    },
    '4xl': {
      fontSize: '4xl',
      lineHeight: 10,
    },
    '5xl': {
      fontSize: '5xl',
      lineHeight: '3.25rem',
    },
    '6xl': {
      fontSize: '6xl',
      lineHeight: '4rem',
    },
  },
}
