export const LocalStorage = (config: { key: string }) => {
  const get = () => {
    return localStorage.getItem(config.key)
  }

  const save = (data: any) => {
    return localStorage.setItem(config.key, data)
  }

  const clear = () => {
    return localStorage.removeItem(config.key)
  }

  return Object.freeze({
    get,
    save,
    clear,
  })
}

export type ILocalStorage = ReturnType<typeof LocalStorage>
