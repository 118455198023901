import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './styles.module.scss'
import { Button } from 'components/button'
import { Input } from 'components/form/input'
import moment from 'moment'

// these are all the optional props
export interface DefaultProps {
  dateOfBirth?: string
}

export type DobFormData = {
  dateOfBirth: string
}

const defaultProps: DefaultProps = {}

// these are all the required props
interface Props extends Partial<DefaultProps> {
  onFormSubmit: (data: DobFormData) => void
  onViewCallback: () => void
  dateOfBirth?: string
  isLoading: boolean
  hasError: boolean
}

const Dob = ({
  onFormSubmit,
  onViewCallback,
  dateOfBirth,
  isLoading,
  hasError,
}: Props) => {
  const onViewWithCallback = useCallback(onViewCallback, [onViewCallback])

  useEffect(() => {
    onViewWithCallback()
  }, [onViewWithCallback])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      dateOfBirth,
    },
  })

  const onSubmit = (data: DobFormData) => {
    onFormSubmit(data)
  }

  useEffect(() => {
    setFocus('dateOfBirth')
  }, [setFocus])

  const isFormButtonEnabled = watch('dateOfBirth')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div>
          <div>
            <Input
              error={errors.dateOfBirth ?? hasError}
              {...register('dateOfBirth', {
                required: true,
                validate: (value) => {
                  return moment(value).isBefore(moment())
                },
              })}
              value={watch('dateOfBirth')}
              type="date"
              max={moment().format('YYYY-MM-DD')}
            />
            {hasError && (
              <div className={styles['dob__has-error']}>
                Please provide date of birth in the following format: MM/DD/YYYY
              </div>
            )}
          </div>

          <div className={styles['dob__submit-container']}>
            {!isFormButtonEnabled && (
              <Button
                size="medium"
                className={styles['dob__submit-container__button']}
                disabled
              >
                Next
              </Button>
            )}
            {isFormButtonEnabled && (
              <Button
                size="medium"
                className={styles['dob__submit-container__button']}
                styleType="fill"
                colour="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

Dob.defaultProps = defaultProps

export default Dob
