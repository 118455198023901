import React, { useMemo } from 'react'
import type { SpaceProps } from '@chakra-ui/react'
import { Box, Flex, Text, forwardRef, Divider } from '@chakra-ui/react'
import { Card } from '../card'

export interface RiskLevelProps extends SpaceProps {
  risk: number // number between 1 and 10
}

enum RiskValues {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}
enum RiskColors {
  low = 'green.500',
  medium = 'yellow.500',
  high = 'red.500',
}

export const RiskLevel: React.FC<RiskLevelProps> = forwardRef<
  RiskLevelProps,
  'div'
>(({ risk, ...props }, ref) => {
  const riskColor = useMemo(() => {
    if (risk < 4) {
      return RiskColors.low
    }

    if (risk > 6) {
      return RiskColors.high
    }

    return RiskColors.medium
  }, [risk])

  const riskLevel = useMemo(() => {
    if (risk < 4) {
      return RiskValues.low
    }

    if (risk > 6) {
      return RiskValues.high
    }

    return RiskValues.medium
  }, [risk])

  return (
    <Card width="full" spacing={0} {...props} ref={ref}>
      <Flex width="full" p={3} justifyContent="space-between">
        <Text variant="body" fontWeight="bold" ml={1}>
          Risk Level
        </Text>
        <Text variant="body" fontWeight="bold" color={riskColor} mr={2}>
          {riskLevel}
        </Text>
      </Flex>
      <Divider />
      <Box p={3}>
        <Box
          width="calc(100% - 9px)"
          h={2}
          bgGradient={`linear(to-r, ${RiskColors.low},  ${RiskColors.medium},  ${RiskColors.high})`}
          borderRadius="base"
          pl={1}
          pr={2}
        >
          <Box
            transitionProperty="var(--chakra-transition-property-common)"
            transitionDuration="var(--chakra-transition-duration-slow)"
            width={1}
            height={4}
            background="black"
            border="1px solid white"
            borderRadius="base"
            position="relative"
            bottom={1}
            left={`calc(${(risk - 1) * 10}%)`}
          />
        </Box>
      </Box>
    </Card>
  )
})
