import type { StackProps } from '@chakra-ui/react'
import {
  Box,
  Divider,
  forwardRef,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import moment from 'moment'
import { useEffect } from 'react'
import { Card } from '../card'
import { noop } from 'lodash'

export interface CountdownProps extends StackProps {
  date: number | Date
}

export const CountdownTextSection = forwardRef<CountdownProps, 'div'>(
  ({ date, ...props }, ref) => {
    const convertedToMsDate = typeof date === 'number' ? date : date.getTime()
    const [diffTime, setDiffTime] = useState(convertedToMsDate - Date.now())
    const duration = moment.duration(diffTime, 'milliseconds')
    const formattedDuration =
      diffTime >= 0
        ? `${duration.days() ? `${duration.days()}d` : ''} ${
            duration.hours() || duration.days() ? `${duration.hours()}h` : ''
          } ${
            duration.minutes() || duration.hours() || duration.days()
              ? `${duration.minutes()}m`
              : ''
          }
            ${duration.seconds()}s`
        : null
    const momentDate = moment.utc(date)
    const formatted = momentDate.format('DD MMM YYYY')
    useEffect(() => {
      const intervalCode = setInterval(() => {
        setDiffTime(convertedToMsDate - Date.now())
      }, 1000)
      return () => {
        clearInterval(intervalCode)
      }
    }, [convertedToMsDate])
    return (
      <HStack justifyContent="space-between" p={3} ref={ref} {...props}>
        {formattedDuration ? (
          <Text>
            <Text as="strong" fontWeight="bold">
              Releasing:{' '}
            </Text>
            {formatted}
          </Text>
        ) : (
          <Text as="strong" fontWeight="bold">
            Released
          </Text>
        )}
        <Text>{formattedDuration}</Text>
      </HStack>
    )
  }
)

const ONE_DAY = 2880

export const CountdownBar = forwardRef<CountdownProps, 'div'>(
  ({ date, sx, ...props }, ref) => {
    const convertedToMsDate = typeof date === 'number' ? date : date.getTime()
    const [diffTime, setDiffTime] = useState(convertedToMsDate - Date.now())
    const isReleased = diffTime <= 0
    const duration = moment.duration(diffTime, 'milliseconds')
    const ONE_PERCENT = ONE_DAY / 100
    const value =
      duration.asMinutes() > ONE_DAY
        ? 0
        : (100 - duration.asMinutes() / ONE_PERCENT).toFixed(1)
    useEffect(() => {
      if (isReleased) {
        return noop
      }
      const intervalCode = setInterval(() => {
        setDiffTime(convertedToMsDate - Date.now())
      }, 1000)
      return () => clearInterval(intervalCode)
    }, [isReleased, convertedToMsDate])
    return (
      <Box
        ref={ref}
        _after={{
          content: '""',
          display: 'block',
          width: isReleased ? '100%' : `${value}%`,
          height: '100%',
          borderRadius: 'inherit',
          bgGradient: `linear(to-r, ${useColorModeValue(
            'green.100',
            'whiteAlpha.300'
          )}, green.500)`,
          transitionProperty: 'var(--chakra-transition-property-common)',
          transitionDuration: 'var(--chakra-transition-duration-slow)',
        }}
        h={2}
        w="full"
        bg={useColorModeValue('gray.200', 'gray.700')}
        borderRadius="full"
        role="progressbar"
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={isReleased ? 100 : Number(value)}
        {...props}
      />
    )
  }
)

export const Countdown = forwardRef<CountdownProps, 'div'>(
  ({ date, ...props }, ref) => {
    return (
      <Card spacing={0} {...props}>
        <CountdownTextSection date={date} />
        <Divider />
        <Box p={3}>
          <CountdownBar date={date} />
        </Box>
      </Card>
    )
  }
)
