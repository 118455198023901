import React, { useEffect } from 'react'
import { useState } from 'react'
import { LocalStorage } from '../../services/LocalStorage'
import { CloseButton, Flex, HStack, Icon, Spacer, Text } from '@chakra-ui/react'
import { WarningOctagon } from 'phosphor-react'

export const AdblockBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const bannerStatusLocalStorage = LocalStorage({ key: 'ADBLOCK_BANNER ' })

  useEffect(() => {
    const wasBannerDismissed = bannerStatusLocalStorage.get()
    const parsedValue =
      wasBannerDismissed !== null ? Boolean(wasBannerDismissed) : false

    setIsVisible(!parsedValue)
  }, [bannerStatusLocalStorage])

  const handleBannerClose = () => {
    setIsVisible(false)

    bannerStatusLocalStorage.save(true)
  }

  return isVisible ? (
    <Flex
      p={6}
      onClick={handleBannerClose}
      ml={-6}
      width="calc(100% + 24px * 2)"
      mt={-6}
      borderRadius="inherit"
      bg="black"
      color="white"
      gridGap={['2px', '12px']}
      alignItems="center"
      mb={8}
    >
      <Flex
        direction={['column', 'row']}
        gridGap={['8px', '16px']}
        alignItems={['flex-start', 'center']}
      >
        <HStack spacing={4}>
          <Icon as={WarningOctagon} boxSize="22px" />
          <Text variant="title">Adblock Detected</Text>
        </HStack>
        <Text>
          This site works best with ad blocker disabled, turn it off if are
          experiencing any issues.
        </Text>
      </Flex>
      <Spacer />
      <CloseButton onClick={handleBannerClose} />
    </Flex>
  ) : null
}
