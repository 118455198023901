import type { FunctionComponent } from 'react'
import React from 'react'
import { useCookies } from 'react-cookie'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { SecuredServer } from 'packlets/generated'
import { Account } from '../account'
import { CheckoutConfirmation } from '../checkout-confirmation'
import CheckoutValidation from '../checkout-validation/checkout-validation'
import { LandingSneakers } from '../landing-sneakers'
import { Login } from '../login'
import { Resubscribe } from '../resubscribe'
import { SportsCardsLoginSuccess } from '../sports-cards-login-success'
import ProtectedRoute from './ProtectedRoute'
import UnprotectedRoute from './UnprotectedRoute'
import useInit from './useInit'
import DualRoute from './DualRoute'
import { WebRoutes } from './WebRoutes'
import { Onboarding } from './onboarding'
import { useReactiveVar } from '@apollo/client'
import { Locale } from '../../gql/local'
import { PrivacyPolicy } from '../privacy-policy'
import { Terms } from '../terms'
import { Tutorial } from './tutorial'
import { useToken } from '../../utils/hooks'
import { AppConfig } from '../../services/AppConfig'
import { NftLoginSuccess } from '../nft-login-success'
import { PokemonLoginSuccess } from 'containers/pokemon-login-success'
import { LoginAccountSelection } from '../login-account-selection'
import { LandingJordans } from 'containers/landing-jordans'
import { LandingSneakersReselling } from 'containers/landing-sneakers-reselling'
import { CryptoLoginSuccess } from 'containers/crypto-login-success'
import { MobileCheckoutValidation } from 'containers/mobile-checkout-validation'
import { Box, Image, useColorModeValue } from '@chakra-ui/react'
import { LandingSneakersBots } from '../landing-sneakers-bots'

interface DefaultProps {}

interface Props extends Partial<DefaultProps> {
  query: URLSearchParams
}

const Routes: FunctionComponent<Props> = ({ query }) => {
  const location = useLocation()
  const [cookies] = useCookies()
  const locale = useReactiveVar(Locale)

  const pendingUserIdCookie = cookies.pending_user_id

  const { loading, user } = useInit()
  const token = useToken()
  let onboardingComponent
  if (user && !user.isOnboardingCompleted) {
    onboardingComponent = (
      <Onboarding user={user} localeCountryCode={locale.countryCode} />
    )
  }

  if (user && user.isOnboardingCompleted && !user.isTutorialCompleted) {
    onboardingComponent = <Tutorial user={user} />
  }
  const logoSplashScreenFilter = useColorModeValue('unset', 'brightness(30)')
  if (loading) {
    return (
      <Box
        sx={{
          width:
            'calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))',
          height:
            'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '16px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Image
            filter={logoSplashScreenFilter}
            alt="logo"
            src="/images/logo-black.svg"
          />
        </Box>
      </Box>
    )
  }
  const isPaymentRelated =
    location.pathname === WebRoutes.mobileCheckoutValidation ||
    location.pathname === WebRoutes.checkoutConfirmation

  if (
    !isPaymentRelated &&
    !token &&
    AppConfig.isNativePlatform &&
    (location.pathname === WebRoutes.sneakers ||
      location.pathname === WebRoutes.nfts ||
      location.pathname === WebRoutes.ambassadorProgram)
  ) {
    return <Redirect to={WebRoutes.login} />
  }

  return (
    <>
      {onboardingComponent}
      <Switch>
        <UnprotectedRoute
          exact
          path={WebRoutes.login}
          pageTitle="Secured - Login"
          externalOnly
          pageComponent={
            <Login
              code={query.get('code') ?? undefined}
              state={query.get('state') ?? undefined}
              errorDescription={query.get('error_description') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.loginAccountSelection}
          pageTitle="Launch Product"
          pageComponent={<LoginAccountSelection />}
        />
        <DualRoute
          exact
          path={WebRoutes.root}
          externalTitle="Secured"
          externalComponent={
            <Redirect to={`${WebRoutes.sneakers}${location.search ?? ''}`} />
          }
          internalTitle="Secured - Dashboard"
          internalComponent={<Redirect to={WebRoutes.account} />}
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.sneakers}
          externalOnly={false}
          pageTitle="Secured"
          pageComponent={
            <LandingSneakers
              coupon={query.get('coupon') ?? undefined}
              errorDescription={query.get('error_description') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.jordansForRetail}
          externalOnly={false}
          pageTitle="Secured - Jordans for Retail"
          pageComponent={
            <LandingJordans
              coupon={query.get('coupon') ?? undefined}
              errorDescription={query.get('error_description') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.sneakerBotting}
          externalOnly={false}
          pageTitle="Secured - Learn how to use bots to get sneakers for retail prices"
          pageComponent={
            <LandingSneakersBots
              coupon={query.get('coupon') ?? undefined}
              errorDescription={query.get('error_description') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.sneakersReselling}
          externalOnly={false}
          pageTitle="Secured - Sneakers Reselling"
          pageComponent={
            <LandingSneakersReselling
              coupon={query.get('coupon') ?? undefined}
              errorDescription={query.get('error_description') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.sportsCardsLoginSuccess}
          pageTitle="Secured"
          pageComponent={<SportsCardsLoginSuccess />}
        />
        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.nftLoginSuccess}
          pageTitle="Secured"
          pageComponent={<NftLoginSuccess />}
        />
        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.pokemonLoginSuccess}
          pageTitle="Secured"
          pageComponent={<PokemonLoginSuccess />}
        />
        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.cryptoLoginSuccess}
          pageTitle="Secured"
          pageComponent={<CryptoLoginSuccess />}
        />

        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.terms}
          pageTitle="Secured - Terms"
          pageComponent={<Terms isLoggedIn={false} />}
        />
        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.privacyPolicy}
          pageTitle="Secured - Privacy Policy"
          pageComponent={<PrivacyPolicy isLoggedIn={false} />}
        />
        <UnprotectedRoute
          exact
          externalOnly={false}
          path={WebRoutes.checkoutValidation}
          pageTitle="Secured"
          pageComponent={
            <CheckoutValidation
              sessionId={query.get('session_id') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.checkoutConfirmation}
          externalOnly={false}
          pageTitle="Secured"
          pageComponent={
            <CheckoutConfirmation
              code={query.get('code') ?? undefined}
              state={query.get('state') ?? undefined}
              pendingUserId={
                query.get('pending_user_id') ?? pendingUserIdCookie ?? undefined
              }
              errorDescription={query.get('error_description') ?? undefined}
            />
          }
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.mobileCheckoutValidation}
          externalOnly={false}
          pageTitle="Secured"
          pageComponent={<MobileCheckoutValidation />}
        />
        <UnprotectedRoute
          exact
          path={WebRoutes.resubscribe}
          pageTitle="Secured"
          pageComponent={
            <Resubscribe
              userCancellationId={query.get('user_cancellation_id') ?? ''}
            />
          }
        />
        <ProtectedRoute
          exact
          path={WebRoutes.account}
          pageTitle="Secured - Account"
          pageComponent={
            <Account
              isLoggedIn
              userId={user?.id}
              discordServer={user?.discordServer ?? SecuredServer.Eu}
              discordId={user?.discordUser?.id ?? ''}
              gId={query.get('gId') ?? ''}
            />
          }
        />
        <Route>
          <Redirect to={WebRoutes.root} />
        </Route>
      </Switch>
    </>
  )
}

export default Routes
