import React from 'react'
import { Analytics } from '../../../services/Analytics'
import { WebRoutes } from '../../../containers/routes/WebRoutes'
import { Box, Flex, Icon } from '@chakra-ui/react'
import { SmartLink } from '../../smart-link'
import { InstagramLogo, TwitterLogo } from 'phosphor-react'
import { Link } from 'react-router-dom'

const ExternalFooter = () => {
  return (
    <Box
      sx={{
        margin: '0 auto',
        marginTop: '184px',
        position: 'relative',
        zIndex: 'docked',
        width: 'inherit',
        maxWidth: '1280px',
        px: 6,
        py: 4,
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: ['column', 'row', 'row'],
          justifyContent: ['center', 'center', 'unset'],
          textAlign: 'center',
          marginBottom: [8, 8, 0],
          fontSize: '13px',
          fontWeight: 'medium',
          gridGap: 2,
          a: {
            textDecoration: 'underline',
          },
        }}
      >
        <Box as="p">
          © {new Date().getUTCFullYear()} S94 Inc. All Rights Reserved
        </Box>
        <Link to={WebRoutes.terms}>Terms</Link>
        <Link to={WebRoutes.privacyPolicy}>Privacy Policy</Link>
        <Box as="a" href="mailto:hello@secured.community">
          Contact Us
        </Box>
      </Box>
      <Flex gridGap={6}>
        <SmartLink
          sx={{
            display: 'inline-block',
            textDecoration: 'none',
          }}
          target="_blank"
          to="https://twitter.com/_secured"
          onClick={() => {
            Analytics.openTwitter()
          }}
          rel="noreferrer"
        >
          <Icon fontSize="24px" as={TwitterLogo} />
        </SmartLink>
        <SmartLink
          sx={{
            display: 'inline-block',
            textDecoration: 'none',
          }}
          target="_blank"
          to="https://www.instagram.com/secured_official/"
          onClick={() => {
            Analytics.openInstagram()
          }}
          rel="noreferrer"
        >
          <Icon fontSize="24px" as={InstagramLogo} />
        </SmartLink>
      </Flex>
    </Box>
  )
}

export default ExternalFooter
