import type { FunctionComponent } from 'react'
import React from 'react'
import { Alert } from '../alert'
import type { ToastProps } from 'react-toast-notifications'
import styles from './toast.module.scss'

const Toast: FunctionComponent<ToastProps> = ({
  appearance,
  children,
  onDismiss,
}) => {
  let type: 'error' | 'success' | 'default' = 'default'
  switch (appearance) {
    case 'error':
      type = 'error'
      break
    case 'success':
      type = 'success'
      break
    case 'info':
    default:
      type = 'default'
  }

  return (
    <Alert
      className={styles.toast}
      title={children?.toString() ?? ''}
      type={type}
      onClose={onDismiss}
    />
  )
}

export default Toast
