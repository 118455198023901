import type { FunctionComponent } from 'react'
import React from 'react'
import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'

interface Props {
  title: string
  imageUrl: string
  imageLocation: 'left' | 'right'
  imageBoxShadow?: boolean
}

export const FeatureText: React.FC = ({ children }) => {
  return (
    <Text
      variant="paragraph"
      sx={{
        color: useColorModeValue('gray.600', 'gray.400'),
        marginBottom: 4,
        fontSize: '18px',
        lineHeight: '27px',
        '&:last-of-type': { marginBottom: '0' },
      }}
    >
      {children}
    </Text>
  )
}

export const Feature: FunctionComponent<Props> = ({
  title,
  imageUrl,
  imageLocation,
  children,
  imageBoxShadow = true,
}) => {
  const isLeft = imageLocation === 'left'
  const flexDirectionHorizontal = isLeft ? 'row-reverse' : 'row'
  const imageSx = imageBoxShadow
    ? {
        overflow: 'hidden',
        background: 'rgba(255, 255, 255, 0.4)',
        backdropFilter: 'blur(15px)',
        transform: 'translate3d(0, 0, 0)',
        borderRadius: '12px',
        boxShadow: '0px 4px 49px 5px rgba(220, 220, 220, 0.25)',
      }
    : {
        overflow: 'hidden',
      }
  return (
    <Flex
      as="section"
      alignItems="center"
      mt={['120px', '120px', '184px']}
      direction={['column', 'column', flexDirectionHorizontal]}
      gridGap={[4, 4, 10]}
      p={[0, 0, 4]}
    >
      <Box flex="93" mt={[10, 10, 0]}>
        <Heading size="2xl" fontSize={['28px', '28px', '37px']}>
          {title}
        </Heading>
        <Box mt={6}>{children}</Box>
      </Box>
      <Box flex="100">
        <Box sx={imageSx}>
          <img src={imageUrl} alt="feature" />
        </Box>
      </Box>
    </Flex>
  )
}
