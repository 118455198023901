import type { ReactNode } from 'react'
import React, { useMemo, useState } from 'react'
import { FirebaseService } from '../../services/FirebaseService'
import type { DefaultFeatureFlag, FeatureFlags } from './feature-flags'
import { defaultFeatureFlags } from './feature-flags'

export const RemoteConfigContext = React.createContext<FeatureFlags>({})
interface DefaultProps {
  defaults: DefaultFeatureFlag
}

interface Props extends Partial<DefaultProps> {
  children: ReactNode
}

const defaultProps: DefaultProps = {
  defaults: defaultFeatureFlags,
}

const RemoteConfigProvider: React.FC<Props> = (props) => {
  const { defaults, children } = props

  const { remoteConfig } = FirebaseService

  remoteConfig.settings.minimumFetchIntervalMillis = 0
  remoteConfig.defaultConfig = useMemo(() => defaults ?? {}, [defaults])

  const [flags, setFlags] = useState<FeatureFlags | DefaultFeatureFlag>(
    remoteConfig.getAll()
  )

  React.useEffect(() => {
    const fetchFlags = async () => {
      try {
        const didActivate = await remoteConfig.fetchAndActivate()
        if (!didActivate) {
          process.env.NODE_ENV !== 'production' &&
            console.info('Firebase fetched configs already activated')
        }
        const remoteFlags = remoteConfig.getAll()
        setFlags(remoteFlags)
      } catch (error) {
        console.error(error)
      }
    }
    fetchFlags()
  }, [defaults, remoteConfig])

  return (
    <RemoteConfigContext.Provider value={flags}>
      {children}
    </RemoteConfigContext.Provider>
  )
}

RemoteConfigProvider.defaultProps = defaultProps

export default RemoteConfigProvider
