import React, { useCallback, useEffect } from 'react'
import { SNAPCHAT_PIXEL_KEY } from '../../constants'
import { Locale } from '../../gql/local'
import SnapchatPixel from 'react-snapchat-pixel'
import { LocalStorage } from '../../services/LocalStorage'
import { useLazyQuery } from '@apollo/client'
import type { User } from 'packlets/generated'
import { CountryCode, CurrencyCode } from 'packlets/generated'
import { FirebaseService } from '../../services/FirebaseService'
import { useFeatureFlags } from '../../providers/remote-config'
import { useUserInfo } from '../../context/UserInfo'
import { Analytics } from 'services/Analytics'
import { GetSelfDocument } from '../../gql/users.generated'

const useInit = () => {
  const [loading, setLoading] = React.useState(true)
  const { setDiscordServer } = useUserInfo()

  const { isNotificationsEnabled: isNotificationsEnabledValue } =
    useFeatureFlags()
  const isNotificationsEnabled =
    isNotificationsEnabledValue?.asBoolean() === true

  const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })
  const token = sessionLocalStorage.get()

  const [getUser, { data }] = useLazyQuery(GetSelfDocument, {
    onCompleted: (response) => {
      Analytics.setUser(response.getSelf)
      initInternalServices(response.getSelf)
    },
    onError: (error) => {
      console.error(JSON.stringify(error))
    },
  })

  const initExternalServices = useCallback(async () => {
    setLoading(true)
    // try {
    //   const ipResonse = await IpApi()

    //   const ipCountryCode = mapCountryToCountryCode(ipResonse?.countryCode)
    //   const ipCurrency = mapCurrencyToCurrencyCode(ipResonse?.currency)

    //   Locale({
    //     countryCode: ipCountryCode,
    //     currency: ipCurrency,
    //   })
    // } catch (e) {
    //   console.error(e)
    // }

    SnapchatPixel.init(SNAPCHAT_PIXEL_KEY)
    SnapchatPixel.pageView()

    setLoading(false)
  }, [])

  // const fetchUserCountryDataByIp = async () => {
  //   const ipResponse = await IpApi().catch(() => {
  //     console.warn(
  //       'Could not fetch data about currency & country code, will use default values'
  //     )

  //     return null
  //   })

  //   const countryCode = mapCountryToCountryCode(ipResponse?.countryCode)
  //   const currency = mapCurrencyToCurrencyCode(ipResponse?.currency)

  //   return {
  //     countryCode,
  //     currency,
  //   }
  // }

  const initInternalServices = async (userData: User) => {
    setLoading(true)

    if (userData.discordServer) {
      setDiscordServer(userData.discordServer)
    }

    const userCountryCode = userData.countryCode
    const userCurrency = userData.currency

    // if (!userCountryCode || !userCurrency) {
    //   const { countryCode, currency } = await fetchUserCountryDataByIp()

    //   Locale({
    //     countryCode,
    //     currency,
    //   })
    // } else {
    //   Locale({
    //     countryCode: userCountryCode,
    //     currency: userCurrency,
    //   })
    // }

    Locale({
      countryCode: userCountryCode || CountryCode.Us,
      currency: userCurrency || CurrencyCode.Usd,
    })

    const user = {
      user_id: userData.id,
      email: userData.email ?? undefined,
    }

    SnapchatPixel.init(SNAPCHAT_PIXEL_KEY, {
      user_email: user.email ?? '',
    })
    SnapchatPixel.pageView()

    FirebaseService.userDidLogIn(userData)
    setLoading(false)
  }

  useEffect(() => {
    if (token) {
      getUser()
    }
    if (!token) {
      initExternalServices()
    }
  }, [getUser, initExternalServices, token])

  return {
    loading,
    user: data?.getSelf,
    isNotificationsEnabled,
  }
}

export default useInit
