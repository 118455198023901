import mixpanel from 'mixpanel-browser'
import { MIXPANEL_KEY } from '../../constants'
import type {
  CountryCode,
  CurrencyCode,
  Region,
  User,
  UserSubscriptionProductType,
  UserSubscriptionType,
} from 'packlets/generated'

export class Analytics {
  private static client: typeof mixpanel

  // eslint-disable-next-line no-empty-function
  private constructor() {}

  public static init() {
    mixpanel.init(MIXPANEL_KEY)
    Analytics.client = mixpanel
  }

  public static setUser(user: User) {
    Analytics.identify(user.id)
    Analytics.client.people.set({
      $email: user.email,
      $distinct_id: user.id,
      $name: user.name || user.discordUser?.username,
      $created: user.createdAt,
      $country_code: user.countryCode,
      currency: user.currency,
      updated: user.updatedAt,
      discordId: user.discordUser?.id,
      discordServer: user.discordServer,
      discordCountryCode: user.discordCountryCode,
      discordUsername: user.discordUser?.username,
      productType: user.userSubscriptionProductType,
      stripeCustomerId: user.stripeCustomerId,
      stripeSubscriptionId: user.stripeSubscriptionId,
      subscriptionType: user.subscriptionType,
    })
  }

  public static identify(id: string) {
    Analytics.client.identify(id)
  }

  public static reset() {
    Analytics.client.reset()
  }

  public static viewDashboard() {
    Analytics.client.track('viewDashboard', {})
  }

  public static viewOnboarding() {
    Analytics.client.track('viewOnboarding')
  }

  public static viewAccount() {
    Analytics.client.track('viewAccount')
  }

  public static viewGuides() {
    Analytics.client.track('viewGuides')
  }

  public static viewTaskGuide() {
    Analytics.client.track('viewTaskGuide')
  }

  public static viewChannelGuide() {
    Analytics.client.track('viewChannelGuide')
  }

  public static viewEditRoles() {
    Analytics.client.track('viewEditRoles')
  }

  public static openDiscord() {
    Analytics.client.track('openDiscord')
  }

  public static openEditMembershipRobin() {
    Analytics.client.track('openEditMembershipRobin')
  }

  public static openBookCall() {
    Analytics.client.track('openBookCall')
  }

  public static updateRoles() {
    Analytics.client.track('updateRoles')
  }

  public static openIntroVideo() {
    Analytics.client.track('openIntroVideo')
  }

  public static viewProduct(productId: string) {
    Analytics.client.track('viewProduct', { productId })
  }

  public static viewProductCollection(productCollectionId: string) {
    Analytics.client.track('viewProductCollection', { productCollectionId })
  }

  public static openCheckoutHelp() {
    Analytics.client.track('openCheckoutHelp')
  }

  public static openRaffleHelp() {
    Analytics.client.track('openRaffleHelp')
  }

  public static openDiscordChannel(productId: string, channelId: string) {
    Analytics.client.track('openDiscordChannel', {
      productId,
      channelId,
    })
  }

  public static openBotHelp() {
    Analytics.client.track('openBotHelp')
  }

  public static viewProductRaffles(productId: string) {
    Analytics.client.track('viewProductRaffles', { productId })
  }

  public static viewProductDrops(productId: string) {
    Analytics.client.track('viewProductDrops', { productId })
  }

  public static updateProductRafflesAvailabilityFilter(
    productId: string,
    regions: Region[]
  ) {
    Analytics.client.track('updateProductRafflesAvailabilityFilter', {
      productId,
      regions,
    })
  }

  public static updateProductDropsAvailabilityFilter(
    productId: string,
    regions: Region[]
  ) {
    Analytics.client.track('updateProductDropsAvailabilityFilter', {
      productId,
      regions,
    })
  }

  public static updateRaffleMarkAsEntered(
    productId: string,
    raffleId: string,
    status: boolean,
    storeId?: string
  ) {
    Analytics.client.track('updateRaffleMarkAsEntered', {
      productId,
      raffleId,
      status,
      storeId,
    })
  }

  public static updateDropRemindMe(
    productId: string,
    dropId: string,
    status: boolean,
    storeId?: string
  ) {
    Analytics.client.track('updateDropRemindMe', {
      productId,
      dropId,
      status,
      storeId,
    })
  }

  public static openRaffle(
    productId: string,
    raffleId: string,
    storeId?: string
  ) {
    Analytics.client.track('openRaffle', {
      productId,
      raffleId,
      storeId,
    })
  }

  public static openDrop(productId: string, dropId: string, storeId?: string) {
    Analytics.client.track('openDrop', {
      productId,
      dropId,
      storeId,
    })
  }

  public static updateUserLocation(
    countryCode?: CountryCode | null,
    currency?: CurrencyCode | null
  ) {
    Analytics.client.track('updateUserLocation', {
      countryCode,
      currency,
    })
  }

  public static openBotSetupHelp() {
    Analytics.client.track('openBotSetupHelp')
  }

  public static viewOnboardingSetLocation() {
    Analytics.client.track('viewOnboardingSetLocation')
  }

  public static viewOnboardingSetExperienceLevel() {
    Analytics.client.track('viewOnboardingSetExperienceLevel')
  }

  public static viewOnboardingSetRoles() {
    Analytics.client.track('viewOnboardingSetRoles')
  }

  public static viewOnboardingSetUserBehaviour() {
    Analytics.client.track('viewOnboardingSetUserBehaviour')
  }

  public static viewOnboardingSetUserDob() {
    Analytics.client.track('viewOnboardingSetUserDob')
  }

  public static viewOnboardingWelcome() {
    Analytics.client.track('viewOnboardingWelcome')
  }

  public static openOnboardingDiscord() {
    Analytics.client.track('openOnboardingDiscord')
  }

  public static viewTutorial() {
    Analytics.client.track('viewTutorial')
  }

  public static clickVisitSecuredHubButton() {
    Analytics.client.track('clickVisitSecuredHubButton')
  }

  public static clickTutorialBrowseUpcomingReleases() {
    Analytics.client.track('clickTutorialBrowseUpcomingReleases')
  }

  public static openTutorialSetupBot() {
    Analytics.client.track('openTutorialSetupBot')
  }

  public static openTutorialDownloadDiscord() {
    Analytics.client.track('openTutorialDownloadDiscord')
  }

  public static openProduct(productId: string, collectionId?: string) {
    Analytics.client.track('openProdict', {
      productId,
      productCollectionId: collectionId,
    })
  }

  public static viewLanding({
    userSubscriptionProductType,
    pricingCode,
    currency,
    promoCode,
  }: {
    userSubscriptionProductType: UserSubscriptionProductType
    pricingCode: string
    currency: CurrencyCode
    promoCode?: string
  }) {
    Analytics.client.track('viewLanding', {
      userSubscriptionProductType,
      pricingCode,
      currency,
      promoCode,
    })
  }

  public static openSignup(signupProps?: {
    coupon?: string
    userSubscriptionProductType: UserSubscriptionProductType
  }) {
    Analytics.client.track('openSignup', {
      coupon: signupProps?.coupon,
      userSubscriptionProductType: signupProps?.userSubscriptionProductType,
    })
  }

  public static openBuyNow(buyNowProps?: {
    plantype?: 'Monthly' | 'Anual'
    coupon?: string
    userSubscriptionProductType: UserSubscriptionProductType
  }) {
    Analytics.client.track('openBuyNow', {
      plantype: buyNowProps?.plantype,
      coupon: buyNowProps?.coupon,
      userSubscriptionProductType: buyNowProps?.userSubscriptionProductType,
    })
  }

  public static openInstagram() {
    Analytics.client.track('openInstagram')
  }

  public static openTwitter() {
    Analytics.client.track('openTwitter')
  }

  public static viewTerms() {
    Analytics.client.track('viewTerms')
  }

  public static initiateCheckout({
    planType,
    price,
    currency,
    userSubscriptionProductType,
    pricingCode,
    promoCode,
  }: {
    planType: UserSubscriptionType
    price: number
    currency: CurrencyCode
    userSubscriptionProductType: UserSubscriptionProductType
    pricingCode: string
    promoCode?: string
  }) {
    Analytics.client.track('initiateCheckout', {
      planType,
      price,
      currency,
      userSubscriptionProductType,
      pricingCode,
      promoCode,
    })
  }

  public static purchase({
    planType,
    price,
    currency,
    userSubscriptionProductType,
    pricingCode,
    promoCode,
  }: {
    planType: UserSubscriptionType
    price: number
    currency: CurrencyCode
    userSubscriptionProductType: UserSubscriptionProductType
    pricingCode: string
    promoCode?: string
  }) {
    Analytics.client.track('purchase', {
      planType,
      price,
      currency,
      userSubscriptionProductType,
      pricingCode,
      promoCode,
    })
  }

  public static discordConnected({
    subscriptionType,
    userSubscriptionProductType,
  }: {
    subscriptionType?: UserSubscriptionType | null
    userSubscriptionProductType: UserSubscriptionProductType
  }) {
    Analytics.client.track('discordConnected', {
      subscriptionType,
      userSubscriptionProductType,
    })
  }

  public static ambassadorSignedUp() {
    Analytics.client.track('ambassadorSignedUp')
  }
}
