import React from 'react'
import { Button } from '../../components/button'
import { Icon } from '../../components/icon'
import { Icons } from '../../components/icon/icon'
import { WebRoutes } from '../routes/WebRoutes'
import styles from './resubscribe.module.scss'

export const ResubscribeSuccess = () => {
  return (
    <div className={styles['resubscribe-success']}>
      <Icon
        type={Icons['check-circle']}
        className={styles['resubscribe-success__icon']}
      />
      <h1 className={styles['resubscribe-success__heading']}>
        Subscription Renewed
      </h1>
      <p className={styles['resubscribe-success__sub-heading']}>
        You can now continue using Secured.
      </p>
      <Button
        className={styles['resubscribe-success__button']}
        styleType="fill"
        colour="primary"
        href={WebRoutes.login}
      >
        Go to Secured Hub
      </Button>
    </div>
  )
}
