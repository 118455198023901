import Firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/remote-config'
import 'firebase/messaging'
import { FIREBASE_CONFIG } from '../../constants'
import type { User } from 'packlets/generated'

export class FirebaseService {
  private static firebaseInstance: Firebase.app.App

  private static analyticsInstance: Firebase.analytics.Analytics

  private static remoteConfigInstance: Firebase.remoteConfig.RemoteConfig

  private static messagingInstance: Firebase.messaging.Messaging

  public static get firebase() {
    return this.firebaseInstance
  }

  public static get analytics() {
    return this.analyticsInstance
  }

  public static get remoteConfig() {
    return this.remoteConfigInstance
  }

  public static get messaging() {
    return this.messagingInstance
  }

  public static initialize() {
    const config = FIREBASE_CONFIG

    this.firebaseInstance = Firebase.initializeApp(config)
    this.analyticsInstance = this.firebaseInstance.analytics()
    this.remoteConfigInstance = this.firebaseInstance.remoteConfig()
    if ('Notification' in window && 'PushManager' in window) {
      this.messagingInstance = this.firebaseInstance.messaging()
    }
  }

  public static userDidLogIn(user: User) {
    this.analytics?.setUserId(user.id)
    this.analytics?.setUserProperties({
      securedUserId: user.id,
      isAdmin: String(user.isAdmin),
    })
  }

  public static userDidLogOut() {
    this.analytics?.setUserId('')
  }
}
