import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { isLinkExternal } from 'utils/utils'
import type { LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { forwardRef, Link as ChakraLink } from '@chakra-ui/react'

interface Props extends Omit<ChakraLinkProps, 'href' | 'isExternal' | 'as'> {
  to: string
}

const SmartLink: React.FC<Props> = forwardRef(
  ({ to, target, children, ...rest }, ref) => {
    const isExternal = isLinkExternal(to)

    return isExternal ? (
      <ChakraLink
        ref={ref}
        href={to}
        target={target || '_blank'}
        _hover={{ textDecoration: 'none' }}
        {...rest}
      >
        {children}
      </ChakraLink>
    ) : (
      <ChakraLink
        ref={ref}
        as={RouterLink}
        to={to}
        _hover={{ textDecoration: 'none' }}
        {...rest}
      >
        {children}
      </ChakraLink>
    )
  }
)

export default SmartLink
