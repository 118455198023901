import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'

export const DeepLinkListener = () => {
  const history = useHistory()
  useEffect(() => {
    App.addListener('appUrlOpen', (data) => {
      const slug = data.url.split(process.env.REACT_APP_HOST ?? '')[1]
      if (slug) {
        history.push(slug)
        void Browser.close()
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })
  }, [history])

  return null
}
