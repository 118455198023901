import { interactive } from '../typography'
import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'

export const Badge: ComponentSingleStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
  },
  variants: {
    outline: {
      textTransform: 'none',
    },
    solid: {
      textTransform: 'none',
    },
    subtle: {
      textTransform: 'none',
    },
  },
  sizes: {
    sm: {
      py: 1,
      px: 2,
      ...interactive.sm,
    },
    md: {
      py: 1,
      px: 3,
      ...interactive.md,
    },
    lg: {
      py: 1,
      px: 3,
      ...interactive.lg,
    },
  },
  defaultProps: {
    size: 'md',
  },
}
