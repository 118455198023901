import { useMemo } from 'react'
import { useColorModeValue } from '@chakra-ui/react'

const sizes = { sm: 2, md: 4, lg: 6 }

export const useScrollStyles = ({ size }: { size: 'sm' | 'md' | 'lg' }) => {
  const trackBgMoz = useColorModeValue(
    'inherit var(--chakra-colors-gray-600)',
    'inherit var(--chakra-colors-gray-600)'
  )
  const trackBg = useColorModeValue('gray.300', 'whiteAlpha.200')
  const trackBgHover = useColorModeValue('gray.400', 'whiteAlpha.300')
  return useMemo(() => {
    return {
      '&::-webkit-scrollbar': {
        width: `${sizes[size]}px`,
        height: `${sizes[size]}px`,
      },
      '&::-webkit-scrollbar-track': { background: 'inherit' },
      '&::-webkit-scrollbar-thumb': { background: trackBg },
      '&::-webkit-scrollbar-thumb:hover': { background: trackBgHover },
      msOverflowStyle: 'none' /* IE and Edge */,
      scrollbarWidth: `${sizes[size]}px` /* Firefox */,
      scrollbarHeight: `${sizes[size]}px` /* Firefox */,
      scrollbarColor: trackBgMoz,
    }
  }, [size, trackBg, trackBgHover, trackBgMoz])
}
