import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './styles.module.scss'
import { Button } from '../../components/button'

import type {
  CountryCode,
  DiscordInterest,
  DiscordInterestType,
  Maybe,
} from 'packlets/generated'
import { useQuery } from '@apollo/client'
import InterestSkeleton from './interest-skeleton'
import CheckboxGroup from '../../components/form/checkbox'
import { GetDiscordInterestsDocument } from '../../gql/discord.generated'

// these are all the optional props
export interface DefaultProps {
  userInterests: DiscordInterest[]
}

export type SelectInterestsFormData = {
  interests: DiscordInterestType[]
}

const defaultProps: DefaultProps = {
  userInterests: [],
}

// these are all the required props
interface Props extends Partial<DefaultProps> {
  onFormSubmit: (data: SelectInterestsFormData) => void
  userCountry?: Maybe<CountryCode>
  countryInterests?: string[]
  onViewCallback: () => void
  isLoading: boolean
}

const SelectInterests = ({
  onFormSubmit,
  userCountry,
  onViewCallback,
  userInterests,
  isLoading,
}: Props) => {
  const onViewWithCallback = useCallback(onViewCallback, [onViewCallback])

  useEffect(() => {
    onViewWithCallback()
  }, [onViewWithCallback])

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      interests: userInterests?.map((userInterest) => userInterest.type),
    },
  })

  const onSubmit = (data: SelectInterestsFormData) => {
    onFormSubmit(data)
  }

  const { loading: loadingGetDiscordInterests, data } = useQuery(
    GetDiscordInterestsDocument,
    {
      variables: {
        input: {
          filters: {
            countryCode: userCountry,
          },
        },
      },
    }
  )

  let interestComponents = [
    <InterestSkeleton key="InterestSkeleton-1" />,
    <InterestSkeleton key="InterestSkeleton-2" />,
    <InterestSkeleton key="InterestSkeleton-3" />,
    <InterestSkeleton key="InterestSkeleton-4" />,
    <InterestSkeleton key="InterestSkeleton-5" />,
    <InterestSkeleton key="InterestSkeleton-6" />,
    <InterestSkeleton key="InterestSkeleton-7" />,
    <InterestSkeleton key="InterestSkeleton-8" />,
  ]
  if (data?.getDiscordInterests && !loadingGetDiscordInterests) {
    interestComponents = data?.getDiscordInterests.interests.map((interest) => (
      <CheckboxGroup.Checkbox
        key={interest.type}
        value={interest.type}
        title={interest.name}
        type="checkbox"
        {...register('interests')}
      />
    ))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div>
          <div>
            <CheckboxGroup
              {...register('interests')}
              className={
                styles['select-interests__section__form__input-row__checkboxes']
              }
              selected={watch('interests')}
            >
              {interestComponents}
            </CheckboxGroup>
          </div>

          <div className={styles['select-interests__submit-container']}>
            <Button
              size="medium"
              className={styles['select-interests__submit-container__button']}
              styleType="fill"
              colour="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

SelectInterests.defaultProps = defaultProps

export default SelectInterests
