import type { SystemStyleObject } from '@chakra-ui/styled-system'
import type { HTMLChakraProps, ThemingProps } from '@chakra-ui/react'
import {
  Box,
  forwardRef,
  Icon,
  Stack,
  useColorModeValue,
  useControllableProp,
} from '@chakra-ui/react'
import { baseTypography } from '../theme/typography'
import type { UseComboboxProps } from 'downshift'
import { useCombobox } from 'downshift'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FloatingLabel,
  FloatingLabelInput,
  FloatingLabelInputGroup,
  FloatingLabelInputRightElement,
} from '../floating-label-input-group'
import { CaretDown } from 'phosphor-react'
import { MotionBox } from '../motion'
import { useScrollStyles } from '../hooks'

export interface SelectOptionItem {
  value: string
  css?:
    | SystemStyleObject
    | ((props: { highlightedIndex: number }) => SystemStyleObject)
  isDisabled?: boolean
}

interface SelectOptionProps
  extends SelectOptionItem,
    Omit<HTMLChakraProps<'div'>, 'css'> {
  highlightedIndex: number
  index: number
  selectedItem: string | null
}

const SelectOption = forwardRef(
  (
    {
      css,
      value,
      highlightedIndex,
      index,
      selectedItem,
      isDisabled,
      ...props
    }: SelectOptionProps,
    ref
  ) => {
    const hoverOrSelectedBg = useColorModeValue('gray.200', 'whiteAlpha.100')
    const bg = useColorModeValue('gray.50', 'gray.900')
    const disabledColor = useColorModeValue('gray.400', 'gray.800')
    const mergedStyles = useMemo<SystemStyleObject>(() => {
      const customStyles =
        typeof css === 'function'
          ? css({
              highlightedIndex,
            }) ?? {}
          : css ?? {}
      return {
        w: 'full',
        ...baseTypography.body,
        userSelect: 'none',
        borderRadius: 'md',
        color: isDisabled ? disabledColor : undefined,
        bg: highlightedIndex === index ? hoverOrSelectedBg : bg,
        _hover: {
          bg: isDisabled ? undefined : hoverOrSelectedBg,
        },
        fontWeight:
          selectedItem === value ? 'bold' : baseTypography.body.fontWeight,
        p: 4,
        ...customStyles,
      }
    }, [
      disabledColor,
      isDisabled,
      bg,
      css,
      highlightedIndex,
      hoverOrSelectedBg,
      index,
      selectedItem,
      value,
    ])

    return (
      <Box as="li" sx={mergedStyles} {...props} ref={ref}>
        {value}
      </Box>
    )
  }
)

export interface SelectProps
  extends Omit<HTMLChakraProps<'div'>, 'onChange'>,
    ThemingProps<'Input'> {
  value?: string | null
  onChange?: UseComboboxProps<string>['onSelectedItemChange']
  label: string
  placeholder?: string
  options: SelectOptionItem[]
  sxLabel?: SystemStyleObject
  sxInput?: SystemStyleObject
  sxInputGroup?: SystemStyleObject
  isInvalid?: boolean
  isDisabled?: boolean
}

export const Select = forwardRef<SelectProps, 'div'>(
  (
    {
      value: valueProp,
      onChange: onChangeProp,
      label,
      options,
      placeholder,
      variant,
      colorScheme,
      bg,
      size,
      sxInput,
      sxLabel,
      sxInputGroup,
      sx: containerSx,
      isInvalid,
      isDisabled,
      ...rest
    },
    ref
  ) => {
    const [inputItems, setInputItems] = useState(options)
    const [internalState, setInternalValue] = useState<string>('')
    const [isControlled, value] = useControllableProp<string | null>(
      valueProp,
      internalState
    )

    const onInputValueChange = useCallback<
      NonNullable<UseComboboxProps<string>['onInputValueChange']>
    >(
      ({ inputValue }) => {
        setInputItems(
          options.filter((item) =>
            item.value
              .toLowerCase()
              .includes(inputValue?.toLowerCase() ?? '_____')
          )
        )
      },
      [options]
    )

    const onIsOpenChange = useCallback<
      NonNullable<UseComboboxProps<string>['onIsOpenChange']>
    >(() => {
      setInputItems(options)
    }, [options])

    const onSelectedItemChange = useMemo(() => {
      const uncontrolledChange: NonNullable<
        UseComboboxProps<string>['onSelectedItemChange']
      > = ({ selectedItem }) => {
        setInternalValue(selectedItem ?? '')
      }
      return isControlled
        ? onChangeProp ?? uncontrolledChange
        : uncontrolledChange
    }, [onChangeProp, isControlled])

    const stateReducer = useCallback<
      NonNullable<UseComboboxProps<string>['stateReducer']>
    >((state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: state.selectedItem ?? changes.inputValue,
          }
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return {
            ...changes,
            selectedItem: state.selectedItem,
            isOpen: false,
            inputValue: state.inputValue,
          }
        default:
          return changes
      }
    }, [])

    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      openMenu,
      selectedItem,
      getItemProps,
      reset,
    } = useCombobox<string>({
      items: inputItems.map((it) => it.value),
      selectedItem: value,
      onSelectedItemChange,
      onInputValueChange,
      onIsOpenChange,
      stateReducer,
    })

    useEffect(() => {
      if (
        valueProp &&
        !options.find((it) => it.value === valueProp && !it.isDisabled)
      ) {
        reset()
        console.warn(
          `Controlled DropdownCombobox tried to set non existent or disabled option ${valueProp}. It caused forced reset. Please do not set unknown values as value prop`
        )
      }
    }, [options, reset, valueProp])

    const containerSxMerged = useMemo(() => {
      return {
        w: 'full',
        pos: 'relative',
        ...containerSx,
      }
    }, [containerSx])
    const scrollStyles = useScrollStyles({ size: 'md' })
    const optionsContainerBg = useColorModeValue('gray.50', 'gray.900')
    const optionsContainerStyles = useMemo<SystemStyleObject>(
      () => ({
        maxHeight: 400,
        w: 'full',
        overflowY: 'scroll',
        backgroundColor: optionsContainerBg,
        padding: 0,
        listStyle: 'none',
        position: 'absolute',
        zIndex: 'dropdown',
        borderRadius: 'md',
        transform: 'translate(0, var(--chakra-space-1))',
        px: 1,
        ...scrollStyles,
      }),
      [optionsContainerBg, scrollStyles]
    )

    const labelSxMerged = useMemo(() => {
      return {
        cursor: isDisabled ? 'not-allowed' : undefined,
        ...sxLabel,
      }
    }, [isDisabled, sxLabel])

    return (
      <Box sx={containerSxMerged} {...rest}>
        <FloatingLabelInputGroup
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          sx={sxInputGroup}
          {...getComboboxProps({
            disabled: isDisabled,
            onClick: () => {
              !isDisabled && openMenu()
            },
          })}
        >
          <FloatingLabel
            {...getLabelProps({ disabled: isDisabled })}
            sx={labelSxMerged}
          >
            {label}
          </FloatingLabel>
          <FloatingLabelInput
            {...getInputProps({
              disabled: isDisabled,
              ref,
            })}
            sx={sxInput}
            placeholder={placeholder}
            bg={bg}
          />
          <FloatingLabelInputRightElement>
            <MotionBox animate={{ rotate: isOpen ? 180 : 0 }}>
              <Icon
                _active={{ outline: 'none' }}
                _focus={{ outline: 'none' }}
                {...getToggleButtonProps({
                  disabled: isDisabled,
                  onClick(e) {
                    e.stopPropagation()
                  },
                })}
                as={CaretDown}
                cursor={isDisabled ? 'not=allowed' : 'pointer'}
                size={16}
              />
            </MotionBox>
          </FloatingLabelInputRightElement>
        </FloatingLabelInputGroup>
        <Stack
          spacing={1}
          as="ul"
          {...getMenuProps()}
          sx={optionsContainerStyles}
        >
          {isOpen &&
            inputItems.map((item, index) => {
              return (
                <SelectOption
                  selectedItem={selectedItem}
                  highlightedIndex={highlightedIndex}
                  index={index}
                  isDisabled={item.isDisabled}
                  {...item}
                  key={`${item.value}`}
                  {...getItemProps({
                    item: item.value,
                    index,
                    disabled: item.isDisabled,
                  })}
                />
              )
            })}
        </Stack>
      </Box>
    )
  }
)

Select.displayName = 'DropdownCombobox'
