import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import type { LayoutProps, SpaceProps } from '@chakra-ui/react'
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { AppNavigation } from './app-navigation'
import { isAdblockEnabled } from '../../utils/adblockCheck'
import { AdblockBanner } from './adblock-banner'
import {
  fullScreenHeight,
  internalHeaderHeight,
  internalNavigationHeight,
  useScrollStyles,
} from 'ui-kit'
import { Container } from './container'
import { SideNav } from './side-nav'
import { AppHeader } from './app-header'
import { AppConfig } from '../../services/AppConfig'

interface Props extends SpaceProps, LayoutProps {
  headerTitle: string
  defaultContainerRef?: React.Ref<HTMLDivElement>
  headingRightSection?: React.ReactNode
  headingLeftSection?: React.ReactNode
  headingVariant?: 'default' | 'center'
  headingSize?: 'sm' | 'md'
}

export const InternalLayout: FunctionComponent<Props> = ({
  headerTitle,
  children,
  defaultContainerRef,
  headingRightSection,
  headingLeftSection,
  headingVariant,
  headingSize,
  ...rest
}) => {
  const [showAdblockBanner, setShowAdblockBanner] = useState(false)

  useEffect(() => {
    const getAdblockState = async () => {
      const adblockState = await isAdblockEnabled()
      setShowAdblockBanner(adblockState)
    }

    getAdblockState()
  }, [])

  const isMobileScreenSize = useBreakpointValue(
    [true, true, false],
    AppConfig.isNativePlatform ? 'base' : 'xl'
  )

  return (
    <Container
      as={Flex}
      sx={{
        maxWidth: '100vw',
        maxHeight: fullScreenHeight,
      }}
    >
      <AppHeader
        variant={headingVariant}
        size={headingSize}
        rightSection={headingRightSection}
        leftSection={headingLeftSection}
      >
        {headerTitle}
      </AppHeader>

      {!isMobileScreenSize ? <SideNav /> : <AppNavigation />}
      <Box
        ref={defaultContainerRef}
        __css={{
          ...useScrollStyles({ size: 'lg' }),
          overflowY: 'scroll',
          overflowX: 'hidden',
          w: 'full',
          pos: 'relative',
          px: 6,
          pt: `calc(var(--chakra-space-4) + ${internalHeaderHeight})`,
          pb: isMobileScreenSize
            ? `calc(var(--chakra-space-4) + ${internalNavigationHeight})`
            : 4,
        }}
        {...rest}
      >
        {showAdblockBanner ? <AdblockBanner /> : <></>}
        {children}
      </Box>
    </Container>
  )
}
