import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'

export const openInInternalBrowserOrRedirect = (
  url: string,
  newTab?: boolean
) => {
  if (Capacitor.isNativePlatform()) {
    void Browser.open({
      url,
    })
  } else if (newTab) {
    window.open(url, '_blank')?.focus()
  } else {
    window.location.href = url
  }
}
