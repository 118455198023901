import type { CurrencyCode } from 'packlets/generated'

declare const twq: twitter.Pixel.Event

declare module twitter.Pixel {
  export interface Event {
    (
      eventType: string,
      event: TwitterEventType,
      options?: TwitterEventOptions
    ): void
  }
}

/**
 * twitter pixel documentation
 * https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
 */
export enum TwitterEventType {
  ViewContent = 'ViewContent',
  InitiateCheckout = 'InitiateCheckout',
  Purchase = 'Purchase',
}

export interface TwitterEventOptions {
  num_items: number
  value: number
  currency: CurrencyCode
}

export class TwitterPixel {
  public static track(event: TwitterEventType, options?: TwitterEventOptions) {
    if (options?.currency) {
      options.currency = options?.currency.toUpperCase() as any
    }

    twq('track', event, options)
  }
}
