import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Button } from 'components/button'

import type { CountryCode, Maybe } from 'packlets/generated'
import { ExperienceLevel } from 'packlets/generated'
import { ButtonRadioGroup } from '../button-radio-group'

// these are all the optional props
export interface DefaultProps {
  experience?: ExperienceLevel
}

export type SelectExperienceLevelFormData = {
  experience: ExperienceLevel
}

const defaultProps: DefaultProps = {}

// these are all the required props
interface Props extends Partial<DefaultProps> {
  onFormSubmit: (data: SelectExperienceLevelFormData) => void
  userCountry?: Maybe<CountryCode>
  onViewCallback: () => void
  experience?: ExperienceLevel
  isLoading: boolean
}

const SelectExperienceLevel = ({
  onFormSubmit,
  onViewCallback,
  experience: experienceProp,
  isLoading,
}: Props) => {
  const onViewWithCallback = useCallback(onViewCallback, [onViewCallback])

  useEffect(() => {
    onViewWithCallback()
  }, [onViewWithCallback])

  const [experience, setExperience] = useState<string>(
    experienceProp as ExperienceLevel
  )

  const isFormButtonEnabled = experience

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    onFormSubmit({ experience: experience as ExperienceLevel })
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        <div>
          <div>
            <ButtonRadioGroup
              onChange={setExperience}
              value={experience}
              options={[
                {
                  value: ExperienceLevel.Beginner,
                  label: 'Beginner',
                  description:
                    'You are brand new to reselling, cook groups and other things like bots and monitors.',
                },
                {
                  value: ExperienceLevel.Intermediate,
                  label: 'Intermediate',
                  description:
                    'You are familiar with cook groups, how they work and the tools that are used.',
                },
              ]}
            />
          </div>

          <div className={styles['select-experience-level__submit-container']}>
            {!isFormButtonEnabled && (
              <Button
                size="medium"
                className={
                  styles['select-experience-level__submit-container__button']
                }
                disabled
              >
                Next
              </Button>
            )}
            {isFormButtonEnabled && (
              <Button
                size="medium"
                className={
                  styles['select-experience-level__submit-container__button']
                }
                styleType="fill"
                colour="primary"
                onClick={onSubmit}
                disabled={isLoading}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

SelectExperienceLevel.defaultProps = defaultProps

export default SelectExperienceLevel
