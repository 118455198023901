import React, { useRef, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Hero,
  HeroCoupon,
  HeroCtaButton,
  HeroCtaContainer,
  HeroLimitedSpacesAvailable,
  HeroSubTitle,
  HeroTitle,
} from 'components/landing/hero'
import {
  ProductImage,
  ProductInfo,
  ProductInfoDescription,
  ProductInfoText,
} from 'components/landing/product-info'
import { ExternalLayout } from 'components/layout'
import hero from '../../images/landing/sneakers-bots/hero.png'
import { Testimonials } from 'components/landing/testimonials'
import { Testimonial } from 'components/landing/testimonial'
import lukenurse7 from '../../images/landing/sneakers-bots/lukenurse7.png'
import ginosaurier from '../../images/landing/sneakers-bots/ginosaurier.png'
import bricksrus from '../../images/landing/sneakers-bots/bricksrus.png'
import soulstar from '../../images/landing/sneakers-bots/soulstar.png'
import gwpd from '../../images/landing/sneakers-bots/gwpd.png'
import Img01 from '../../images/landing/sneakers-bots/Img01.png'
import Img02 from '../../images/landing/sneakers-bots/Img02.png'
import Img03 from '../../images/landing/sneakers-bots/Img03.png'
import Img04 from '../../images/landing/sneakers-bots/Img04.png'
import { CtaBlock, CtaBlockText } from 'components/landing/cta-block'
import { Feature, FeatureText } from 'components/landing/feature'
import { LandingCtaButton } from 'components/landing/landing-cta-button'
import { SupportSection } from 'components/landing/support-section'
import { Block } from 'components/landing/block'
import { Pricing } from '../../components/landing/pricing'
import { BlogBlock } from 'components/landing/blog-block'
import adidas from '../../images/landing/brands/adidas.png'
import nike from '../../images/landing/brands/nike.png'
import offWhite from '../../images/landing/brands/off-white.png'
import shopify from '../../images/landing/brands/shopify.png'
import supreme from '../../images/landing/brands/supreme.png'
import { useToasts } from 'react-toast-notifications'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Locale } from 'gql/local'
import { UserSubscriptionProductType } from 'packlets/generated'
import { Analytics } from 'services/Analytics'
import { GetPricingDataDocument } from 'gql/checkout.generated'
import { useFeatureFlags } from 'providers/remote-config'
import { SubscriptionCheckout } from 'features/subscription-checkout'
import { StripeClient } from 'services/StripeClient/StripeClient'
import {
  WorksWith,
  WorksWithBrandCard,
} from '../../components/landing/works-with'
import { BsDiscord } from 'react-icons/bs'
import { Chat } from 'phosphor-react'

const stripePromise = StripeClient.loadStripe()

interface Props {
  coupon?: string
  errorDescription?: string
}

export const LandingSneakersBots: React.FC<Props> = ({
  coupon,
  errorDescription,
}) => {
  const toast = useToasts()
  const locale = useReactiveVar(Locale)
  const subscriptionSectionRef = useRef<HTMLDivElement>(null)

  const onSignupClick = () => {
    Analytics.openSignup({
      coupon,
      userSubscriptionProductType: UserSubscriptionProductType.Sneakers,
    })

    scrollToSubscriptionSection()
  }

  const scrollToSubscriptionSection = (behavior: ScrollBehavior = 'smooth') => {
    if (subscriptionSectionRef) {
      subscriptionSectionRef.current?.scrollIntoView({ behavior })
    }
  }

  const { isAbTestingPriceActive: isAbTestingPriceActiveValue } =
    useFeatureFlags()

  const isAbTestingPriceActive =
    isAbTestingPriceActiveValue !== undefined
      ? isAbTestingPriceActiveValue?.asBoolean() === true
      : null
  const pricingCode = 'ENY'

  const { data: pricingData, loading: loadingPricingData } = useQuery(
    GetPricingDataDocument,
    {
      variables: {
        input: {
          currency: locale.currency,
          promoCode: coupon,
          pricingCode,
        },
      },
    }
  )

  useEffect(() => {
    if (errorDescription) {
      toast.removeAllToasts()
      toast.addToast(errorDescription, {
        appearance: 'error',
        autoDismiss: false,
      })
    }
    // eslint-disable-next-line
  }, [errorDescription])

  return (
    <ExternalLayout onSignup={onSignupClick}>
      <Hero>
        <HeroTitle>
          Rent or run slots with the best bots on the market
        </HeroTitle>
        <HeroSubTitle>
          Buy the sneakers you want at retail price using the best bots in the
          market. We'll teach you exactly how to use them.
        </HeroSubTitle>
        <HeroCtaContainer>
          <HeroCtaButton onClick={onSignupClick}>Sign Up</HeroCtaButton>

          <HeroLimitedSpacesAvailable>
            Limited spaces available
          </HeroLimitedSpacesAvailable>

          {coupon &&
            pricingData?.getPricingData.monthly.discountMetadata?.amount && (
              <HeroCoupon>
                Use Code: {coupon} for{' '}
                {pricingData?.getPricingData.monthly.discountMetadata?.amount}%
                off
              </HeroCoupon>
            )}
        </HeroCtaContainer>
      </Hero>

      <ProductInfo>
        <ProductImage src={hero} alt="Secured hero" />
        <ProductInfoDescription>
          <ProductInfoText>
            <p>
              Secured is a sneakerhead community that gives you access to the
              best sneaker bots on the market. We'll teach you how to use them
              with in-depth guides and Zoom calls, and you can rent them out
              from us at a reasonable price.
            </p>
            <p>
              If you're not confident running a bot yet - let an expert from
              Secured run a slot for you. Only pay if we cop for you.
            </p>
            <p>
              Every member gets complimentary access to the Secured Bot, which
              can help you check out up to five times faster on more than 200
              websites, including Shopify, Yeezy Supply, and Supreme.
            </p>
          </ProductInfoText>
        </ProductInfoDescription>
      </ProductInfo>
      <WorksWith>
        <WorksWithBrandCard src={shopify} alt="shopify" />
        <WorksWithBrandCard src={supreme} alt="supreme" />
        <WorksWithBrandCard src={nike} alt="nike" />
        <WorksWithBrandCard src={offWhite} alt="offWhite" />
        <WorksWithBrandCard src={adidas} alt="adidas" />
      </WorksWith>
      <Testimonials>
        <Testimonial
          description="I've been here 5 days. Made £800+. Used the FREE bot which comes with the discord to cop the supreme dunks. Thanks to Secured’s raffle monitors I was able to enter all raffles and was able to secure a pair of the Jordan 1 University Blues! On top of that I had 2 Zoom calls with the admins who walked me through how ACO works. 1000% Recommend!"
          imageUrl={lukenurse7}
          username="lukenurse7"
        />
        <Testimonial
          description="def worth the money. The botting guides are awesome it's literally step-by-step guidance on how to set them up and run them. not 100% confident in my botting skills just yet but will get there in no time. Their Secured Bot is pretty awesome got to give 'em credit. don't be insecure, come learn with Secured guys"
          imageUrl={ginosaurier}
          username="ginosaurier"
        />
        <Testimonial
          description="missed a bunch of drops in 2021 because of bots so I was like fuc* it. I'll start running them too so ended up with Secured after seeing their collaboration with Frugal on YouTube. Man this is a nice group, bots like Cyber, Wrath, Kodai or TSB. you name it they have it. With the guidance of their team I’ve gotten much better at botting now. def worth it guys! "
          imageUrl={bricksrus}
          username="BricksR'us"
        />
        <Testimonial
          description="got mad when I took an L on a pair of Seafoam for my girl. I was like enough is enough. Knew about these guys from Instagram ads they're literally everywhere. Started learning about bots with some patient guidance thanks to @sb and @shmurda and now I am ready to run for the Marina Blue drop in February. Thumbs up Secured!"
          imageUrl={soulstar}
          username="Soulstar"
        />
        <Testimonial
          description="Secured ACO is very good, can submit bulk profiles for better chances at checkouts"
          imageUrl={gwpd}
          username="GWPD"
        />
      </Testimonials>

      <CtaBlock>
        <CtaBlockText>
          Join Secured and start getting sneakers for retail prices
        </CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>

      <Feature
        title="Learn how to bot with guides and advice from our team of sneakerheads "
        imageUrl={Img01}
        imageLocation="right"
      >
        <FeatureText>
          Check our step-by-step guides and learn how to bot like a PRO. How to
          set up bots, how to bot Supreme, Shopify, Yeezy Supply, and many more.
        </FeatureText>
        <FeatureText>
          Still not sure? Book a Zoom call with an expert from Secured, and we
          will answer any questions you have.
        </FeatureText>
      </Feature>

      <Feature
        title="Check out faster up to five times with the Secured Bot "
        imageUrl={Img02}
        imageLocation="left"
      >
        <FeatureText>
          Stop going manually for drops. Increase your chances of buying for
          retail up to five times with the Secured ACO Bot. It works with more
          than 200 websites, including Kith, Undefeated, and Footlocker.
        </FeatureText>
      </Feature>

      <Feature
        title="Rent the best sneaker bots on the market at reasonable prices"
        imageUrl={Img03}
        imageLocation="right"
      >
        <FeatureText>
          Buying sneaker bots is not cost-effective. Rent one from Secured,
          learn how to use it, run it for the drop you want and get'em for
          retail.
        </FeatureText>
      </Feature>

      <Feature
        title="Let an expert sneakerhead run a slot for you"
        imageUrl={Img04}
        imageLocation="left"
      >
        <FeatureText>
          Not comfortable yet with bots? A member of our expert team can run a
          bot for you and buy the pair you want for retail. No risk involved -
          pay us only if we cop.
        </FeatureText>
      </Feature>

      <CtaBlock>
        <CtaBlockText>Ready to learn how to bot?</CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>

      <SupportSection>
        <Block
          title="Community Access"
          subTitle="Connect with fellow sneakerheads"
          description="Secured is a diverse community of enthusiasts which means regardless of your experience level, beginner or expert, there's always people that you can chat with and learn from."
          icon={BsDiscord}
        />
        <Block
          title="1 on 1 support"
          subTitle="You’re in the best place to learn"
          description="with our easy to understand written guides and around the clock support staff, you can quickly become an expert in getting sneakers for retail prices."
          icon={Chat}
        />
      </SupportSection>
      <Pricing
        ref={subscriptionSectionRef}
        coupon={coupon}
        amount={pricingData?.getPricingData.monthly.discountMetadata?.amount}
      >
        <Elements stripe={stripePromise}>
          <SubscriptionCheckout
            mt={12}
            coupon={coupon}
            userSubscriptionProductType={UserSubscriptionProductType.Sneakers}
            monthly={pricingData?.getPricingData.monthly}
            annual={pricingData?.getPricingData.annual}
            currency={locale.currency}
            pricingCode={pricingCode}
            isLoading={isAbTestingPriceActive === null || loadingPricingData}
          />
        </Elements>
      </Pricing>
      <BlogBlock>
        Visit the Secured Blog to learn more about sneakers and how Secured can
        help you secure more pairs.
      </BlogBlock>
    </ExternalLayout>
  )
}
