import classNames from 'classnames'
import React from 'react'
import { Button } from '../../../components/button'
import { Icon } from '../../../components/icon'
import { Icons } from '../../../components/icon/icon'
import { StatusTag } from '../../../components/status-tag'
import styles from './connect-discord.module.scss'

export enum ConnectDiscordState {
  default = 'default',
  connected = 'connected',
  failed = 'failed',
}

type ContentModel = {
  description: string | JSX.Element
  isSuccessStatusTagVisible: boolean
  isFailureStatusTagVisible: boolean
  isButtonVisible: boolean
  isFootnoteVisible: boolean
}

interface DefaultProps {
  className?: string
  isButtonDisabled: boolean
}

interface Props extends Partial<DefaultProps> {
  state: ConnectDiscordState
  error: string | null
  onConnectDiscord: () => void
}

const defaultProps: DefaultProps = {
  isButtonDisabled: false,
}

const ConnectDiscord: React.FC<Props> = ({
  className,
  state,
  error,
  isButtonDisabled,
  onConnectDiscord,
}) => {
  const getContentModel = (): ContentModel => {
    switch (state) {
      default:
      case ConnectDiscordState.default:
        return {
          description:
            'Use Discord to chat with the Secured community and keep up to date with release notifications and other discussions. You will need Discord to log in and use Secured.',
          isSuccessStatusTagVisible: false,
          isFailureStatusTagVisible: false,
          isButtonVisible: true,
          isFootnoteVisible: true,
        }
      case ConnectDiscordState.connected:
        return {
          description:
            'You can now access the Secured Hub to continue your account setup.',
          isSuccessStatusTagVisible: true,
          isFailureStatusTagVisible: false,
          isButtonVisible: false,
          isFootnoteVisible: false,
        }
      case ConnectDiscordState.failed: {
        const descriptionText = `${error} If you are having issues you can contact us`

        return {
          description: (
            <>
              {descriptionText}{' '}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a
                className={
                  styles['connect-discord__section__description__link']
                }
                href="mailto:hello@secured.community"
              >
                here
              </a>
              .
            </>
          ),
          isSuccessStatusTagVisible: false,
          isFailureStatusTagVisible: true,
          isButtonVisible: true,
          isFootnoteVisible: false,
        }
      }
    }
  }

  const content = getContentModel()

  return (
    <div className={classNames([className, styles['connect-discord']])}>
      <h3 className={styles['connect-discord__h3']}>Connect Discord</h3>
      <div className={styles['connect-discord__section']}>
        {content.isSuccessStatusTagVisible && (
          <StatusTag
            className={styles['connect-discord__section__status-tag']}
            title="Discord Connected"
            color="success"
            size="medium"
            icon={<Icon type={Icons['check-circle']} />}
          />
        )}
        {content.isFailureStatusTagVisible && (
          <StatusTag
            className={styles['connect-discord__section__status-tag']}
            title="Connection Failed"
            color="error"
            size="medium"
          />
        )}
        <p className={styles['connect-discord__section__description']}>
          {content.description}
        </p>
        {content.isButtonVisible && (
          <Button
            className={styles['connect-discord__section__button']}
            styleType="fill"
            colour="primary"
            icon={
              <Icon
                type={Icons.discord}
                className={styles['connect-discord__section__button__icon']}
              />
            }
            iconPosition="left"
            disabled={isButtonDisabled}
            onClick={onConnectDiscord}
          >
            Connect
          </Button>
        )}
      </div>
      {content.isFootnoteVisible && (
        <p className={styles['connect-discord__footnote']}>
          Don’t have a Discord Account? you can create one{' '}
          <a
            target="_blank"
            href="https://discord.com/register"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      )}
    </div>
  )
}

ConnectDiscord.defaultProps = defaultProps

export default ConnectDiscord
