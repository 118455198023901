import type { FunctionComponent } from 'react'
import React from 'react'
import type { ToastContainerProps } from 'react-toast-notifications'
import styles from './toast-container.module.scss'

const ToastContainer: FunctionComponent<ToastContainerProps> = ({
  children,
}) => {
  return <div className={styles['toast-container']}>{children}</div>
}

export default ToastContainer
