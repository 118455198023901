import type { ReactNode } from 'react'
import React from 'react'
import Checkbox from './checkbox'
import type { DefaultProps, Props } from './checkbox-group-types'

const defaultProps: DefaultProps = {
  children: [],
  selected: '',
}

type IFC<P> = React.FunctionComponent<P> & {
  Checkbox: typeof Checkbox
}

const CheckboxGroup: IFC<Props> = ({
  children,
  className,
  selected,
  onChange,
}) => {
  const createChildElement = (
    child: ReactNode | React.ReactElement,
    index: number = 0
  ) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        key: `${child.props.name}${index}`,
        selected,
        onChange,
      })
    }
    return null
  }

  return (
    <div className={className}>
      {Array.isArray(children) && children.map(createChildElement)}
      {!Array.isArray(children) && createChildElement(children, 0)}
    </div>
  )
}

CheckboxGroup.Checkbox = Checkbox
CheckboxGroup.defaultProps = defaultProps

export default CheckboxGroup
