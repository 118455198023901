import React from 'react'
import { useEffect } from 'react'
import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton, Tooltip, useColorModeValue } from '@chakra-ui/react'
import { useColorMode } from '@chakra-ui/react'
import { Moon, Sun } from 'phosphor-react'

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode()
  const mode = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(Moon, Sun)
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const windowsCondition =
        (e.key === 't' || e.key === 'T') && e.ctrlKey && e.altKey
      const macCondition = e.key === '†' && e.metaKey && e.altKey

      if (windowsCondition || macCondition) {
        toggleColorMode()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [toggleColorMode])
  return (
    <Tooltip label="Toggle theme (beta)">
      <IconButton
        size="sm"
        fontSize="lg"
        colorScheme={mode === 'light' ? 'yellow' : 'blue'}
        variant="ghost"
        ml="2"
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        aria-label={`Switch to ${mode} mode (beta)`}
        {...props}
      />
    </Tooltip>
  )
}
