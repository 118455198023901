export type SecuredCountryDataType = {
  id: string
  name: string
  interests: string[]
}

export type SecuredCityDataType = {
  id: string
  name: string
}

export type SecuredInterestsDataType = {
  id: string
  name: string
  description: string
}

export const COUNTRIES: SecuredCountryDataType[] = [
  {
    id: '799680667024752731',
    name: 'USA',
    interests: [
      '801116865568047124',
      '801116866637594668',
      '801116863928991755',
      '801116859629699183',
      '801116864943357972',
      '801129360907173888',
      '801117238131687434',
      '801117362316640297',
    ],
  },
  {
    id: '799680686008041543',
    name: 'Canada',
    interests: [
      '801116865568047124',
      '801116866637594668',
      '801116863928991755',
      '801116859629699183',
      '801116864943357972',
      '801129360907173888',
      '801117238131687434',
      '801117362316640297',
    ],
  },
  {
    id: '801885430902554625',
    name: 'Rest of World',
    interests: [
      '801116865568047124',
      '801116866637594668',
      '801116863928991755',
      '801116859629699183',
      '801116864943357972',
      '801129360907173888',
      '801117238131687434',
      '801117362316640297',
    ],
  },
]

export const CITIES: SecuredCityDataType[] = [
  {
    id: '801885665104101406',
    name: 'New York',
  },
  {
    id: '801885680413573140',
    name: 'LA',
  },
  {
    id: '801885694426742795',
    name: 'Chicago',
  },
  {
    id: 'other',
    name: 'Other',
  },
]

export const INTERESTS: SecuredInterestsDataType[] = [
  {
    id: '801116865568047124',
    name: 'Bot Restocks',
    description: 'Buying bots for retail prices.',
  },
  {
    id: '801116866637594668',
    name: 'Bot Flipping',
    description:
      'Purchasing bots with the intention of selling them on for profits.',
  },
  {
    id: '801117238131687434',
    name: 'Group Buys',
    description:
      'Interested in any Group Buys (Bots, Proxies) that Secured partners with.',
  },
  {
    id: '801116863928991755',
    name: 'Lowkey Flips',
    description:
      'Interested in reselling Collectables/Cards/Toys. E.G Funko/Kaws.',
  },
  {
    id: '801116859629699183',
    name: 'Brick Flips',
    description:
      'Interested in reselling less popular items that are still profitable - mainly done through using discount codes on sites.',
  },
  {
    id: '801116864943357972',
    name: 'USA Restocks',
    description: 'Interested in being notified about restocks.',
  },
  {
    id: '801129360907173888',
    name: 'Gaming',
    description: 'Interested in Gaming. E.G we may host competitions.',
  },
  {
    id: '801117362316640297',
    name: 'Twitter Army',
    description:
      'Interested in helping Secured get Group Buys by commenting on Twitter posts etc when notified.',
  },
]
