import {
  Button,
  Center,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

export const BlogBlock: React.FC = ({ children }) => {
  return (
    <Center flexDirection="column">
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '585px',
          background: useColorModeValue('blackAlpha.200', 'whiteAlpha.300'),
          borderRadius: '8px',
          padding: '32px',
          marginTop: ['104px', '104px', '208px'],
          transform: 'translate3d(0, 0, 0)',
          backdropFilter: 'blur(15px)',
        }}
      >
        <Heading as="h1" textAlign="center" size="xl">
          Want to learn more?
        </Heading>
        <Text
          mt={2}
          variant="paragraph"
          color={useColorModeValue('gray.600', 'gray.400')}
          fontSize="18px"
          lineHeight="150%"
          textAlign="center"
        >
          {children}
        </Text>
        <Button
          as="a"
          mt={6}
          size="lg"
          width="100%"
          maxWidth="288px"
          href="https://blog.secured.community/"
        >
          Visit Blog
        </Button>
      </Flex>
    </Center>
  )
}
