import type { ThemeConfig } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { Button } from './style-configs/button'
import { Checkbox } from './style-configs/checkbox'
import { Radio } from './style-configs/radio'
import { Switch } from './style-configs/switch'
import { Alert } from './style-configs/alert'
import { Navigation } from './style-configs/navigation'
import { Badge } from './style-configs/badge'
import { DisplayText } from './style-configs/display-text'
import { Text } from './style-configs/text'
import { Link } from './style-configs/link'
import { Heading } from './style-configs/heading'
import { Input } from './style-configs/input'
import { mode } from '@chakra-ui/theme-tools'
import { Card } from './style-configs/card'
import { Tabs } from './style-configs/tabs'
import { AppConfig } from '../../services/AppConfig'

export const theme = extendTheme({
  config: {
    initialColorMode: 'system',
  } as ThemeConfig,
  styles: {
    global: (props: any) => ({
      body: {
        fontFamily: 'body',
        bg: mode('white', 'gray.900')(props),
      },
      ':focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
        boxShadow: 'none !important',
      },
      '*': {
        WebkitTapHighlightColor: 'transparent',
      },
    }),
  },
  fonts: {
    body: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
    mono: "'JetBrains Mono', monospaced",
  },
  colors: {
    // This is a placeholder in order for theme type generator see it as possible colorScheme in the typings
    primary: {
      50: '#000',
      100: '#000',
      200: '#000',
      300: '#000',
      400: '#000',
      500: '#000',
      600: '#000',
      700: '#000',
      800: '#000',
      900: '#000',
    },
    gray: {
      50: '#FCFCFC',
      100: '#F7F7F7',
      200: '#F0F0F0',
      300: '#E8E8E8',
      400: '#C0C0C0',
      500: '#969696',
      600: '#686868',
      700: '#484848',
      800: '#2C2C2C',
      900: '#1A1A1A',
    },
  },
  // i stands for inverted
  shadows: {
    ixs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    ism: '0 -1px 2px 0 rgba(0, 0, 0, 0.05)',
    ibase: '0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    imd: '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    ilg: '0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    ixl: '0 -20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    i2xl: '0 -25px 50px -12px rgba(0, 0, 0, 0.25)',
    // TODO: Remove when Safari supports :focus-visible https://caniuse.com/css-focus-visible
    outline: AppConfig.isNativePlatform
      ? 'none'
      : '0 0 0 3px rgba(66, 153, 225, 0.6)',
    iinner: 'inset 0 -2px 4px 0 rgba(0,0,0,0.06)',
    'idark-lg':
      'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px -5px 10px, rgba(0, 0, 0, 0.4) 0px -15px 40px',
  },
  components: {
    Alert,
    Badge,
    Button,
    Card,
    Checkbox,
    DisplayText,
    Heading,
    Input,
    Link,
    Navigation,
    Radio,
    Switch,
    Tabs,
    Text,
  },
  fontSizes: {
    '3xl': '1.75rem',
  },
})
