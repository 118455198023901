import type { SecuredServer } from 'packlets/generated'
import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { Toast } from '../components/toast'
import { ToastContainer } from '../components/toast-container'
import { UserInfoContext } from '../context/UserInfo'
import {
  defaultFeatureFlags,
  RemoteConfigProvider,
} from '../providers/remote-config'
import { FacebookPixel } from '../services/FacebookPixel'
import { TikTokEventType, TikTokPixel } from '../services/TikTokPixel'
import '../styles/index.scss'
import { Routes } from './routes'

interface DefaultProps {}

interface Props extends Partial<DefaultProps> {}

const Root: FunctionComponent<Props> = () => {
  const [discordServer, setDiscordServer] = React.useState<
    SecuredServer | undefined
  >()
  const query = new URLSearchParams(useLocation().search)
  useEffect(() => {
    FacebookPixel.pageView()
    TikTokPixel.track(TikTokEventType.Browse)
  }, [])

  const [width, setWidth] = React.useState(window.innerWidth)
  const breakpoint = 600

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <RemoteConfigProvider defaults={defaultFeatureFlags}>
      <ToastProvider
        components={{ Toast, ToastContainer }}
        placement={width < breakpoint ? 'bottom-center' : 'top-center'}
      >
        <UserInfoContext.Provider value={{ discordServer, setDiscordServer }}>
          <Routes query={query} />
        </UserInfoContext.Provider>
      </ToastProvider>
    </RemoteConfigProvider>
  )
}

export default Root
