import type { SystemStyleObject } from '@chakra-ui/styled-system'
import type { HTMLChakraProps, ThemingProps } from '@chakra-ui/react'
import {
  Box,
  Checkbox,
  forwardRef,
  Icon,
  Stack,
  useColorModeValue,
  useControllableProp,
} from '@chakra-ui/react'
import { baseTypography } from '../theme/typography'
import type { UseComboboxProps } from 'downshift'
import { useCombobox } from 'downshift'
import React, { useCallback, useMemo, useState } from 'react'
import {
  FloatingLabel,
  FloatingLabelInput,
  FloatingLabelInputGroup,
  FloatingLabelInputRightElement,
} from '../floating-label-input-group'
import { CaretUp } from 'phosphor-react'
import { MotionBox } from '../motion'
import { useScrollStyles } from '../hooks'

export interface MultiselectOptionItem {
  value: string
  css?:
    | SystemStyleObject
    | ((props: {
        highlightedIndex: number
        checked: boolean
      }) => SystemStyleObject)
  isDisabled?: boolean
}

export interface MultiselectProps
  extends Omit<HTMLChakraProps<'div'>, 'onChange'>,
    ThemingProps<'Input'> {
  value?: string[]
  onChange?: UseComboboxProps<string>['onSelectedItemChange']
  label?: string | ((selectedElements: string[]) => string)
  placeholder?: string
  options: MultiselectOptionItem[]
  sxLabel?: SystemStyleObject
  sxInput?: SystemStyleObject
  sxInputGroup?: SystemStyleObject
  isInvalid?: boolean
  isDisabled?: boolean
}

interface MultiselectOptionProps
  extends MultiselectOptionItem,
    Omit<HTMLChakraProps<'div'>, 'css'> {
  highlightedIndex: number
  index: number
  checked: boolean
}

const MultiselectOption = forwardRef(
  (
    {
      id,
      css,
      value,
      highlightedIndex,
      index,
      checked,
      isDisabled,
      ...props
    }: MultiselectOptionProps,
    ref
  ) => {
    const hoverOrSelectedBg = useColorModeValue('gray.200', 'whiteAlpha.100')
    const bg = useColorModeValue('gray.50', 'gray.900')
    const disabledColor = useColorModeValue('gray.400', 'gray.800')
    const mergedStyles = useMemo<SystemStyleObject>(() => {
      const customStyles =
        typeof css === 'function'
          ? css({
              highlightedIndex,
              checked,
            }) ?? {}
          : css ?? {}
      return {
        display: 'flex',
        justifyContent: 'space-between',
        w: 'full',
        ...baseTypography.body,
        userSelect: 'none',
        borderRadius: 'md',
        color: isDisabled ? disabledColor : undefined,
        bg: highlightedIndex === index ? hoverOrSelectedBg : bg,
        _hover: {
          bg: isDisabled ? undefined : hoverOrSelectedBg,
        },
        fontWeight: checked ? 'bold' : baseTypography.body.fontWeight,
        p: 4,
        ...customStyles,
      }
    }, [
      bg,
      checked,
      css,
      highlightedIndex,
      disabledColor,
      isDisabled,
      hoverOrSelectedBg,
      index,
    ])

    return (
      <Box as="li" sx={mergedStyles} {...props} ref={ref}>
        {value}
        <Box pos="relative">
          <Checkbox
            isDisabled={isDisabled}
            isChecked={checked}
            colorScheme="primary"
            bg={useColorModeValue('white', undefined)}
          />
          <input
            // This input is hack to overlap over Checkbox as for some reason Chakra UI checkbox causes dialog to close
            disabled={isDisabled}
            type="checkbox"
            checked={checked}
            value={value}
            onChange={() => null}
            style={{
              position: 'absolute',
              appearance: 'none',
              width: '20px',
              height: '20px',
              left: 0,
            }}
          />
        </Box>
      </Box>
    )
  }
)

export const useDefaultMultiselectOnChange = (
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  return useCallback<
    NonNullable<UseComboboxProps<string>['onSelectedItemChange']>
  >(
    ({ selectedItem }) => {
      if (!selectedItem) {
        return
      }
      setSelectedItems((selectedItems) => {
        const index = selectedItems.indexOf(selectedItem)
        if (index > 0) {
          return [
            ...selectedItems.slice(0, index),
            ...selectedItems.slice(index + 1),
          ]
        }
        if (index === 0) {
          return [...selectedItems.slice(1)]
        }
        return [...selectedItems, selectedItem]
      })
    },
    [setSelectedItems]
  )
}

export const Multiselect = forwardRef<MultiselectProps, 'div'>(
  (
    {
      id,
      value: valueProp,
      onChange: onChangeProp,
      label,
      options,
      placeholder,
      variant,
      colorScheme,
      size,
      sxInput,
      sxLabel,
      sxInputGroup,
      sx: containerSx,
      isInvalid,
      isDisabled,
      ...rest
    },
    ref
  ) => {
    const [inputItems, setInputItems] = useState(options)
    const [internalState, setSelectedItems] = useState<string[]>([])
    const [isControlled, selectedItems] = useControllableProp<string[]>(
      valueProp,
      internalState
    )

    const onInputValueChange = useCallback<
      NonNullable<UseComboboxProps<string>['onInputValueChange']>
    >(
      ({ inputValue }) => {
        setInputItems(
          options.filter((item) =>
            item.value
              .toLowerCase()
              .includes(inputValue?.toLowerCase()?.trim() ?? '_____')
          )
        )
      },
      [options]
    )

    const defaultOnChange = useDefaultMultiselectOnChange(setSelectedItems)

    const onSelectedItemChange = useCallback<
      NonNullable<UseComboboxProps<string>['onSelectedItemChange']>
    >(
      (changes) => {
        isControlled
          ? onChangeProp?.(changes) ?? defaultOnChange(changes)
          : defaultOnChange(changes)
      },
      [defaultOnChange, isControlled, onChangeProp]
    )
    const stateReducer = useCallback<
      NonNullable<UseComboboxProps<string>['stateReducer']>
    >((state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: state.inputValue, // don't add the item string as input value at selection.
          }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: state.inputValue, // don't add the item string as input value at selection.
          }
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: state.inputValue, // don't add the item string as input value at selection.
          }
        default:
          return changes
      }
    }, [])

    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      openMenu,
      getItemProps,
    } = useCombobox<string>({
      items: inputItems.map((it) => it.value),
      onSelectedItemChange,
      selectedItem: null,
      stateReducer,
      onInputValueChange,
    })

    const containerSxMerged = useMemo(() => {
      return {
        w: 'full',
        pos: 'relative',
        ...containerSx,
      }
    }, [containerSx])

    const optionsContainerBg = useColorModeValue('gray.50', 'gray.900')
    const scrollStyles = useScrollStyles({ size: 'md' })
    const optionsContainerStyles: SystemStyleObject =
      useMemo<SystemStyleObject>(
        () => ({
          maxHeight: 400,
          w: 'full',
          overflowY: 'scroll',
          backgroundColor: optionsContainerBg,
          padding: 0,
          listStyle: 'none',
          position: 'absolute',
          zIndex: 'dropdown',
          borderRadius: 'md',
          transform: 'translate(0, var(--chakra-space-1))',
          px: 1,
          ...scrollStyles,
        }),
        [optionsContainerBg, scrollStyles]
      )

    const defaultLabelText =
      selectedItems.length > 0
        ? selectedItems.length <= 3
          ? selectedItems.join(', ')
          : `${selectedItems.length} elements selected`
        : 'Select elements'

    const labelSxMerged = useMemo(() => {
      return {
        cursor: isDisabled ? 'not-allowed' : undefined,
        ...sxLabel,
      }
    }, [isDisabled, sxLabel])

    const labelText =
      typeof label === 'function'
        ? label(selectedItems) ?? defaultLabelText
        : label ?? defaultLabelText

    return (
      <Box sx={containerSxMerged} {...rest}>
        <FloatingLabelInputGroup
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          sx={sxInputGroup}
          {...getComboboxProps({
            disabled: isDisabled,
            onClick() {
              !isDisabled && openMenu()
            },
          })}
        >
          <FloatingLabel
            {...getLabelProps({
              htmlFor: id,
              disabled: isDisabled,
            })}
            sx={labelSxMerged}
          >
            {labelText}
          </FloatingLabel>
          <FloatingLabelInput
            isDisabled={isDisabled}
            {...getInputProps({
              id,
              disabled: isDisabled,
              ref,
            })}
            sx={sxInput}
            placeholder={placeholder}
          />
          <FloatingLabelInputRightElement>
            <MotionBox animate={{ rotate: isOpen ? 180 : 0 }}>
              <Icon
                _active={{ outline: 'none' }}
                _focus={{ outline: 'none' }}
                {...getToggleButtonProps({
                  disabled: isDisabled,
                  onClick(e) {
                    e.stopPropagation()
                  },
                })}
                as={CaretUp}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                size={16}
              />
            </MotionBox>
          </FloatingLabelInputRightElement>
        </FloatingLabelInputGroup>
        <Stack
          spacing={1}
          as="ul"
          {...getMenuProps()}
          sx={optionsContainerStyles}
        >
          {isOpen &&
            inputItems.map((item, index) => {
              return (
                <MultiselectOption
                  highlightedIndex={highlightedIndex}
                  checked={selectedItems.includes(item.value)}
                  {...item}
                  key={`${item.value}`}
                  index={index}
                  {...getItemProps({
                    disabled: item.isDisabled,
                    item: item.value,
                    index,
                  })}
                />
              )
            })}
        </Stack>
      </Box>
    )
  }
)

Multiselect.displayName = 'Multiselect'
