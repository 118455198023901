import React, { useLayoutEffect } from 'react'
import { hydrate, render } from 'react-dom'
import Root from './containers/Root'
import { reportWebVitals } from './report-web-vitals'
import { Router, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import type { DefaultOptions } from '@apollo/client'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { LocalStorage } from './services/LocalStorage'
import { API_CLIENT_ID, API_CLIENT_VERSION, API_URL } from './constants'
import './index.module.scss'
import { Analytics } from './services/Analytics'
import { possibleTypes } from 'packlets/generated'
import type { GraphQLError } from 'graphql'
import { FirebaseService } from './services/FirebaseService'
import { CookiesProvider } from 'react-cookie'
import { DeepLinkListener } from './deep-link-listener'
import { PushNotificationsListener } from './push-notifications-listener'
import './styles/base.css'
import './styles/colours.css'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import 'focus-visible/dist/focus-visible'
import '@fontsource/jetbrains-mono'

// ----- Inter font ------
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'

import 'flag-icon-css/css/flag-icon.css'
import { theme } from 'ui-kit'
import { initSentry } from './providers/sentry'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import Purchases from 'cordova-plugin-purchases/www/plugin'
import { Capacitor } from '@capacitor/core'
import { onClassChange } from './utils/utils'

const history = createBrowserHistory()
initSentry(history)

onClassChange(document.querySelector('body') as HTMLBodyElement, (node) => {
  if (node.ELEMENT_NODE) {
    const castedNode = node as HTMLBodyElement
    if (castedNode.classList.contains('chakra-ui-dark')) {
      document.documentElement.style.setProperty(
        '--button-bg',
        `var(--dark-button-bg)`
      )
      document.documentElement.style.setProperty(
        '--button--primary--active-bg',
        `var(--dark-button--primary--active-bg)`
      )

      document.documentElement.style.setProperty(
        '--ui-background',
        `var(--dark-ui-background)`
      )
      document.documentElement.style.setProperty('--ui-01', `var(--dark-ui-01)`)
      document.documentElement.style.setProperty('--ui-02', `var(--dark-ui-02)`)
      document.documentElement.style.setProperty('--ui-03', `var(--dark-ui-03)`)
      document.documentElement.style.setProperty('--ui-04', `var(--dark-ui-04)`)
      document.documentElement.style.setProperty(
        '--text-01',
        `var(--dark-text-01)`
      )
      document.documentElement.style.setProperty(
        '--text-02',
        `var(--dark-text-02)`
      )
      document.documentElement.style.setProperty(
        '--text-03',
        `var(--dark-text-03)`
      )
      document.documentElement.style.setProperty(
        '--text-04',
        `var(--dark-text-04)`
      )
      document.documentElement.style.setProperty(
        '--success-01',
        `var(--dark-success-01)`
      )
      document.documentElement.style.setProperty(
        '--error-01',
        `var(--dark-error-01)`
      )
    } else {
      document.documentElement.style.removeProperty('--button-bg')
      document.documentElement.style.removeProperty(
        '--button--primary--active-bg'
      )
      document.documentElement.style.removeProperty('--ui-background')
      document.documentElement.style.removeProperty('--ui-01')
      document.documentElement.style.removeProperty('--ui-02')
      document.documentElement.style.removeProperty('--ui-03')
      document.documentElement.style.removeProperty('--ui-04')
      document.documentElement.style.removeProperty('--text-01')
      document.documentElement.style.removeProperty('--text-02')
      document.documentElement.style.removeProperty('--text-03')
      document.documentElement.style.removeProperty('--text-04')
      document.documentElement.style.removeProperty('--success-01')
      document.documentElement.style.removeProperty('--error-01')
    }
  }
})

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

interface ServerError extends GraphQLError {
  statusCode?: number
}

const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })
const discordSessionLocalStorage = LocalStorage({
  key: 'AUTH_DISCORD_SESSION_STORAGE',
})

export const cache = new InMemoryCache({
  possibleTypes: possibleTypes.possibleTypes,
})

const httpLink = createHttpLink({
  uri: API_URL,
})

const wsLink = new WebSocketLink({
  uri: `wss://${API_URL.split('https://')[1]}`,
  options: {
    connectionParams: () => {
      sessionLocalStorage.get()
      discordSessionLocalStorage.get()

      return {
        'client-id': API_CLIENT_ID,
        'client-version': API_CLIENT_VERSION,
        'x-api-token': sessionLocalStorage.get() ?? '',
        'x-api-discord-token': discordSessionLocalStorage.get() ?? '',
      }
    },
    reconnect: true,
    reconnectionAttempts: 10,
  },
})

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  sessionLocalStorage.get()
  discordSessionLocalStorage.get()

  return {
    headers: {
      ...headers,
      'client-id': API_CLIENT_ID,
      'client-version': API_CLIENT_VERSION,
      'x-api-token': sessionLocalStorage.get() ?? '',
      'x-api-discord-token': discordSessionLocalStorage.get() ?? '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((gqlError: ServerError) => {
      if (
        gqlError.extensions?.exception?.response?.statusCode === 403 ||
        gqlError.statusCode === 403
      ) {
        Analytics.reset()

        FirebaseService.userDidLogOut()

        void client.clearStore()
        sessionLocalStorage.clear()
        discordSessionLocalStorage.clear()
        window.location.reload()
      }
    })
  }
})

const clientOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
  },
  watchQuery: {
    fetchPolicy: 'network-only', // Ideally this should be cache-and-network but causing issues if client is passed to outside components.
  },
}

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(splitLink)),
  cache,
  defaultOptions: clientOptions,
})

FirebaseService.initialize()

if (Capacitor.isNativePlatform()) {
  Purchases.setDebugLogsEnabled(true)
  Purchases.setup('DeSvMSITVMMYQNjoaKbxpnAOQXRXCXzx')
}

const rootElement = document.getElementById('root')
Analytics.init()
const app = (
  <ApolloProvider client={client}>
    <CookiesProvider>
      <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
          <Router history={history}>
            <PushNotificationsListener />
            <DeepLinkListener />
            <ScrollToTop />
            <Root />
          </Router>
        </ChakraProvider>
      </React.StrictMode>
    </CookiesProvider>
  </ApolloProvider>
)

if (rootElement?.hasChildNodes()) {
  hydrate(app, rootElement)
} else {
  render(app, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
