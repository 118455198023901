import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { InternalLayout } from '../../components/layout'
import { SecuredServer } from 'packlets/generated'
import styles from './account.module.scss'
import type { SecuredInterestsDataType as SecuredInterestsDataType_EU } from '../../constants-eu'
import {
  CITIES as CITIES_EU,
  COUNTRIES as COUNTRIES_EU,
  INTERESTS as INTERESTS_EU,
} from '../../constants-eu'
import type { SecuredInterestsDataType as SecuredInterestsDataType_USA } from '../../constants-usa'
import {
  CITIES as CITIES_USA,
  COUNTRIES as COUNTRIES_USA,
  INTERESTS as INTERESTS_USA,
} from '../../constants-usa'
import { LocalStorage } from '../../services/LocalStorage'
import { Analytics } from '../../services/Analytics'
import { EditAdminServer } from './edit-admin-server'
import type { EditAdminServerFormData } from './edit-admin-server'
import { FirebaseService } from '../../services/FirebaseService'
import {
  UserReferralDetails,
  UserReferralDetailsSkeleton,
} from './user-referral-details'
import { EditRoles } from './edit-roles'
import AccountMembership from './account-membership'
import { Box, Button, Text } from '@chakra-ui/react'
import { Card } from 'ui-kit'
import { AppConfig } from '../../services/AppConfig'
import {
  GetSelfDocument,
  GetUserDiscordRolesDocument,
  UpdateUserDocument,
} from '../../gql/users.generated'
import {
  GetSelfUserReferralDocument,
  GetSelfUserReferralMetaDocument,
} from '../../gql/user-referrals.generated'
import { LogoutDocument } from '../../gql/auth.generated'
import { ColorModeSwitcher } from 'components/color-mode-switcher'
import { formatPercentage, formatPriceCents } from 'utils/utils'

interface DefaultProps {}

interface Props extends Partial<DefaultProps> {
  isLoggedIn: boolean
  userId?: string
  discordId: string
  discordServer: SecuredServer
  gId: string
}

const defaultProps: DefaultProps = {}

const Account: FunctionComponent<Props> = ({
  userId,
  discordServer,
  discordId,
}) => {
  const { data: selfData, loading: selfLoading } = useQuery(GetSelfDocument)

  const { data: userReferralData, loading: userReferralLoading } = useQuery(
    GetSelfUserReferralDocument
  )

  const { data: userReferralMetaData, loading: userReferralMetaLoading } =
    useQuery(GetSelfUserReferralMetaDocument)

  const [updateAdmin, { loading: loadingUpdateAdmin }] = useMutation(
    UpdateUserDocument,
    {
      onCompleted() {
        window.location.reload()
      },
      onError: (e) => {
        console.error(e)
      },
    }
  )

  const onAdminUpdate = (data: EditAdminServerFormData) => {
    if (selfData && data) {
      updateAdmin({
        variables: {
          id: selfData?.getSelf.id,
          input: {
            ...data,
          },
        },
      })
    }
  }

  useEffect(() => {
    Analytics.viewAccount()
  }, [])

  const [showWizard, setShowWizard] = useState(false)
  const client = useApolloClient()
  const sessionLocalStorage = LocalStorage({ key: 'AUTH_SESSION_STORAGE' })

  const [logout] = useMutation(LogoutDocument)

  const onLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.error(`Error on logout`, error)
    }

    Analytics.reset()

    FirebaseService.userDidLogOut()

    client.clearStore()
    sessionLocalStorage.clear()
    window.location.replace('/')
  }

  const { loading, data, refetch } = useQuery(GetUserDiscordRolesDocument, {
    notifyOnNetworkStatusChange: true,
    variables: { userId },
  })
  const countries =
    discordServer === SecuredServer.Eu ? COUNTRIES_EU : COUNTRIES_USA
  const cities = discordServer === SecuredServer.Eu ? CITIES_EU : CITIES_USA
  const interests =
    discordServer === SecuredServer.Eu ? INTERESTS_EU : INTERESTS_USA

  let country
  let city = {
    id: 'other',
    name: 'Other',
  }
  const userInterests:
    | SecuredInterestsDataType_EU[]
    | SecuredInterestsDataType_USA[] = []

  if (!loading && data) {
    for (let index = 0; index < data.getUserDiscordRoles.length; index++) {
      const role = data.getUserDiscordRoles[index]
      country = countries.find((aCountry) => aCountry.id === role) ?? country
      city = cities.find((aCity) => aCity.id === role) ?? city
      const interest = interests.find((anInterest) => anInterest.id === role)
      if (interest) {
        userInterests.push(interest)
      }
    }
  }

  const onWizardComplete = () => {
    Analytics.updateRoles()
    refetch()
  }

  let editRolesComponent = null
  if (selfData) {
    country = selfData.getSelf.countryCode
    editRolesComponent = (
      <EditRoles
        discordServer={discordServer}
        userId={selfData.getSelf.id}
        discordId={discordId}
        showWizard={showWizard}
        setShowWizard={setShowWizard}
        userCountry={
          selfData.getSelf.discordCountryCode ?? selfData.getSelf.countryCode
        }
        userInterests={selfData.getSelf.discordInterests}
        onWizardComplete={onWizardComplete}
      />
    )
  }

  return (
    <InternalLayout headerTitle="Account">
      <Box maxW={['full', 'full', '414px']}>
        {!loading && editRolesComponent}
        <div>
          <Card p={4} mt={4}>
            <ColorModeSwitcher />
            <Text as="span" ml={2}>
              Toggle theme (beta)
            </Text>
          </Card>
          {!AppConfig.isNativePlatform && (
            <AccountMembership
              loading={selfLoading}
              userId={selfData?.getSelf.id}
            />
          )}
          {(userReferralLoading || userReferralMetaLoading) && (
            <UserReferralDetailsSkeleton
              className={styles['account-page__section']}
            />
          )}
          {!userReferralLoading &&
            userReferralData &&
            userReferralData.getSelfUserReferral &&
            !userReferralMetaLoading &&
            userReferralMetaData && (
              <UserReferralDetails
                className={styles['account-page__section']}
                creditAmount={formatPriceCents(
                  userReferralData.getSelfUserReferral.creditCents,
                  userReferralData.getSelfUserReferral.creditCurrencyCode
                )}
                percentageOff={formatPercentage(
                  userReferralData.getSelfUserReferral.percentageOff
                )}
                code={userReferralData.getSelfUserReferral.code}
                totalRedeemCount={
                  userReferralMetaData.getSelfUserReferralMeta
                    .totalRedeemedReferralsCount
                }
                availableCredit={formatPriceCents(
                  userReferralMetaData.getSelfUserReferralMeta
                    .creditBalanceCents,
                  userReferralMetaData.getSelfUserReferralMeta
                    .creditBalanceCurrencyCode
                )}
              />
            )}
          {selfData?.getSelf.isAdmin && (
            <React.Fragment>
              <div className={styles['account-page__section']}>
                <h3 className={styles['account-page__section__h3']}>
                  Admin Settings
                </h3>
                <p className={styles['account-page__section__p']}>
                  Edit admin server
                </p>
              </div>
              <EditAdminServer
                className={styles['account-page__edit-currency-location']}
                onFormSubmit={onAdminUpdate}
                data={{
                  discordServer: selfData.getSelf.discordServer,
                }}
                isLoading={loadingUpdateAdmin}
              />
            </React.Fragment>
          )}

          <Button
            w="full"
            onClick={onLogout}
            variant="outline"
            colorScheme="red"
          >
            Log Out
          </Button>
        </div>
      </Box>
    </InternalLayout>
  )
}

Account.defaultProps = defaultProps

export default Account
