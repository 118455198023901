declare const rdt: reddit.Pixel

declare module reddit {
  interface Pixel {
    (track: string, event: RedditEventType): void
  }
}

/**
 * TikTok pixel documentation
 * https://ads.tiktok.com/marketing_api/docs?rid=yqyw52eh4of&id=1701890973258754
 */
export enum RedditEventType {
  ViewContent = 'ViewContent',
  AddToCart = 'AddToCart',
  Purchase = 'Purchase',
}

export class RedditPixel {
  public static track(event: RedditEventType) {
    rdt('track', event)
  }
}
