import { baseDisplay } from '../typography'
import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const DisplayText: ComponentStyleConfig = {
  baseStyle: baseDisplay.baseStyle,
  sizes: baseDisplay.sizes,
  defaultProps: {
    size: 'xl',
  },
}
