import type { FunctionComponent, ReactElement } from 'react'
import * as React from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'

interface Props {
  title: string
  subTitle?: string
  description: string | ReactElement
  icon: React.ComponentType
}

export const Block: FunctionComponent<Props> = ({
  title,
  subTitle,
  description,
  icon,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        padding: 8,
        transform: 'translate3d(0, 0, 0)',
        background: useColorModeValue('blackAlpha.100', 'whiteAlpha.300'),
        backdropFilter: 'blur(15px)',
        borderRadius: 'lg',
      }}
    >
      <Text
        sx={{
          fontSize: 'md',
          fontWeight: 'bold',
          letterSpacing: '0.13em',
          textTransform: 'uppercase',
          color: useColorModeValue('gray.700', 'gray.400'),
          marginBottom: 6,
        }}
      >
        {title}
      </Text>
      {subTitle && <Heading size="md">{subTitle}</Heading>}
      <Flex
        mt={1}
        direction="column"
        justifyContent="space-between"
        height="full"
        gridGap={8}
      >
        <Text
          variant="paragraph"
          color={useColorModeValue('gray.600', 'gray.400')}
          fontSize="18px"
          lineHeight="150%"
        >
          {description}
        </Text>
        <Box>
          <Icon as={icon} fontSize="32px" />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Block
