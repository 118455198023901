import type {
  HTMLChakraProps,
  TextProps,
  HeadingProps,
  StackProps,
} from '@chakra-ui/react'
import { chakra, Stack, Heading, Text } from '@chakra-ui/react'
import type { HTMLMotionProps } from 'framer-motion'
import { motion } from 'framer-motion'

type Merge<P, T> = Omit<P, keyof T> & T

export type MotionBoxProps = Merge<
  HTMLChakraProps<'div'>,
  HTMLMotionProps<'div'>
>
export type MotionStackProps = Merge<StackProps, HTMLMotionProps<'div'>>
export type MotionHeadingProps = Merge<HeadingProps, HTMLMotionProps<'header'>>
export type MotionTextProps = Merge<TextProps, HTMLMotionProps<'p'>>

export const MotionBox: React.FC<MotionBoxProps> = motion(chakra.div)
export const MotionStack: React.FC<MotionStackProps> = motion(Stack)
export const MotionHeading: React.FC<MotionHeadingProps> = motion(Heading)
export const MotionText: React.FC<MotionTextProps> = motion(Text)
