import { createContext, useContext } from 'react'
import type { SecuredServer } from 'packlets/generated'

export type UserInfoType = {
  discordServer: SecuredServer | undefined
  setDiscordServer: (server: SecuredServer) => void
}

export const UserInfoContext = createContext<UserInfoType>({
  discordServer: undefined,
  setDiscordServer: () => console.warn('no discord server provider'),
})

export const useUserInfo = () => useContext(UserInfoContext)
