import { baseTypography } from '../typography'
import type {
  Anatomy,
  MultiStyleConfig,
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { getColor, mode } from '@chakra-ui/theme-tools'

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props
  return {
    focusBorderColor: fc || mode('gray.200', 'gray.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  }
}

const filledVariant: PartsStyleFunction<Anatomy<'field'>> = (props) => {
  const { theme } = props
  return {
    field: {
      bg: mode('gray.200', 'gray.800')(props),
      border: '1px solid currentColor',
      _invalid: {
        color: getColor(theme, getDefaults(props).errorBorderColor),
        border: `1px solid ${getColor(
          theme,
          getDefaults(props).errorBorderColor
        )}`,
        bg: mode('red.50', 'red.900')(props),
      },
      _focus: {
        color: mode('gray.900', 'gray.50')(props),
        border: `1px solid ${getColor(
          theme,
          getDefaults(props).focusBorderColor
        )}`,
      },
    },
  }
}

const size: Record<string, SystemStyleObject> = {
  lg: {
    ...baseTypography.body,
    fontSize: 'lg',
    px: 4,
    h: 14,
  },

  md: {
    ...baseTypography.body,
    px: 3,
    h: 12,
  },

  sm: {
    ...baseTypography.body,
    fontSize: 'sm',
    px: 2,
    h: 10,
  },

  xs: {
    ...baseTypography.body,
    fontSize: 'xs',
    px: 1,
    h: 6,
  },
}

const sizes: Record<string, PartsStyleObject<Anatomy<'field' | 'addon'>>> = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
}

export const Input = {
  parts: ['field', 'addon'],
  variants: {
    filled: filledVariant,
  },
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'filled',
  },
} as MultiStyleConfig
