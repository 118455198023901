export const API_URL = process.env.REACT_APP_API_URL || ''
export const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID || ''
export const API_CLIENT_VERSION = process.env.REACT_APP_API_CLIENT_VERSION || ''

export type SecuredCountryDataType = {
  id: string
  name: string
  interests: string[]
}

export type SecuredCityDataType = {
  id: string
  name: string
}

export type SecuredInterestsDataType = {
  id: string
  name: string
  description: string
}

const commonInterests: string[] = [
  '814456518686670888',
  '697114756988928051',
  '748960194712830072',
  '748959763404161134',
  '697114758142230599',
  '717312199621738517',
  '763006819109961728',
  '763010178424897547',
]

export const COUNTRIES: SecuredCountryDataType[] = [
  {
    id: '696753200237117603',
    name: 'USA',
    interests: [
      '814456518686670888',
      '697114756988928051',
      '748960194712830072',
      '697114758545014784',
      '718573641108947086',
      '763006871531028481',
      '697114758142230599',
      '717312199621738517',
      '763010178424897547',
    ],
  },
  {
    id: '696753007655649373',
    name: 'United Kingdom',
    interests: [
      '814456518686670888',
      '697114756988928051',
      '748960194712830072',
      '748959763404161134',
      '697114758142230599',
      '717312199621738517',
      '763006819109961728',
      '697114757789909092',
      '763010178424897547',
    ],
  },
  {
    id: '701025631277940746',
    name: 'Canada',
    interests: [
      '814456518686670888',
      '697114756988928051',
      '748960194712830072',
      '697114758545014784',
      '718573641108947086',
      '763006871531028481',
      '697114758142230599',
      '717312199621738517',
      '763010178424897547',
    ],
  },
  {
    id: '701025635761389588',
    name: 'France',
    interests: [...commonInterests],
  },
  {
    id: '701025632959725578',
    name: 'Germany',
    interests: [...commonInterests],
  },
  {
    id: '701025631789514842',
    name: 'Italy',
    interests: [...commonInterests],
  },
  {
    id: '701049460863533126',
    name: 'Netherlands',
    interests: [...commonInterests],
  },
  {
    id: '701025633597259848',
    name: 'Spain',
    interests: [...commonInterests],
  },
  {
    id: '697114499345416273',
    name: 'Other European Country',
    interests: [...commonInterests],
  },
  {
    id: '697114569121857606',
    name: 'Rest of World',
    interests: [
      '814456518686670888',
      '697114756988928051',
      '748960194712830072',
      '697114758545014784',
      '718573641108947086',
      '763006871531028481',
      '697114758142230599',
      '717312199621738517',
      '763010178424897547',
    ],
  },
]

export const CITIES: SecuredCityDataType[] = [
  {
    id: '697114752094044251',
    name: 'London',
  },
  {
    id: '697114754229207090',
    name: 'New York',
  },
  {
    id: '697114752643760169',
    name: 'LA',
  },
  {
    id: '762995725986168862',
    name: 'Chicago',
  },
  {
    id: '697114754833055834',
    name: 'Paris',
  },
  {
    id: '697114756179558401',
    name: 'Milan',
  },
  {
    id: '697114755902734376',
    name: 'Berlin',
  },
  {
    id: 'other',
    name: 'Other',
  },
]

export const INTERESTS: SecuredInterestsDataType[] = [
  {
    id: '814456518686670888',
    name: 'Botting',
    description:
      'Stay informed with all the essential information and updates you need to bot.',
  },
  {
    id: '697114756988928051',
    name: 'Bot Restocks',
    description: 'Buying bots for retail prices.',
  },
  {
    id: '717312199621738517',
    name: 'Group Buys',
    description:
      'Interested in any Group Buys (Bots, Proxies) that Secured partners with.',
  },
  {
    id: '697114758545014784',
    name: 'Lowkey Flips',
    description:
      'Interested in reselling Collectables/Cards/Toys. E.G Funko/Kaws.',
  },
  {
    id: '718573641108947086',
    name: 'Brick Flips',
    description:
      'Interested in reselling less popular items that are still profitable - mainly done through using discount codes on sites.',
  },
  {
    id: '748959763404161134',
    name: 'EU/UK Brick Flips',
    description:
      'Interested in reselling less popular items that are still profitable - mainly done through using discount codes on sites.',
  },
  {
    id: '763006819109961728',
    name: 'EU/UK Restocks',
    description: 'Interested in being notified about restocks.',
  },
  {
    id: '763006871531028481',
    name: 'USA Restocks',
    description: 'Interested in being notified about restocks.',
  },
  {
    id: '697114757789909092',
    name: 'Offspring Store',
    description:
      'Interested in Offspring drops - this store works different to others and requires you to be fast.',
  },
  {
    id: '697114758142230599',
    name: 'Gaming',
    description: 'Interested in Gaming. E.G we may host competitions.',
  },
  {
    id: '763010178424897547',
    name: 'Twitter Army',
    description:
      'Interested in helping Secured get Group Buys by commenting on Twitter posts etc when notified.',
  },
]
