import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Modal } from '../../../components/modal'
import styles from './edit-roles.module.scss'
import { Alert } from '../../../components/alert'
import type {
  CountryCode,
  DiscordInterest,
  Maybe,
  SecuredServer,
} from 'packlets/generated'
import { useMutation } from '@apollo/client'
import { SelectInterests } from 'features/select-interests'
import type { SelectInterestsFormData } from 'features/select-interests'
import { UpdateUserDiscordInterestsDocument } from 'gql/users.generated'

interface DefaultProps {}

interface Props extends Partial<DefaultProps> {
  userId: string
  discordId?: string
  showWizard: boolean
  discordServer: SecuredServer
  setShowWizard: React.Dispatch<React.SetStateAction<boolean>>
  userInterests: DiscordInterest[]
  userCountry?: Maybe<CountryCode> | null
  onWizardComplete?: () => void
}

const defaultProps: DefaultProps = {}

const EditRoles: FunctionComponent<Props> = ({
  userId,
  showWizard,
  setShowWizard,
  userCountry,
  onWizardComplete,
  userInterests,
}) => {
  const [hasError, setHasError] = useState(false)

  const [
    updateUserDiscordInterests,
    { loading: loadingUpdateUserDiscordInterests },
  ] = useMutation(UpdateUserDiscordInterestsDocument, {
    ignoreResults: true,
    onCompleted() {
      setShowWizard(false)
      setHasError(false)
      onWizardComplete && onWizardComplete()
    },
    onError(e) {
      console.error(e)
    },
  })

  const onInterestsSave = async (data: SelectInterestsFormData) => {
    updateUserDiscordInterests({
      variables: {
        userId,
        input: {
          interestTypes: data.interests,
        },
      },
    })
  }

  return (
    <div>
      {showWizard && (
        <Modal
          className={styles['edit-roles__modal']}
          handleClose={() => setShowWizard(false)}
          title="Role Setup"
          alert={
            hasError ? (
              <Alert
                type="error"
                title="Something went wrong, please try again."
                onClose={() => setHasError(false)}
              />
            ) : (
              <></>
            )
          }
        >
          <React.Fragment>
            <SelectInterests
              userCountry={userCountry}
              userInterests={userInterests}
              onFormSubmit={onInterestsSave}
              onViewCallback={() => {}}
              isLoading={loadingUpdateUserDiscordInterests}
            />
          </React.Fragment>
        </Modal>
      )}
    </div>
  )
}

EditRoles.defaultProps = defaultProps

export default EditRoles
