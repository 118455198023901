import type { CurrencyCode } from 'packlets/generated'

declare const ttq: tiktok.Pixel

declare module tiktok {
  interface Pixel {
    track(event: TikTokEventType, options?: TikTokEventOptions): void
  }
}

/**
 * TikTok pixel documentation
 * https://ads.tiktok.com/marketing_api/docs?rid=yqyw52eh4of&id=1701890973258754
 */
export enum TikTokEventType {
  Browse = 'Browse',
  ViewContent = 'ViewContent',
  StartCheckout = 'StartCheckout',
  Purchase = 'Purchase',
}

export interface TikTokEventOptions {
  content_id: string // product id
  content_type: string
  quantity: number
  value: number
  currency: CurrencyCode
}

export class TikTokPixel {
  public static track(event: TikTokEventType, options?: TikTokEventOptions) {
    if (options?.currency) {
      options.currency = options?.currency.toUpperCase() as any
    }

    ttq.track(event, options)
  }
}
