import React, { useRef, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Hero,
  HeroCoupon,
  HeroCtaButton,
  HeroCtaContainer,
  HeroLimitedSpacesAvailable,
  HeroSubTitle,
  HeroTitle,
} from 'components/landing/hero'
import {
  ProductImage,
  ProductInfo,
  ProductInfoDescription,
  ProductInfoText,
} from 'components/landing/product-info'
import { ExternalLayout } from 'components/layout'
import hero from '../../images/landing/hero.png'
import { Testimonials } from 'components/landing/testimonials'
import { Testimonial } from 'components/landing/testimonial'
import CyldAvatar from '../../images/landing/sneakers-reselling/Cyld-avatar.png'
import kalanzAvatar from '../../images/landing/sneakers-reselling/kalanz-avatar.png'
import KidCuddleAvatar from '../../images/landing/sneakers-reselling/KidCuddle-avatar.png'
import PlutoAvatar from '../../images/landing/sneakers-reselling/Pluto-avatar.png'
import rayKickzAvatar from '../../images/landing/sneakers-reselling/ray.kickz-avatar.png'
import { CtaBlock, CtaBlockText } from 'components/landing/cta-block'
import { Feature, FeatureText } from 'components/landing/feature'
import { LandingCtaButton } from 'components/landing/landing-cta-button'
import { SupportSection } from 'components/landing/support-section'
import { Block } from 'components/landing/block'
import { Pricing } from '../../components/landing/pricing'
import { BlogBlock } from 'components/landing/blog-block'
import Img01 from '../../images/landing/Img01.png'
import Img02 from '../../images/landing/Img02.png'
import Img03 from '../../images/landing/Img03.png'
import Img04 from '../../images/landing/Img04.png'
import Img05 from '../../images/landing/sneakers-reselling/ProfitEstimations-landing.png'
import adidas from '../../images/landing/brands/adidas.png'
import nike from '../../images/landing/brands/nike.png'
import offWhite from '../../images/landing/brands/off-white.png'
import shopify from '../../images/landing/brands/shopify.png'
import supreme from '../../images/landing/brands/supreme.png'
import { useToasts } from 'react-toast-notifications'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Locale } from 'gql/local'
import { UserSubscriptionProductType } from 'packlets/generated'
import { Analytics } from 'services/Analytics'
import { GetPricingDataDocument } from 'gql/checkout.generated'
import { useFeatureFlags } from 'providers/remote-config'
import { SubscriptionCheckout } from 'features/subscription-checkout'
import { StripeClient } from 'services/StripeClient/StripeClient'
import {
  WorksWith,
  WorksWithBrandCard,
} from '../../components/landing/works-with'
import { BsDiscord } from 'react-icons/bs'
import { Chat } from 'phosphor-react'

const stripePromise = StripeClient.loadStripe()

interface Props {
  coupon?: string
  errorDescription?: string
}

const LandingSneakersReselling: React.FC<Props> = ({
  coupon,
  errorDescription,
}) => {
  const toast = useToasts()
  const locale = useReactiveVar(Locale)
  const subscriptionSectionRef = useRef<HTMLDivElement>(null)

  const onSignupClick = () => {
    Analytics.openSignup({
      coupon,
      userSubscriptionProductType: UserSubscriptionProductType.Sneakers,
    })

    scrollToSubscriptionSection()
  }

  const scrollToSubscriptionSection = (behavior: ScrollBehavior = 'smooth') => {
    if (subscriptionSectionRef) {
      subscriptionSectionRef.current?.scrollIntoView({ behavior })
    }
  }

  const { isAbTestingPriceActive: isAbTestingPriceActiveValue } =
    useFeatureFlags()

  const isAbTestingPriceActive =
    isAbTestingPriceActiveValue !== undefined
      ? isAbTestingPriceActiveValue?.asBoolean() === true
      : null
  const pricingCode = 'ENY'

  const { data: pricingData, loading: loadingPricingData } = useQuery(
    GetPricingDataDocument,
    {
      variables: {
        input: {
          currency: locale.currency,
          promoCode: coupon,
          pricingCode,
        },
      },
    }
  )

  useEffect(() => {
    if (errorDescription) {
      toast.removeAllToasts()
      toast.addToast(errorDescription, {
        appearance: 'error',
        autoDismiss: false,
      })
    }
    // eslint-disable-next-line
  }, [errorDescription])

  return (
    <ExternalLayout onSignup={onSignupClick}>
      <Hero>
        <HeroTitle>
          Learn how to make money buying and selling sneakers
        </HeroTitle>
        <HeroSubTitle>
          Get access to premium tools, reselling guides, and one on one
          training. Start your reselling business and make more money with
          Secured
        </HeroSubTitle>
        <HeroCtaContainer>
          <HeroCtaButton onClick={onSignupClick}>Sign Up</HeroCtaButton>

          <HeroLimitedSpacesAvailable>
            Limited spaces available
          </HeroLimitedSpacesAvailable>

          {coupon &&
            pricingData?.getPricingData.monthly.discountMetadata?.amount && (
              <HeroCoupon>
                Use Code: {coupon} for{' '}
                {pricingData?.getPricingData.monthly.discountMetadata?.amount}%
                off
              </HeroCoupon>
            )}
        </HeroCtaContainer>
      </Hero>

      <ProductInfo>
        <ProductImage src={hero} alt="Secured hero" />
        <ProductInfoDescription>
          <ProductInfoText>
            <p>
              Secured is a members-only premium service that was created to
              teach you how to resell sneakers and start a profitable side
              business. Thousands of resellers have built their businesses by
              leveraging our tools and expert information.
            </p>
            <p>
              The Secured Hub gives you access to the latest release
              information, reselling guides, profit estimations, all raffles,
              sneaker bots, and other tools to buy the most profitable sneakers
              at retail prices. Plus you will have access to the Secured Discord
              community where you can learn from top resellers around.
            </p>
          </ProductInfoText>
        </ProductInfoDescription>
      </ProductInfo>
      <WorksWith>
        <WorksWithBrandCard src={shopify} alt="shopify" />
        <WorksWithBrandCard src={supreme} alt="supreme" />
        <WorksWithBrandCard src={nike} alt="nike" />
        <WorksWithBrandCard src={offWhite} alt="offWhite" />
        <WorksWithBrandCard src={adidas} alt="adidas" />
      </WorksWith>
      <Testimonials>
        <Testimonial
          description="I’ve been here 5 days. Made £800+. Used the free bot which comes with the discord to cop the supreme dunks. Entered all the raffles for the Jordan 1 uni blues thanks to the 24/7 monitors and got a pair. Also a very helpful community and active admins. Would 1000% recommend it."
          imageUrl={rayKickzAvatar}
          username="ray.kickz"
        />
        <Testimonial
          description="Very informative cook group, lots of extremely knowledgeable and experienced members of the community - on hand to support and advise you on all things regarding resale. Also general is full of friendly characters with someone always up for a chat. Fantastic cook group for both new and experienced resellers."
          imageUrl={kalanzAvatar}
          username="kalanz"
        />
        <Testimonial
          description="I’ve been here since the middle of January and honestly I was making more or less nothing from reselling, now I have made around $2k profits and I wanna thank the amazing staff and members who answered my questions no matter how silly they sounded. Thanks a lot guys."
          imageUrl={PlutoAvatar}
          username="Pluto"
        />
        <Testimonial
          description="I’ve been here since the middle of January and honestly I was making more or less nothing from reselling, now I have made around $2k profits and I wanna thank the amazing staff and members who answered my questions no matter how silly they sounded. Thanks a lot guys."
          imageUrl={CyldAvatar}
          username="Cyld"
        />
        <Testimonial
          description="My third week here and if I could describe it in one word: incredible. Had a tough year in 2020 and lost a lot of passion of sneakers but Secured has brought it back. Made just shy of £500 in the last 3 days thanks to the outstanding resources available. If you are not a member already, then you are missing out, not just on profit, but on an excellent community too."
          imageUrl={KidCuddleAvatar}
          username="KidCuddle"
        />
      </Testimonials>

      <CtaBlock>
        <CtaBlockText>Join Secured and start making money today</CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>

      <Feature title="Release Calendar" imageUrl={Img01} imageLocation="right">
        <FeatureText>
          Browse our curated feed of the most popular upcoming sneaker &
          streetwear releases that our team of experts know will sell out fast.
          These are the best products to purchase because they should earn you a
          strong profit margin when resold.
        </FeatureText>
        <FeatureText>
          We cover every important release across the United States and Canada.
        </FeatureText>
      </Feature>

      <Feature title="Drop Alerts" imageUrl={Img03} imageLocation="left">
        <FeatureText>
          Secured groups together every online store releasing the product you
          want, so come drop day you're prepared and know exactly where to go.
        </FeatureText>
        <FeatureText>
          Once a drop goes live, you can use our direct links to go straight to
          the product, saving you valuable seconds.
        </FeatureText>
      </Feature>

      <Feature title="Raffles" imageUrl={Img02} imageLocation="right">
        <FeatureText>
          Secured finds all the raffles that you can enter for each sneaker,
          lets you enter them all from one place and allows you to browse by
          location so you're not hit with any crazy shipping costs when you win
          your pair for retail price.
        </FeatureText>
      </Feature>

      <Feature title="Bots" imageUrl={Img04} imageLocation="left">
        <FeatureText>
          With your Secured membership, you get unlimited access to our checkout
          bot, which will help you check out faster on more than 240 sites,
          including Supreme, Yeezy Supply, Nike, and Adidas.
        </FeatureText>
        <FeatureText>
          You can also rent a bot like Cyber, Wrath, and Koday, or if you are
          not comfortable running one yet, you can have an expert member of our
          team run the bot for you for a small fee.
        </FeatureText>
      </Feature>

      <Feature
        title="Profit Estimations"
        imageUrl={Img05}
        imageLocation="right"
      >
        <FeatureText>
          Learn which sneakers can bring you the most profit with our Estimated
          Profit option available in the Secured Hub. In-house experts make
          profit estimations based on comprehensive market analysis.
        </FeatureText>
      </Feature>

      <CtaBlock>
        <CtaBlockText>Start your reselling business today</CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>

      <SupportSection>
        <Block
          title="Community Access"
          subTitle="Connect with fellow sneakerheads"
          description="Secured is a diverse community of enthusiasts which means regardless of your experience level, beginner or expert, there's always people that you can chat with and learn from."
          icon={BsDiscord}
        />
        <Block
          title="1 on 1 support"
          subTitle="You’re in the best place to learn"
          description="with our easy to understand written guides and around the clock support staff, you can quickly become an expert in getting sneakers for retail prices."
          icon={Chat}
        />
      </SupportSection>
      <Pricing
        ref={subscriptionSectionRef}
        coupon={coupon}
        amount={pricingData?.getPricingData.monthly.discountMetadata?.amount}
      >
        <Elements stripe={stripePromise}>
          <SubscriptionCheckout
            mt={12}
            coupon={coupon}
            userSubscriptionProductType={UserSubscriptionProductType.Sneakers}
            monthly={pricingData?.getPricingData.monthly}
            annual={pricingData?.getPricingData.annual}
            currency={locale.currency}
            pricingCode={pricingCode}
            isLoading={isAbTestingPriceActive === null || loadingPricingData}
          />
        </Elements>
      </Pricing>
      <BlogBlock>
        Visit the Secured Blog to learn more about sneakers and how Secured can
        help you secure more pairs.
      </BlogBlock>
    </ExternalLayout>
  )
}

export default LandingSneakersReselling
