import { useMutation, useReactiveVar } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Redirect, useHistory } from 'react-router'
import type {
  UserSubscriptionProductType,
  UserSubscriptionType,
} from 'packlets/generated'
import styles from './checkout-validation.module.scss'
import { FacebookPixel } from '../../services/FacebookPixel'
import { LocalStorage } from '../../services/LocalStorage'
import { Locale } from '../../gql/local'
import { Analytics } from '../../services/Analytics'
import SnapchatPixel from 'react-snapchat-pixel'
import { TikTokEventType, TikTokPixel } from '../../services/TikTokPixel'
import { RedditEventType, RedditPixel } from '../../services/RedditPixel'
import { WebRoutes } from '../routes/WebRoutes'
import { parseError } from '../../utils/errorParser'
import { TwitterPixel, TwitterEventType } from 'services/TwitterPixel'
import {
  GoogleTagManager,
  GoogleTagManagerEventType,
} from 'services/GoogleTagManager'
import { ValidateCheckoutDocument } from '../../gql/checkout.generated'

interface LocalStorageSubscription {
  subscriptionType: UserSubscriptionType
  productPrice: number
  productId: string
  pricingCode: string
  promoCode?: string
}

interface Props {
  sessionId?: string
}

const CheckoutValidation: React.FC<Props> = ({ sessionId }) => {
  const history = useHistory()
  const locale = useReactiveVar(Locale)
  const [cookies, setCookie] = useCookies()

  const fbp = cookies._fbp
  const fbc = cookies._fbc

  const onError = (e: Error) => {
    const errorPath = encodeURI(`/?error_description=${parseError(e)}`)
    history.replace(errorPath)
  }

  const [validateStripeCheckout] = useMutation(ValidateCheckoutDocument, {
    onCompleted: (data) => {
      const { id } = data.validateStripeCheckout

      setCookie('pending_user_id', id, {
        expires: moment().add(7, 'days').toDate(),
      })

      const path = `${WebRoutes.checkoutConfirmation}?pending_user_id=${id}`
      history.replace(path)
    },
    onError,
  })

  useEffect(() => {
    if (!sessionId) {
      return
    }

    const subscriptionLocalStorage = LocalStorage({ key: 'SUBSCRIPTION' })
    const subscriptionProductTypeLocalStorage = LocalStorage({
      key: 'SUBSCRIPTION_PRODUCT_TYPE',
    })
    const subscriptionLs = subscriptionLocalStorage.get()
    const userSubscriptionProductType =
      subscriptionProductTypeLocalStorage.get()
    subscriptionLocalStorage.clear()
    subscriptionProductTypeLocalStorage.clear()

    const subscription: LocalStorageSubscription = JSON.parse(
      subscriptionLs as string
    )

    const {
      productId,
      productPrice,
      subscriptionType,
      promoCode,
      pricingCode,
    } = subscription

    console.error(`Validating stripeSessionId ${sessionId}`)

    const fbEventId = uuidv4()

    Analytics.purchase({
      planType: subscriptionType,
      price: productPrice,
      currency: locale.currency,
      userSubscriptionProductType:
        userSubscriptionProductType as UserSubscriptionProductType,
      promoCode,
      pricingCode,
    })

    SnapchatPixel.track('PURCHASE', {
      currency: locale.currency,
      value: productPrice / 100,
      contentType: 'product',
      numItems: 1,
      contents: [
        {
          id: productId,
          quantity: 1,
        },
      ],
    })

    FacebookPixel.track({
      name: 'Purchase',
      eventId: fbEventId,
      customData: {
        currency: locale.currency,
        value: productPrice / 100,
        contentType: 'product',
        numItems: 1,
        contents: [
          {
            id: productId,
            quantity: 1,
          },
        ],
      },
    })

    TikTokPixel.track(TikTokEventType.Purchase, {
      content_id: productId,
      content_type: 'product',
      quantity: 1,
      currency: locale.currency,
      value: productPrice / 100,
    })

    TwitterPixel.track(TwitterEventType.Purchase, {
      num_items: 1,
      currency: locale.currency,
      value: productPrice / 100,
    })

    RedditPixel.track(RedditEventType.Purchase)

    GoogleTagManager.track(GoogleTagManagerEventType.Purchase, {
      transaction_id: sessionId,
      value: productPrice / 100,
      currency: locale.currency,
    })

    validateStripeCheckout({
      variables: {
        input: {
          sessionId,
          analyticsEvent: {
            eventId: fbEventId,
            eventSourceUrl: window.location.href,
            fbp,
            fbc,
          },
        },
      },
    })
  }, [locale.currency, sessionId, validateStripeCheckout, fbp, fbc])

  if (!sessionId) {
    return <Redirect to="/" />
  }

  return (
    <div className={styles['checkout-validation-page']}>
      <div className={styles['checkout-validation-page__logo-container']}>
        <img alt="logo" src="/images/logo-black.svg" />
      </div>
    </div>
  )
}

export default CheckoutValidation
