import React from 'react'
import { Link } from 'react-router-dom'
import { WebRoutes } from '../../../containers/routes/WebRoutes'
import { Box, Button, Flex, Image, useColorModeValue } from '@chakra-ui/react'
import { ColorModeSwitcher } from 'components/color-mode-switcher'

interface Props {
  coupon?: string
  isLoggedIn?: boolean
  actionsType?: 'none' | 'loginSignup' | 'loginOnly' | 'signupOnly'
  onSignup?: () => void
}

export const ExternalHeader: React.FC<Props> = ({
  onSignup,
  actionsType = 'loginSignup',
  isLoggedIn = false,
}) => {
  const hasLogin = actionsType === 'loginOnly' || actionsType === 'loginSignup'
  const hasSignup =
    (actionsType === 'signupOnly' || actionsType === 'loginSignup') && onSignup

  return (
    <Box
      sx={{
        position: 'sticky',
        left: 0,
        top: '0.5px',
        width: '100%',
        transform: 'translate3d(0, 0, 0)',
        backgroundColor: useColorModeValue('transparent', 'blackAlpha.100'),
        backdropFilter: 'blur(8px)',
        paddingTop: 'env(safe-area-inset-top)',
        zIndex: 'sticky',
      }}
    >
      <Box
        sx={{
          height: '72px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          px: 6,
          py: 4,
          margin: '0 auto',
          maxWidth: '1280px',
          width: '100%',
        }}
      >
        <Box flex={1}>
          <Link to="/">
            <Image
              filter={useColorModeValue('unset', 'brightness(30)')}
              alt="Secured Logo"
              src="/images/logo-black.svg"
            />
          </Link>
        </Box>

        {!isLoggedIn && (
          <Flex gridGap={1}>
            <ColorModeSwitcher />
            {hasLogin && (
              <Button
                as={Link}
                to={WebRoutes.login}
                variant="outline"
                size="sm"
              >
                Log In
              </Button>
            )}
            {hasSignup && (
              <Button size="sm" onClick={onSignup}>
                Sign Up
              </Button>
            )}
          </Flex>
        )}
      </Box>
    </Box>
  )
}
