import { interactive } from '../typography'
import type {
  Anatomy,
  MultiStyleConfig,
  PartsStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const variantLine: PartsStyleFunction<Anatomy<'tablist' | 'tab'>> = (props) => {
  const { colorScheme: c, orientation } = props
  const isVertical = orientation === 'vertical'
  const borderProp = orientation === 'vertical' ? 'borderStart' : 'borderBottom'
  const marginProp = isVertical ? 'marginStart' : 'marginBottom'

  return {
    tablist: {
      [borderProp]: '1px solid',
      borderColor: 'inherit',
    },
    tab: {
      [borderProp]: '3px solid',
      borderColor: 'transparent',
      [marginProp]: '-2px',
      color: mode(`${c}.500`, `${c}.500`)(props),
      _active: {
        bg: mode('gray.200', 'whiteAlpha.300')(props),
      },
      _selected: {
        color: mode(`${c}.900`, `${c}.100`)(props),
        borderColor: 'currentColor',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  }
}

const variantLineReversed: PartsStyleFunction<Anatomy<'tablist' | 'tab'>> = (
  props
) => {
  const { colorScheme: c, orientation } = props
  const isVertical = orientation === 'vertical'
  const borderProp = orientation === 'vertical' ? 'borderStart' : 'borderTop'
  const marginProp = isVertical ? 'marginStart' : 'marginTop'

  return {
    tablist: {
      [borderProp]: '1px solid',
      borderColor: 'inherit',
    },
    tab: {
      [borderProp]: '3px solid',
      borderColor: 'transparent',
      [marginProp]: '-2px',
      color: mode(`${c}.500`, `${c}.500`)(props),
      _active: {
        bg: mode('gray.200', 'whiteAlpha.300')(props),
      },
      _selected: {
        color: mode(`${c}.900`, `${c}.100`)(props),
        borderColor: 'currentColor',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  }
}

const borderWidth = '3px'

const size: Record<string, SystemStyleObject> = {
  lg: {
    borderWidth,
    ...interactive.lg,
  },
  md: {
    borderWidth,
    ...interactive.md,
  },
  sm: {
    borderWidth,
    ...interactive.sm,
  },
  xs: {
    borderWidth: '1.5px',
    ...interactive.sm,
  },
}

export const Tabs = {
  baseStyle: {
    tab: {
      fontWeight: 'bold',
    },
  },
  parts: ['root'],
  sizes: {
    root: size,
  },
  variants: {
    line: variantLine,
    'reversed-line': variantLineReversed,
  },
  defaultProps: {
    size: 'lg',
    colorScheme: 'gray',
  },
} as MultiStyleConfig
