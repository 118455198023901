import type { radioAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { Checkbox } from './checkbox'
import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { PartsStyleObject } from '@chakra-ui/theme-tools'

const baseStyleControl: SystemStyleFunction = (props) => {
  const baseStyle =
    Checkbox?.baseStyle instanceof Function
      ? (Checkbox.baseStyle(props) as PartsStyleObject<typeof parts>)
      : (Checkbox.baseStyle as PartsStyleObject<typeof parts>)

  return {
    ...baseStyle.control,
    borderRadius: 'full',
    _checked: {
      // Imperfect 3rd party typings here (still pretty perfect tho)
      // @ts-expect-error
      ...baseStyle.control?._checked,
    },
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  control: baseStyleControl(props),
})

export const Radio: ComponentStyleConfig = {
  baseStyle,
}
