import { Button } from '../../components/button'
import { ExternalLayout } from '../../components/layout'
import React from 'react'
import styles from './pokemon-login-success.module.scss'
import { Icon } from '../../components/icon'
import { Icons } from '../../components/icon/icon'
import { DISCORD_SERVER_URL_POKEMON, ENV } from '../../constants'

const PokemonLoginSuccess = () => {
  return (
    <ExternalLayout headerActionsType="none" isFooterVisible={false}>
      <div className={styles['pokemon-login-success']}>
        <div className={styles['pokemon-login-success__header']}>
          <h1 className={styles['pokemon-login-success__header__h1']}>
            Welcome to Secured
          </h1>
        </div>
        <div className={styles['pokemon-login-success__discord-section']}>
          <p
            className={
              styles['pokemon-login-success__discord-section__heading']
            }
          >
            Start investing with our members
          </p>
          <Button
            className={styles['pokemon-login-success__discord-section__button']}
            styleType="fill"
            colour="primary"
            href={
              ENV === 'production' || ENV === 'staging'
                ? `${DISCORD_SERVER_URL_POKEMON}/902978729628827658` // Onboarding URL for production / staging
                : `${DISCORD_SERVER_URL_POKEMON}/915552486264291342` // Onboarding URL for development
            }
            iconPosition="left"
            icon={
              <Icon
                type={Icons.discord}
                className={
                  styles['pokemon-login-success__discord-section__button__icon']
                }
              />
            }
          >
            Launch Secured
          </Button>
        </div>
      </div>
    </ExternalLayout>
  )
}

export { PokemonLoginSuccess }
