import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import styles from './tutorial.module.scss'
import classNames from 'classnames'
import { Modal } from 'components/modal'
import type { User } from 'packlets/generated'
import { SecuredServer } from 'packlets/generated'
import { useMutation } from '@apollo/client'
import { Button } from 'components/button'
import { getDiscordDownloadLink } from 'utils/utils'
import { Analytics } from 'services/Analytics'
import Video4WebM from 'images/tutorial/bot-action-4-Kellen.webm'
import Video4MP4 from 'images/tutorial/bot-action-4-Kellen.mp4'
import Image5 from 'images/tutorial/discord-img.png'
import Image6 from 'images/tutorial/monitors-img.png'
import Image7 from 'images/tutorial/community-img.png'
import Image8 from 'images/tutorial/lets-go-img.png'
import { UpdateUserDocument } from 'gql/users.generated'
import { useUserInfo } from 'context/UserInfo'
import {
  DISCORD_SERVER_URL_US,
  DISCORD_SERVER_URL_EU,
} from '../../../constants'

interface DefaultProps {
  className?: string
}

interface Props extends Partial<DefaultProps> {
  user: User
}

const defaultProps: DefaultProps = {}

enum WizardSteps {
  'one' = 1,
  'two' = 2,
  'three' = 3,
  'four' = 4,
  'five' = 5,
}

const Onboarding: FunctionComponent<Props> = ({ className, user }) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { discordServer } = useUserInfo()

  const [wizardStep, setWizardStep] = useState<WizardSteps>(WizardSteps.one)

  const [updateUser] = useMutation(UpdateUserDocument)

  const stepTitles = {
    [WizardSteps.one]: 'Use the Secured Bot',
    [WizardSteps.two]: 'Discord intro',
    [WizardSteps.three]: 'Restock Alerts',
    [WizardSteps.four]: 'Chat with the community 👋',
    [WizardSteps.five]: "Let's go  🎉",
  }

  const onNextStep = async (nextStep?: WizardSteps) => {
    if (nextStep) {
      setWizardStep(nextStep)
      return
    }

    Analytics.clickTutorialBrowseUpcomingReleases()

    await updateUser({
      variables: {
        id: user.id,
        input: {
          isTutorialCompleted: true,
          isOnboardingCompleted: true,
        },
      },
    })

    const url =
      discordServer === SecuredServer.Eu
        ? DISCORD_SERVER_URL_EU
        : DISCORD_SERVER_URL_US

    window.location.href = url
  }

  useEffect(() => {
    Analytics.viewTutorial()
  }, [])

  return (
    <div className={className}>
      <Modal
        steps={{
          currentStep: wizardStep,
          total: Object.keys(WizardSteps).length / 2,
        }}
        sectionRef={sectionRef}
        className={classNames(styles.tutorial__modal, {
          // @ts-expect-error
          [styles[`tutorial__modal--step-${wizardStep}`]]: wizardStep,
        })}
        title="How to use Secured"
        subTitle={stepTitles[wizardStep]}
      >
        <React.Fragment>
          {wizardStep === WizardSteps.one && (
            <div className={styles.tutorial__step}>
              <div className={styles.tutorial__step__image}>
                <div className={styles.tutorial__step__image__inner}>
                  {/* <img alt={stepTitles[wizardStep]} src={Image4} /> */}
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video autoPlay loop playsInline>
                    <source src={Video4WebM} type="video/webm" />
                    <source src={Video4MP4} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </div>
              </div>
              <div className={styles.tutorial__step__content}>
                <ul>
                  <li>
                    Once you've added your item to your basket, the Secured bot
                    can do the rest
                  </li>
                  <li>The Secured Bot can even fill in captchas for you</li>
                </ul>
              </div>
              <div className={styles.tutorial__step__actions}>
                <Button
                  target="_blank"
                  colour="default"
                  href="https://www.notion.so/bump/How-to-Set-Up-The-Secured-Bot-307a6323175649a4a291c7e45261dbde"
                  onClick={() => {
                    Analytics.openTutorialSetupBot()
                  }}
                >
                  Set up Bot
                </Button>
                <Button onClick={() => onNextStep(WizardSteps.two)}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {wizardStep === WizardSteps.two && (
            <div className={styles.tutorial__step}>
              <div className={styles.tutorial__step__image}>
                <div className={styles.tutorial__step__image__inner}>
                  <img alt={stepTitles[wizardStep]} src={Image5} />
                </div>
              </div>
              <div className={styles.tutorial__step__content}>
                <ul>
                  <li>
                    Secured uses Discord to power a lot of our features and
                    experience.
                  </li>
                  <li>
                    Make sure to download the Discord mobile app to unlock all
                    of Secured's best features such as restock and raffle
                    alerts, drop reminders as well as chatting with our
                    community.
                  </li>
                </ul>
              </div>
              <div className={styles.tutorial__step__actions}>
                <Button
                  className={styles['tutorial__step__actions__mobile-button']}
                  colour="default"
                  target="_blank"
                  href={getDiscordDownloadLink()}
                  onClick={() => {
                    Analytics.openTutorialDownloadDiscord()
                  }}
                >
                  Download Discord
                </Button>
                <Button onClick={() => onNextStep(WizardSteps.three)}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {wizardStep === WizardSteps.three && (
            <div className={styles.tutorial__step}>
              <div className={styles.tutorial__step__image}>
                <div className={styles.tutorial__step__image__inner}>
                  <img alt={stepTitles[wizardStep]} src={Image6} />
                </div>
              </div>
              <div className={styles.tutorial__step__content}>
                <ul>
                  <li>
                    Looking for more pairs? Head to the relevant store's monitor
                    channel in Discord for restock alerts.
                  </li>
                  <li>
                    A monitor channel will update with messages as products come
                    into stock.
                  </li>
                  <li>
                    When you see a message come through for a product you want
                    to buy, just click the blue link and checkout!
                  </li>
                  <li>
                    Top tip: Make sure to{' '}
                    <a
                      target="_blank"
                      href={getDiscordDownloadLink()}
                      rel="noreferrer"
                    >
                      download the Discord app
                    </a>{' '}
                    so you never miss a restock alert.
                  </li>
                </ul>
              </div>
              <div className={styles.tutorial__step__actions}>
                <Button onClick={() => onNextStep(WizardSteps.four)}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {wizardStep === WizardSteps.four && (
            <div className={styles.tutorial__step}>
              <div className={styles.tutorial__step__image}>
                <div className={styles.tutorial__step__image__inner}>
                  <img alt={stepTitles[wizardStep]} src={Image7} />
                </div>
              </div>
              <div className={styles.tutorial__step__content}>
                <ul>
                  <li>
                    The best part about Secured is our community that chats on
                    Discord
                  </li>
                  <li>Learn more tips and tricks from your fellow members!</li>
                  <li>
                    We often host voice chats so we can all purchase together -
                    get involved on Discord.
                  </li>
                </ul>
              </div>
              <div className={styles.tutorial__step__actions}>
                <Button onClick={() => onNextStep(WizardSteps.five)}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {wizardStep === WizardSteps.five && (
            <div className={styles.tutorial__step}>
              <div className={styles.tutorial__step__image}>
                <div className={styles.tutorial__step__image__inner}>
                  <img alt={stepTitles[wizardStep]} src={Image8} />
                </div>
              </div>
              <div className={styles.tutorial__step__content}>
                <ul>
                  <li>
                    You're now ready to start getting sneakers and more for
                    retail!
                  </li>
                  <li>
                    The best resource to learn from is our guides{' '}
                    <a
                      target="_blank"
                      href="https://www.notion.so/bump/Secured-Guides-a48334e41fb8431c97df969d06429db8"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </li>
                </ul>
              </div>
              <div className={styles.tutorial__step__actions}>
                <Button onClick={() => onNextStep()}>Launch Discord</Button>
              </div>
            </div>
          )}
        </React.Fragment>
      </Modal>
    </div>
  )
}

Onboarding.defaultProps = defaultProps

export default Onboarding
