import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import styles from './onboarding.module.scss'
import classNames from 'classnames'
import { Modal } from 'components/modal'
import type { CountryCode, User } from 'packlets/generated'
import { ExperienceLevel } from 'packlets/generated'
import { useMutation } from '@apollo/client'
import { Icon } from 'components/icon'
import { Icons } from 'components/icon/icon'
import { Analytics } from 'services/Analytics'
import type { SelectInterestsFormData } from 'features/select-interests'
import { SelectInterests } from 'features/select-interests'
import { SelectExperienceLevel } from './onboarding-steps/select-experience-level'
import type { SelectExperienceLevelFormData } from './onboarding-steps/select-experience-level/select-experience-level'
import type { SelectBehaviourFormData } from './onboarding-steps/select-behaviour/select-behaviour'
import SelectBehaviour from './onboarding-steps/select-behaviour/select-behaviour'
import { Dob } from './onboarding-steps/dob'
import type { DobFormData } from './onboarding-steps/dob/dob'
import { useCallback } from 'react'
import { useUserInfo } from 'context/UserInfo'
import {
  SetExperienceLevelDocument,
  UpdateUserDiscordCountryDocument,
  UpdateUserDiscordInterestsDocument,
  UpdateUserDocument,
  UpdateUserMetadataDocument,
} from 'gql/users.generated'

interface DefaultProps {
  className?: string
}

interface Props extends Partial<DefaultProps> {
  user: User
  localeCountryCode?: CountryCode
}

const defaultProps: DefaultProps = {}

enum WizardSteps {
  'one' = 1,
  'two' = 2,
  'three' = 3,
  'four' = 4,
  'save' = 6,
}

const Onboarding: FunctionComponent<Props> = ({ className, user }) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [hasNoInterests, setHasNoInterests] = useState(false)
  const [hasMetaDateError, setHasMetaDateError] = useState(false)
  const userCountry = user.discordCountryCode ?? user.countryCode
  const { setDiscordServer } = useUserInfo()!

  const getWizardStep = useCallback(
    (aUser: User): WizardSteps => {
      if (!aUser.metadata?.experienceLevel) {
        return WizardSteps.one
      }
      if (
        aUser.metadata.experienceLevel === ExperienceLevel.Intermediate &&
        !aUser.metadata.userBehaviour &&
        aUser.discordInterests.length === 0 &&
        !hasNoInterests
      ) {
        return WizardSteps.two
      }
      if (!aUser.metadata.userBehaviour) {
        return WizardSteps.three
      }
      if (!aUser.metadata.dateOfBirth) {
        return WizardSteps.four
      }
      return WizardSteps.save
    },
    [hasNoInterests]
  )

  const [wizardStep, setWizardStep] = useState<WizardSteps>(getWizardStep(user))

  const [updateUserDiscordCountry] = useMutation(
    UpdateUserDiscordCountryDocument,
    {
      onError(e) {
        console.error(e)
      },
    }
  )

  const [updateUser] = useMutation(UpdateUserDocument)

  const [setExperienceLevel] = useMutation(SetExperienceLevelDocument, {
    ignoreResults: true,
  })

  const [
    updateUserDiscordInterests,
    { loading: loadingUpdateUserDiscordInterests },
  ] = useMutation(UpdateUserDiscordInterestsDocument, {
    ignoreResults: true,
    onCompleted(response) {
      setWizardStep(getWizardStep(response.updateUserDiscordInterests))
    },
    onError(e) {
      console.error(e)
    },
  })

  const [updateUserMetadata, { loading: loadingUpdateUserMetadata }] =
    useMutation(UpdateUserMetadataDocument, {
      onCompleted(response) {
        if (response.updateUserMetadata.metadata?.dateOfBirth) {
          updateUser({
            variables: {
              id: user.id,
              input: {
                isOnboardingCompleted: true,
              },
            },
          })
          if (response.updateUserMetadata.discordServer) {
            setDiscordServer(response.updateUserMetadata.discordServer)
          }
          return
        }
        setWizardStep(getWizardStep(response.updateUserMetadata))
      },
      onError(e) {
        if (e.message === 'Invalid time value') {
          setHasMetaDateError(true)
        }
      },
    })

  const onSelectExperienceLevelSave = async (
    data: SelectExperienceLevelFormData
  ) => {
    setExperienceLevel({
      variables: {
        userId: user.id,
        experienceLevel: data.experience,
      },
    })
    updateUserMetadata({
      variables: {
        userId: user.id,
        input: {
          experienceLevel: data.experience,
        },
      },
    })
  }

  const onSelectInterestsSave = async (data: SelectInterestsFormData) => {
    if (data.interests.length === 0) {
      setHasNoInterests(true)
      return
    }
    updateUserDiscordInterests({
      variables: {
        userId: user.id,
        input: {
          interestTypes: data.interests,
        },
      },
    })
  }

  useEffect(() => {
    updateUserDiscordCountry({
      variables: {
        userId: user.id,
        countryCode: 'US',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setWizardStep(getWizardStep(user))
  }, [getWizardStep, hasNoInterests, user])

  const onSelectBehaviourSave = async (data: SelectBehaviourFormData) => {
    updateUserMetadata({
      variables: {
        userId: user.id,
        input: {
          userBehaviour: data.userBehaviour,
        },
      },
    })
  }

  const onDobSave = async (data: DobFormData) => {
    setHasMetaDateError(false)
    updateUserMetadata({
      variables: {
        userId: user.id,
        input: {
          dateOfBirth: data.dateOfBirth,
        },
      },
    })
  }

  const onSelectExperienceLevelView = () => {
    Analytics.viewOnboardingSetExperienceLevel()
  }

  const onSelectInterestsView = () => {
    Analytics.viewOnboardingSetRoles()
  }

  const onSelectBehaviourView = () => {
    Analytics.viewOnboardingSetUserBehaviour()
  }

  const onDobView = () => {
    Analytics.viewOnboardingSetUserDob()
  }

  const stepTitles = {
    [WizardSteps.one]: 'Set your experience level',
    [WizardSteps.two]: 'Choose your interests',
    [WizardSteps.three]: 'What best describes you?',
    [WizardSteps.four]: 'When is your birthday?',
    [WizardSteps.save]: '',
  }

  return (
    <div className={className}>
      {wizardStep === WizardSteps.save && (
        <Modal
          className={styles['onboarding__loading-modal']}
          title="Setting up your profile"
        >
          <div
            className={styles['onboarding__loading-modal__loading-container']}
          >
            <Icon
              className={
                styles['onboarding__loading-modal__loading-container__icon']
              }
              type={Icons.loader}
            />
          </div>
        </Modal>
      )}
      {!loadingUpdateUserDiscordInterests && (
        <Modal
          steps={{
            currentStep: wizardStep,
            total: 5,
          }}
          sectionRef={sectionRef}
          className={classNames(styles.onboarding__modal, {
            // @ts-expect-error
            [styles[`onboarding__modal--step-${wizardStep}`]]: wizardStep,
          })}
          title="Welcome to Secured"
          subTitle={stepTitles[wizardStep]}
        >
          <React.Fragment>
            {wizardStep === WizardSteps.one && (
              <SelectExperienceLevel
                onFormSubmit={onSelectExperienceLevelSave}
                onViewCallback={onSelectExperienceLevelView}
                isLoading={loadingUpdateUserMetadata}
              />
            )}
            {wizardStep === WizardSteps.two && (
              <SelectInterests
                userCountry={userCountry}
                onFormSubmit={onSelectInterestsSave}
                onViewCallback={onSelectInterestsView}
                isLoading={loadingUpdateUserDiscordInterests}
              />
            )}
            {wizardStep === WizardSteps.three && (
              <SelectBehaviour
                onFormSubmit={onSelectBehaviourSave}
                onViewCallback={onSelectBehaviourView}
                isLoading={loadingUpdateUserMetadata}
              />
            )}
            {wizardStep === WizardSteps.four && (
              <Dob
                onFormSubmit={onDobSave}
                onViewCallback={onDobView}
                isLoading={loadingUpdateUserMetadata}
                hasError={hasMetaDateError}
              />
            )}
          </React.Fragment>
        </Modal>
      )}
    </div>
  )
}

Onboarding.defaultProps = defaultProps

export default Onboarding
