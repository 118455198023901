import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleTrack: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  if (c === 'primary') {
    return {
      bg: mode('gray.300', 'whiteAlpha.400')(props),
      _checked: {
        bg: mode(`${c}.900`, `${c}.500`)(props),
      },
    }
  }
  return {}
}

const baseStyle: PartsStyleFunction = (props) => {
  return {
    track: baseStyleTrack(props),
  }
}

export const Switch = {
  baseStyle,
}
