import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Button } from 'components/button'

import { UserBehaviour } from 'packlets/generated'
import { ButtonRadioGroup } from '../button-radio-group'

// these are all the optional props
export interface DefaultProps {
  userBehaviour?: UserBehaviour
}

export type SelectBehaviourFormData = {
  userBehaviour: UserBehaviour
}

const defaultProps: DefaultProps = {}

// these are all the required props
interface Props extends Partial<DefaultProps> {
  onFormSubmit: (data: SelectBehaviourFormData) => void
  onViewCallback: () => void
  userBehaviour?: UserBehaviour
  isLoading: boolean
}

const SelectBehaviour = ({
  onFormSubmit,
  onViewCallback,
  userBehaviour: userBehaviorProp,
  isLoading,
}: Props) => {
  const onViewWithCallback = useCallback(onViewCallback, [onViewCallback])

  useEffect(() => {
    onViewWithCallback()
  }, [onViewWithCallback])

  const [userBehaviour, setUserBehaviour] = useState<string>(
    userBehaviorProp as UserBehaviour
  )

  const isFormButtonEnabled = userBehaviour

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    onFormSubmit({ userBehaviour: userBehaviour as UserBehaviour })
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        <div>
          <div>
            <ButtonRadioGroup
              options={[
                {
                  label: 'Collector',
                  value: UserBehaviour.Collector,
                },
                {
                  label: 'Reseller',
                  value: UserBehaviour.Reseller,
                },
              ]}
              onChange={setUserBehaviour}
              value={userBehaviour}
            />
          </div>

          <div className={styles['select-behaviour__submit-container']}>
            {!isFormButtonEnabled && (
              <Button
                size="medium"
                className={styles['select-behaviour__submit-container__button']}
                disabled
              >
                Next
              </Button>
            )}
            {isFormButtonEnabled && (
              <Button
                size="medium"
                className={styles['select-behaviour__submit-container__button']}
                styleType="fill"
                colour="primary"
                onClick={onSubmit}
                disabled={isLoading}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

SelectBehaviour.defaultProps = defaultProps

export default SelectBehaviour
