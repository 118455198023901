import React from 'react'
import { Button } from '../../components/button'
import styles from './resubscribe.module.scss'
import { Divider } from '../../components/divider'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Locale } from '../../gql/local'
import { formatPriceCents } from '../../utils/utils'
import { GetPricingDataDocument } from '../../gql/checkout.generated'

export interface Props {
  isButtonDisabled: boolean
  onButtonClick: VoidFunction
}

export const ResubscribeMain = ({ isButtonDisabled, onButtonClick }: Props) => {
  const locale = useReactiveVar(Locale)

  const { data: pricingData } = useQuery(GetPricingDataDocument, {
    variables: {
      input: {
        currency: locale.currency,
        pricingCode: 'ENY',
      },
    },
  })

  return (
    <div>
      <div className={styles['resubscribe-main__header']}>
        <h1 className={styles['resubscribe-main__header__h1']}>
          Renew Subscription
        </h1>
        <p className={styles['resubscribe-main__header__sub-heading']}>
          Claim 50% off next month’s subscription
        </p>
      </div>
      <div className={styles['resubscribe-main__main']}>
        <div className={styles['resubscribe-main__main__row']}>
          <p className={styles['resubscribe-main__main__row__left']}>
            Monthly Subscription
          </p>
          <div className={styles['resubscribe-main__main__row__right']}>
            <div
              className={styles['resubscribe-main__main__row__right__prices']}
            >
              <p>
                {pricingData &&
                  formatPriceCents(
                    pricingData.getPricingData.monthly.priceCents / 2,
                    locale.currency
                  )}
              </p>
              <p
                className={
                  styles[
                    'resubscribe-main__main__row__right__prices__old-price'
                  ]
                }
              >
                {pricingData &&
                  formatPriceCents(
                    pricingData.getPricingData.monthly.priceCents,
                    locale.currency
                  )}
              </p>
            </div>
          </div>
        </div>
        <Divider className={styles['resubscribe-main__main__divider']} />
        <p className={styles['resubscribe-main__main__description']}>
          50% off next month's renewal only
        </p>
        <Button
          className={styles['resubscribe-main__main__button']}
          styleType="fill"
          colour="primary"
          disabled={isButtonDisabled}
          onClick={onButtonClick}
        >
          Renew Subscription
        </Button>
      </div>
      <p className={styles['resubscribe-main__footnote']}>
        *Subscription price will be 50% off for next month only and revert to
        full price for consecutive months.
      </p>
    </div>
  )
}
