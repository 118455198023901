import type { ReactNode } from 'react'
import React, { forwardRef } from 'react'
import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react'

export const Pricing = forwardRef<
  HTMLDivElement,
  { coupon?: string; children: ReactNode; amount?: number }
>(({ children, coupon, amount }, ref) => {
  const couponBg = useColorModeValue('blackAlpha.100', 'whiteAlpha.300')
  const couponColor = useColorModeValue('gray.600', 'gray.300')
  return (
    <Box as="section" mt={['100px', '100px', '184px']} ref={ref}>
      <Heading
        fontSize={['3xl', '3xl', '4xl']}
        mt={coupon ? 4 : 10}
        textAlign="center"
      >
        Pricing
      </Heading>
      {coupon && amount && (
        <Text
          sx={{
            borderRadius: 'base',
            margin: '0 auto',
            background: couponBg,
            backdropFilter: 'blur(15px)',
            transform: 'translate3d(0, 0, 0)',
            p: 4,
            maxWidth: '556px',
            color: couponColor,
            marginTop: 4,
            textAlign: 'center',
            fontFamily: "'JetBrains Mono', monospace",
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          Get {amount}% off your first payment on our monthly plan
        </Text>
      )}
      {children}
    </Box>
  )
})
