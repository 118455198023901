import React, { useLayoutEffect, useRef } from 'react'
import type { IconButtonProps } from '@chakra-ui/react'
import {
  IconButton,
  useBreakpointValue,
  chakra,
  forwardRef,
  useChakra,
  Box,
} from '@chakra-ui/react'
import { WebRoutes } from '../../../containers/routes/WebRoutes'
import { useHistory } from 'react-router-dom'
import {
  sideNavDesktopWidth,
  sideNaviPadWidth,
} from '../../../ui-kit/theme/tokens'
import { CaretLeft } from 'phosphor-react'
import { baseDisplay, baseTypography } from '../../../ui-kit'
import type {
  StyleFunctionProps,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { AppConfig } from '../../../services/AppConfig'
import { mode } from '@chakra-ui/theme-tools'
import { mergeRefs } from '@chakra-ui/react-utils'
import type { MarkOptional } from 'ts-essentials'

type AppHeaderVariants = 'default' | 'center'
type AppHeaderSizes = 'sm' | 'md'
type AppHeaderParts = 'text' | 'container' | 'back-button'

export interface AppHeaderProps {
  rightSection?: React.ReactNode
  leftSection?: React.ReactNode
  variant?: AppHeaderVariants
  size?: AppHeaderSizes
}

export const AppHeaderBackButton = forwardRef<
  MarkOptional<IconButtonProps, 'aria-label'>,
  'button'
>((props, ref) => {
  const history = useHistory()

  return (
    <IconButton
      ref={ref}
      size="md"
      variant="ghost"
      onClick={() => {
        if (history.length === 0) {
          history.push(WebRoutes.root)
        } else {
          history.goBack()
        }
      }}
      icon={<CaretLeft size={props.size === 'xs' ? 16 : 24} />}
      {...props}
      aria-label={props['aria-label'] ? props['aria-label'] : 'Navigate back'}
    />
  )
})

const sizes: Record<
  AppHeaderParts,
  Record<AppHeaderSizes, SystemStyleObject>
> = {
  'back-button': {
    sm: {},
    md: {},
  },
  container: {
    sm: {
      px: 3,
      pt: `calc(var(--chakra-space-3) + env(safe-area-inset-top))`,
      pb: 3,
    },
    md: {
      px: 6,
      pt: `calc(var(--chakra-space-4) + env(safe-area-inset-top))`,
      pb: 4,
    },
  },
  text: {
    sm: {
      ...baseTypography.subheading,
    },
    md: {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes['4xl'],
    },
  },
}

const variants: Record<
  AppHeaderParts,
  Record<AppHeaderVariants, SystemStyleObject>
> = {
  'back-button': {
    default: {},
    center: {},
  },
  text: {
    default: {
      textAlign: 'left',
    },
    center: {
      textAlign: 'center',
    },
  },
  container: {
    default: {},
    center: {},
  },
}

const baseStyles = ({
  isMobileScreenSize,
  ...props
}: { isMobileScreenSize: boolean } & Omit<
  StyleFunctionProps,
  'colorScheme'
>) => ({
  text: {
    w: 'full',
  } as SystemStyleObject,
  container: {
    display: 'flex',
    pos: 'fixed',
    zIndex: 'sticky',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.25rem',
    top: 0,
    bg: mode('white', 'gray.900')(props),
    w: isMobileScreenSize
      ? 'full'
      : {
          base: `calc(100% - ${sideNaviPadWidth})`,
          xl: `calc(100% - ${sideNavDesktopWidth})`,
        },
    left: isMobileScreenSize
      ? 0
      : { base: sideNaviPadWidth, xl: sideNavDesktopWidth },
  } as SystemStyleObject,
})

export const AppHeader = forwardRef<AppHeaderProps, 'div'>((props, ref) => {
  const {
    children,
    rightSection,
    leftSection,
    size = 'md',
    variant = 'default',
    ...rest
  } = props

  const elementRef = useRef<HTMLDivElement>(null)
  const isMobileScreenSize = useBreakpointValue(
    [true, true, false],
    AppConfig.isNativePlatform ? 'base' : 'xl'
  ) as boolean

  const styles = baseStyles({ isMobileScreenSize, ...useChakra(), ...props })

  useLayoutEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.offsetHeight
      document.documentElement.style.setProperty(
        '--internal-header-height',
        `calc(${height / 16}rem - env(safe-area-inset-top))`
      )
    }
  }, [isMobileScreenSize, children])

  return (
    <chakra.div
      ref={mergeRefs(elementRef, ref)}
      {...rest}
      __css={{
        ...styles.container,
        ...variants.container[variant],
        ...sizes.container[size],
      }}
    >
      <Box>{leftSection}</Box>
      <chakra.p
        __css={{
          ...styles.text,
          ...sizes.text[size],
          ...variants.text[variant],
        }}
      >
        {children}
      </chakra.p>
      <Box>{rightSection}</Box>
    </chakra.div>
  )
})
