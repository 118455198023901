import { Button } from '../../components/button'
import { ExternalLayout } from '../../components/layout'
import React from 'react'
import styles from './nft-login-success.module.scss'
import { Icon } from '../../components/icon'
import { Icons } from '../../components/icon/icon'
import { DISCORD_SERVER_URL_NFT, ENV } from '../../constants'

export const NftLoginSuccess = () => {
  return (
    <ExternalLayout headerActionsType="none" isFooterVisible={false}>
      <div className={styles['nft-login-success']}>
        <div className={styles['nft-login-success__header']}>
          <h1 className={styles['nft-login-success__header__h1']}>
            Welcome to Secured
          </h1>
        </div>
        <div className={styles['nft-login-success__discord-section']}>
          <p className={styles['nft-login-success__discord-section__heading']}>
            Start investing with our members
          </p>
          <Button
            className={styles['nft-login-success__discord-section__button']}
            styleType="fill"
            colour="primary"
            href={
              ENV === 'production'
                ? `${DISCORD_SERVER_URL_NFT}/880852853193834536`
                : DISCORD_SERVER_URL_NFT
            } // Onboarding URL for production
            iconPosition="left"
            icon={
              <Icon
                type={Icons.discord}
                className={
                  styles['nft-login-success__discord-section__button__icon']
                }
              />
            }
          >
            Launch Secured
          </Button>
        </div>
      </div>
    </ExternalLayout>
  )
}
