import {
  Navigation,
  NavigationItem,
  NavigationItemIcon,
  NavigationItemText,
} from 'ui-kit'
import { User } from 'phosphor-react'
import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import type { ReactNode } from 'react'
import { WebRoutes } from '../../../containers/routes/WebRoutes'
import { useHistory } from 'react-router'

interface AppNavigationItemProps {
  to: string
  icon: React.ComponentType
  children: ReactNode
}

const AppNavigationItem = (props: AppNavigationItemProps) => {
  const location = useLocation()
  const history = useHistory()
  const match = location.pathname === props.to
  const navigate = useCallback(() => {
    history.push(props.to)
  }, [history, props.to])

  return (
    <NavigationItem
      onClick={navigate}
      onKeyPress={navigate}
      isActive={match}
      tabIndex={0}
    >
      <NavigationItemIcon as={props.icon} />
      <NavigationItemText>{props.children}</NavigationItemText>
    </NavigationItem>
  )
}

export const AppNavigation = () => {
  const pathname = useLocation().pathname
  const isHidden = pathname.includes('products')

  if (isHidden) {
    return null
  }

  return (
    <Navigation>
      <AppNavigationItem icon={User} to={WebRoutes.account}>
        Profile
      </AppNavigationItem>
    </Navigation>
  )
}
