import type { BoxProps, ButtonProps, LinkProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  Flex,
  forwardRef,
  Icon,
  Link,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { sideNavDesktopWidth, sideNaviPadWidth } from '../theme/tokens'
import React from 'react'
import { baseTypography } from '../theme/typography'

export interface SideNavigationLinkProps extends LinkProps {
  icon: any
  isActive?: boolean
}

export const SideNavigationLink = forwardRef<SideNavigationLinkProps, 'a'>(
  ({ icon, children, isActive, ...rest }, ref) => {
    const isIPad = useBreakpointValue([false, false, true, true, false])
    const bgHover = useColorModeValue('gray.100', 'whiteAlpha.100')
    const hoverColor = useColorModeValue('gray.700', 'gray.300')

    const activeBg = useColorModeValue('gray.100', 'whiteAlpha.200')
    const activeColor = useColorModeValue('black', 'gray.100')

    return (
      <Tooltip isDisabled={!isIPad} label={children} placement="right">
        <Link
          ref={ref}
          width={isIPad ? 'max-content' : 'full'}
          display="block"
          p={3}
          borderRadius="md"
          transition="all 0.3s"
          aria-current={isActive ? 'page' : undefined}
          {...baseTypography.link}
          textDecoration="none"
          _hover={{
            bg: bgHover,
            color: hoverColor,
          }}
          _activeLink={{
            bg: activeBg,
            color: activeColor,
          }}
          {...rest}
        >
          <Flex gridGap="0.75em">
            <Icon as={icon} boxSize="20px" />
            {isIPad ? null : <Text as="span">{children}</Text>}
          </Flex>
        </Link>
      </Tooltip>
    )
  }
)

export interface SideNavigationButtonProps extends ButtonProps {
  icon: any
}

export const SideNavigationButton = forwardRef<
  SideNavigationButtonProps,
  'div'
>(({ icon, children, ...rest }, ref) => {
  const isIPad = useBreakpointValue([false, false, true, true, false])

  return (
    <Tooltip isDisabled={!isIPad} label={children} placement="right">
      <Button
        ref={ref}
        width={isIPad ? 'max-content' : 'full'}
        p={3}
        textAlign="left"
        transition="all 0.3s"
        justifyContent="flex-start"
        {...rest}
      >
        <Flex gridGap="0.75em">
          <Icon as={icon} boxSize="20px" />
          {isIPad ? null : <Text as="span">{children}</Text>}
        </Flex>
      </Button>
    </Tooltip>
  )
})

export const SideNavigation = forwardRef<BoxProps, 'nav'>(
  ({ children, ...props }, ref) => {
    const textColor = useColorModeValue('gray.600', 'gray.400')
    const borderColor = useColorModeValue('gray.200', 'gray.700')

    return (
      <Box
        as="nav"
        display="flex"
        flexDirection="column"
        ref={ref}
        height="100vh"
        width="full"
        color={textColor}
        px={6}
        py={6}
        borderRightWidth={1}
        borderColor={borderColor}
        maxWidth={{ base: sideNaviPadWidth, xl: sideNavDesktopWidth }}
        {...props}
      >
        {children}
      </Box>
    )
  }
)
