import React from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box, forwardRef } from '@chakra-ui/react'
import { fullScreenHeight } from 'ui-kit'
import classNames from 'classnames'
import { merge } from 'lodash'

export const Container = forwardRef<BoxProps, 'div'>(
  ({ sx, ...props }, ref) => (
    <Box
      ref={ref}
      {...props}
      className={classNames('secured-container', props.className)}
      sx={merge(
        {
          width: '100%',
          minHeight: fullScreenHeight,
          maxHeight: fullScreenHeight,
        },
        sx
      )}
    />
  )
)
