import React, { useCallback, useEffect, useRef } from 'react'
import type { CreateTypes } from 'canvas-confetti'
import ReactCanvasConfetti from 'react-canvas-confetti'
import styles from './school-pride-confetti.module.scss'

const SchoolPrideConfetti: React.FC = () => {
  const isAnimationEnabled = useRef<boolean>(false)
  const animationInstance = useRef<CreateTypes | null>(null)
  const endDate = useRef<number>(0)
  const timeout = useRef<number>()

  const nextTickAnimation = useCallback(() => {
    makeShot(60, 0)
    makeShot(120, 1)
    if (
      isAnimationEnabled.current &&
      animationInstance &&
      Date.now() < endDate.current
    ) {
      requestAnimationFrame(nextTickAnimation)
    }
  }, [])

  const startAnimation = useCallback(() => {
    if (!isAnimationEnabled.current) {
      isAnimationEnabled.current = true
      nextTickAnimation()
    }
  }, [nextTickAnimation])

  const stopAnimation = useCallback(() => {
    isAnimationEnabled.current = false

    if (animationInstance.current) {
      animationInstance.current.reset()
    }
  }, [])

  useEffect(() => {
    return () => {
      stopAnimation()

      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [stopAnimation])

  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      endDate.current = Date.now() + 600 // ms
      startAnimation()
    }, 500)
  }, [startAnimation])

  const makeShot = (angle: number, originX: number) => {
    if (animationInstance.current) {
      animationInstance.current({
        particleCount: 4,
        angle,
        spread: 55,
        origin: { x: originX },
        colors: ['#25bb06', '#068fbb'],
      })
    }
  }

  const getInstance = (instance: CreateTypes | null) => {
    animationInstance.current = instance
  }

  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        className={styles['school-pride-confetti__canvas']}
      />
    </>
  )
}

export default SchoolPrideConfetti
