import { useQuery, useReactiveVar } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Block } from '../../components/landing/block'
import { CtaBlock, CtaBlockText } from '../../components/landing/cta-block'
import { Feature, FeatureText } from '../../components/landing/feature'
import {
  Hero,
  HeroCoupon,
  HeroCtaButton,
  HeroCtaContainer,
  HeroLimitedSpacesAvailable,
  HeroSubTitle,
  HeroTitle,
} from '../../components/landing/hero'
import { LandingCtaButton } from '../../components/landing/landing-cta-button'
import { Pricing } from '../../components/landing/pricing'
import {
  ProductImage,
  ProductInfo,
  ProductInfoDescription,
  ProductInfoTags,
  ProductInfoText,
} from '../../components/landing/product-info'
import { SupportSection } from '../../components/landing/support-section'
import { Testimonial } from '../../components/landing/testimonial'
import { Testimonials } from '../../components/landing/testimonials'
import {
  WorksWith,
  WorksWithBrandCard,
} from '../../components/landing/works-with'
import { ExternalLayout } from '../../components/layout'
import { SubscriptionCheckout } from '../../features/subscription-checkout'
import { UserSubscriptionProductType } from 'packlets/generated'
import { Locale } from '../../gql/local'
import adidas from '../../images/landing/brands/adidas.png'
import nike from '../../images/landing/brands/nike.png'
import offWhite from '../../images/landing/brands/off-white.png'
import shopify from '../../images/landing/brands/shopify.png'
import supreme from '../../images/landing/brands/supreme.png'
import hero from '../../images/landing/hero.png'
import Img01 from '../../images/landing/Img01.png'
import Img02 from '../../images/landing/Img02.png'
import Img03 from '../../images/landing/Img03.png'
import Img04 from '../../images/landing/Img04.png'
import Img05 from '../../images/landing/Img05.png'
import iuoc2000 from '../../images/landing/testimonials/iuoc2000.png'
import jamiewdl from '../../images/landing/testimonials/jamiewdl.png'
import P0inters from '../../images/landing/testimonials/P0inters.png'
import Rjl50 from '../../images/landing/testimonials/Rjl50.png'
import shafeenk from '../../images/landing/testimonials/shafeenk.png'
import { useFeatureFlags } from '../../providers/remote-config'
import { Analytics } from '../../services/Analytics'
import { FacebookPixel } from '../../services/FacebookPixel'
import {
  GoogleTagManager,
  GoogleTagManagerEventType,
} from '../../services/GoogleTagManager'
import { RedditEventType, RedditPixel } from '../../services/RedditPixel'
import { StripeClient } from '../../services/StripeClient/StripeClient'
import { TikTokEventType, TikTokPixel } from '../../services/TikTokPixel'
import { GetPricingDataDocument } from '../../gql/checkout.generated'
import { Chat } from 'phosphor-react'
import { BsDiscord } from 'react-icons/bs'
import { Box, useColorModeValue } from '@chakra-ui/react'

const stripePromise = StripeClient.loadStripe()

interface Props {
  coupon?: string
  errorDescription?: string
}

export const LandingSneakers = ({ coupon, errorDescription }: Props) => {
  const location = useLocation()
  const toast = useToasts()
  const locale = useReactiveVar(Locale)

  const locationHash = location.hash

  const subscriptionSectionRef = useRef<HTMLDivElement>(null)

  const { isAbTestingPriceActive: isAbTestingPriceActiveValue } =
    useFeatureFlags()
  const isAbTestingPriceActive =
    isAbTestingPriceActiveValue !== undefined
      ? isAbTestingPriceActiveValue?.asBoolean() === true
      : null

  /**
   * ENY - $30
   * AOR - $39
   * FNP - $49
   */
  // const pricingCode = isAbTestingPriceActive ? 'FNP' : 'ENY'
  const pricingCode = 'ENY'

  const { data: pricingData, loading: loadingPricingData } = useQuery(
    GetPricingDataDocument,
    {
      variables: {
        input: {
          currency: locale.currency,
          promoCode: coupon,
          pricingCode,
        },
      },
    }
  )

  const scrollToSubscriptionSection = (behavior: ScrollBehavior = 'smooth') => {
    if (subscriptionSectionRef) {
      subscriptionSectionRef.current?.scrollIntoView({ behavior })
    }
  }

  const onSignupClick = () => {
    Analytics.openSignup({
      coupon,
      userSubscriptionProductType: UserSubscriptionProductType.Sneakers,
    })

    scrollToSubscriptionSection()
  }

  useEffect(() => {
    if (isAbTestingPriceActiveValue !== undefined) {
      Analytics.viewLanding({
        userSubscriptionProductType: UserSubscriptionProductType.Sneakers,
        pricingCode,
        currency: locale.currency,
        promoCode: coupon,
      })
      FacebookPixel.track({
        name: 'ViewContent',
      })
      TikTokPixel.track(TikTokEventType.ViewContent)
      RedditPixel.track(RedditEventType.ViewContent)
      GoogleTagManager.track(GoogleTagManagerEventType.ViewContent, {
        value: UserSubscriptionProductType.Sneakers,
      })
    }
  }, [isAbTestingPriceActiveValue, locale, pricingCode, coupon])

  useEffect(() => {
    if (locationHash === '#pricing') {
      scrollToSubscriptionSection('auto')
    }
  }, [locationHash])

  useEffect(() => {
    if (errorDescription) {
      toast.removeAllToasts()
      toast.addToast(errorDescription, {
        appearance: 'error',
        autoDismiss: false,
      })
    }
    // eslint-disable-next-line
  }, [errorDescription])
  return (
    <ExternalLayout onSignup={onSignupClick}>
      <Hero>
        <HeroTitle>
          Tired of Seeing{' '}
          <Box as="span" display={['block', 'block', 'inline-block']}>
            “Sold Out” ?
          </Box>
        </HeroTitle>
        <HeroSubTitle>
          Get access to sneaker bots, early drop links and every raffle, all in
          one place. So you can start getting sneakers for retail prices.
        </HeroSubTitle>
        <HeroCtaContainer>
          <HeroCtaButton onClick={onSignupClick}>Sign Up</HeroCtaButton>

          <HeroLimitedSpacesAvailable>
            Limited spaces available
          </HeroLimitedSpacesAvailable>

          {coupon &&
            pricingData?.getPricingData.monthly.discountMetadata?.amount && (
              <HeroCoupon>
                Use Code: {coupon} for{' '}
                {pricingData?.getPricingData.monthly.discountMetadata?.amount}%
                off
              </HeroCoupon>
            )}
        </HeroCtaContainer>
        <Box
          fontWeight="bold"
          fontSize="12px"
          textAlign="center"
          color={useColorModeValue('#CA7900', '#FFCD82')}
          mt="1"
        >
          Supports all major releases across US & Canada
        </Box>
      </Hero>
      <ProductInfo>
        <ProductImage src={hero} alt="Secured hero" />
        <ProductInfoDescription>
          <ProductInfoText>
            <p>
              Secured is a subscription service that helps you purchase sneakers
              and streetwear directly from brands, before they sell out.
            </p>
            <p>
              Members get access to the Secured Discord community. This means
              you will have access to all the tools and information you will
              need to stop paying resell prices.
            </p>
          </ProductInfoText>
          <ProductInfoTags />
        </ProductInfoDescription>
      </ProductInfo>
      <WorksWith>
        <WorksWithBrandCard src={shopify} alt="shopify" />
        <WorksWithBrandCard src={supreme} alt="supreme" />
        <WorksWithBrandCard src={nike} alt="nike" />
        <WorksWithBrandCard src={offWhite} alt="offWhite" />
        <WorksWithBrandCard src={adidas} alt="adidas" />
      </WorksWith>
      <Testimonials>
        <Testimonial
          description="Perfect cook group to find everything useful in one place, with
              top quality support from both admins and members"
          imageUrl={jamiewdl}
          username="@jamiewdl"
        />
        <Testimonial
          description="Before I joined SECURED, I knew a little about reselling. Now
              that I’ve been a member for over 6 months I feel super confident
              and well informed. Best decision this year!"
          imageUrl={shafeenk}
          username="@shafeenk"
        />
        <Testimonial
          description="Taught me everything I needed to know about reselling and
              helped me build my brand"
          imageUrl={Rjl50}
          username="@Rjl50"
        />
        <Testimonial
          description="Secured doesn't just provide monitoring services, the members are always willing to help answer whatever questions you have."
          imageUrl={iuoc2000}
          username="@iuoc2000"
        />
        <Testimonial
          description="It’s just awesome. Been here since it started and never
              regretted it. It’s Newbie friendly with loads of guides but also
              very good if you’re experienced in reselling"
          imageUrl={P0inters}
          username="@P0inters"
        />
      </Testimonials>
      <CtaBlock>
        <CtaBlockText>
          Learn how to become a successful sneaker reseller with a Secured
          membership. Already trusted by thousands of resellers globally.
        </CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>
      <Feature
        title="Let our team run bots for you"
        imageUrl={Img05}
        imageLocation="left"
      >
        <FeatureText>
          Don't want to learn the complexities of botting? Secured's got you
          covered with Free Auto-Checkout (ACO) services. Our team of
          experienced, successful botters will run the best bots on your behalf,
          to get checkouts at retail prices for you on every profitable release.
          Just let us know what products you would like and we’ll do the rest.
          We’ve generated more than 2,000+ checkouts, securing over $175,000+
          worth of products at retail prices for our members in just the past 3
          months. All of this is included in our standard membership.
        </FeatureText>
      </Feature>
      <Feature
        title="Discover hyped products before they release"
        imageUrl={Img01}
        imageLocation="right"
      >
        <FeatureText>
          Browse our curated feed of the most popular upcoming sneaker &
          streetwear releases that our team of experts know will sell out fast.
          These are the best products to purchase because they should earn you a
          strong profit margin when resold.
        </FeatureText>
        <FeatureText>
          We cover every important release across the United States and Canada.
        </FeatureText>
      </Feature>
      <Feature
        title="Enter all raffles from one place"
        imageUrl={Img02}
        imageLocation="left"
      >
        <FeatureText>
          Secured finds all the raffles that you can enter for each sneaker,
          lets you enter them all from one place and allows you to browse by
          location so you're not hit with any crazy shipping costs when you win
          your pair for retail price.
        </FeatureText>
      </Feature>
      <Feature
        title="Know exactly where & when a sneaker drops"
        imageUrl={Img03}
        imageLocation="right"
      >
        <FeatureText>
          Secured groups together every online store releasing the product you
          want, so come drop day you're prepared and know exactly where to go.
        </FeatureText>
        <FeatureText>
          Once a drop goes live, you can use our direct links to go straight to
          the product, saving you valuable seconds.
        </FeatureText>
      </Feature>
      <Feature
        title="Free Sneaker & Streetwear Bot"
        imageUrl={Img04}
        imageLocation="left"
      >
        <FeatureText>
          With your Secured membership you get unlimited access to our checkout
          bot which will help you checkout faster on more than 240 sites
          including Supreme, Yeezy Supply, Nike and Adidas.
        </FeatureText>
        <FeatureText>
          We provide 1 on 1 support through instant chat and optional zoom calls
          to make sure you understand all the important stuff.
        </FeatureText>
      </Feature>
      <CtaBlock>
        <CtaBlockText>
          Stop wasting time searching everywhere for early sneaker information.
          Join Secured to get the most accurate information, early links and
          raffles for every drop.
        </CtaBlockText>
        <LandingCtaButton onClick={onSignupClick}>Sign Up</LandingCtaButton>
      </CtaBlock>
      <SupportSection>
        <Block
          title="Community Access"
          subTitle="Connect with fellow sneakerheads"
          description="Secured is a diverse community of enthusiasts which means regardless of your experience level, beginner or expert, there's always people that you can chat with and learn from."
          icon={BsDiscord}
        />
        <Block
          title="1 on 1 support"
          subTitle="You’re in the best place to learn"
          description="with our easy to understand written guides and around the clock support staff, you can quickly become an expert in getting sneakers for retail prices."
          icon={Chat}
        />
      </SupportSection>
      <Pricing
        ref={subscriptionSectionRef}
        coupon={coupon}
        amount={pricingData?.getPricingData.monthly.discountMetadata?.amount}
      >
        <Elements stripe={stripePromise}>
          <SubscriptionCheckout
            mt={12}
            coupon={coupon}
            userSubscriptionProductType={UserSubscriptionProductType.Sneakers}
            monthly={pricingData?.getPricingData.monthly}
            annual={pricingData?.getPricingData.annual}
            currency={locale.currency}
            pricingCode={pricingCode}
            isLoading={isAbTestingPriceActive === null || loadingPricingData}
          />
        </Elements>
      </Pricing>
    </ExternalLayout>
  )
}

export default LandingSneakers
