export enum WebRoutes {
  // External
  root = '/',
  login = '/login',
  loginAccountSelection = '/login/accounts',

  sneakers = '/sneakers',
  nfts = '/nft',

  jordansForRetail = '/jordans-for-retail',
  sneakersReselling = '/sneakers-reselling',
  ambassadorProgram = '/ambassador-program',
  sneakerBotting = '/sneaker-botting',

  checkoutValidation = '/checkout-validation',
  checkoutConfirmation = '/checkout-confirmation',
  mobileCheckoutValidation = '/mobile-checkout-validation',

  sportsCardsLoginSuccess = '/sports-cards-login-success',
  nftLoginSuccess = '/nft-login-success',
  pokemonLoginSuccess = '/pokemon-login-success',
  cryptoLoginSuccess = '/crypto-login-success',
  resubscribe = '/resubscribe',

  terms = '/terms',
  privacyPolicy = '/privacy-policy',

  // Internal
  guides = '/guides',
  discordChannelGuide = '/guides/discord-channel-guide',
  taskGuide = '/guides/task-guide',

  account = '/account',

  products = '/products/:productId',
  productCollections = '/product-collections/:productCollectionId',

  releaseCalendar = '/release-calendar',

  upgradeAnnual = '/upgrade-annual',

  alerts = '/alerts',
  editAlerts = '/edit-alerts',
}
