import { makeVar } from '@apollo/client'
import { CountryCode, CurrencyCode } from 'packlets/generated'

type Locale = {
  currency: CurrencyCode
  countryCode: CountryCode
}

export default makeVar<Locale>({
  currency: CurrencyCode.Usd,
  countryCode: CountryCode.Us,
})
