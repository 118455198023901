declare const fbq: facebook.Pixel.Event

declare module facebook.Pixel {
  export interface Event {
    (
      eventType: string,
      eventName: string,
      eventData?: FacebookEventCustomData | null,
      eventId?: string | null
    ): void
  }
}

export type FacebookEventContent = {
  id: string
  quantity: number
}

type FacebookEventCustomData = {
  currency?: string
  value?: number
  contentType?: 'product' | 'product_group'
  contents?: [FacebookEventContent]
  numItems?: number
}

export interface FacebookEvent {
  name: string
  eventId?: string
  customData?: FacebookEventCustomData
}

export class FacebookPixel {
  public static track(event: FacebookEvent) {
    fbq('track', event.name, event.customData ?? null, event.eventId ?? null)
  }

  public static pageView() {
    fbq('track', 'PageView')
  }
}
