import { baseDisplay, baseTypography } from '../typography'
import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'

export const Heading: ComponentSingleStyleConfig = {
  sizes: {
    xs: {
      ...baseTypography.subheading,
    },
    sm: {
      ...baseTypography.title,
    },
    md: {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes.xl,
    },
    lg: {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes['2xl'],
    },
    xl: {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes['3xl'],
    },
    '2xl': {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes['4xl'],
    },
    '3xl': {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes['5xl'],
    },
    '4xl': {
      ...baseDisplay.baseStyle,
      ...baseDisplay.sizes['6xl'],
    },
  },
  defaultProps: {
    size: 'subheading',
  },
}
