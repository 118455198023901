import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleControl: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  if (c === 'primary') {
    return {
      border: '2px solid',
      borderRadius: 'sm',
      borderColor: 'inherit',

      _checked: {
        bg: mode(`gray.900`, `gray.500`)(props),
        borderColor: mode(`gray.900`, `gray.500`)(props),
        color: mode('white', 'gray.900')(props),

        _hover: {
          bg: mode(`gray.800`, `gray.400`)(props),
          borderColor: mode(`gray.800`, `gray.400`)(props),
        },

        _disabled: {
          borderColor: mode('gray.200', 'transparent')(props),
          bg: mode('gray.200', 'whiteAlpha.300')(props),
          color: mode('gray.500', 'whiteAlpha.500')(props),
        },
      },

      _indeterminate: {
        bg: mode(`gray.900`, `gray.500`)(props),
        borderColor: mode(`gray.900`, `gray.500`)(props),
        color: mode('white', 'gray.900')(props),
      },

      _disabled: {
        bg: mode('gray.100', 'whiteAlpha.100')(props),
        borderColor: mode('gray.100', 'transparent')(props),
      },

      _focus: {
        boxShadow: 'outline',
      },

      _invalid: {
        borderColor: mode('red.900', 'red.500')(props),
      },
    }
  }
  return {}
}

const baseStyle: PartsStyleFunction<any> = (props) => ({
  control: baseStyleControl(props),
})

export const Checkbox: ComponentStyleConfig = {
  baseStyle,
}
