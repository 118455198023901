import type { FunctionComponent } from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './alert.module.scss'
import { Icon } from '../icon'
import { Icons } from '../icon/icon'
import { Button } from '../button'

interface DefaultProps {
  className?: string
  type: 'error' | 'success' | 'default'
}

interface Props extends Partial<DefaultProps> {
  title: string
  onClose?: () => void
}

const defaultProps: DefaultProps = {
  type: 'default',
}

const Alert: FunctionComponent<Props> = ({
  className,
  type,
  title,
  onClose,
}) => {
  return (
    <div
      className={classNames(styles.alert, {
        // @ts-expect-error
        [styles[`alert--${type}`]]: type,
        [className as string]: className,
      })}
    >
      <div className={styles.alert__title}>{title}</div>
      <Button
        className={styles.alert__button}
        styleType="ghost"
        icon={
          <Icon
            type={Icons.cross}
            className={classNames(styles.alert__button__icon, {
              // @ts-expect-error
              [styles[`alert__button__icon--${type}`]]: type,
            })}
          />
        }
        onClick={onClose}
      />
    </div>
  )
}

Alert.defaultProps = defaultProps

export default Alert
