import type { ReactElement, ReactNode } from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './button.module.scss'
import { Link } from 'react-router-dom'

interface DefaultProps {
  className?: string
  type: 'submit' | 'button' | 'reset'
  disabled: boolean
  onClick?: (event: React.MouseEvent) => void
  styleType: 'fill' | 'outline' | 'ghost'
  colour: 'default' | 'success' | 'error' | 'primary'
  icon?: ReactElement
  iconPosition: 'left' | 'right'
  to?: string
  href?: string
  target?: string
  id?: string
  size: 'smaller' | 'small' | 'medium' | 'large'
  inactive: boolean
}
interface Props extends Partial<DefaultProps> {
  children?: ReactNode
}

const defaultProps: DefaultProps = {
  className: '',
  type: 'submit',
  styleType: 'fill',
  colour: 'primary',
  disabled: false,
  iconPosition: 'right',
  size: 'small',
  inactive: false,
}

const Button = ({
  className,
  type = 'submit',
  disabled = false,
  onClick,
  styleType,
  colour,
  icon,
  iconPosition,
  href,
  to,
  target,
  id,
  size,
  inactive,
  children,
}: Props) => {
  let iconElement
  if (icon) {
    iconElement = React.cloneElement(icon, {
      className: classNames(styles.button__icon, {
        [icon.props.className as string]: icon.props.className,
        // @ts-expect-error
        [styles[`button__icon--${size}`]]: size,
        // @ts-expect-error
        [styles[`button__icon--${iconPosition}--${size}`]]:
          iconPosition && size,
        [styles[`button__icon--no-title`]]: children === '',
      }),
    })
  }

  if (to) {
    return (
      <Link
        to={to}
        onClick={onClick}
        className={classNames(styles.button, {
          // @ts-expect-error
          [styles[`button--${size}`]]: size,
          // @ts-expect-error
          [styles[`button--${styleType}`]]: styleType,
          // @ts-expect-error
          [styles[`button--${styleType}--${colour}`]]: colour && styleType,
          [styles[`button--inactive`]]: inactive,
          [styles['button--disabled']]: disabled,
          [className as string]: className,
        })}
      >
        {icon && iconPosition === 'left' && iconElement}
        {children}
        {icon && iconPosition === 'right' && iconElement}
      </Link>
    )
  }

  if (href && !disabled) {
    return (
      <a
        href={href}
        target={target}
        rel="noopener noreferrer"
        type={type}
        onClick={onClick}
        id={id}
        className={classNames(styles.button, {
          // @ts-expect-error
          [styles[`button--${size}`]]: size,
          // @ts-expect-error
          [styles[`button--${styleType}`]]: styleType,
          // @ts-expect-error
          [styles[`button--${styleType}--${colour}`]]: colour && styleType,
          [styles[`button--inactive`]]: inactive,
          [styles['button--disabled']]: disabled,
          [className as string]: className,
        })}
      >
        {icon && iconPosition === 'left' && iconElement}
        {children}
        {icon && iconPosition === 'right' && iconElement}
      </a>
    )
  }

  return (
    <button
      type={type}
      onClick={onClick}
      id={id}
      disabled={disabled}
      className={classNames(styles.button, {
        // @ts-expect-error
        [styles[`button--${size}`]]: size,
        // @ts-expect-error
        [styles[`button--${styleType}`]]: styleType,
        // @ts-expect-error
        [styles[`button--${styleType}--${colour}`]]: colour && styleType,
        [styles[`button--inactive`]]: inactive,
        [styles['button--disabled']]: disabled,
        [className as string]: className,
      })}
    >
      {icon && iconPosition === 'left' && iconElement}
      {children}
      {icon && iconPosition === 'right' && iconElement}
    </button>
  )
}

Button.defaultProps = defaultProps

export default Button
