import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { ExternalLayout } from '../../components/layout'
import { Analytics } from '../../services/Analytics'
import { Link } from 'react-router-dom'
import { WebRoutes } from '../routes/WebRoutes'
import { Box } from '@chakra-ui/react'

interface Props {
  isLoggedIn: boolean
}

const Terms: FunctionComponent<Props> = ({ isLoggedIn }) => {
  useEffect(() => {
    Analytics.viewTerms()
  }, [])

  const content = (
    <div>
      <Box
        sx={{
          paddingTop: '40px',
          maxWidth: '632px',
          h1: { fontWeight: 'bold', fontSize: '32px', marginBottom: '40px' },
          section: {
            marginBottom: '40px',
            h2: { fontWeight: 'bold', fontSize: '16px', marginBottom: '8px' },
            p: {
              marginTop: '16px',
              marginBottom: '8px',
              '&:last-of-type': { marginBottom: '0' },
            },
            ul: {
              marginTop: '8px',
              li: {
                marginTop: '8px',
                listStyle: 'disc',
                marginLeft: '20px',
              },
            },
            ol: {
              marginTop: '8px',
              li: { marginTop: '8px', marginLeft: '20px', listStyle: 'none' },
            },
          },
        }}
      >
        <h1>Terms of Service</h1>
        <section>
          <h2>Last updated: 30th June 2021</h2>
          <p>
            Welcome, and thank you for your interest in S94 Inc. ({' '}
            <strong>“Secured, ” </strong>
            <strong>“we, ”</strong> or <strong>“us”</strong>) and our website at
            www.secured.community, along with our related websites,
            applications, mobile applications, and other services provided by us
            (collectively, the <strong>“Service”</strong>). These Terms of
            Service are a legally binding contract between you and Secured
            regarding your use of the Service.
          </p>
        </section>
        <section>
          <h2>PLEASE READ THE FOLLOWING TERMS CAREFULLY:</h2>
          <p>
            <strong>
              BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR
              OTHERWISE ACCESSING OR USING THE SERVICE
            </strong>
            , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION
            TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING
            TERMS AND CONDITIONS, INCLUDING SECURED’S PRIVACY POLICY (TOGETHER,
            THESE <strong>“TERMS”</strong>). IF YOU ARE NOT ELIGIBLE, OR DO NOT
            AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE
            SERVICE. YOUR USE OF THE SERVICE, AND SECURED’S PROVISION OF THE
            SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY SECURED AND BY YOU TO BE
            BOUND BY THESE TERMS.
          </p>
          <p>
            <strong>ARBITRATION NOTICE.</strong> Except for certain kinds of
            disputes described in Section 17, you agree that disputes arising
            under these Terms will be resolved by binding, individual
            arbitration, and BY ACCEPTING THESE TERMS, YOU AND SECURED ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS
            ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT
            TO GO TO COURT to assert or defend your rights under this contract
            (except for matters that may be taken to small claims court). Your
            rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or
            jury. (See Section 17.)
          </p>
        </section>
        <section>
          <p>
            <strong>1. Secured Service Overview.</strong> Secured is a
            subscription service that helps members purchase sneakers,
            streetwear and more directly from brands before they sell out.
            Members get access to our Secured Hub platform (the{' '}
            <strong>“Secured Platform”</strong>) that details all upcoming
            release information for popular products and information on how to
            maximize your chances of success when purchasing online. The Secured
            Hub app (the <strong>“Secured App”</strong>) highlights all upcoming
            sneaker releases and raffles and includes information on how to
            maximize your chances of success when a product is released. We
            offer members with an active membership access to tools which
            automate manual tasks such as our autofill Chrome extension, which
            speeds up filling in online forms while purchasing products, as well
            as our downloadable toolbox, which helps members create good quality
            email addresses, test Proxies and create website cookies. We also
            offer guidance on which products users should purchase online to
            maximize their profit when reselling on the secondary market.
          </p>
        </section>
        <section>
          <p>
            <strong>2. Eligibility. </strong> You must be at least 18-years old
            to use the Service. By agreeing to these Terms, you represent and
            warrant to us that: (a) you are at least 18-years old; (b) you have
            not previously been suspended or removed from the Service; and (c)
            your registration and your use of the Service is in compliance with
            any and all applicable laws and regulations. If you are an entity,
            organization, or company, the individual accepting these Terms on
            your behalf represents and warrants that they have authority to bind
            you to these Terms and you agree to be bound by these Terms.
          </p>
        </section>
        <section>
          <p>
            <strong>3. Accounts and Registration.</strong> To access most
            features of the Service, you must register for an account. When you
            register for an account, you may be required to provide us with some
            information about yourself, such as your name, email address, or
            other contact information. You agree that the information you
            provide to us is accurate and that you will keep it accurate and up
            to date at all times. When you register, you will be asked to
            provide a password. You are solely responsible for maintaining the
            confidentiality of your account and password, and you accept
            responsibility for all activities that occur under your account. If
            you believe that your account is no longer secure, then you must
            immediately notify us at{' '}
            <a href="mailto:hello@secured.community.">
              hello@secured.community.
            </a>
          </p>
        </section>
        <section>
          <p>
            <strong>3.1 Discord Service.</strong> After registering for an
            account, we will request that you create an account on the Discord
            chat and social platform (the<strong>”Discord Service”</strong>) so
            you can join our exclusive Discord channel. The Discord Service is
            provided by Discord Inc. ( <strong>“Discord”</strong>) and your use
            of the Discord Service is subject to the Discord Terms of Service
            available at discord.com/terms ({' '}
            <strong>“Discord Terms of Service”</strong>). By agreeing to these
            terms and continuing to use Secured, you agree to be bound by the
            Discord Terms of Service, as the same may be modified by Discord
            from time to time.
          </p>
        </section>
        <section>
          <p>
            <strong>4. General Payment Terms.</strong> Certain features of the
            Service may require you to pay fees. Before you pay any fees, you
            will have an opportunity to review and accept the fees that you will
            be charged. All fees are in your local currency as specified on the
            payment page and are non-refundable.
          </p>
        </section>
        <section>
          <p>
            <strong>4.1 Price.</strong> Secured reserves the right to determine
            pricing for the Service. Secured will make reasonable efforts to
            keep pricing information published on the website up to date. We
            encourage you to check our website periodically for current pricing
            information. Secured may change the fees for any feature of the
            Service, including additional fees or charges, if Secured gives you
            advance notice of changes before they apply. Secured, at its sole
            discretion, may make promotional offers with different features and
            different pricing to any of Secured’s customers. These promotional
            offers, unless made to you, will not apply to your offer or these
            Terms.
          </p>
        </section>
        <section>
          <p>
            <strong>4.2 Authorization.</strong> You authorize Secured to charge
            all sums for the orders that you make and any level of Service you
            select as described in these Terms or published by Secured,
            including all applicable taxes, to the payment method specified in
            your account. If you pay any fees with a credit card, Secured may
            seek pre-authorization of your credit card account prior to your
            purchase to verify that the credit card is valid and has the
            necessary funds or credit available to cover your purchase. Payment
            processing services for Secured, including the processing and
            storing of credit card data, are provided by Stripe Inc. ({' '}
            <strong>“Stripe”</strong>) and are subject to the Stripe Services
            Agreement available at stripe.com/legal ({' '}
            <strong>“Stripe Services Agreement”</strong>). By agreeing to these
            terms and continuing to use Secured, you agree to be bound by the
            Stripe Services Agreement, as the same may be modified by Stripe
            from time to time. As a condition of Secured enabling payment
            processing services through Stripe, you agree to provide Secured
            accurate and complete information about you and your business, and
            you authorize Secured to share with Stripe this information and
            transaction information related to your use of the payment
            processing services provided by Stripe, as further described in our
            Privacy Policy.
          </p>
        </section>
        <section>
          <p>
            <strong>4.3 Subscription Service.</strong> The Service may include
            automatically recurring payments for periodic charges ({' '}
            <strong>“Subscription Service”</strong>). If you activate a
            Subscription Service, you authorize Secured to periodically charge,
            on a going-forward basis and until cancellation of either the
            recurring payments or your account, all accrued sums on or before
            the payment due date for the accrued sums. The{' '}
            <strong>“Subscription Billing Date”</strong> is the date when you
            purchase your first subscription to the Service. For information on
            the
            <strong>“Subscription Fee,”</strong> please see your member portal.
            Your account will be charged automatically on the Subscription
            Billing Date all applicable fees and taxes for the next subscription
            period. The subscription will continue unless and until you cancel
            your subscription, or we terminate it. You must cancel your
            subscription before it renews in order to avoid billing of the next
            periodic Subscription Fee to your account. We will bill the periodic
            Subscription Fee to the payment method you provide to us during
            registration (or to a different payment method if you change your
            payment information). You may cancel the Subscription Service
            through your member portal or by contacting us at
            <a href="mailto:hello@secured.community.">
              hello@secured.community.
            </a>
          </p>
        </section>
        <section>
          <p>
            <strong>4.4 Delinquent Accounts.</strong> Secured may suspend or
            terminate access to the Service, including fee-based portions of the
            Service, for any account for which any amount is due but unpaid. In
            addition to the amount due for the Service, a delinquent account
            will be charged with fees or charges that are incidental to any
            chargeback or collection of any the unpaid amount, including
            collection fees.
          </p>
        </section>
        <section>
          <h2>5. Licenses</h2>
        </section>
        <section>
          <p>
            <strong>5.1 Limited License.</strong> Subject to your complete and
            ongoing compliance with these Terms, Secured grants you, solely for
            your personal, non-commercial use, a limited, non-exclusive,
            non-transferable, non-sublicensable, revocable license to : (a)
            install and use one object code copy of any mobile application
            associated with the Service obtained from a legitimate marketplace
            (whether installed by you or pre-installed on your mobile device by
            the device manufacturer) on a mobile device that you own or control;
            and (b) access and use the Service.
          </p>
        </section>
        <section>
          <p>
            <strong>5.2 License Restrictions.</strong> Except and solely to the
            extent such a restriction is impermissible under applicable law, you
            may not: (a) reproduce, distribute, publicly display, or publicly
            perform the Service; (b) make modifications to the Service; or (c)
            interfere with or circumvent any feature of the Service, including
            any security or access control mechanism. If you are prohibited
            under applicable law from using the Service, you may not use it.
          </p>
        </section>
        <section>
          <p>
            <strong>5.3 Feedback.</strong> If you choose to provide input and
            suggestions regarding problems with or proposed modifications or
            improvements to the Service ( <strong>“Feedback”</strong>), then you
            hereby grant Secured an unrestricted, perpetual, irrevocable,
            non-exclusive, fully-paid, royalty-free right to exploit the
            Feedback in any manner and for any purpose, including to improve the
            Service and create other products and services.
          </p>
        </section>
        <section>
          <p>
            <strong>6. Ownership; Proprietary Rights.</strong> The Service is
            owned and operated by Secured. The visual interfaces, graphics,
            design, compilation, information, data, computer code (including
            source code or object code), products, software, services, and all
            other elements of the Service ( <strong>“Materials”</strong>)
            provided by Secured are protected by intellectual property and other
            laws. All Materials included in the Service are the property of
            Secured or its third-party licensors. Except as expressly authorized
            by Secured, you may not make use of the Materials. Secured reserves
            all rights to the Materials not granted expressly in these Terms.
          </p>
        </section>
        <section>
          <h2>7. Third-Party Terms</h2>
        </section>
        <section>
          <p>
            <strong>7.1 Third-Party Services and Linked Websites.</strong>{' '}
            Secured may provide tools through the Service that enable you to
            export information, including User Content (defined below), to
            third-party services, including through features that allow you to
            link your account on Secured with an account on the third-party
            service, such as Twitter or Facebook, or through our implementation
            of third-party buttons (such as “like” or “share” buttons). By using
            one of these tools, you agree that Secured may transfer that
            information to the applicable third-party service. Third-party
            services are not under Secured’s control, and, to the fullest extent
            permitted by law, Secured is not responsible for any third-party
            service’s use of your exported information. The Service may also
            contain links to third-party websites. Linked websites are not under
            Secured’s control, and Secured is not responsible for their content.
          </p>
        </section>
        <section>
          <p>
            <strong>7.2 Third-Party Software.</strong> The Service may include
            or incorporate third-party software components that are generally
            available free of charge under licenses granting recipients broad
            rights to copy, modify, and distribute those components ({' '}
            <strong>“Third-Party Components”</strong>). Although the Service is
            provided to you subject to these Terms, nothing in these Terms
            prevents, restricts, or is intended to prevent or restrict you from
            obtaining Third-Party Components under the applicable third-party
            licenses or to limit your use of Third-Party Components under those
            third-party licenses.
          </p>
        </section>
        <section>
          <h2>8. User Content</h2>
        </section>
        <section>
          <p>
            <strong>8.1 User Content Generally.</strong> Certain features of the
            Service may permit users to upload content to the Service, including
            messages, reviews, photos, video, images, folders, data, text, and
            other types of works ( <strong>“User Content”</strong>) and to
            publish User Content on the Service. You retain any copyright and
            other proprietary rights that you may hold in the User Content that
            you post to the Service.
          </p>
        </section>
        <section>
          <p>
            <strong>8.2 Limited License Grant to Secured.</strong> By providing
            User Content to or via the Service, you grant Secured a worldwide,
            non-exclusive, royalty-free, fully paid right and license (with the
            right to sublicense) to host, store, transfer, display, perform,
            reproduce, modify for the purpose of formatting for display, and
            distribute your User Content, in whole or in part, in any media
            formats and through any media channels now known or hereafter
            developed.
          </p>
        </section>
        <section>
          <p>
            <strong>8.3 Limited License Grant to Other Users.</strong> By
            providing User Content to or via the Service to other users of the
            Service, you grant those users a non-exclusive license to access and
            use that User Content as permitted by these Terms and the
            functionality of the Service.
          </p>
        </section>
        <section>
          <p>
            <strong>8.4 Specific Rules for Photographs and Images.</strong> If
            you post a photograph or image to the Service that includes one or
            more persons, you hereby grant such persons and their
            administrators, guardians, heirs, and trustees, if any, an
            irrevocable, perpetual, royalty free, fully paid-up, worldwide
            license to reproduce, distribute, and publicly display that
            photograph for personal use and through any online platform or
            service, including the Service, Facebook, Instagram, and Twitter,
            but not to promote any third-party product, good or service. The
            license contained in this Section does not permit the subject of any
            photo or their administrators, guardians, heirs, or trustees to sell
            that image or photograph, whether on a standalone basis or as
            embodied in any product.
          </p>
        </section>
        <section>
          <p>
            <strong>8.5 User Content Representations and Warranties.</strong>{' '}
            Secured disclaims any and all liability in connection with User
            Content. You are solely responsible for your User Content and the
            consequences of providing User Content via the Service. By providing
            User Content via the Service, you affirm, represent, and warrant to
            us that:
          </p>
          <ol>
            <li>
              9. you are the creator and owner of the User Content, or have the
              necessary licenses, rights, consents, and permissions to authorize
              Secured and users of the Service to use and distribute your User
              Content as necessary to exercise the licenses granted by you in
              this Section, in the manner contemplated by Secured, the Service,
              and these Terms;
            </li>
            <li>
              10. your User Content, and the use of your User Content as
              contemplated by these Terms, does not and will not: (i) infringe,
              violate, or misappropriate any third-party right, including any
              copyright, trademark, patent, trade secret, moral right, privacy
              right, right of publicity, or any other intellectual property or
              proprietary right; (ii) slander, defame, libel, or invade the
              right of privacy, publicity or other property rights of any other
              person; or (iii) cause Secured to violate any law or regulation;
              and
            </li>
            <li>
              11. your User Content could not be deemed by a reasonable person
              to be objectionable, profane, indecent, pornographic, harassing,
              threatening, embarrassing, hateful, or otherwise inappropriate.
            </li>
          </ol>
        </section>
        <section>
          <p>
            <strong>11.1 User Content Disclaimer.</strong> We are under no
            obligation to edit or control User Content that you or other users
            post or publish and will not be in any way responsible or liable for
            User Content. Secured may, however, at any time and without prior
            notice, screen, remove, edit, or block any User Content that in our
            sole judgment violates these Terms or is otherwise objectionable.
            You understand that, when using the Service, you will be exposed to
            User Content from a variety of sources and acknowledge that User
            Content may be inaccurate, offensive, indecent, or objectionable.
            You agree to waive, and do waive, any legal or equitable right or
            remedy you have or may have against Secured with respect to User
            Content. If notified by a user or content owner that User Content
            allegedly does not conform to these Terms, we may investigate the
            allegation and determine in our sole discretion whether to remove
            the User Content, which we reserve the right to do at any time and
            without notice. For clarity, Secured does not permit
            copyright-infringing activities on the Service.
          </p>
        </section>
        <section>
          <p>
            <strong>11.2 Monitoring Content.</strong> Secured does not control
            and does not have any obligation to monitor: (a) User Content; (b)
            any content made available by third parties; or (c) the use of the
            Service by its users. You acknowledge and agree that Secured
            reserves the right to, and may from time to time, monitor any and
            all information transmitted or received through the Service for
            operational and other purposes. If at any time Secured chooses to
            monitor the content, Secured still assumes no responsibility or
            liability for content or any loss or damage incurred as a result of
            the use of content. During monitoring, information may be examined,
            recorded, copied, and used in accordance with our Privacy Policy
            (defined below).
          </p>
        </section>
        <section>
          <h2>12. Communications</h2>
        </section>
        <section>
          <p>
            <strong>12.1 Text Messaging.</strong> Secured and those acting on
            our behalf may send you text (SMS) messages at the phone number you
            provide us. These messages may include operational messages about
            your use of the Service, as well as marketing messages. Text
            messages may be sent using an automatic telephone dialing system.
            Standard data and message rates may apply whenever you send or
            receive such messages, as specified by your carrier. IF YOU WISH TO
            OPT OUT OF ALL MESSAGES FROM SECURED, YOU CAN EMAIL
            <a href="mailto:hello@secured.community.">
              HELLO@SECURED.COMMUNITY,
            </a>{' '}
            HOWEVER YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL MESSAGES
            MAY IMPACT YOUR USE OF THE SERVICE. You may continue to receive text
            messages for a short period while we process your request, including
            messages confirming the receipt of your opt-out request.
          </p>
        </section>
        <section>
          <p>
            <strong>12.2 Push Notifications.</strong> When you install our app
            on your mobile device, you agree to receive push notifications,
            which are messages an app sends you on your mobile device when the
            app is not on. You can turn off notifications by visiting your
            mobile device’s “settings” page.
          </p>
        </section>
        <section>
          <p>
            <strong>12.3 Email.</strong> We may send you emails concerning our
            products and services, as well as those of third parties. You may
            opt out of promotional emails by following the unsubscribe
            instructions in the promotional email itself.
          </p>
        </section>
        <section>
          <p>
            <strong>13. Prohibited Conduct.</strong> BY USING THE SERVICE, YOU
            AGREE NOT TO:
          </p>
          <ol>
            <li>
              14. use the Service for any illegal purpose or in violation of any
              local, state, national, or international law;
            </li>
            <li>
              15. harass, threaten, demean, embarrass, bully, or otherwise harm
              any other user of the Service;
            </li>
            <li>
              16. violate, or encourage others to violate, any right of a third
              party, including by infringing or misappropriating any third-party
              intellectual property right;
            </li>
            <li>
              17. interfere with security-related features of the Service,
              including by: (a) disabling or circumventing features that prevent
              or limit use or copying of any content; or (b)reverse engineering
              or otherwise attempting to discover the source code of any portion
              of the Service except to the extent that the activity is expressly
              permitted by applicable law;
            </li>
            <li>
              18. interfere with the operation of the Service or any user’s
              enjoyment of the Service, including by: (i)uploading or otherwise
              disseminating any virus, adware, spyware, worm, or other malicious
              code; (ii) making any unsolicited offer or advertisement to
              another user of the Service; (iii) collecting personal information
              about another user or third party without consent; or (iv)
              interfering with or disrupting any network, equipment, or server
              connected to or used to provide the Service;
            </li>
            <li>
              19. perform any fraudulent activity including impersonating any
              person or entity, claiming a false affiliation, accessing any
              other Service account without permission, or falsifying your age
              or date of birth;
            </li>
            <li>
              20. sell or otherwise transfer the access granted under these
              Terms or any Materials (as defined in Section 6) or any right or
              ability to view, access, or use any Materials; or
            </li>
            <li>
              21. attempt to do any of the acts described in this Section 10 or
              assist or permit any person in engaging in any of the acts
              described in this Section 10.
            </li>
          </ol>
        </section>
        <section>
          <h2>22. Copyright and Intellectual Property Protection</h2>
        </section>
        <section>
          <p>
            <strong>22.1 DMCA Notification.</strong> We comply with the
            provisions of the Digital Millennium Copyright Act applicable to
            Internet service providers (17 U.S.C. § 512, as amended). If you
            have an intellectual property rights-related complaint about
            material posted on the Service, you may contact our Designated Agent
            at the following address: <br />
            <br />
            S94 Inc. <br />
            107 Cheapside <br />
            9th Floor / S94 Ltd. <br />
            London, EC2V 6DN <br />
            United Kingdom <br />
            Phone: +447946221701 <br />
            Email: <a href="mailto:jack@sobump.com">jack@sobump.com</a>
          </p>
        </section>
        <section>
          <p>
            <strong>22.2 Content of Notification.</strong> Any notice alleging
            that materials hosted by or distributed through the Service infringe
            intellectual property rights must include the following information:
            <ol>
              <li>
                23. an electronic or physical signature of the person authorized
                to act on behalf of the owner of the copyright or other right
                being infringed;
              </li>
              <li>
                24. a description of the copyrighted work or other intellectual
                property that you claim has been infringed;
              </li>
              <li>
                25. a description of the material that you claim is infringing
                and where it is located on the Service;
              </li>
              <li> 26. your address, telephone number, and email address;</li>
              <li>
                27. a statement by you that you have a good faith belief that
                the use of the materials on the Service of which you are
                complaining is not authorized by the copyright or intellectual
                property owner, its agent, or the law; and
              </li>
              <li>
                28. a statement by you that the above information in your notice
                is accurate and that, under penalty of perjury, you are the
                copyright or intellectual property owner or authorized to act on
                the copyright or intellectual property owner’s behalf.
              </li>
            </ol>
          </p>
        </section>

        <section>
          <p>
            <strong>28.1 Repeat Infringers.</strong> Secured will promptly
            terminate the accounts of users that are determined by Secured to be
            repeat infringers.
          </p>
        </section>
        <section>
          <p>
            <strong>29. Modification of Terms.</strong> We reserve the right to
            change these Terms on a going-forward basis at any time upon 7 days’
            notice. Please check these Terms periodically for changes. If a
            change to these Terms materially modifies your rights or
            obligations, we may require that you accept the modified Terms in
            order to continue to use the Service. Material modifications are
            effective upon the earliest of your receipt of notice or acceptance
            of the modified Terms. Immaterial modifications are effective upon
            publication. Except as expressly permitted in this Section 12, these
            Terms may be amended only by a written agreement signed by
            authorized representatives of the parties to these Terms. Disputes
            arising under these Terms will be resolved in accordance with the
            version of these Terms that was in effect at the time the dispute
            arose.
          </p>
        </section>
        <section>
          <p>
            <strong>
              30. Term, Termination, and Modification of the Service
            </strong>
          </p>
        </section>
        <section>
          <p>
            <strong>30.1 Term. </strong> These Terms are effective beginning
            when you accept the Terms or first download, install, access, or use
            the Service, and ending when terminated as described in Section
            13.2.
          </p>
        </section>
        <section>
          <p>
            <strong>30.2 Termination.</strong> If you violate any provision of
            these Terms, your authorization to access the Service and these
            Terms automatically terminate. In addition, Secured may, at its sole
            discretion, terminate these Terms or your account on the Service, or
            suspend or terminate your access to the Service, at any time for any
            reason or no reason, with or without notice. You may terminate your
            account and these Terms at any time through your member portal or by
            contacting customer service at{' '}
            <a href="mailto:hello@secured.community.">
              hello@secured.community.
            </a>
          </p>
        </section>
        <section>
          <p>
            <strong>30.3 Effect of Termination.</strong> Upon termination of
            these Terms: (a) your license rights will terminate and you must
            immediately cease all use of the Service; (b) you will no longer be
            authorized to access your account or the Service; (c) you must pay
            Secured any unpaid amount that was due prior to termination; and (d)
            all payment obligations accrued prior to termination and Sections
            5.3, 6, 13.3, 14, 15, 16, 17, and 18 will survive.
          </p>
        </section>
        <section>
          <p>
            <strong>30.4 Modification of the Service.</strong> Secured reserves
            the right to modify or discontinue the Service at any time
            (including by limiting or discontinuing certain features of the
            Service), temporarily or permanently, without notice to you. Secured
            will have no liability for any change to the Service or any
            suspension or termination of your access to or use of the Service.
          </p>
        </section>
        <section>
          <p>
            <strong>31. Indemnity.</strong> To the fullest extent permitted by
            law, you are responsible for your use of the Service, and you will
            defend and indemnify Secured, its affiliates and their respective
            shareholders, directors, managers, members, officers, employees,
            consultants, and agents (together, the{' '}
            <strong>“Secured Entities”</strong>) from and against every claim
            brought by a third party, and any related liability, damage, loss,
            and expense, including attorneys’ fees and costs, arising out of or
            connected with: (a) your unauthorized use of, or misuse of, the
            Service; (b) your violation of any portion of these Terms, any
            representation, warranty, or agreement referenced in these Terms, or
            any applicable law or regulation; (c) your violation of any
            third-party right, including any intellectual property right or
            publicity, confidentiality, other property, or privacy right; or (d)
            any dispute or issue between you and any third party. We reserve the
            right, at our own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you
            (without limiting your indemnification obligations with respect to
            that matter), and in that case, you agree to cooperate with our
            defense of those claims.
          </p>
        </section>
        <section>
          <p>
            <strong>32. Disclaimers; No Warranties</strong>
          </p>
        </section>
        <section>
          <p>
            <strong>32.1 </strong> THE SERVICE AND ALL MATERIALS AND CONTENT
            AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS
            AVAILABLE” BASIS. SECURED DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL
            MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A)
            ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY
            WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. SECURED
            DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR
            ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE
            UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL
            COMPONENTS, AND SECURED DOES NOT WARRANT THAT ANY OF THOSE ISSUES
            WILL BE CORRECTED.
          </p>
        </section>
        <section>
          <p>
            <strong>32.2</strong> NO ADVICE OR INFORMATION, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR SECURED ENTITIES OR ANY
            MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY
            WARRANTY REGARDING ANY OF THE SECURED ENTITIES OR THE SERVICE THAT
            IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR
            ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH
            ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY
            PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE
            ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
            COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE
            SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.
          </p>
        </section>
        <section>
          <p>
            <strong>32.3</strong> THE LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS
            IN THIS SECTION 15 APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
            Secured does not disclaim any warranty or other right that Secured
            is prohibited from disclaiming under applicable law.
          </p>
        </section>
        <section>
          <p>
            <strong>33. Limitation of Liability</strong>
          </p>
        </section>
        <section>
          <p>
            <strong>33.1 </strong> TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO
            EVENT WILL THE SECURED ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS)
            ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR
            INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT
            ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
            ANY SECURED ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
          </p>
        </section>
        <section>
          <p>
            <strong>33.2</strong> EXCEPT AS PROVIDED IN SECTION 17.6 AND TO THE
            FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE
            SECURED ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO
            THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR
            OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR
            OTHERWISE, IS LIMITED TO THE GREATER OF: (a) THE AMOUNT YOU HAVE
            PAID TO SECURED FOR ACCESS TO AND USE OF THE SERVICE IN THE 12
            MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO THE CLAIM
            AND (b) USD$100.
          </p>
        </section>
        <section>
          <p>
            <strong>33.3</strong> EACH PROVISION OF THESE TERMS THAT PROVIDES
            FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
            EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS
            BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN
            ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.
            EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER
            PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 16 WILL
            APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </p>
        </section>
        <section>
          <p>
            <strong>34. Dispute Resolution and Arbitration</strong>
          </p>
        </section>
        <section>
          <p>
            <strong>34.1 Generally.</strong> In the interest of resolving
            disputes between you and Secured in the most expedient and cost
            effective manner, and except as described in Section 17.2 and 17.4,
            you and Secured agree that every dispute arising in connection with
            these Terms will be resolved by binding arbitration. Arbitration is
            less formal than a lawsuit in court. Arbitration uses a neutral
            arbitrator instead of a judge or jury, may allow for more limited
            discovery than in court, and can be subject to very limited review
            by courts. Arbitrators can award the same damages and relief that a
            court can award. This agreement to arbitrate disputes includes all
            claims arising out of or relating to any aspect of these Terms,
            whether based in contract, tort, statute, fraud, misrepresentation,
            or any other legal theory, and regardless of whether a claim arises
            during or after the termination of these Terms. YOU UNDERSTAND AND
            AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND Secured ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
            ACTION.
          </p>
        </section>
        <section>
          <p>
            <strong>34.2 Exceptions.</strong> Despite the provisions of Section
            17.1, nothing in these Terms will be deemed to waive, preclude, or
            otherwise limit the right of either party to: (a) bring an
            individual action in small claims court; (b) pursue an enforcement
            action through the applicable federal, state, or local agency if
            that action is available; (c) seek injunctive relief in a court of
            law in aid of arbitration; or (d) to file suit in a court of law to
            address an intellectual property infringement claim.
          </p>
        </section>
        <section>
          <p>
            <strong>34.3 Opt-Out. </strong> If you do not wish to resolve
            disputes by binding arbitration, you may opt out of the provisions
            of this Section 17 within 30 days after the date that you agree to
            these Terms by sending a letter to S94 Inc., Attention: Legal
            Department – Arbitration Opt-Out, 9th Floor 107 Cheapside, London,
            EC2V 6DN, United Kingdom that specifies: your full legal name, the
            email address associated with your account on the Service, and a
            statement that you wish to opt out of arbitration ({' '}
            <strong>“Opt-Out Notice”</strong>). Once Secured receives your
            Opt-Out Notice, this Section 17 will be void and any action arising
            out of these Terms will be resolved as set forth in Section 18.2.
            The remaining provisions of these Terms will not be affected by your
            Opt-Out Notice.
          </p>
        </section>
        <section>
          <p>
            <strong>34.4 Arbitrator.</strong> Any arbitration between you and
            Secured will be settled under the Federal Arbitration Act and
            administered by the American Arbitration Association ({' '}
            <strong>“AAA”</strong>) under its Consumer Arbitration Rules
            (collectively, <strong>“AAA Rules”</strong>) as modified by these
            Terms. The AAA Rules and filing forms are available online at
            www.adr.org, by calling the AAA at +1-800-778-7879, or by contacting
            Secured. The arbitrator has exclusive authority to resolve any
            dispute relating to the interpretation, applicability, or
            enforceability of this binding arbitration agreement.
          </p>
        </section>
        <section>
          <p>
            <strong>34.5 Notice of Arbitration; Process.</strong> A party who
            intends to seek arbitration must first send a written notice of the
            dispute to the other party by certified U.S. Mail or by Federal
            Express (signature required) or, only if that other party has not
            provided a current physical address, then by electronic mail ({' '}
            <strong>“Notice of Arbitration”</strong>). Secured’s address for
            Notice is: S94 Inc., 9th Floor 107 Cheapside, London, EC2V 6DN,
            United Kingdom. The Notice of Arbitration must: (a) describe the
            nature and basis of the claim or dispute; and (b) set forth the
            specific relief sought ( <strong>“Demand”</strong>). The parties
            will make good faith efforts to resolve the claim directly, but if
            the parties do not reach an agreement to do so within 30 days after
            the Notice of Arbitration is received, you or Secured may commence
            an arbitration proceeding. All arbitration proceedings between the
            parties will be confidential unless otherwise agreed by the parties
            in writing. During the arbitration, the amount of any settlement
            offer made by you or Secured must not be disclosed to the arbitrator
            until after the arbitrator makes a final decision and award, if any.
            If the arbitrator awards you an amount higher than the last written
            settlement amount offered by Secured in settlement of the dispute
            prior to the award, Secured will pay to you the higher of: (a) the
            amount awarded by the arbitrator and (b) USD$10,000.
          </p>
        </section>
        <section>
          <p>
            <strong>34.6 Fees.</strong> If you commence arbitration in
            accordance with these Terms, Secured will reimburse you for your
            payment of the filing fee, unless your claim is for more than
            USD$10,000, in which case the payment of any fees will be decided by
            the AAA Rules. Any arbitration hearing will take place at a location
            to be agreed upon in New York, New York, but if the claim is for
            USD$10,000 or less, you may choose whether the arbitration will be
            conducted: (a) solely on the basis of documents submitted to the
            arbitrator; (b) through a non-appearance based telephone hearing; or
            (c) by an in-person hearing as established by the AAA Rules in the
            county (or parish) of your billing address. If the arbitrator finds
            that either the substance of your claim or the relief sought in the
            Demand is frivolous or brought for an improper purpose (as measured
            by the standards set forth in Federal Rule of Civil Procedure
            11(b)), then the payment of all fees will be governed by the AAA
            Rules. In that case, you agree to reimburse Secured for all monies
            previously disbursed by it that are otherwise your obligation to pay
            under the AAA Rules. Regardless of the manner in which the
            arbitration is conducted, the arbitrator must issue a reasoned
            written decision sufficient to explain the essential findings and
            conclusions on which the decision and award, if any, are based. The
            arbitrator may make rulings and resolve disputes as to the payment
            and reimbursement of fees or expenses at any time during the
            proceeding and upon request from either party made within 14 days of
            the arbitrator’s ruling on the merits.
          </p>
        </section>
        <section>
          <p>
            <strong>34.7 No Class Actions.</strong> YOU AND SECURED AGREE THAT
            EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both
            you and Secured agree otherwise, the arbitrator may not consolidate
            more than one person’s claims, and may not otherwise preside over
            any form of a representative or class proceeding.
          </p>
        </section>
        <section>
          <p>
            <strong>34.8 Modifications to this Arbitration Provision.</strong>{' '}
            If Secured makes any future change to this arbitration provision,
            other than a change to Secured’s address for Notice of Arbitration,
            you may reject the change by sending us written notice within 30
            days of the change to Secured’s address for Notice of Arbitration,
            in which case your account with Secured will be immediately
            terminated and this arbitration provision, as in effect immediately
            prior to the changes you rejected will survive.
          </p>
        </section>
        <section>
          <p>
            <strong>34.9 Enforceability.</strong> If Section 17.7 or the
            entirety of this Section 17 is found to be unenforceable, or if
            Secured receives an Opt-Out Notice from you, then the entirety of
            this Section 17 will be null and void and, in that case, the
            exclusive jurisdiction and venue described in Section 18.2 will
            govern any action arising out of or related to these Terms.
          </p>
        </section>
        <section>
          <p>
            <strong>35. Miscellaneous</strong>
          </p>
        </section>
        <section>
          <p>
            <strong>35.1 General Terms.</strong> These Terms, together with the
            Privacy Policy and any other agreements expressly incorporated by
            reference into these Terms, are the entire and exclusive
            understanding and agreement between you and Secured regarding your
            use of the Service. You may not assign or transfer these Terms or
            your rights under these Terms, in whole or in part, by operation of
            law or otherwise, without our prior written consent. We may assign
            these Terms at any time without notice or consent. The failure to
            require performance of any provision will not affect our right to
            require performance at any other time after that, nor will a waiver
            by us of any breach or default of these Terms, or any provision of
            these Terms, be a waiver of any subsequent breach or default or a
            waiver of the provision itself. Use of Section headers in these
            Terms is for convenience only and will not have any impact on the
            interpretation of any provision. Throughout these Terms the use of
            the word “including” means “including but not limited to.” If any
            part of these Terms is held to be invalid or unenforceable, the
            unenforceable part will be given effect to the greatest extent
            possible, and the remaining parts will remain in full force and
            effect.
          </p>
        </section>
        <section>
          <p>
            <strong>35.2 Governing Law.</strong> These Terms are governed by the
            laws of Delaware without regard to conflict of law principles. You
            and Secured submit to the personal and exclusive jurisdiction of the
            courts located within New York, New York for resolution of any
            lawsuit or court proceeding permitted under these Terms. We operate
            the Service from our offices in London, and we make no
            representation that Materials included in the Service are
            appropriate or available for use in other locations.
          </p>
        </section>
        <section>
          <p>
            <strong>35.3 Privacy Policy.</strong> Please read the{' '}
            <Link to={WebRoutes.privacyPolicy}>Secured Privacy Policy</Link>{' '}
            (the <strong>“Privacy Policy”</strong>) carefully for information
            relating to our collection, use, storage, and disclosure of your
            personal information. The Secured Privacy Policy is incorporated by
            this reference into, and made a part of, these Terms.
          </p>
        </section>
        <section>
          <p>
            <strong>35.4 Additional Terms.</strong> Your use of the Service is
            subject to all additional terms, policies, rules, or guidelines
            applicable to the Service or certain features of the Service that we
            may post on or link to from the Service (the{' '}
            <strong>“Additional Terms”</strong>). All Additional Terms are
            incorporated by this reference into, and made a part of, these
            Terms.
          </p>
        </section>
        <section>
          <p>
            <strong>35.5 Consent to Electronic Communications.</strong> By using
            the Service, you consent to receiving certain electronic
            communications from us as further described in our Privacy Policy.
            Please read our Privacy Policy to learn more about our electronic
            communications practices. You agree that any notices, agreements,
            disclosures, or other communications that we send to you
            electronically will satisfy any legal communication requirements,
            including that those communications be in writing.
          </p>
        </section>
        <section>
          <p>
            <strong>35.6 Contact Information.</strong> The Service is offered by
            S94 Inc., located at 9th Floor 107 Cheapside, London, EC2V 6DN,
            United Kingdom. You may contact us by sending correspondence to that
            address or by emailing us at{' '}
            <a href="mailto:hello@secured.community.">
              hello@secured.community.
            </a>
          </p>
        </section>
        <section>
          <p>
            <strong>35.7 Notice to California Residents.</strong> If you are a
            California resident, under California Civil Code Section 1789.3, you
            may contact the Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
            California 95834, or by telephone at +1-800-952-5210 in order to
            resolve a complaint regarding the Service or to receive further
            information regarding use of the Service.
          </p>
        </section>
        <section>
          <p>
            <strong>35.8 No Support.</strong> We are under no obligation to
            provide support for the Service. In instances where we may offer
            support, the support will be subject to published policies.
          </p>
        </section>
        <section>
          <p>
            <strong>36. Notice Regarding Apple.</strong> This Section 19 only
            applies to the extent you are using our mobile application on an iOS
            device. You acknowledge that these Terms are between you and Secured
            only, not with Apple Inc. ( <strong>“Apple”</strong>), and Apple is
            not responsible for the Service or the content of it. Apple has no
            obligation to furnish any maintenance and support services with
            respect to the Service. If the Service fails to conform to any
            applicable warranty, you may notify Apple, and Apple will refund any
            applicable purchase price for the mobile application to you. To the
            maximum extent permitted by applicable law, Apple has no other
            warranty obligation with respect to the Service. Apple is not
            responsible for addressing any claims by you or any third party
            relating to the Service or your possession and/or use of the
            Service, including: (1) product liability claims; (2) any claim that
            the Service fails to conform to any applicable legal or regulatory
            requirement; or (3) claims arising under consumer protection or
            similar legislation. Apple is not responsible for the investigation,
            defense, settlement, and discharge of any third-party claim that the
            Service and/or your possession and use of the Service infringe a
            third party’s intellectual property rights. You agree to comply with
            any applicable third-party terms when using the Service. Apple and
            Apple’s subsidiaries are third-party beneficiaries of these Terms,
            and upon your acceptance of these Terms, Apple will have the right
            (and will be deemed to have accepted the right) to enforce these
            Terms against you as a third-party beneficiary of these Terms. You
            hereby represent and warrant that: (a) you are not located in a
            country that is subject to a U.S. Government embargo or that has
            been designated by the U.S. Government as a “terrorist supporting”
            country; and (b) you are not listed on any U.S. Government list of
            prohibited or restricted parties.
          </p>
        </section>
      </Box>
    </div>
  )

  return <ExternalLayout isLoggedIn={isLoggedIn}>{content}</ExternalLayout>
}

export default Terms
