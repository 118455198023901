import {
  Circle,
  Icon,
  Spacer,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { ReactComponent as Logo } from '../s-logo.svg'
import { DiscordLogo, Moon, Sun, User } from 'phosphor-react'
import { WebRoutes } from '../../../containers/routes/WebRoutes'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useUserInfo } from '../../../context/UserInfo'
import {
  DISCORD_SERVER_URL_EU,
  DISCORD_SERVER_URL_US,
} from '../../../constants'
import { SecuredServer } from 'packlets/generated'
import type { SideNavigationLinkProps } from 'ui-kit'
import {
  SideNavigationLink,
  SideNavigation,
  SideNavigationButton,
} from 'ui-kit'

const SideNavigationRouterLink = (
  props: Omit<SideNavigationLinkProps, 'isActive'> & { to: string }
) => {
  const location = useLocation()
  const match = location.pathname === props.to
  return <SideNavigationLink as={RouterLink} {...props} isActive={match} />
}

export const SideNav = () => {
  const { discordServer } = useUserInfo()

  const logoColor = useColorModeValue('black', 'white')
  const circleBg = useColorModeValue('gray.200', 'gray.700')

  const { toggleColorMode } = useColorMode()
  const SwitchIcon = useColorModeValue(Moon, Sun)
  const mode = useColorModeValue('dark', 'light')
  return (
    <SideNavigation>
      <Circle as={RouterLink} size="44px" bg={circleBg} to={WebRoutes.root}>
        <Icon as={Logo} fill={logoColor} />
      </Circle>
      <Stack spacing={8} mt={6} h="full">
        <Stack>
          <SideNavigationLink
            icon={DiscordLogo}
            target="_blank"
            href={
              discordServer === SecuredServer.Eu
                ? DISCORD_SERVER_URL_EU
                : DISCORD_SERVER_URL_US
            }
          >
            Launch Discord
          </SideNavigationLink>
        </Stack>
        <Spacer />
        <Stack spacing={2}>
          <SideNavigationButton
            icon={SwitchIcon}
            onClick={toggleColorMode}
            variant="ghost"
            colorScheme={mode === 'light' ? 'yellow' : 'blue'}
          >
            Toggle theme β
          </SideNavigationButton>
          <SideNavigationRouterLink icon={User} to={WebRoutes.account}>
            Profile
          </SideNavigationRouterLink>
        </Stack>
      </Stack>
    </SideNavigation>
  )
}
