import { useEffect } from 'react'
import { PushNotifications } from '@capacitor/push-notifications'
import { useHistory } from 'react-router'
import { Capacitor } from '@capacitor/core'
import { useMutation } from '@apollo/client'
import type { DeviceType } from 'packlets/generated'
import { AppConfig } from './services/AppConfig'
import { AttachDeviceToSessionDocument } from './gql/users.generated'

export const PushNotificationsListener = () => {
  const [attachDeviceToSession] = useMutation(AttachDeviceToSessionDocument, {
    onError: (error) => {
      console.error(error)
    },
  })
  const history = useHistory()
  useEffect(() => {
    if (
      Capacitor.isPluginAvailable('PushNotifications') &&
      AppConfig.isNativePlatform
    ) {
      PushNotifications.addListener('registration', (token) => {
        attachDeviceToSession({
          variables: {
            input: {
              token: token.value,
              deviceType: Capacitor.getPlatform() as DeviceType,
            },
          },
        })
      })

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        ({ notification }) => {
          notification.data.route && history.push(notification.data.route)
        }
      )
    }
  }, [attachDeviceToSession, history])

  return null
}
