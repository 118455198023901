import type { TabListProps, TabsProps } from '@chakra-ui/react'
import {
  chakra,
  forwardRef,
  Tabs,
  useColorModeValue,
  useTab,
  useTabList,
} from '@chakra-ui/react'
import type { SystemStyleObject } from '@chakra-ui/theme-tools'
import { interactive } from '../theme/typography'
import classNames from 'classnames'
import React from 'react'

export const SwitcherTab = forwardRef<{}, 'button'>((props, ref) => {
  const switchStyles: SystemStyleObject = {
    ...interactive.md,
    py: 2,
    bg: useColorModeValue('gray.200', 'gray.700'),
    color: useColorModeValue('gray.700', 'gray.400'),
    borderRadius: 'md',
    flex: '1 1 auto',
    transition: 'background 0.2s, color 0.2s',
    _focus: { boxShadow: 'outline' },
    _selected: {
      bg: useColorModeValue('black', 'gray.200'),
      color: useColorModeValue('white', 'gray.700'),
    },
  }

  const tabProps = useTab({ ...props, ref })

  return <chakra.button sx={switchStyles} {...tabProps} />
})

export const SwitcherTabs = forwardRef<
  Omit<TabsProps, 'variant' | 'colorScheme'>,
  'div'
>((props, ref) => {
  return <Tabs ref={ref} __css={{ w: 'full' }} {...props} />
})

export const SwitcherTabList = forwardRef<
  Omit<TabListProps, 'variant' | 'colorScheme'>,
  'div'
>((props, ref) => {
  return (
    <chakra.div
      ref={ref}
      {...useTabList({
        ...props,
        className: classNames('s-switcher-tab-list', props.className),
      })}
      __css={{
        display: 'flex',
        w: 'full',
        p: 1,
        bg: useColorModeValue('gray.200', 'gray.700'),
        borderRadius: 'md',
        gap: '0.25rem',
      }}
    />
  )
})
