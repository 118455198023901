import { ENV } from '../../constants'
import type Firebase from 'firebase/app'

export type FirebaseValue = Firebase.remoteConfig.Value

export interface FeatureFlags {
  sampleFeature?: FirebaseValue
  isNotificationsEnabled?: FirebaseValue
  isAbTestingPriceActive?: FirebaseValue
  isAlertsFeatureEnabled?: FirebaseValue
}

export interface DefaultFeatureFlag {
  [key: string]: string | number | boolean
}

// Note: Intentionally not extending FeatureFlags type, since Firebase has two separate types for defaults and actual fetched values.
// Note the Value type.

export const defaultFeatureFlags: DefaultFeatureFlag = {
  isNotificationsEnabled: false,
  isAbTestingPriceActive: false,
  isAlertsFeatureEnabled: ENV === 'local',
}
