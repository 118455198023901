import type { IconProps, StackProps, TextProps } from '@chakra-ui/react'
import {
  forwardRef,
  HStack,
  Icon,
  StylesProvider,
  Text,
  useMultiStyleConfig,
  useStyles,
  VStack,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { merge } from 'lodash'
import classNames from 'classnames'

function useMemoizedMerge<T>(...args: T[]) {
  return useMemo<T>(() => {
    return merge({}, ...args)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...args])
}

export const Navigation = forwardRef<StackProps, 'nav'>(
  ({ children, ...props }, ref) => {
    const styles = useMultiStyleConfig('Navigation', props)
    const mergedSx = useMemoizedMerge(styles.container, props.sx)
    return (
      <HStack
        as="nav"
        ref={ref}
        {...props}
        className={classNames('secured-navigation', props.className)}
        sx={mergedSx}
      >
        <StylesProvider value={styles}>{children}</StylesProvider>
      </HStack>
    )
  }
)

export interface NavigationItemProps extends StackProps {
  isActive: boolean
}

export const NavigationItem = forwardRef<NavigationItemProps, 'li'>(
  ({ isActive, ...props }, ref) => {
    const styles = useStyles()
    const mergedStyles = useMemoizedMerge(styles.item, props.sx)
    return (
      <VStack
        as="li"
        ref={ref}
        spacing={1}
        aria-current={isActive ? 'page' : undefined}
        className={classNames('secured-navigation-item', props.className)}
        {...props}
        sx={mergedStyles}
      />
    )
  }
)

export const NavigationItemIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  const styles = useStyles()
  const mergedSx = useMemoizedMerge(styles.icon, props.sx)
  return <Icon ref={ref} {...props} sx={mergedSx} />
})

export const NavigationItemText = forwardRef<TextProps, 'p'>((props, ref) => {
  const styles = useStyles()
  const mergedSx = useMemoizedMerge(styles.text, props.sx)
  return <Text as="p" ref={ref} {...props} sx={mergedSx} />
})
