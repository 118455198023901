import type { FunctionComponent } from 'react'
import React from 'react'
import classNames from 'classnames'
import styles from './divider.module.scss'

interface DefaultProps {
  className?: string
}

interface Props extends Partial<DefaultProps> {}

const defaultProps: DefaultProps = {}

const Divider: FunctionComponent<Props> = ({ className }) => {
  return <div className={classNames(className, styles.divider)} />
}

Divider.defaultProps = defaultProps

export default Divider
