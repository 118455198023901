import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Container,
  Heading,
  Text,
  Icon,
  Stack,
  useColorModeValue,
  Button,
} from '@chakra-ui/react'
import { HouseSimple } from 'phosphor-react'
import React, { useEffect, useMemo, useCallback } from 'react'
import { ExternalLayout } from '../../components/layout'
import { SecuredServer, UserSubscriptionProductType } from 'packlets/generated'
import { AppConfig } from '../../services/AppConfig'
import { LocalStorage } from '../../services/LocalStorage'
import { ReactComponent as Logo } from './logo.svg'
import { useLoginToSecured } from '../login/useLoginToSecured'
import { useHistory } from 'react-router'
import {
  DISCORD_SERVER_URL_EU,
  DISCORD_SERVER_URL_NFT,
  DISCORD_SERVER_URL_POKEMON,
  DISCORD_SERVER_URL_US,
  DISCORD_SERVER_URL_CRYPTO,
  SERVER_TO_TEXT,
} from '../../constants'
import { WebRoutes } from '../routes/WebRoutes'
import {
  ExchangeDiscordSessionDocument,
  GetActiveUserAccountsDocument,
} from '../../gql/auth.generated'
import { BsDiscord } from 'react-icons/bs'

const LoginAccountSelection: React.FC = () => {
  const { logInToSecured, terminateDiscordSession } = useLoginToSecured()
  const history = useHistory()

  const { data: activeUserAccounts } = useQuery(GetActiveUserAccountsDocument, {
    // Thanks to Apollo, if you are on network-only (as we are)
    // and in case you have a query and a mutation in a component
    // the query will be called once again after the mutation
    // is executed.
    // Setting fetch-policy to cache first
    // stop the second request.
    fetchPolicy: 'cache-first',
  })
  useEffect(() => {
    const discordSessionLocalStorage = LocalStorage({
      key: 'AUTH_DISCORD_SESSION_STORAGE',
    })
    if (!discordSessionLocalStorage.get()) {
      history.replace(WebRoutes.login)
    }
  }, [history])

  const logoFill = useColorModeValue('black', 'white')

  const [exchangeDiscordSession, { loading: exchangeDiscordSessionLoading }] =
    useMutation(ExchangeDiscordSessionDocument, {
      onCompleted(data) {
        logInToSecured(data.exchangeDiscordSession)
        history.replace(WebRoutes.root)
      },
      onError(error) {
        console.error(error)
        history.replace(WebRoutes.login)
      },
    })

  const accountWithAccessToSecuredHub = useMemo(() => {
    if (!activeUserAccounts || !activeUserAccounts.activeUserAccounts) {
      return false
    }

    return activeUserAccounts.activeUserAccounts.find(
      (user) =>
        user.userSubscriptionProductType ===
        UserSubscriptionProductType.Sneakers
    )
  }, [activeUserAccounts])

  const accountsFilteredByDiscordServer = useMemo(() => {
    if (!activeUserAccounts || !activeUserAccounts.activeUserAccounts) {
      return false
    }

    return activeUserAccounts.activeUserAccounts.filter((acc, idx) => {
      return (
        activeUserAccounts.activeUserAccounts.findIndex(
          (accToFind) => accToFind.discordServer === acc.discordServer
        ) === idx
      )
    })
  }, [activeUserAccounts])

  const handleSecuredHubLogin = useCallback(() => {
    if (!accountWithAccessToSecuredHub) {
      return
    }
    void exchangeDiscordSession({
      variables: {
        accountId: accountWithAccessToSecuredHub.id,
      },
    })
  }, [exchangeDiscordSession, accountWithAccessToSecuredHub])

  const redirectToDiscordServer = useCallback(
    (server?: SecuredServer | null) => {
      if (!server) {
        return undefined
      }

      return () => {
        terminateDiscordSession()

        switch (server) {
          case SecuredServer.Eu: {
            window.location.replace(DISCORD_SERVER_URL_EU)
            return
          }
          case SecuredServer.Us: {
            window.location.replace(DISCORD_SERVER_URL_US)
            return
          }
          case SecuredServer.Nft: {
            window.location.replace(DISCORD_SERVER_URL_NFT)
            return
          }
          case SecuredServer.Pokemon: {
            window.location.replace(DISCORD_SERVER_URL_POKEMON)
            return
          }
          case SecuredServer.Crypto: {
            window.location.replace(DISCORD_SERVER_URL_CRYPTO)
          }
        }
      }
    },
    [terminateDiscordSession]
  )

  const Wrapper = AppConfig.isNativePlatform ? Container : ExternalLayout
  const wrapperProps = AppConfig.isNativePlatform
    ? {
        h: 'calc(100vh - 41px - 8px)',
      }
    : {
        headerActionsType: 'none' as 'none',
        fullHeightContent: true,
      }
  const cardBgColor = useColorModeValue('gray.100', 'gray.800')
  return (
    <Wrapper {...wrapperProps}>
      <Stack
        alignItems="center"
        justifyContent={AppConfig.isNativePlatform ? 'flex-start' : 'center'}
        h="full"
        mx={[AppConfig.isNativePlatform ? 0 : 6, 0]}
        mt={[AppConfig.isNativePlatform ? 2 : 16, 0]}
      >
        {AppConfig.isNativePlatform && (
          <>
            <Box mt={4} fill={logoFill}>
              <Logo />
            </Box>
          </>
        )}
        {!activeUserAccounts ? null : (
          <Stack
            alignItems="center"
            pt={AppConfig.isNativePlatform ? 8 : undefined}
          >
            <Heading align="center" size="xl" mb={8}>
              Welcome back
            </Heading>
            {accountWithAccessToSecuredHub && (
              <Box
                width={['full', 400]}
                mt="0"
                mb={8}
                p={6}
                bg={cardBgColor}
                borderRadius="base"
              >
                <Heading align="center" mb={6} fontWeight="semibold">
                  Launch Secured Hub
                </Heading>
                <Text align="center" fontWeight="normal" mb={6}>
                  See whats Dropping
                </Text>
                <Button
                  leftIcon={<HouseSimple size={20} />}
                  width="full"
                  onClick={handleSecuredHubLogin}
                  disabled={exchangeDiscordSessionLoading}
                >
                  Go to Secured Hub
                </Button>
              </Box>
            )}
            <Box
              width={['full', 400]}
              p={6}
              bg={cardBgColor}
              borderRadius="base"
            >
              <Heading align="center" mb={6} fontWeight="semibold">
                Launch Discord
              </Heading>
              <Text align="center" fontWeight="normal" mb={6}>
                Start investing with our members
              </Text>
              {accountsFilteredByDiscordServer &&
                accountsFilteredByDiscordServer.map(
                  (user) =>
                    user.discordServer && (
                      <Button
                        key={user.id}
                        mb={4}
                        leftIcon={<Icon as={BsDiscord} fontSize="20px" />}
                        width="full"
                        onClick={redirectToDiscordServer(user.discordServer)}
                        disabled={exchangeDiscordSessionLoading}
                      >
                        Go to Secured {SERVER_TO_TEXT[user.discordServer]}
                      </Button>
                    )
                )}
            </Box>
            {!AppConfig.isNativePlatform && <Box height={20} />}
          </Stack>
        )}
      </Stack>
    </Wrapper>
  )
}

export default LoginAccountSelection
