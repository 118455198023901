import type { ComponentStyleConfig } from '@chakra-ui/theme'
import { mode } from '@chakra-ui/theme-tools'

export const Card: ComponentStyleConfig = {
  baseStyle: (props) => {
    return {
      p: 0,
      borderColor: mode('gray.100', 'gray.800')(props),
      borderWidth: '1px',
      borderRadius: 'xl',
      bg: mode('white', 'gray.800')(props),
    }
  },
}
