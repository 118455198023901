import React from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Image, useColorModeValue } from '@chakra-ui/react'

interface WorksWithBrandCardProps extends BoxProps {
  src: string
  alt: string
}

export const WorksWithBrandCard = ({
  src,
  className,
  alt,
  ...rest
}: WorksWithBrandCardProps) => {
  return (
    <Box
      {...rest}
      sx={{
        position: 'relative',
        height: ['56px', '56px', '64px'],
        flex: [1, 1, 'unset'],
        bg: useColorModeValue('blackAlpha.100', 'whiteAlpha.300'),
        backdropFilter: 'blur(15px)',
        transform: 'translate3d(0, 0, 0)',
        borderRadius: 'md',
      }}
    >
      <Image
        src={src}
        alt={alt}
        sx={{
          py: 4,
          px: 2,
          height: 'auto',
          maxHeight: '64px',
          verticalAlign: 'unset',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          filter: useColorModeValue('unset', 'brightness(2)'),
        }}
      />
    </Box>
  )
}
export const WorksWith: React.FC = ({ children }) => {
  return (
    <Box as="section" textAlign="center" mt={['120px', '120px', '184px']}>
      <Box
        as="header"
        sx={{
          fontWeight: 'bold',
          letterSpacing: '0.13em',
          textTransform: 'uppercase',
          color: useColorModeValue('gray.600', 'gray.400'),
        }}
      >
        Works With
      </Box>
      <Box
        sx={{
          mt: 6,
          display: ['block', 'block', 'flex'],
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: 'full',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(125px, 1fr))',
            gridColumnGap: '16px',
            gridRowGap: '16px',
            gridAutoFlow: 'dense',
            gridAutoRows: 'min-content',
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        mt={6}
        as="footer"
        sx={{
          color: useColorModeValue('gray.600', 'gray.400'),
        }}
      >
        + 200 more sites
      </Box>
    </Box>
  )
}
