import React from 'react'
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'

interface TestimonialProps {
  imageUrl: string
  username: string
  youtubeId?: string
  description?: string
}

export const Testimonial = ({
  description,
  imageUrl,
  username,
  youtubeId,
}: TestimonialProps) => {
  const quoteColor = useColorModeValue('gray.700', 'gray.300')
  return (
    <Box
      sx={{
        padding: '24px',
        background: useColorModeValue('blackAlpha.100', 'whiteAlpha.300'),
        backdropFilter: 'blur(15px)',
        transform: 'translate3d(0, 0, 0)',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 350px',
        margin: '0 8px',
      }}
    >
      {description && (
        <Box
          as="p"
          mb={4}
          flex={1}
          fontStyle="italic"
          fontWeight="medium"
          color={quoteColor}
        >
          “{description}”
        </Box>
      )}
      {youtubeId && (
        <Box mb={4} flex={1}>
          <iframe
            width="350"
            height="200"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      )}
      <Flex alignItems="center">
        <Box
          sx={{
            width: '48px',
            mr: 4,
            borderRadius: 'full',
            overflow: 'hidden',
          }}
        >
          <img src={imageUrl} alt="testimonial user pic" />
        </Box>
        <Text as="span" variant="title" fontWeight="normal">
          {username}
        </Text>
      </Flex>
    </Box>
  )
}

export default Testimonial
