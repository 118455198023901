import React from 'react'
import styles from './styles.module.scss'

const InterestSkeleton = () => {
  return (
    <div>
      <div className={styles['interest-skeleton__inner']}>
        <div className={styles['interest-skeleton__inner__title']}>
          <div />
        </div>
        <div className={styles['interest-skeleton__inner__sub-title']}>
          <div />
        </div>
        <div className={styles['interest-skeleton__inner__sub-title-2']}>
          <div />
        </div>
      </div>
    </div>
  )
}

export default InterestSkeleton
