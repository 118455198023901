import React from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { chakra, Divider, forwardRef, Text } from '@chakra-ui/react'

export const LabelledDivider: React.FC = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <chakra.div
        ref={ref}
        {...props}
        __css={{
          display: 'flex',
          alignItems: 'center',
          gridGap: 3,
        }}
      >
        <Divider />
        <Text variant="caption" color="gray.500" whiteSpace="nowrap">
          {children}
        </Text>
        <Divider />
      </chakra.div>
    )
  }
)
