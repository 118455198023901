/* eslint-disable */
// ####################################+################################################
// ################# THIS IS GENERATED FILE DO NOT TOUCH!!!!!!!!!!!!####################
// ####################################-################################################
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string
}

export type Activity = {
  __typename?: 'Activity'
  action: ActivityActionType
  createdAt: Scalars['DateTime']
  createdBy: User
  entityId: Scalars['String']
  id: Scalars['ID']
  type: ActivityEntityType
  updatedAt: Scalars['DateTime']
}

export enum ActivityActionType {
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
}

export enum ActivityEntityType {
  Drop = 'drop',
  Product = 'product',
  ProductCollection = 'productCollection',
  Raffle = 'raffle',
  Store = 'store',
}

export type Alert = {
  createdAt: Scalars['DateTime']
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  type: AlertType
  updatedAt: Scalars['DateTime']
}

export type AlertCreatedFiltersInput = {
  type?: InputMaybe<AlertType>
}

export type AlertCreatedInput = {
  filters?: InputMaybe<AlertCreatedFiltersInput>
}

export enum AlertType {
  AnnouncementCreated = 'announcementCreated',
  DropCreated = 'dropCreated',
  DropImminent = 'dropImminent',
  RaffleCreated = 'raffleCreated',
}

export enum AlertTypeQuery {
  AllAnnouncements = 'allAnnouncements',
  AllDrops = 'allDrops',
  AllRaffles = 'allRaffles',
  AnnouncementCreated = 'announcementCreated',
  DropCreated = 'dropCreated',
  DropImminent = 'dropImminent',
  RaffleCreated = 'raffleCreated',
}

export type AlertUnion =
  | AnnouncementAlert
  | DropCreatedAlert
  | ImminentDropAlert
  | RaffleCreatedAlert

export type Ambassador = {
  __typename?: 'Ambassador'
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  extra: Scalars['String']
  id: Scalars['ID']
  instagramHandle?: Maybe<Scalars['String']>
  name: Scalars['String']
  productType: UserSubscriptionProductType
  tiktokHandle?: Maybe<Scalars['String']>
  twitterHandle?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  youtubeHandle?: Maybe<Scalars['String']>
}

export type Announcement = {
  __typename?: 'Announcement'
  createdAt: Scalars['DateTime']
  description: Scalars['String']
  discordServer: SecuredServer
  externalURL?: Maybe<Scalars['String']>
  id: Scalars['ID']
  product?: Maybe<Product>
  title: Scalars['String']
  type?: Maybe<AnnouncementType>
  updatedAt: Scalars['DateTime']
}

export type AnnouncementAlert = Alert & {
  __typename?: 'AnnouncementAlert'
  announcement: Announcement
  createdAt: Scalars['DateTime']
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  type: AlertType
  updatedAt: Scalars['DateTime']
}

export enum AnnouncementType {
  DropTimeUpdate = 'dropTimeUpdate',
  NewLink = 'newLink',
  ReleaseUpdate = 'releaseUpdate',
  UrgentRafle = 'urgentRafle',
}

export type CheckoutAnalyticsEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  eventSourceUrl?: InputMaybe<Scalars['String']>
  fbc?: InputMaybe<Scalars['String']>
  fbp?: InputMaybe<Scalars['String']>
}

export enum CopType {
  Bot = 'bot',
  Manual = 'manual',
}

export enum CountryCode {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Ic = 'IC',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export type CreateAmbassadorInput = {
  email: Scalars['String']
  extra?: InputMaybe<Scalars['String']>
  instagramHandle?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  productType: UserSubscriptionProductType
  tiktokHandle?: InputMaybe<Scalars['String']>
  twitterHandle?: InputMaybe<Scalars['String']>
  youtubeHandle?: InputMaybe<Scalars['String']>
}

export type CreateAnnouncementInput = {
  description: Scalars['String']
  discordServer: SecuredServer
  externalURL?: InputMaybe<Scalars['String']>
  productId?: InputMaybe<Scalars['String']>
  title: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type CreateBillingPortalResponse = {
  __typename?: 'CreateBillingPortalResponse'
  url: Scalars['String']
}

export type CreateDeviceInput = {
  deviceType: DeviceType
  token: Scalars['String']
}

export type CreateDiscordUserInput = {
  accessTokenExpiresAt?: InputMaybe<Scalars['DateTime']>
  avatar: Scalars['String']
  email: Scalars['String']
  id: Scalars['String']
  isVerified: Scalars['Boolean']
  scope: Scalars['String']
  username: Scalars['String']
}

export type CreateDropInput = {
  copType: CopType
  currency: CurrencyCode
  deliveryType: DropDeliveryType
  isDropTimeRandom: Scalars['Boolean']
  priceCents: Scalars['Int']
  productId: Scalars['String']
  region: Scalars['String']
  releasesAt: Scalars['DateTime']
  server: SecuredServer
  storeId: Scalars['String']
  timezone: Scalars['String']
  title: Scalars['String']
  type: DropType
  url: Scalars['String']
}

export type CreateOpenSeaCollectionInput = {
  collectionId: Scalars['String']
  contractAddress?: InputMaybe<Scalars['String']>
  mintPrice?: InputMaybe<Scalars['Float']>
  officialLinks: Array<Scalars['String']>
  revealDate?: InputMaybe<Scalars['DateTime']>
}

export type CreateProductCollectionInput = {
  brands: Array<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  internalNotes?: InputMaybe<Scalars['String']>
  isDraft: Scalars['Boolean']
  media: Array<MediaInput>
  releases: Array<ItemReleaseInput>
  resellAction: ItemResellAction
  seasonRelease?: InputMaybe<Scalars['String']>
  securedReleaseChannels: Array<SecuredDiscordChannelInput>
  status: ItemStatus
  title: Scalars['String']
}

export type CreateProductInput = {
  botKeywords: Array<Scalars['String']>
  brands: Array<Scalars['String']>
  category: ProductCategory
  collectionId?: InputMaybe<Scalars['String']>
  colors: Array<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  gender: ProductGender
  internalNotes?: InputMaybe<Scalars['String']>
  isBotCopAvailable: Scalars['Boolean']
  isDraft: Scalars['Boolean']
  isHyped: Scalars['Boolean']
  media: Array<MediaInput>
  prices: Array<ProductPriceInput>
  rating?: InputMaybe<ItemRating>
  releases: Array<ItemReleaseInput>
  resellAction: ItemResellAction
  resellStores: Array<ProductResellStoreInput>
  riskLevel?: InputMaybe<Scalars['Int']>
  seasonRelease?: InputMaybe<Scalars['String']>
  securedReleaseChannels: Array<SecuredDiscordChannelInput>
  status: ItemStatus
  storeGroupIds?: InputMaybe<Array<Scalars['String']>>
  styleCode?: InputMaybe<Scalars['String']>
  subcategory: ProductSubcategory
  title: Scalars['String']
}

export type CreateRaffleInput = {
  closesAt: Scalars['DateTime']
  delivery?: InputMaybe<PackageDeliveryType>
  internalNotes?: InputMaybe<Scalars['String']>
  isAccountRequired?: InputMaybe<Scalars['Boolean']>
  isPreAuthRequired?: InputMaybe<Scalars['Boolean']>
  opensAt: Scalars['DateTime']
  productId: Scalars['String']
  region: Scalars['String']
  server: SecuredServer
  storeId?: InputMaybe<Scalars['String']>
  timezone: Scalars['String']
  title: Scalars['String']
  type?: InputMaybe<RaffleType>
  url: Scalars['String']
}

export type CreateSessionCheckoutResponse = {
  __typename?: 'CreateSessionCheckoutResponse'
  sessionId: Scalars['String']
}

export type CreateStoreGroupInput = {
  name: Scalars['String']
  productIds?: InputMaybe<Array<Scalars['String']>>
  storeIds?: InputMaybe<Array<Scalars['String']>>
}

export type CreateStoreInput = {
  copType: CopType
  countryCode?: InputMaybe<CountryCode>
  dropTime?: InputMaybe<Scalars['String']>
  instagramUsernames?: InputMaybe<Array<Scalars['String']>>
  isPreAuthRequired: Scalars['Boolean']
  isRecommended: Scalars['Boolean']
  isSecuredBotSupported: Scalars['Boolean']
  name: Scalars['String']
  region: Scalars['String']
  releaseUrl?: InputMaybe<Scalars['String']>
  siteType: SiteType
  storeGroupIds?: InputMaybe<Array<Scalars['String']>>
  timezone?: InputMaybe<Scalars['String']>
  url: Scalars['String']
}

export type CreateStripeBillingPortalInput = {
  redirectUrl: Scalars['String']
}

export type CreateStripeCheckoutInput = {
  analyticsEvent?: InputMaybe<CheckoutAnalyticsEventInput>
  cancelUrl: Scalars['String']
  currencyCode: CurrencyCode
  pricingCode: Scalars['String']
  promotionCode?: InputMaybe<Scalars['String']>
  subscriptionType: UserSubscriptionType
  successUrl: Scalars['String']
  userSubscriptionProductType: UserSubscriptionProductType
}

export type CreateStripePendingUserInput = {
  stripeCustomerId: Scalars['String']
  stripeSubscriptionId: Scalars['String']
  userSubscriptionProductType: Scalars['String']
}

export type CreateUploadResponse = {
  __typename?: 'CreateUploadResponse'
  fileName: Scalars['String']
  uploadUrl: Scalars['String']
}

export enum CurrencyCode {
  Eur = 'eur',
  Gbp = 'gbp',
  Usd = 'usd',
}

export type Device = {
  __typename?: 'Device'
  createdAt: Scalars['DateTime']
  deviceType: DeviceType
  id: Scalars['ID']
  token: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
}

export enum DeviceType {
  Android = 'android',
  Ios = 'ios',
  Web = 'web',
}

export type DiscordInterest = {
  __typename?: 'DiscordInterest'
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: DiscordInterestType
}

export enum DiscordInterestType {
  EuBotFlipping = 'euBotFlipping',
  EuBotRestocks = 'euBotRestocks',
  EuBotting = 'euBotting',
  EuBrickFlips = 'euBrickFlips',
  EuEuukBrickFlips = 'euEUUKBrickFlips',
  EuEuukRestocks = 'euEUUKRestocks',
  EuGaming = 'euGaming',
  EuGroupBuys = 'euGroupBuys',
  EuLowkeyFlips = 'euLowkeyFlips',
  EuOffspringStore = 'euOffspringStore',
  EuTwitterArmy = 'euTwitterArmy',
  EuUsaRestocks = 'euUSARestocks',
  NaBotFlipping = 'naBotFlipping',
  NaBotRestocks = 'naBotRestocks',
  NaBrickFlips = 'naBrickFlips',
  NaGaming = 'naGaming',
  NaGroupBuys = 'naGroupBuys',
  NaLowkeyFlips = 'naLowkeyFlips',
  NaTwitterArmy = 'naTwitterArmy',
  NaUsaRestocks = 'naUSARestocks',
}

export type DiscordSession = {
  __typename?: 'DiscordSession'
  createdAt: Scalars['DateTime']
  device: Device
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  token: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type DiscordUser = {
  __typename?: 'DiscordUser'
  accessTokenExpiresAt?: Maybe<Scalars['DateTime']>
  avatar: Scalars['String']
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  id: Scalars['String']
  isVerified: Scalars['Boolean']
  scope: Scalars['String']
  updatedAt: Scalars['DateTime']
  username: Scalars['String']
}

export type DiscountMetadata = {
  __typename?: 'DiscountMetadata'
  /** Percentage from 0 to 100 or cents of fixed value */
  amount: Scalars['Int']
  type: DiscountType
}

export enum DiscountType {
  FixedAmount = 'fixedAmount',
  Percentage = 'percentage',
}

export type Drop = {
  __typename?: 'Drop'
  copType: CopType
  createdAt: Scalars['DateTime']
  currency?: Maybe<CurrencyCode>
  deliveryType?: Maybe<DropDeliveryType>
  id: Scalars['ID']
  isDropTimeRandom: Scalars['Boolean']
  isReminderSet: Scalars['Boolean']
  priceCents?: Maybe<Scalars['Float']>
  product: Product
  region: Scalars['String']
  releasesAt: Scalars['DateTime']
  releasesAtUTC: Scalars['DateTime']
  server: SecuredServer
  status: DropStatus
  store: Store
  timezone: Scalars['String']
  title: Scalars['String']
  type?: Maybe<DropType>
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export type DropCreatedAlert = Alert & {
  __typename?: 'DropCreatedAlert'
  createdAt: Scalars['DateTime']
  drop: Drop
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  type: AlertType
  updatedAt: Scalars['DateTime']
}

export enum DropDeliveryType {
  Delivery = 'Delivery',
  Instore = 'Instore',
}

export enum DropReleaseRange {
  ThisWeek = 'thisWeek',
  Today = 'today',
}

export enum DropStatus {
  ComingSoon = 'comingSoon',
  InStock = 'inStock',
  OutOfStock = 'outOfStock',
}

export enum DropType {
  New = 'new',
  Restock = 'restock',
}

export enum DropsSortBy {
  CreatedAscending = 'createdAscending',
  CreatedDescending = 'createdDescending',
  ReleasesAtAscending = 'releasesAtAscending',
  ReleasesAtDescending = 'releasesAtDescending',
}

export type EntityOwner = {
  __typename?: 'EntityOwner'
  createdBy?: Maybe<User>
  id: Scalars['String']
  updatedBy?: Maybe<User>
}

export type ExchangeDiscordCodeInput = {
  code: Scalars['String']
  isNewCheckout?: InputMaybe<Scalars['Boolean']>
  pendingUserId?: InputMaybe<Scalars['String']>
  redirectUrl: Scalars['String']
}

export type ExchangeDiscordCodeResponse =
  | LoginResponse
  | MultipleAccountsResponse

export enum ExperienceLevel {
  Beginner = 'beginner',
  Intermediate = 'intermediate',
}

export enum FileType {
  Jpeg = 'jpeg',
  Jpg = 'jpg',
  Png = 'png',
}

export type GetActivitiesOptionsInput = {
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
}

export type GetActivitiesResponse = {
  __typename?: 'GetActivitiesResponse'
  activities: Array<Activity>
  pagination: Pagination
}

export type GetAlertsFiltersInput = {
  discordServer?: InputMaybe<SecuredServer>
  type?: InputMaybe<AlertTypeQuery>
  userId?: InputMaybe<Scalars['String']>
}

export type GetAlertsInput = {
  filters?: InputMaybe<GetAlertsFiltersInput>
  pagination: PaginationInput
}

export type GetAlertsResponse = {
  __typename?: 'GetAlertsResponse'
  alerts: Array<AlertUnion>
  pagination: Pagination
}

export type GetAllAlertsFiltersInput = {
  discordServer?: InputMaybe<SecuredServer>
  type?: InputMaybe<AlertTypeQuery>
}

export type GetAllAlertsOptionsInput = {
  filters?: InputMaybe<GetAllAlertsFiltersInput>
  pagination: PaginationInput
}

export type GetAnnouncementsFiltersInput = {
  productId?: InputMaybe<Scalars['String']>
  type?: InputMaybe<AnnouncementType>
}

export type GetAnnouncementsOptionsInput = {
  filters?: InputMaybe<GetAnnouncementsFiltersInput>
  pagination: PaginationInput
}

export type GetCouponCodeRedeemersByUserResponse = {
  __typename?: 'GetCouponCodeRedeemersByUserResponse'
  pagination: Pagination
  redeemers: Array<ReferralRedeemer>
}

export type GetDiscordInterestsFilterInput = {
  countryCode?: InputMaybe<CountryCode>
}

export type GetDiscordInterestsOptionsInput = {
  filters: GetDiscordInterestsFilterInput
}

export type GetDiscordInterestsResponse = {
  __typename?: 'GetDiscordInterestsResponse'
  interests: Array<DiscordInterest>
}

export type GetDropsByRangeInput = {
  pagination?: InputMaybe<PaginationInput>
  releaseRange: DropReleaseRange
}

export type GetDropsFiltersInput = {
  copType?: InputMaybe<CopType>
  dropRange?: InputMaybe<DropReleaseRange>
  isReleasing?: InputMaybe<Scalars['Boolean']>
  isSecuredBotSupported?: InputMaybe<Scalars['Boolean']>
  regions?: InputMaybe<Array<Region>>
  type?: InputMaybe<DropType>
}

export type GetDropsOptionsInput = {
  filters?: InputMaybe<GetDropsFiltersInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
  sortBy: DropsSortBy
}

export type GetDropsResponse = {
  __typename?: 'GetDropsResponse'
  drops: Array<Drop>
  pagination: Pagination
}

export type GetMyAlertsFiltersInput = {
  type?: InputMaybe<AlertTypeQuery>
}

export type GetMyAlertsOptionsInput = {
  filters?: InputMaybe<GetMyAlertsFiltersInput>
  pagination: PaginationInput
}

export type GetMyProductAlertSubscriptionsFilterInput = {
  isHyped?: InputMaybe<Scalars['Boolean']>
}

export type GetMyProductAlertSubscriptionsOptionsInput = {
  filters: GetMyProductAlertSubscriptionsFilterInput
  pagination: PaginationInput
}

export type GetNotificationsSettingsFilterInput = {
  userId?: InputMaybe<Scalars['String']>
}

export type GetNotificationsSettingsOptionsInput = {
  filters: GetNotificationsSettingsFilterInput
}

export type GetOpenSeaCollectionsFiltersInput = {
  collectionIds?: InputMaybe<Array<Scalars['String']>>
}

export type GetOpenSeaCollectionsInput = {
  filters?: InputMaybe<GetOpenSeaCollectionsFiltersInput>
  pagination: PaginationInput
}

export type GetOpenSeaCollectionsResponse = {
  __typename?: 'GetOpenSeaCollectionsResponse'
  collections: Array<OpenSeaCollection>
  pagination: Pagination
}

export type GetPendingUsersFiltersInput = {
  isActiveUser?: InputMaybe<Scalars['Boolean']>
  isSubscriptionValid?: InputMaybe<Scalars['Boolean']>
}

export type GetPendingUsersOptionsInput = {
  filters?: InputMaybe<GetPendingUsersFiltersInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<PendingUsersSortBy>
}

export type GetPendingUsersResponse = {
  __typename?: 'GetPendingUsersResponse'
  pagination: Pagination
  users: Array<PendingUser>
}

export type GetPricingDataInput = {
  currency: CurrencyCode
  pricingCode: Scalars['String']
  promoCode?: InputMaybe<Scalars['String']>
}

export type GetPricingDataResponse = {
  __typename?: 'GetPricingDataResponse'
  annual: PricingData
  monthly: PricingData
}

export type GetProductAlertSubscriptionsResponse = {
  __typename?: 'GetProductAlertSubscriptionsResponse'
  pagination: Pagination
  productAlertSubscriptions: Array<ProductAlertSubscription>
}

export type GetProductCategoriesOptionsInput = {
  filters?: InputMaybe<GetProductsFilterInput>
}

export type GetProductCategoriesResponse = {
  __typename?: 'GetProductCategoriesResponse'
  categories: Array<ProductCategory>
}

export type GetProductCollectionMetaResponse = {
  __typename?: 'GetProductCollectionMetaResponse'
  totalProductsCount: Scalars['Int']
}

export type GetProductCollectionsFilterInput = {
  contentType?: InputMaybe<ProductCollectionFilterContentType>
  productReleaseRange?: InputMaybe<ProductReleaseRange>
  releaseZone?: InputMaybe<ItemReleaseZone>
}

export type GetProductCollectionsOptionsInput = {
  filters?: InputMaybe<GetProductCollectionsFilterInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
}

export type GetProductCollectionsResponse = {
  __typename?: 'GetProductCollectionsResponse'
  pagination: Pagination
  productCollections: Array<ProductCollection>
}

export type GetProductDropsMetaResponse = {
  __typename?: 'GetProductDropsMetaResponse'
  lastUpdate: Scalars['DateTime']
  releasedDropsCount: Scalars['Int']
  upcomingDropsCount: Scalars['Int']
}

export type GetProductRafflesMetaResponse = {
  __typename?: 'GetProductRafflesMetaResponse'
  availableRafflesCount: Scalars['Int']
  closedRafflesCount: Scalars['Int']
  enteredRafflesCount: Scalars['Int']
  lastUpdate: Scalars['DateTime']
}

export type GetProductsFilterInput = {
  categories?: InputMaybe<Array<ProductCategory>>
  contentType?: InputMaybe<ProductFilterContentType>
  isDraft?: InputMaybe<Scalars['Boolean']>
  isHyped?: InputMaybe<Scalars['Boolean']>
  isLive?: InputMaybe<Scalars['Boolean']>
  isReleased?: InputMaybe<Scalars['Boolean']>
  isSecuredBotSupported?: InputMaybe<Scalars['Boolean']>
  isSubscribed?: InputMaybe<Scalars['Boolean']>
  productReleaseRange?: InputMaybe<ProductReleaseRange>
  releaseAfterDate?: InputMaybe<Scalars['DateTime']>
  releaseZone?: InputMaybe<ItemReleaseZone>
  userId?: InputMaybe<Scalars['String']>
}

export type GetProductsOptionsInput = {
  filters?: InputMaybe<GetProductsFilterInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
}

export type GetProductsResponse = {
  __typename?: 'GetProductsResponse'
  pagination: Pagination
  products: Array<Product>
}

export type GetProductsWithNewestRafflesInput = {
  pagination: PaginationInput
}

export type GetProductsWithNewestRafflesResponse = {
  __typename?: 'GetProductsWithNewestRafflesResponse'
  pagination: Pagination
  raffles: Array<RaffleWithProductReleaseDate>
}

export type GetRafflesFiltersInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
  isOpenFirst?: InputMaybe<Scalars['Boolean']>
  isPreAuthRequired?: InputMaybe<Scalars['Boolean']>
  isStoreLinked?: InputMaybe<Scalars['Boolean']>
  regions?: InputMaybe<Array<Region>>
}

export type GetRafflesOptionsInput = {
  filters?: InputMaybe<GetRafflesFiltersInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
  sortBy: RafflesSortBy
}

export type GetRafflesResponse = {
  __typename?: 'GetRafflesResponse'
  pagination: Pagination
  raffles: Array<Raffle>
}

export type GetReleasesFilterInput = {
  productReleaseRange?: InputMaybe<ProductReleaseRange>
  releaseZone?: InputMaybe<ItemReleaseZone>
}

export type GetReleasesOptionsInput = {
  filters?: InputMaybe<GetReleasesFilterInput>
}

export type GetReleasesResponse = {
  __typename?: 'GetReleasesResponse'
  releases: Array<Release>
}

export type GetStoreGroupsOptionsInput = {
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
}

export type GetStoreGroupsResponse = {
  __typename?: 'GetStoreGroupsResponse'
  pagination: Pagination
  storeGroups: Array<StoreGroup>
}

export type GetStoresFiltersInput = {
  regions?: InputMaybe<Array<Region>>
}

export type GetStoresOptionsInput = {
  filters?: InputMaybe<GetStoresFiltersInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
}

export type GetStoresResponse = {
  __typename?: 'GetStoresResponse'
  pagination: Pagination
  stores: Array<Store>
}

export type GetUsedReferralsByUserInput = {
  pagination: PaginationInput
}

export type GetUserReferralMetaResponse = {
  __typename?: 'GetUserReferralMetaResponse'
  creditBalanceCents: Scalars['Int']
  creditBalanceCurrencyCode: CurrencyCode
  totalRedeemedReferralsCount: Scalars['Int']
}

export type GetUsersFiltersInput = {
  server?: InputMaybe<SecuredServer>
}

export type GetUsersOptionsInput = {
  filters?: InputMaybe<GetUsersFiltersInput>
  pagination: PaginationInput
  query?: InputMaybe<Scalars['String']>
}

export type GetUsersResponse = {
  __typename?: 'GetUsersResponse'
  pagination: Pagination
  users: Array<User>
}

export type ImminentDropAlert = Alert & {
  __typename?: 'ImminentDropAlert'
  createdAt: Scalars['DateTime']
  drop: Drop
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  notBefore: Scalars['DateTime']
  type: AlertType
  updatedAt: Scalars['DateTime']
}

export enum ItemRating {
  PersonalPurchase = 'personalPurchase',
  Resell = 'resell',
}

export type ItemRelease = {
  __typename?: 'ItemRelease'
  date: Scalars['DateTime']
  zone: ItemReleaseZone
}

export type ItemReleaseInput = {
  date: Scalars['DateTime']
  zone: ItemReleaseZone
}

export enum ItemReleaseZone {
  Eu = 'eu',
  Na = 'na',
}

export enum ItemResellAction {
  Hold = 'hold',
  Sell = 'sell',
}

export enum ItemStatus {
  ComingSoon = 'comingSoon',
  Released = 'released',
}

export type ListAnnouncementsResponse = {
  __typename?: 'ListAnnouncementsResponse'
  announcements: Array<Announcement>
  pagination: Pagination
}

export type LoginResponse = {
  __typename?: 'LoginResponse'
  session: Session
  user: User
}

export type Media = {
  __typename?: 'Media'
  name: Scalars['String']
  url: Scalars['String']
}

export type MediaInput = {
  name: Scalars['String']
}

export type MultipleAccountsResponse = {
  __typename?: 'MultipleAccountsResponse'
  accounts: Array<User>
  session: DiscordSession
}

export type Mutation = {
  __typename?: 'Mutation'
  attachDeviceToSession: Device
  createAmbassador: Ambassador
  createAnnouncement: Announcement
  createDiscordAuthorizationLink: Scalars['String']
  createDrop: Drop
  createOpenSeaCollection: OpenSeaCollection
  createPendingUserFromStripeCustomer: PendingUser
  createProduct: Product
  createProductAlertSubscription: ProductAlertSubscription
  createProductAlertSubscriptions: Array<ProductAlertSubscription>
  createProductCollection: ProductCollection
  createProductCollectionUploadUrl: CreateUploadResponse
  createProductUploadUrl: CreateUploadResponse
  createRaffle: Raffle
  createStore: Store
  createStoreGroup: StoreGroup
  createStripeBillingPortal: CreateBillingPortalResponse
  createStripeCheckout: CreateSessionCheckoutResponse
  deleteDrop: Scalars['Boolean']
  deleteProduct: Scalars['Boolean']
  deleteProductAlertSubscription: Scalars['Boolean']
  deleteProductCollection: Scalars['Boolean']
  deleteRaffle: Scalars['Boolean']
  deleteStore: Scalars['Boolean']
  deleteStoreGroup: Scalars['Boolean']
  exchangeDiscordCode: ExchangeDiscordCodeResponse
  exchangeDiscordSession: LoginResponse
  logout: Scalars['Boolean']
  markRaffleAsEntered: Raffle
  removeDropReminder: Drop
  removeRaffleEntry: Raffle
  resubscribeMember: Scalars['Boolean']
  setDropReminder: Drop
  setExperienceLevel: User
  setIsDiscordUserResetAllowed: PendingUser
  updateAnnouncement: Announcement
  updateDrop: Drop
  updateNotificationsSettings: NotificationsSettings
  updateProduct: Product
  updateProductCollection: ProductCollection
  updateRaffle: Raffle
  updateStore: Store
  updateStoreGroup: StoreGroup
  updateUser: User
  updateUserDiscordCountry: User
  updateUserDiscordInterests: User
  updateUserMetadata: User
  upgradeMemberToAnnual: Scalars['Boolean']
  validateRevenueCatCheckout: PendingUser
  validateStripeCheckout: PendingUser
}

export type MutationAttachDeviceToSessionArgs = {
  input: CreateDeviceInput
}

export type MutationCreateAmbassadorArgs = {
  input: CreateAmbassadorInput
}

export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput
}

export type MutationCreateDiscordAuthorizationLinkArgs = {
  redirectUrl: Scalars['String']
}

export type MutationCreateDropArgs = {
  input: CreateDropInput
}

export type MutationCreateOpenSeaCollectionArgs = {
  input: CreateOpenSeaCollectionInput
}

export type MutationCreatePendingUserFromStripeCustomerArgs = {
  input: CreateStripePendingUserInput
}

export type MutationCreateProductArgs = {
  input: CreateProductInput
}

export type MutationCreateProductAlertSubscriptionArgs = {
  productId: Scalars['String']
}

export type MutationCreateProductAlertSubscriptionsArgs = {
  productIds: Array<Scalars['String']>
}

export type MutationCreateProductCollectionArgs = {
  input: CreateProductCollectionInput
}

export type MutationCreateProductCollectionUploadUrlArgs = {
  fileType: FileType
}

export type MutationCreateProductUploadUrlArgs = {
  fileType: FileType
}

export type MutationCreateRaffleArgs = {
  input: CreateRaffleInput
}

export type MutationCreateStoreArgs = {
  input: CreateStoreInput
}

export type MutationCreateStoreGroupArgs = {
  input: CreateStoreGroupInput
}

export type MutationCreateStripeBillingPortalArgs = {
  input: CreateStripeBillingPortalInput
  userId: Scalars['String']
}

export type MutationCreateStripeCheckoutArgs = {
  input: CreateStripeCheckoutInput
}

export type MutationDeleteDropArgs = {
  id: Scalars['String']
}

export type MutationDeleteProductArgs = {
  id: Scalars['String']
}

export type MutationDeleteProductAlertSubscriptionArgs = {
  productId: Scalars['String']
}

export type MutationDeleteProductCollectionArgs = {
  id: Scalars['String']
}

export type MutationDeleteRaffleArgs = {
  id: Scalars['String']
}

export type MutationDeleteStoreArgs = {
  id: Scalars['String']
}

export type MutationDeleteStoreGroupArgs = {
  id: Scalars['String']
}

export type MutationExchangeDiscordCodeArgs = {
  input: ExchangeDiscordCodeInput
}

export type MutationExchangeDiscordSessionArgs = {
  accountId: Scalars['String']
}

export type MutationMarkRaffleAsEnteredArgs = {
  id: Scalars['String']
}

export type MutationRemoveDropReminderArgs = {
  id: Scalars['String']
}

export type MutationRemoveRaffleEntryArgs = {
  id: Scalars['String']
}

export type MutationResubscribeMemberArgs = {
  input: ResubscribeMemberInput
}

export type MutationSetDropReminderArgs = {
  id: Scalars['String']
}

export type MutationSetExperienceLevelArgs = {
  experienceLevel: ExperienceLevel
  userId: Scalars['String']
}

export type MutationSetIsDiscordUserResetAllowedArgs = {
  isDiscordUserResetAllowed: Scalars['Boolean']
  pendingUserId: Scalars['String']
}

export type MutationUpdateAnnouncementArgs = {
  announcementId: Scalars['String']
  input: CreateAnnouncementInput
}

export type MutationUpdateDropArgs = {
  id: Scalars['String']
  input: UpdateDropInput
}

export type MutationUpdateNotificationsSettingsArgs = {
  input: UpdateNotificationsSettingsInput
  userId?: InputMaybe<Scalars['String']>
}

export type MutationUpdateProductArgs = {
  id: Scalars['String']
  input: UpdateProductInput
}

export type MutationUpdateProductCollectionArgs = {
  id: Scalars['String']
  input: UpdateProductCollectionInput
}

export type MutationUpdateRaffleArgs = {
  id: Scalars['String']
  input: UpdateRaffleInput
}

export type MutationUpdateStoreArgs = {
  id: Scalars['String']
  input: UpdateStoreInput
}

export type MutationUpdateStoreGroupArgs = {
  id: Scalars['String']
  input: UpdateStoreGroupInput
}

export type MutationUpdateUserArgs = {
  id: Scalars['String']
  input: UpdateUserInput
}

export type MutationUpdateUserDiscordCountryArgs = {
  countryCode: Scalars['String']
  userId: Scalars['String']
}

export type MutationUpdateUserDiscordInterestsArgs = {
  input: UpdateUserDiscordInterestsInput
  userId: Scalars['String']
}

export type MutationUpdateUserMetadataArgs = {
  input: UpdateUserMetadataInput
  userId: Scalars['String']
}

export type MutationValidateRevenueCatCheckoutArgs = {
  input: ValidateRevenueCatPurchaseInput
}

export type MutationValidateStripeCheckoutArgs = {
  input: ValidateSessionCheckoutInput
}

export type NotificationsSettings = {
  __typename?: 'NotificationsSettings'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  isHypedProductSubscribed: Scalars['Boolean']
  updatedAt: Scalars['DateTime']
}

export type OpenSeaCollection = {
  __typename?: 'OpenSeaCollection'
  collectionId: Scalars['String']
  contractAddress?: Maybe<Scalars['String']>
  id: Scalars['String']
  mintPrice?: Maybe<Scalars['Float']>
  officialLinks: Array<Scalars['String']>
  revealDate?: Maybe<Scalars['DateTime']>
}

export enum PackageDeliveryType {
  Pickup = 'pickup',
  Postage = 'postage',
}

export type Pagination = {
  __typename?: 'Pagination'
  hitsPerPage: Scalars['Int']
  numberOfHits: Scalars['Int']
  numberOfPages: Scalars['Int']
  page: Scalars['Int']
}

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PendingUser = {
  __typename?: 'PendingUser'
  checkoutConfirmationUrl: Scalars['String']
  countryCode?: Maybe<Scalars['String']>
  couponCodeUsed?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isActiveUser: Scalars['Boolean']
  isDiscordUserResetAllowed: Scalars['Boolean']
  revenueCatSubscriberId?: Maybe<Scalars['String']>
  stripeCustomerId?: Maybe<Scalars['String']>
  stripeSubscriptionId?: Maybe<Scalars['String']>
  subscriptionType?: Maybe<UserSubscriptionType>
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
  userSubscriptionProductType: UserSubscriptionProductType
}

export enum PendingUsersSortBy {
  CreatedAscending = 'createdAscending',
  CreatedDescending = 'createdDescending',
}

export type PricingData = {
  __typename?: 'PricingData'
  currency: CurrencyCode
  discountMetadata?: Maybe<DiscountMetadata>
  discountedPriceCents?: Maybe<Scalars['Int']>
  priceCents: Scalars['Int']
  productId: Scalars['String']
}

export type Product = {
  __typename?: 'Product'
  botKeywords: Array<Scalars['String']>
  brands: Array<Scalars['String']>
  category: ProductCategory
  collection?: Maybe<ProductCollection>
  colors: Array<Scalars['String']>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  gender: ProductGender
  id: Scalars['ID']
  internalNotes?: Maybe<Scalars['String']>
  isBotCopAvailable: Scalars['Boolean']
  isDraft: Scalars['Boolean']
  isHyped: Scalars['Boolean']
  media: Array<Media>
  prices: Array<ProductPrice>
  rating?: Maybe<ItemRating>
  releaseDate?: Maybe<Scalars['DateTime']>
  releases: Array<ItemRelease>
  resellAction: ItemResellAction
  resellStores: Array<ProductResellStore>
  riskLevel?: Maybe<Scalars['Int']>
  seasonRelease?: Maybe<Scalars['String']>
  securedReleaseChannel?: Maybe<SecuredDiscordChannel>
  securedReleaseChannels: Array<SecuredDiscordChannel>
  sizes: Array<SizeLabel>
  status: ItemStatus
  storeGroups: Array<StoreGroup>
  styleCode?: Maybe<Scalars['String']>
  subcategory: ProductSubcategory
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type ProductAlertSubscription = {
  __typename?: 'ProductAlertSubscription'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  isHypedAlert?: Maybe<Scalars['Boolean']>
  product: Product
  updatedAt: Scalars['DateTime']
}

export enum ProductCategory {
  Accessories = 'accessories',
  Bottoms = 'bottoms',
  Footwear = 'footwear',
  Lifestyle = 'lifestyle',
  Outerwear = 'outerwear',
  Tops = 'tops',
}

export type ProductCollection = {
  __typename?: 'ProductCollection'
  brands: Array<Scalars['String']>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  internalNotes?: Maybe<Scalars['String']>
  isDraft: Scalars['Boolean']
  media: Array<Media>
  releaseDate?: Maybe<Scalars['DateTime']>
  releases: Array<ItemRelease>
  resellAction: ItemResellAction
  seasonRelease?: Maybe<Scalars['String']>
  securedReleaseChannel?: Maybe<SecuredDiscordChannel>
  securedReleaseChannels: Array<SecuredDiscordChannel>
  status: ItemStatus
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export enum ProductCollectionFilterContentType {
  All = 'all',
  UpcomingReleases = 'upcomingReleases',
}

export enum ProductFilterContentType {
  All = 'all',
  UpcomingReleases = 'upcomingReleases',
}

export enum ProductGender {
  Child = 'child',
  Mens = 'mens',
  Toddler = 'toddler',
  Unisex = 'unisex',
  Womens = 'womens',
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  currencyCode: CurrencyCode
  estimatedProfitCents?: Maybe<Scalars['Int']>
  expectedResalePriceCents?: Maybe<Scalars['Int']>
  priceCents: Scalars['Int']
}

export type ProductPriceInput = {
  currencyCode: CurrencyCode
  estimatedProfitCents?: InputMaybe<Scalars['Int']>
  expectedResalePriceCents?: InputMaybe<Scalars['Int']>
  priceCents: Scalars['Int']
}

export enum ProductReleaseRange {
  ThisWeek = 'thisWeek',
  Today = 'today',
}

export type ProductResellStore = {
  __typename?: 'ProductResellStore'
  resellStore: ResellStore
  url: Scalars['String']
}

export type ProductResellStoreInput = {
  resellStore: ResellStore
  url: Scalars['String']
}

export enum ProductSubcategory {
  Bags = 'bags',
  Belts = 'belts',
  Boots = 'boots',
  Coats = 'coats',
  Decks = 'decks',
  Eyewear = 'eyewear',
  Headwear = 'headwear',
  HighTop = 'highTop',
  Hoodies = 'hoodies',
  Jackets = 'jackets',
  Jeans = 'jeans',
  Jewellery = 'jewellery',
  Keychains = 'keychains',
  Knitwear = 'knitwear',
  LongSleeveTShirt = 'longSleeveTShirt',
  LowTop = 'lowTop',
  Other = 'other',
  Scarfs = 'scarfs',
  Shirts = 'shirts',
  Shorts = 'shorts',
  Sliders = 'sliders',
  Socks = 'socks',
  Sports = 'sports',
  Stickers = 'stickers',
  Sweatpants = 'sweatpants',
  Sweatshirts = 'sweatshirts',
  TShirts = 'tShirts',
  TechAccessories = 'techAccessories',
  Ties = 'ties',
  Toys = 'toys',
  Trousers = 'trousers',
  Wallets = 'wallets',
}

export type Query = {
  __typename?: 'Query'
  activeUserAccounts: Array<User>
  getActivites: GetActivitiesResponse
  getActivitiesByUserId: GetActivitiesResponse
  getAlerts: GetAlertsResponse
  getAllAlerts: GetAlertsResponse
  getAnnouncements: ListAnnouncementsResponse
  getCouponCodeRedeemersByUser: GetCouponCodeRedeemersByUserResponse
  getDiscordInterests: GetDiscordInterestsResponse
  getDropById: Drop
  getDropOwner: EntityOwner
  getDrops: GetDropsResponse
  getDropsByProductId: GetDropsResponse
  getDropsByRange: GetDropsResponse
  getMyAlerts: GetAlertsResponse
  getMyProductAlertSubscriptions: GetProductAlertSubscriptionsResponse
  getNotificationsSettings: NotificationsSettings
  getOpenSeaCollections: GetOpenSeaCollectionsResponse
  getPendingUserById: PendingUser
  getPendingUserByUserId: PendingUser
  getPendingUsers: GetPendingUsersResponse
  getPricingData: GetPricingDataResponse
  getProductById: Product
  getProductCategoriesByProductCollectionId: GetProductCategoriesResponse
  getProductCollectionById: ProductCollection
  getProductCollectionMeta: GetProductCollectionMetaResponse
  getProductCollectionOwner: EntityOwner
  getProductCollections: GetProductCollectionsResponse
  getProductDropsMeta: GetProductDropsMetaResponse
  getProductOwner: EntityOwner
  getProductRafflesMeta: GetProductRafflesMetaResponse
  getProducts: GetProductsResponse
  getProductsByProductCollectionId: GetProductsResponse
  getProductsWithNewestRaffles: GetProductsWithNewestRafflesResponse
  getRaffleById: Raffle
  getRaffleOwner: EntityOwner
  getRaffles: GetRafflesResponse
  getRafflesByProductId: GetRafflesResponse
  getReferralMetaByUser: GetUserReferralMetaResponse
  getReleases: GetReleasesResponse
  getSelf: User
  getSelfUserReferral?: Maybe<UserReferral>
  getSelfUserReferralMeta: GetUserReferralMetaResponse
  getStoreById: Store
  getStoreGroupById: StoreGroup
  getStoreGroups: GetStoreGroupsResponse
  getStoreGroupsByProductId: Array<StoreGroup>
  getStoreGroupsByStoreId: Array<StoreGroup>
  getStoreOwner: EntityOwner
  getStores: GetStoresResponse
  getUserById: User
  getUserDiscordRoles: Array<Scalars['String']>
  getUsers: GetUsersResponse
  root: Scalars['String']
}

export type QueryGetActivitesArgs = {
  input?: InputMaybe<GetActivitiesOptionsInput>
}

export type QueryGetActivitiesByUserIdArgs = {
  input?: InputMaybe<GetActivitiesOptionsInput>
  userId: Scalars['String']
}

export type QueryGetAlertsArgs = {
  input?: InputMaybe<GetAlertsInput>
}

export type QueryGetAllAlertsArgs = {
  input?: InputMaybe<GetAllAlertsOptionsInput>
}

export type QueryGetAnnouncementsArgs = {
  input?: InputMaybe<GetAnnouncementsOptionsInput>
}

export type QueryGetCouponCodeRedeemersByUserArgs = {
  input?: InputMaybe<GetUsedReferralsByUserInput>
  userId: Scalars['String']
}

export type QueryGetDiscordInterestsArgs = {
  input: GetDiscordInterestsOptionsInput
}

export type QueryGetDropByIdArgs = {
  id: Scalars['String']
}

export type QueryGetDropOwnerArgs = {
  id: Scalars['String']
}

export type QueryGetDropsArgs = {
  input?: InputMaybe<GetDropsOptionsInput>
}

export type QueryGetDropsByProductIdArgs = {
  input?: InputMaybe<GetDropsOptionsInput>
  productId: Scalars['String']
}

export type QueryGetDropsByRangeArgs = {
  input?: InputMaybe<GetDropsByRangeInput>
}

export type QueryGetMyAlertsArgs = {
  input?: InputMaybe<GetMyAlertsOptionsInput>
}

export type QueryGetMyProductAlertSubscriptionsArgs = {
  input?: InputMaybe<GetMyProductAlertSubscriptionsOptionsInput>
}

export type QueryGetNotificationsSettingsArgs = {
  input?: InputMaybe<GetNotificationsSettingsOptionsInput>
}

export type QueryGetOpenSeaCollectionsArgs = {
  input?: InputMaybe<GetOpenSeaCollectionsInput>
}

export type QueryGetPendingUserByIdArgs = {
  id: Scalars['String']
}

export type QueryGetPendingUserByUserIdArgs = {
  userId: Scalars['String']
}

export type QueryGetPendingUsersArgs = {
  input?: InputMaybe<GetPendingUsersOptionsInput>
}

export type QueryGetPricingDataArgs = {
  input: GetPricingDataInput
}

export type QueryGetProductByIdArgs = {
  productId: Scalars['String']
}

export type QueryGetProductCategoriesByProductCollectionIdArgs = {
  collectionId: Scalars['String']
  input?: InputMaybe<GetProductCategoriesOptionsInput>
}

export type QueryGetProductCollectionByIdArgs = {
  id: Scalars['String']
}

export type QueryGetProductCollectionMetaArgs = {
  id: Scalars['String']
}

export type QueryGetProductCollectionOwnerArgs = {
  id: Scalars['String']
}

export type QueryGetProductCollectionsArgs = {
  input?: InputMaybe<GetProductCollectionsOptionsInput>
}

export type QueryGetProductDropsMetaArgs = {
  filters?: InputMaybe<GetDropsFiltersInput>
  productId: Scalars['String']
}

export type QueryGetProductOwnerArgs = {
  id: Scalars['String']
}

export type QueryGetProductRafflesMetaArgs = {
  filters?: InputMaybe<GetRafflesFiltersInput>
  productId: Scalars['String']
}

export type QueryGetProductsArgs = {
  input?: InputMaybe<GetProductsOptionsInput>
}

export type QueryGetProductsByProductCollectionIdArgs = {
  collectionId: Scalars['String']
  input?: InputMaybe<GetProductsOptionsInput>
}

export type QueryGetProductsWithNewestRafflesArgs = {
  input?: InputMaybe<GetProductsWithNewestRafflesInput>
}

export type QueryGetRaffleByIdArgs = {
  id: Scalars['String']
}

export type QueryGetRaffleOwnerArgs = {
  id: Scalars['String']
}

export type QueryGetRafflesArgs = {
  input?: InputMaybe<GetRafflesOptionsInput>
}

export type QueryGetRafflesByProductIdArgs = {
  input?: InputMaybe<GetRafflesOptionsInput>
  productId: Scalars['String']
}

export type QueryGetReferralMetaByUserArgs = {
  userId: Scalars['String']
}

export type QueryGetReleasesArgs = {
  input?: InputMaybe<GetReleasesOptionsInput>
}

export type QueryGetStoreByIdArgs = {
  id: Scalars['String']
}

export type QueryGetStoreGroupByIdArgs = {
  id: Scalars['String']
}

export type QueryGetStoreGroupsArgs = {
  input?: InputMaybe<GetStoreGroupsOptionsInput>
}

export type QueryGetStoreGroupsByProductIdArgs = {
  productId: Scalars['String']
}

export type QueryGetStoreGroupsByStoreIdArgs = {
  storeId: Scalars['String']
}

export type QueryGetStoreOwnerArgs = {
  id: Scalars['String']
}

export type QueryGetStoresArgs = {
  input?: InputMaybe<GetStoresOptionsInput>
}

export type QueryGetUserByIdArgs = {
  id: Scalars['String']
}

export type QueryGetUserDiscordRolesArgs = {
  userId: Scalars['String']
}

export type QueryGetUsersArgs = {
  input?: InputMaybe<GetUsersOptionsInput>
}

export type Raffle = {
  __typename?: 'Raffle'
  closesAt?: Maybe<Scalars['DateTime']>
  closesAtUTC?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  delivery?: Maybe<PackageDeliveryType>
  externalRaffleId: Scalars['String']
  id: Scalars['ID']
  internalNotes?: Maybe<Scalars['String']>
  isAccountRequired?: Maybe<Scalars['Boolean']>
  isEntered: Scalars['Boolean']
  isPreAuthRequired?: Maybe<Scalars['Boolean']>
  opensAt?: Maybe<Scalars['DateTime']>
  opensAtUTC?: Maybe<Scalars['DateTime']>
  product: Product
  region: Scalars['String']
  server: SecuredServer
  status: RaffleStatus
  store?: Maybe<Store>
  timezone: Scalars['String']
  title: Scalars['String']
  type?: Maybe<RaffleType>
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export type RaffleCreatedAlert = Alert & {
  __typename?: 'RaffleCreatedAlert'
  createdAt: Scalars['DateTime']
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  raffle: Raffle
  type: AlertType
  updatedAt: Scalars['DateTime']
}

export enum RaffleStatus {
  Closed = 'closed',
  Open = 'open',
}

export enum RaffleType {
  InApp = 'inApp',
  InStore = 'inStore',
  Online = 'online',
  Social = 'social',
}

export type RaffleWithProductReleaseDate = {
  __typename?: 'RaffleWithProductReleaseDate'
  botKeywords: Array<Scalars['String']>
  brands: Array<Scalars['String']>
  category: ProductCategory
  collection?: Maybe<ProductCollection>
  colors: Array<Scalars['String']>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  gender: ProductGender
  id: Scalars['ID']
  internalNotes?: Maybe<Scalars['String']>
  isBotCopAvailable: Scalars['Boolean']
  isDraft: Scalars['Boolean']
  isHyped: Scalars['Boolean']
  media: Array<Media>
  prices: Array<ProductPrice>
  raffle: Raffle
  rating?: Maybe<ItemRating>
  releaseDate: Scalars['DateTime']
  releases: Array<ItemRelease>
  resellAction: ItemResellAction
  resellStores: Array<ProductResellStore>
  riskLevel?: Maybe<Scalars['Int']>
  seasonRelease?: Maybe<Scalars['String']>
  securedReleaseChannel?: Maybe<SecuredDiscordChannel>
  securedReleaseChannels: Array<SecuredDiscordChannel>
  sizes: Array<SizeLabel>
  status: ItemStatus
  storeGroups: Array<StoreGroup>
  styleCode?: Maybe<Scalars['String']>
  subcategory: ProductSubcategory
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export enum RafflesSortBy {
  ClosesAtAscending = 'closesAtAscending',
  ClosesAtDescending = 'closesAtDescending',
  CreatedAscending = 'createdAscending',
  CreatedDescending = 'createdDescending',
}

export type ReferralAmount = {
  __typename?: 'ReferralAmount'
  cents: Scalars['Float']
  currency: Scalars['String']
}

export type ReferralRedeemer = {
  __typename?: 'ReferralRedeemer'
  amountOff: ReferralAmount
  couponCodeUsed: Scalars['String']
  credits: ReferralAmount
  email: Scalars['String']
}

export enum Region {
  Canada = 'canada',
  Europe = 'europe',
  France = 'france',
  Germany = 'germany',
  Italy = 'italy',
  Netherlands = 'netherlands',
  Spain = 'spain',
  Uk = 'uk',
  Us = 'us',
  Worldwide = 'worldwide',
}

export type Release = {
  __typename?: 'Release'
  product?: Maybe<Product>
  productCollection?: Maybe<ProductCollection>
  type: ReleaseType
}

export enum ReleaseType {
  Product = 'product',
  ProductCollection = 'productCollection',
}

export enum ResellStore {
  Bump = 'bump',
  Goat = 'goat',
  Stockx = 'stockx',
}

export type ResubscribeMemberInput = {
  userCancellationId: Scalars['String']
}

export type SecuredDiscordChannel = {
  __typename?: 'SecuredDiscordChannel'
  channelId: Scalars['String']
  name: Scalars['String']
  server: SecuredServer
  url?: Maybe<Scalars['String']>
}

export type SecuredDiscordChannelInput = {
  channelId: Scalars['String']
  name: Scalars['String']
  server: SecuredServer
}

export enum SecuredServer {
  Crypto = 'crypto',
  Eu = 'eu',
  Nft = 'nft',
  Pokemon = 'pokemon',
  Us = 'us',
}

export type Session = {
  __typename?: 'Session'
  createdAt: Scalars['DateTime']
  device: Device
  expiresAt: Scalars['DateTime']
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  subscriptionProductType: UserSubscriptionProductType
  token: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
}

export enum SiteType {
  Custom = 'custom',
  Demandware = 'demandware',
  Footsite = 'footsite',
  Frenzy = 'frenzy',
  Magento = 'magento',
  Mesh = 'mesh',
  Prestashop = 'prestashop',
  Shopify = 'shopify',
  Snkrs = 'snkrs',
}

export enum SizeLabel {
  L = 'L',
  M = 'M',
  OneSize = 'OneSize',
  S = 'S',
  Us3 = 'US3',
  Us4 = 'US4',
  Us5 = 'US5',
  Us6 = 'US6',
  Us7 = 'US7',
  Us8 = 'US8',
  Us9 = 'US9',
  Us10 = 'US10',
  Us11 = 'US11',
  Us12 = 'US12',
  Us13 = 'US13',
  Us14 = 'US14',
  Us15 = 'US15',
  Us16 = 'US16',
  Us35 = 'US35',
  Us45 = 'US45',
  Us55 = 'US55',
  Us65 = 'US65',
  Us75 = 'US75',
  Us85 = 'US85',
  Us95 = 'US95',
  Us105 = 'US105',
  Us115 = 'US115',
  Us125 = 'US125',
  Us135 = 'US135',
  Us145 = 'US145',
  Us155 = 'US155',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxs = 'XXS',
  Xxxl = 'XXXL',
  Xxxs = 'XXXS',
}

export type Store = {
  __typename?: 'Store'
  copType: CopType
  countryCode?: Maybe<CountryCode>
  createdAt: Scalars['DateTime']
  dropTime?: Maybe<Scalars['String']>
  hostname: Scalars['String']
  id: Scalars['ID']
  instagramUsernames?: Maybe<Array<Scalars['String']>>
  isPreAuthRequired: Scalars['Boolean']
  isRecommended: Scalars['Boolean']
  isSecuredBotSupported: Scalars['Boolean']
  name: Scalars['String']
  region: Scalars['String']
  releaseUrl?: Maybe<Scalars['String']>
  siteType: SiteType
  storeGroups: Array<StoreGroup>
  timezone: Scalars['String']
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export type StoreGroup = {
  __typename?: 'StoreGroup'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  products: Array<Product>
  stores: Array<Store>
  updatedAt: Scalars['DateTime']
}

export type Subscription = {
  __typename?: 'Subscription'
  alertCreated: AlertUnion
}

export type SubscriptionAlertCreatedArgs = {
  input?: InputMaybe<AlertCreatedInput>
}

export type UpdateDropInput = {
  copType?: InputMaybe<CopType>
  currency?: InputMaybe<CurrencyCode>
  deliveryType?: InputMaybe<DropDeliveryType>
  isDropTimeRandom?: InputMaybe<Scalars['Boolean']>
  priceCents?: InputMaybe<Scalars['Int']>
  productId: Scalars['String']
  region?: InputMaybe<Scalars['String']>
  releasesAt?: InputMaybe<Scalars['DateTime']>
  server?: InputMaybe<SecuredServer>
  storeId: Scalars['String']
  timezone?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<DropType>
  url?: InputMaybe<Scalars['String']>
}

export type UpdateNotificationsSettingsInput = {
  isHypedProductSubscribed: Scalars['Boolean']
}

export type UpdateProductCollectionInput = {
  brands?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  internalNotes?: InputMaybe<Scalars['String']>
  isDraft?: InputMaybe<Scalars['Boolean']>
  media?: InputMaybe<Array<MediaInput>>
  releases?: InputMaybe<Array<ItemReleaseInput>>
  resellAction?: InputMaybe<ItemResellAction>
  seasonRelease?: InputMaybe<Scalars['String']>
  securedReleaseChannels?: InputMaybe<Array<SecuredDiscordChannelInput>>
  status?: InputMaybe<ItemStatus>
  title?: InputMaybe<Scalars['String']>
}

export type UpdateProductInput = {
  botKeywords?: InputMaybe<Array<Scalars['String']>>
  brands?: InputMaybe<Array<Scalars['String']>>
  category?: InputMaybe<ProductCategory>
  collectionId?: InputMaybe<Scalars['String']>
  colors?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<ProductGender>
  internalNotes?: InputMaybe<Scalars['String']>
  isBotCopAvailable?: InputMaybe<Scalars['Boolean']>
  isDraft?: InputMaybe<Scalars['Boolean']>
  isHyped?: InputMaybe<Scalars['Boolean']>
  media?: InputMaybe<Array<MediaInput>>
  prices?: InputMaybe<Array<ProductPriceInput>>
  rating?: InputMaybe<ItemRating>
  releases?: InputMaybe<Array<ItemReleaseInput>>
  resellAction?: InputMaybe<ItemResellAction>
  resellStores?: InputMaybe<Array<ProductResellStoreInput>>
  riskLevel?: InputMaybe<Scalars['Int']>
  seasonRelease?: InputMaybe<Scalars['String']>
  securedReleaseChannels?: InputMaybe<Array<SecuredDiscordChannelInput>>
  status?: InputMaybe<ItemStatus>
  storeGroupIds?: InputMaybe<Array<Scalars['String']>>
  styleCode?: InputMaybe<Scalars['String']>
  subcategory?: InputMaybe<ProductSubcategory>
  title?: InputMaybe<Scalars['String']>
}

export type UpdateRaffleInput = {
  closesAt?: InputMaybe<Scalars['DateTime']>
  delivery?: InputMaybe<PackageDeliveryType>
  internalNotes?: InputMaybe<Scalars['String']>
  isAccountRequired?: InputMaybe<Scalars['Boolean']>
  isPreAuthRequired?: InputMaybe<Scalars['Boolean']>
  opensAt?: InputMaybe<Scalars['DateTime']>
  productId?: InputMaybe<Scalars['String']>
  region?: InputMaybe<Scalars['String']>
  server?: InputMaybe<SecuredServer>
  storeId?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<RaffleType>
  url?: InputMaybe<Scalars['String']>
}

export type UpdateStoreGroupInput = {
  name?: InputMaybe<Scalars['String']>
  productIds?: InputMaybe<Array<Scalars['String']>>
  storeIds?: InputMaybe<Array<Scalars['String']>>
}

export type UpdateStoreInput = {
  copType?: InputMaybe<CopType>
  countryCode?: InputMaybe<CountryCode>
  dropTime?: InputMaybe<Scalars['String']>
  instagramUsernames?: InputMaybe<Array<Scalars['String']>>
  isPreAuthRequired?: InputMaybe<Scalars['Boolean']>
  isRecommended?: InputMaybe<Scalars['Boolean']>
  isSecuredBotSupported?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  region?: InputMaybe<Scalars['String']>
  releaseUrl?: InputMaybe<Scalars['String']>
  siteType?: InputMaybe<SiteType>
  storeGroupIds?: InputMaybe<Array<Scalars['String']>>
  timezone?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type UpdateUserDiscordInterestsInput = {
  interestTypes: Array<DiscordInterestType>
}

export type UpdateUserInput = {
  countryCode?: InputMaybe<CountryCode>
  currency?: InputMaybe<CurrencyCode>
  discordServer?: InputMaybe<SecuredServer>
  discordUser?: InputMaybe<CreateDiscordUserInput>
  email?: InputMaybe<Scalars['String']>
  isAdmin?: InputMaybe<Scalars['Boolean']>
  isOnboardingCompleted?: InputMaybe<Scalars['Boolean']>
  isTutorialCompleted?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  revenueCatSubscriberId?: InputMaybe<Scalars['String']>
  stripeCustomerId?: InputMaybe<Scalars['String']>
  stripeSubscriptionId?: InputMaybe<Scalars['String']>
}

export type UpdateUserMetadataInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>
  experienceLevel?: InputMaybe<ExperienceLevel>
  userBehaviour?: InputMaybe<UserBehaviour>
}

export type User = {
  __typename?: 'User'
  countryCode?: Maybe<CountryCode>
  couponCodeUsed?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  currency?: Maybe<CurrencyCode>
  discordCountryCode?: Maybe<CountryCode>
  discordInterests: Array<DiscordInterest>
  discordServer?: Maybe<SecuredServer>
  discordUser?: Maybe<DiscordUser>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isAdmin: Scalars['Boolean']
  isOnboardingCompleted: Scalars['Boolean']
  isTutorialCompleted: Scalars['Boolean']
  metadata: UserMetadata
  name?: Maybe<Scalars['String']>
  revenueCatSubscriberId?: Maybe<Scalars['String']>
  stripeCustomerId?: Maybe<Scalars['String']>
  stripeSubscriptionId?: Maybe<Scalars['String']>
  subscriptionType?: Maybe<UserSubscriptionType>
  totalRafflesEntered: Scalars['Int']
  type: UserType
  updatedAt: Scalars['DateTime']
  userSubscriptionProductType: UserSubscriptionProductType
}

export enum UserBehaviour {
  Collector = 'collector',
  Reseller = 'reseller',
}

export type UserMetadata = {
  __typename?: 'UserMetadata'
  dateOfBirth?: Maybe<Scalars['DateTime']>
  experienceLevel?: Maybe<ExperienceLevel>
  userBehaviour?: Maybe<UserBehaviour>
}

export type UserReferral = {
  __typename?: 'UserReferral'
  code: Scalars['String']
  createdAt: Scalars['DateTime']
  creditCents: Scalars['Int']
  creditCurrencyCode: CurrencyCode
  id: Scalars['ID']
  percentageOff: Scalars['Float']
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
}

export enum UserSubscriptionProductType {
  Crypto = 'crypto',
  Nft = 'nft',
  Pokemon = 'pokemon',
  Sneakers = 'sneakers',
  SportsCards = 'sportsCards',
}

export enum UserSubscriptionType {
  Annual = 'annual',
  Monthly = 'monthly',
}

export enum UserType {
  Bot = 'bot',
  Discord = 'discord',
}

export type ValidateRevenueCatPurchaseInput = {
  analyticsEvent?: InputMaybe<CheckoutAnalyticsEventInput>
  email: Scalars['String']
  revenueCatId: Scalars['String']
  userSubscriptionProductType?: InputMaybe<UserSubscriptionProductType>
}

export type ValidateSessionCheckoutInput = {
  analyticsEvent?: InputMaybe<CheckoutAnalyticsEventInput>
  sessionId: Scalars['String']
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Alert: [
      'AnnouncementAlert',
      'DropCreatedAlert',
      'ImminentDropAlert',
      'RaffleCreatedAlert',
    ],
    AlertUnion: [
      'AnnouncementAlert',
      'DropCreatedAlert',
      'ImminentDropAlert',
      'RaffleCreatedAlert',
    ],
    ExchangeDiscordCodeResponse: ['LoginResponse', 'MultipleAccountsResponse'],
  },
}
export default result
