import type { ReactElement } from 'react'
import React, { createRef } from 'react'
import styles from './dropdown.module.scss'
import classNames from 'classnames'
import { Icon } from '../../icon'
import { Icons } from '../../icon/icon'
import { mergeRefs } from '../../../utils/utils'
import type { FieldError } from 'react-hook-form'

export interface DefaultProps {
  className?: string
  disabled: boolean
  error?: FieldError
  selected: boolean
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  placeholder?: string
  name: string
}

// these are all the required props
export interface Props extends Partial<DefaultProps> {
  children: ReactElement | ReactElement[] | null
  label: string
  name: string
}

const defaultProps: DefaultProps = {
  onChange: () => {},
  disabled: false,
  className: '',
  selected: false,
  name: '',
}

const Dropdown = React.forwardRef(
  (
    {
      name,
      className,
      selected,
      onChange,
      disabled,
      placeholder,
      children,
    }: Props,
    ref
  ) => {
    const localInputRef = createRef<HTMLInputElement>()

    const handleOnClick = () => {
      localInputRef?.current?.click()
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onKeyPress={handleOnClick}
        onClick={handleOnClick}
        aria-checked={Boolean(selected)}
        className={classNames(styles.dropdown, className)}
      >
        <select
          disabled={disabled}
          className={classNames(styles.dropdown__select, {
            [styles['dropdown__select--selected']]: selected,
          })}
          name={name}
          ref={mergeRefs(ref, localInputRef)}
          onChange={onChange}
        >
          {placeholder && (
            <option value="" selected>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <div className={styles.dropdown__icon}>
          <Icon
            className={styles.dropdown__icon__icon}
            type={Icons['chevron-down']}
          />
        </div>
      </div>
    )
  }
)

Dropdown.defaultProps = defaultProps

export default Dropdown
