import type {
  Anatomy,
  PartsStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

type NavigationAnatomy = Anatomy<'container' | 'item' | 'text' | 'icon'>

const baseStyle: PartsStyleFunction<NavigationAnatomy> = (
  props: StyleFunctionProps
) => {
  return {
    container: {
      boxShadow: 'ibase',
      justifyContent: 'space-around',
      position: 'fixed',
      bottom: '0px',
      width: '100vw',
      pl: 'env(safe-area-inset-left)',
      pr: 'env(safe-area-inset-right)',
      pb: 'env(safe-area-inset-bottom)',
      left: '0px',
      bg: mode('white', 'gray.900')(props),
      minH: 'calc(var(--chakra-space-14) + env(safe-area-inset-bottom))',
      zIndex: 'sticky',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      minWidth: '45px',
      color: mode('gray.400', 'gray.600')(props),
      cursor: 'pointer',
      userSelect: 'none',
      _hover: {
        cursor: 'pointer',
        color: mode('gray.500', 'gray.500')(props),
      },
      _activeLink: {
        cursor: 'unset',
        color: mode('gray.800', 'gray.400')(props),
      },
      _active: {
        color: mode('gray.800', 'gray.400')(props),
      },
    },
    text: {
      fontSize: 'xs',
    },
  }
}

export const Navigation: ComponentMultiStyleConfig = {
  parts: ['container', 'item', 'text', 'icon'],
  baseStyle,
}
