import React, { useState } from 'react'
import styles from './user-referral-details.module.scss'
import { Button } from 'components/button'

interface DefaultProps {
  className?: string
}

interface Props extends Partial<DefaultProps> {
  creditAmount: string
  percentageOff: string
  code: string
  totalRedeemCount: number
  availableCredit: string
}

const defaultProps: DefaultProps = {}

const UserReferralDetails: React.FC<Props> = ({
  className,
  creditAmount,
  percentageOff,
  code,
  totalRedeemCount,
  availableCredit,
}) => {
  const [isCopyIng, setIsCopying] = useState(false)

  const onCopyCode = () => {
    if (isCopyIng === true) {
      return
    }

    navigator.clipboard.writeText(code)
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 2000)
  }

  return (
    <div className={className}>
      <div className={styles['user-referral-details__section']}>
        <h3
          className={styles['user-referral-details__section__h3']}
        >{`Refer & Earn ${creditAmount}`}</h3>
        <p className={styles['user-referral-details__section__p']}>
          {`Get ${creditAmount} off next month's membership and give a friend ${percentageOff} off their first month, when they sign up using your coupon code at checkout.`}
        </p>
      </div>
      <div className={styles['user-referral-details__content']}>
        <div className={styles['user-referral-details__content__title']}>
          Referral Code
        </div>
        <div className={styles['user-referral-details__content__code']}>
          <div
            className={styles['user-referral-details__content__code__title']}
          >
            {code}
          </div>
          <div>
            <Button
              styleType="ghost"
              className={styles['user-referral-details__content__code__copy']}
              size="medium"
              onClick={onCopyCode}
            >
              {isCopyIng ? 'Copied' : 'Copy'}
            </Button>
          </div>
        </div>
        <div className={styles['user-referral-details__content__details']}>
          <div
            className={styles['user-referral-details__content__details__row']}
          >
            <div
              className={
                styles['user-referral-details__content__details__row__left']
              }
            >
              Signups
            </div>
            <div
              className={
                styles['user-referral-details__content__details__row__right']
              }
            >
              {totalRedeemCount}
            </div>
          </div>
          <div
            className={
              styles['user-referral-details__content__details__separator']
            }
          />
          <div
            className={styles['user-referral-details__content__details__row']}
          >
            <div
              className={
                styles['user-referral-details__content__details__row__left']
              }
            >
              Available Credit
            </div>
            <div
              className={
                styles['user-referral-details__content__details__row__right']
              }
            >
              {availableCredit}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

UserReferralDetails.defaultProps = defaultProps

export default UserReferralDetails
