import * as Sentry from '@sentry/capacitor'
import { reactRouterV5Instrumentation } from '@sentry/react'
import type { History } from 'history'
import { Integrations } from '@sentry/tracing'
import { SENTRY_DSN_WEB, SENTRY_DSN_APP, ENV } from '../constants'
import { AppConfig } from '../services/AppConfig'

export const initSentry = (history: History<unknown>) => {
  Sentry.init({
    dsn: AppConfig.isNativePlatform ? SENTRY_DSN_APP : SENTRY_DSN_WEB,
    environment: ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

export const captureException = (error: Error) => {
  Sentry.captureException(error)
}
