import * as _ from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import {
  CURRENCY_SYMBOLS,
  DISCORD_SERVER_URL_EU,
  DISCORD_SERVER_URL_US,
  DISCORD_SERVER_URL_NFT,
  DISCORD_SERVER_URL_POKEMON,
  REGION_TO_COUNTRY_CODE,
} from '../constants'
import type { ProductPrice, Region } from 'packlets/generated'
import {
  CountryCode,
  CurrencyCode,
  ItemReleaseZone,
  SecuredServer,
} from 'packlets/generated'

export const mergeRefs = (...refs: any) => {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) {
    return null
  }
  if (filteredRefs.length === 0) {
    return filteredRefs[0]
  }

  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}

export const mapCurrencyToCurrencyCode = (currency?: string): CurrencyCode => {
  if (
    currency &&
    Object.values(CurrencyCode as object).includes(currency.toLowerCase())
  ) {
    return currency.toLowerCase() as CurrencyCode
  }
  return CurrencyCode.Usd
}

export const mapCountryToCountryCode = (country?: string): CountryCode => {
  if (
    country &&
    Object.values(CountryCode as object).includes(country.toUpperCase())
  ) {
    return country.toUpperCase() as CountryCode
  }
  return CountryCode.Us
}

export const mapRegionToCountryFlagClass = (
  region: Region | string
): string | undefined => {
  const countryCode = REGION_TO_COUNTRY_CODE[region]
  return `flag-icon flag-icon-${countryCode}`
}

export const getPriceFromCurrency = (
  prices: Pick<ProductPrice, 'currencyCode' | 'priceCents'>[],
  currency: CurrencyCode
): ProductPrice | undefined | null => {
  if (prices.length === 0) {
    return null
  }

  const currencyPrice = prices.find((price) => price.currencyCode === currency)

  if (currencyPrice) {
    return currencyPrice
  }

  const usdPrice = prices.find(
    (price) => price.currencyCode === CurrencyCode.Usd
  )

  if (usdPrice) {
    return usdPrice
  }

  return prices[0]
}

export const formatPriceCents = (
  priceCents: number,
  currency: CurrencyCode
): string => {
  let price: number | string = priceCents / 100
  if (!Number.isInteger(price)) {
    price = price.toFixed(2)
  }

  return `${CURRENCY_SYMBOLS[currency]}${price}`
}

export const formatPercentage = (percentage: number): string => {
  return `${percentage * 100}%`
}

/**
 * Reads the value by the key but in a less annoying type-safe manner allowing `undefined | null` as a key
 * @param constant - your constant object
 * @param key - the key from constant object you want to read
 * @param fallback - fallback in case your key is Falsy or what's returned is nullish (0, "", false will work)
 */
export const readConstant = <T extends object>(
  constant: T,
  key: keyof T | undefined | null,
  fallback?: any
) => {
  return key ? constant[key] ?? fallback : fallback
}

export const infiniteScroll = (
  callback: () => void
): _.DebouncedFunc<() => void> => {
  const handleScroll = _.debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >
      document.documentElement.offsetHeight - 500
    ) {
      callback()
    }
  }, 100)

  document.addEventListener('scroll', handleScroll, false)

  return handleScroll
}

export const dateWithOffset = (date: string, timezone: string): Moment => {
  const offsetffset = `${moment
    .utc(date)
    .format('YYYY-MM-DD[T]HH:mm:ss')}${timezone}`
  return moment(offsetffset).local()
}

export const dateToText = (date: Date, withTime?: boolean): string => {
  const momentDate = moment.utc(date)
  let parsedDate = momentDate.format('DD MMM')

  if (momentDate.isSame(moment(), 'day')) {
    parsedDate = 'Today'
    if (withTime) {
      parsedDate += `, ${momentDate.format('HH:mm')}`
    }
  }
  if (momentDate.isSame(moment().add('1', 'days'), 'day')) {
    parsedDate = 'Tomorrow'
    if (withTime) {
      parsedDate += `, ${momentDate.format('HH:mm')}`
    }
  }
  return parsedDate
}

export const dateToTime = (date: Date): string => {
  const momentDate = moment.utc(date)
  return momentDate.format('HH:mm')
}

export const getDiscordServerUrl = (discordServer?: SecuredServer): string => {
  if (!discordServer) {
    return ''
  }
  if (isMobile) {
    return getDiscordDownloadLink()
  }
  switch (discordServer) {
    case SecuredServer.Eu: {
      return DISCORD_SERVER_URL_EU
    }
    case SecuredServer.Us: {
      return DISCORD_SERVER_URL_US
    }
    case SecuredServer.Nft: {
      return DISCORD_SERVER_URL_NFT
    }
    case SecuredServer.Pokemon: {
      return DISCORD_SERVER_URL_POKEMON
    }
    default:
      return ''
  }
}

export const getDiscordDownloadLink = (): string => {
  if (isAndroid) {
    return 'https://play.google.com/store/apps/details?id=com.discord&attemptId=ab1ff86b-cebe-45da-b5ca-41c511533f52'
  }
  if (isIOS) {
    return 'https://apps.apple.com/us/app/discord-chat-for-games/id985746746?attemptId=983328e6-751d-448c-9bdb-af2b00e9fd62'
  }
  return 'https://discord.com/download'
}

export const getItemZoneFromSecuredServer = (
  discordServer: SecuredServer
): ItemReleaseZone => {
  switch (discordServer) {
    case SecuredServer.Eu:
      return ItemReleaseZone.Eu
    case SecuredServer.Us:
      return ItemReleaseZone.Na
    case SecuredServer.Nft:
      return ItemReleaseZone.Na
    case SecuredServer.Pokemon:
      return ItemReleaseZone.Na
    case SecuredServer.Crypto:
      return ItemReleaseZone.Na
  }
  return ItemReleaseZone.Na
}

export const isEU = (countryCode: string) => {
  switch (countryCode) {
    case CountryCode.At:
    case CountryCode.Be:
    case CountryCode.Bg:
    case CountryCode.Hr:
    case CountryCode.Cy:
    case CountryCode.Cz:
    case CountryCode.Dk:
    case CountryCode.Ee:
    case CountryCode.Fi:
    case CountryCode.Fr:
    case CountryCode.De:
    case CountryCode.Gr:
    case CountryCode.Hu:
    case CountryCode.Ie:
    case CountryCode.It:
    case CountryCode.Lv:
    case CountryCode.Lt:
    case CountryCode.Lu:
    case CountryCode.Mt:
    case CountryCode.Nl:
    case CountryCode.Pl:
    case CountryCode.Pt:
    case CountryCode.Ro:
    case CountryCode.Sk:
    case CountryCode.Si:
    case CountryCode.Es:
    case CountryCode.Se:
      return true
    default:
      return false
  }
}

export const isLinkExternal = (url: string) =>
  new RegExp(/^https?:\/\//).test(url)

export function onClassChange<T extends HTMLElement>(
  element: T,
  callback: (node: Node) => void
) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        callback(mutation.target)
      }
    })
  })
  observer.observe(element, { attributes: true })
  return observer.disconnect
}
