import React from 'react'
import styles from './user-referral-details.module.scss'

interface DefaultProps {
  className?: string
}

interface Props extends Partial<DefaultProps> {}

const defaultProps: DefaultProps = {}

const UserReferralDetailsSkeleton: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <div className={styles['user-referral-details-skeleton__section']}>
        <div
          className={styles['user-referral-details-skeleton__section__h3']}
        />
        <div className={styles['user-referral-details-skeleton__section__p']} />
      </div>
      <div className={styles['user-referral-details-skeleton__content']}>
        <div
          className={styles['user-referral-details-skeleton__content__title']}
        />
        <div
          className={styles['user-referral-details-skeleton__content__code']}
        >
          <div
            className={
              styles['user-referral-details-skeleton__content__code__title']
            }
          />
        </div>
        <div
          className={styles['user-referral-details-skeleton__content__details']}
        >
          <div
            className={
              styles['user-referral-details-skeleton__content__details__row']
            }
          >
            <div
              className={
                styles[
                  'user-referral-details-skeleton__content__details__row__left'
                ]
              }
            />
            <div
              className={
                styles[
                  'user-referral-details-skeleton__content__details__row__right'
                ]
              }
            />
          </div>
          <div
            className={
              styles[
                'user-referral-details-skeleton__content__details__separator'
              ]
            }
          />
          <div
            className={
              styles['user-referral-details-skeleton__content__details__row']
            }
          >
            <div
              className={
                styles[
                  'user-referral-details-skeleton__content__details__row__left'
                ]
              }
            />
            <div
              className={
                styles[
                  'user-referral-details-skeleton__content__details__row__right'
                ]
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

UserReferralDetailsSkeleton.defaultProps = defaultProps

export default UserReferralDetailsSkeleton
