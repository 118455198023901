import type { FunctionComponent } from 'react'
import React from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import styles from './edit-admin-server.module.scss'
import { Dropdown } from '../../../components/form/dropdown'
import type { SecuredServer } from 'packlets/generated'
import { SERVER_TO_TEXT } from '../../../constants'
import { Button } from '../../../components/button'
import { Icon } from '../../../components/icon'
import { Icons } from '../../../components/icon/icon'

export type EditAdminServerFormData = {
  discordServer: SecuredServer | null | undefined
}

interface Props {
  onFormSubmit: (data: EditAdminServerFormData) => void
  data: EditAdminServerFormData
  isLoading?: boolean
  isSuccess?: boolean
  className?: string
}

export const EditAdminServer: FunctionComponent<Props> = ({
  className,
  onFormSubmit,
  data,
  isLoading = false,
  isSuccess = false,
}) => {
  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: data,
  })

  const { isDirty } = formState

  const onSubmit = (aData: EditAdminServerFormData) => {
    onFormSubmit(aData)
  }

  const isFormButtonEnabled = watch('discordServer')

  return (
    <div className={classNames(className)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className={styles['edit-admin-server__form__input-row']}>
            <Dropdown
              label="Discord Server"
              selected={!!watch('discordServer')}
              {...register('discordServer', { required: true })}
              className={
                styles['edit-admin-server__form__input-row__country-dropdown']
              }
            >
              <React.Fragment>
                <option value="" disabled selected>
                  Server
                </option>
                {Object.keys(SERVER_TO_TEXT).map((server) => {
                  return (
                    <option key={server} value={server}>
                      {SERVER_TO_TEXT[server]}
                    </option>
                  )
                })}
              </React.Fragment>
            </Dropdown>
          </div>
          <div>
            {!isFormButtonEnabled && (
              <Button
                size="medium"
                className={
                  styles['edit-admin-server__form__submit-container__button']
                }
                disabled
              >
                Save Changes
              </Button>
            )}
            {isFormButtonEnabled && !isLoading && (
              <Button
                size="medium"
                className={
                  styles['edit-admin-server__form__submit-container__button']
                }
                styleType={!isSuccess ? 'fill' : 'outline'}
                colour={!isSuccess ? 'primary' : 'success'}
                disabled={!isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                {!isSuccess ? 'Save Changes' : 'Saved'}
              </Button>
            )}
            {isLoading && (
              <Button
                size="medium"
                className={
                  styles['edit-admin-server__form__submit-container__button']
                }
                icon={<Icon type={Icons.loader} />}
                disabled
              />
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
