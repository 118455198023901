import React, { useState, useEffect, useCallback } from 'react'
import { forwardRef, useColorModeValue } from '@chakra-ui/system'

import { chakra } from '@chakra-ui/react'
import { createContext } from 'utils/createContext'
import { AnimatePresence } from 'framer-motion'
import type { BoxProps, FlexProps, SystemStyleObject } from '@chakra-ui/react'
import { Portal, Box } from '@chakra-ui/react'
import { MotionBox } from 'ui-kit'

interface MobileMenuContext {
  isOpen: boolean
  onClose(): void
  closeOnOverlayClick?: boolean
}

const [MobileMenuContextProvider, useMobileMenuContext] =
  createContext<MobileMenuContext>({
    strict: true,
    name: 'MobileMenuContext',
    errorMessage:
      'useMobileMenuContext: `context` is undefined. Seems you forgot to wrap menu components in `<MobileMenu />`',
  })

export const MobileMenuOverlay = forwardRef<BoxProps, 'div'>(
  ({ children, onClick: externalOnClick, ...props }, ref) => {
    const { isOpen, onClose, closeOnOverlayClick } = useMobileMenuContext()

    const onClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
      (e) => {
        externalOnClick?.(e)
        closeOnOverlayClick && onClose()
      },
      [closeOnOverlayClick, externalOnClick, onClose]
    )

    useEffect(() => {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset'

      return () => {
        document.body.style.overflow = 'unset'
      }
    }, [isOpen])

    const overlayStyles: SystemStyleObject = {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 'sticky',
      opacity: isOpen ? 1 : 0,
      pointerEvents: isOpen ? 'all' : 'none',
      transition: 'opacity 0.3s ease-in-out',
    }

    return <Box ref={ref} onClick={onClick} __css={overlayStyles} {...props} />
  }
)

export const MobileMenuContent = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => {
    const { isOpen } = useMobileMenuContext()
    const bgColor = useColorModeValue('white', 'gray.800')
    const minimumTopSpace = '80px'

    const menuContentStyles: SystemStyleObject = {
      py: '1',
      w: 'full',
      backgroundColor: bgColor,
      borderTopRightRadius: '3xl',
      borderTopLeftRadius: '3xl',
      zIndex: 'sticky',
      position: 'fixed',
      bottom: 0,
      left: 0,
    }

    return (
      <MotionBox
        ref={ref}
        __css={menuContentStyles}
        animate={{
          maxHeight: isOpen ? `calc(100vh - ${minimumTopSpace})` : '0px',
        }}
        initial={{
          maxHeight: '0px',
        }}
        exit={{
          maxHeight: '0px',
        }}
        // @ts-expect-error
        transition={{
          duration: 0.3,
        }}
        {...props}
      >
        {children}
      </MotionBox>
    )
  }
)

export interface MobileMenuProps extends MobileMenuContext {}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  children,
  ...context
}) => {
  return (
    <MobileMenuContextProvider value={context}>
      <AnimatePresence>
        {context.isOpen && <Portal>{children}</Portal>}
      </AnimatePresence>
    </MobileMenuContextProvider>
  )
}

export const MobileMenuHeader = forwardRef<FlexProps, 'div'>(
  (
    {
      children,
      onTouchStart: externalOnTouchStart,
      onTouchEnd: externalOnTouchEnd,
      ...props
    },
    ref
  ) => {
    const { onClose } = useMobileMenuContext()

    const borderColor = useColorModeValue('gray.200', 'gray.700')
    const minimalTouchOffset = 10 // 10px diff between start and end
    const [touchStartPosY, setTouchStartPosY] = useState<number>(0)

    const handleTouchStart = useCallback<
      React.TouchEventHandler<HTMLDivElement>
    >(
      (ev) => {
        const touchEvent = ev.touches[0]
        externalOnTouchStart?.(ev)
        setTouchStartPosY(touchEvent.clientY)
      },
      [externalOnTouchStart]
    )

    const handleTouchEnd = useCallback<React.TouchEventHandler<HTMLDivElement>>(
      (ev) => {
        const touchEvent = ev.changedTouches[0]
        externalOnTouchEnd?.(ev)

        if (touchEvent.clientY > touchStartPosY + minimalTouchOffset) {
          onClose()
        }
      },
      [onClose, touchStartPosY, externalOnTouchEnd]
    )

    const handleBgColor = useColorModeValue('gray.200', 'gray.700')

    const menuHeaderStyles: SystemStyleObject = {
      display: 'flex',
      pb: '4',
      pt: '1',
      w: 'full',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomWidth: '1px',
      borderColor,
      flexDirection: 'column',
    }

    const menuHeaderHandleDash: SystemStyleObject = {
      w: '6',
      h: '3px',
      borderRadius: '2',
      backgroundColor: handleBgColor,
    }

    return (
      <chakra.div
        ref={ref}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        __css={menuHeaderStyles}
        {...props}
      >
        <chakra.div __css={menuHeaderHandleDash} />
        {children}
      </chakra.div>
    )
  }
)

export const MobileMenuBody = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useMobileMenuContext()
    return (
      <chakra.div
        ref={ref}
        __css={{
          pt: '6',
          py: '4',
          pb: '100',
          overflowY: 'auto',
          maxH: 'calc(100vh - 140px)',
        }}
        {...props}
      >
        {children}
      </chakra.div>
    )
  }
)

export const MobileMenuFooter = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useMobileMenuContext()
    return (
      <chakra.div
        ref={ref}
        __css={{
          px: '6',
          py: '4',
          position: 'fixed',
          bottom: '0',
          width: 'full',
        }}
        {...props}
      >
        {children}
      </chakra.div>
    )
  }
)
