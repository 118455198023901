import React from 'react'
import { Box, Icon, useColorModeValue } from '@chakra-ui/react'
import { BsDiscord } from 'react-icons/bs'

export const ProductImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <Box mr={4} mb={[10, 10, 0]} flex={1}>
      <img alt={alt} src={src} />
    </Box>
  )
}

export const ProductInfoDescription: React.FC = ({ children }) => {
  return <Box flex={1}>{children}</Box>
}
export const ProductInfoText: React.FC = ({ children }) => {
  return (
    <Box
      px={[0, 0, 6]}
      mb={8}
      sx={{
        p: {
          fontWeight: 'medium',
          marginBottom: 4,
          fontSize: '18px',
          lineHeight: '150%',
          letterSpacing: '-0.02em',
          color: useColorModeValue('gray.600', 'gray.400'),
          '&:last-of-type': { mb: '0' },
        },
      }}
    >
      {children}
    </Box>
  )
}

export const ProductInfoTags = () => {
  const tagStyle = {
    bg: useColorModeValue('blackAlpha.100', 'whiteAlpha.300'),
    p: 3,
    backdropFilter: 'blur(15px)',
    transform: 'translate3d(0, 0, 0)',
    borderRadius: 'full',
    display: 'inline-flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: 'fit-content',
    mb: 3,
  }
  return (
    <Box px={[0, 0, 6]}>
      <Box sx={tagStyle}>
        <Box mr={2}>
          <Icon as={BsDiscord} fontSize="21px" />
        </Box>
        <Box as="p" fontWeight="bold">
          Community
        </Box>
      </Box>
    </Box>
  )
}

export const ProductInfo: React.FC = ({ ...rest }) => {
  return (
    <Box
      display={['block', 'block', 'flex']}
      as="section"
      alignItems="center"
      mt={['96px', '96px', '296px']}
      {...rest}
    />
  )
}
