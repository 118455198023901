import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import type { alertAnatomy as parts } from '@chakra-ui/anatomy'
import type { ComponentStyleConfig } from '@chakra-ui/theme'

const baseStyle: PartsStyleFunction<typeof parts> = () => {
  return {
    container: {
      borderRadius: 'base',
    },
  }
}

export const Alert: ComponentStyleConfig = {
  baseStyle,
}
