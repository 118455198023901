import { ApolloError, useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'
import { Button } from '../../components/button'
import { ExternalLayout } from '../../components/layout'
import { DISCORD_CHECKOUT_REDIRECT_URL } from '../../constants'
import { LocalStorage } from '../../services/LocalStorage'
import styles from './checkout-confirmation.module.scss'
import { ConnectDiscord } from './connect-discord'
import { ConnectDiscordState } from './connect-discord/connect-discord'
import { SchoolPrideConfetti } from './school-pride-confetti'
import { openInInternalBrowserOrRedirect } from '../../utils/app'
import { useLoginToSecured } from '../login/useLoginToSecured'
import { ErrorCode, parseError } from '../../utils/errorParser'
import { Analytics } from '../../services/Analytics'
import {
  CreateDiscordAuthorizationLinkDocument,
  ExchangeDiscordCodeDocument,
} from '../../gql/auth.generated'

interface Props {
  code?: string
  state?: string
  pendingUserId?: string
  errorDescription?: string
}

const discordOAuthStateStorage = LocalStorage({ key: 'DISCORD_OAUTH_STATE' })

const CheckoutConfirmation: React.FC<Props> = ({
  code,
  state,
  pendingUserId,
  errorDescription,
}) => {
  const [, , removeCookie] = useCookies()
  const [hasError, setHasError] = useState(!!errorDescription)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loginState, setLoginState] = useState<ConnectDiscordState>(
    hasError ? ConnectDiscordState.failed : ConnectDiscordState.default
  )

  const { logInToSecured } = useLoginToSecured()
  const [loginWithDiscord, { loading: loadingLoginWithDiscord }] = useMutation(
    CreateDiscordAuthorizationLinkDocument,
    {
      onCompleted(data) {
        const authorizationLink = data.createDiscordAuthorizationLink
        const url = new URL(authorizationLink)

        const token = uuidv4()
        const aState = JSON.stringify({
          token,
          pendingUserId,
        })

        url.searchParams.set('state', btoa(aState))
        discordOAuthStateStorage.save(token)
        openInInternalBrowserOrRedirect(url.toString())
      },
      onError(error) {
        setErrorState(error)
      },
    }
  )

  const [exchangeDiscordCode, { loading: loadingExchangeCode }] = useMutation(
    ExchangeDiscordCodeDocument,
    {
      onCompleted(data) {
        logInToSecured(data.exchangeDiscordCode)
        removeCookie('pending_user_id')
        // TODO: Proper logging for multiple accounts
        if (data.exchangeDiscordCode.__typename === 'LoginResponse') {
          Analytics.discordConnected({
            subscriptionType: data.exchangeDiscordCode.user.subscriptionType,
            userSubscriptionProductType:
              data.exchangeDiscordCode.session.subscriptionProductType,
          })
        }
        setLoginState(ConnectDiscordState.connected)
      },
      onError(error) {
        console.error(error)
        setErrorState(error)
      },
    }
  )

  const setErrorState = useCallback(
    (error: ApolloError | Error) => {
      const hasAnError = error !== null && error !== undefined
      setHasError(hasAnError)

      const theLoginState = error
        ? ConnectDiscordState.failed
        : ConnectDiscordState.default
      const parsedErrorMessage = parseError(error)

      setErrorMessage(parsedErrorMessage)
      setLoginState(theLoginState)

      if (
        error instanceof ApolloError &&
        (error.graphQLErrors[0].extensions?.code ===
          ErrorCode.pendingUserToUserMismatch ||
          error.graphQLErrors[0].extensions?.code ===
            ErrorCode.userMissingSubscription)
      ) {
        removeCookie('pending_user_id')
      }
    },
    [removeCookie]
  )

  useEffect(() => {
    if (!code || !state) {
      return
    }

    const parsedState = JSON.parse(atob(state)) as any

    const currentToken = discordOAuthStateStorage.get()
    discordOAuthStateStorage.clear()

    if (currentToken !== parsedState.token) {
      setErrorState(new Error('State token does not match.'))

      return
    }

    exchangeDiscordCode({
      variables: {
        input: {
          code,
          redirectUrl: DISCORD_CHECKOUT_REDIRECT_URL,
          pendingUserId: parsedState.pendingUserId,
        },
      },
    })
  }, [code, exchangeDiscordCode, setErrorState, state])

  const isLoading = loadingLoginWithDiscord || loadingExchangeCode

  const isConfettiVisible = loginState === ConnectDiscordState.default && !code

  return (
    <>
      <ExternalLayout headerActionsType="none" isFooterVisible={false}>
        <div className={styles['checkout-confirmation-page']}>
          <div className={styles['checkout-confirmation-page__header']}>
            <h1 className={styles['checkout-confirmation-page__header__h1']}>
              Welcome to Secured
            </h1>
            <p
              className={
                styles['checkout-confirmation-page__header__subheading']
              }
            >
              Complete your account setup below
            </p>
          </div>
          <ConnectDiscord
            className={styles['checkout-confirmation-page__connect-discord']}
            isButtonDisabled={isLoading}
            error={errorMessage}
            state={loginState}
            onConnectDiscord={() => {
              loginWithDiscord({
                variables: {
                  redirectUrl: DISCORD_CHECKOUT_REDIRECT_URL,
                },
              })
            }}
          />
          {loginState === 'connected' && (
            <Button
              className={styles['checkout-confirmation-page__continue-button']}
              styleType="fill"
              colour="primary"
              to="/"
            >
              Continue
            </Button>
          )}
        </div>
      </ExternalLayout>

      {isConfettiVisible && <SchoolPrideConfetti />}
    </>
  )
}

export default CheckoutConfirmation
